import React, { useCallback, useState, useMemo } from "react";
import { objectOf, any, func, string } from "prop-types";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Select from "antd/es/select";
import Typography from "antd/es/typography/Typography";

const { Text } = Typography;
const { Option } = Select;

const ContactAssignMember = ({
  buttonSize,
  contact,
  teamMembers,
  onAssignMember
}) => {
  const [visible, setVisible] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(contact.memberNotifyId);

  const toggle = useCallback(() => {
    setVisible(!visible);
    setSelectedMemberId(contact.memberNotifyId);
  }, [visible, setVisible, setSelectedMemberId]);

  const save = useCallback(() => {
    if (selectedMemberId) {
      onAssignMember(contact.id, contact.relationshipId, selectedMemberId, () => {
        toggle();
      });
    }
  }, [contact, selectedMemberId, toggle]);

  const members = useMemo(() => Object.values(teamMembers), [teamMembers]);

  const teamMember = teamMembers[contact.memberNotifyId];

  return (
    <div>
      <div className="center-v">
        <div style={{ marginRight: 6 }}>
          <Text>
          {teamMember ?
            `Assigned to: ${[teamMember.first_name, teamMember.last_name].join(" ")}` :
            'Not Assigned Yet'}
          </Text>
        </div>

        <Button size={buttonSize} type="primary" onClick={toggle}>
          Change
        </Button>
      </div>

      <Modal
        title="Assign Team Member"
        visible={visible}
        confirmLoading={!!contact.assigningMember}
        onCancel={toggle}
        onOk={save}
      >
        <Select
          showSearch
          placeholder="Select a Team Member"
          style={{ width: "100%" }}
          value={selectedMemberId}
          onChange={id => setSelectedMemberId(id)}
        >
          {members.map(member => (
            <Option key={member.notify_id} value={member.notify_id}>
              {[member.first_name, member.last_name].join(" ")}
            </Option>
          ))}
        </Select>
      </Modal>
    </div>
  )
};

ContactAssignMember.propTypes = {
  buttonSize: string,
  contact: objectOf(any).isRequired,
  teamMembers: objectOf(any).isRequired,
  onAssignMember: func.isRequired
};

ContactAssignMember.defaultProps = {
  buttonSize: "default"
};

export default ContactAssignMember;
