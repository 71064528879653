import lodash from "lodash";
import * as firebase from "firebase/app";
import "firebase/firestore";

import configureStore from "../store/configureStore";
import { firebaseStorage } from "./firebaseSetting";
import { accountSlug, uuidv4 } from "../utils/other";
import { addMessage, updateMessage, removeMessage } from "../actions/messages";
import { fetchWorkflowTasksContacts } from "../actions/tasks";
import {
  setThreads,
  setScheduledConversations,
  removeScheduledConversations,
} from "../actions/conversations";
import { ADD_LIST_SUCCESS, UPDATE_LIST_ATTRS } from "../constants";

const MESSAGES_LIMIT = 10;
const THREADS_LIMIT = 1000;
const LISTS_LIMIT = 300;

export const getTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();

export const getRefRootDoc = (org = accountSlug()) =>
  firebase.firestore().collection("orgs").doc(org);

export const getRef = (name, org = accountSlug()) =>
  firebase.firestore().collection("orgs").doc(org).collection(name);

export const uploadFile = async (dataFile) => {
  const sessionId = new Date().getTime();
  const storageRef = firebaseStorage.ref().child(`${sessionId}`);
  let url = null;

  try {
    const snapshot = await storageRef.put(dataFile);
    url = await snapshot.ref.getDownloadURL();
  } catch (error) {
    console.log(error);
  }

  return url;
};

let refreshTime = null;
const runRefreshWorkflowTasks = () => {
  if (refreshTime) {
    clearTimeout(refreshTime);
    refreshTime = null;
  }

  refreshTime = setTimeout(() => {
    FirestoreService.dispatch(fetchWorkflowTasksContacts());
  }, 1500);
};

class FirestoreService {
  _teamMembersRef = null;
  _messagesRef = null;
  _threadsRef = null;
  _listsRef = null;
  _subscribes = {};
  _lastDoc = null;

  static get dispatch() {
    return configureStore.dispatch;
  }

  static get state() {
    return configureStore.getState();
  }

  _buildData = (doc) => {
    const messageData = doc.data();
    const scheduledAt =
      messageData.deliveryStatus === "scheduled" && messageData.scheduledAt
        ? messageData.scheduledAt.toDate()
        : null;

    return {
      ...messageData,
      scheduledAt,
      createdAt: messageData.createdAt ? messageData.createdAt.toDate() : new Date(),
      key: doc.id,
      refId: doc.id,
    };
  };

  _messagesSnapshot = (querySnapshot) => {
    let needToRefreshTasks = false;

    lodash.forEach(querySnapshot.docChanges(), (change) => {
      const message = this._buildData(change.doc);

      if (change.type === "added" && message.deliveryStatus !== "hidden") {
        FirestoreService.dispatch(addMessage(message));
      }
      if (change.type === "modified") {
        FirestoreService.dispatch(updateMessage(message));

        if (message.wfResponseId) {
          needToRefreshTasks = true;
        }
      }
      if (change.type === "removed") {
        FirestoreService.dispatch(removeMessage(message));
      }
    });

    if (needToRefreshTasks) {
      runRefreshWorkflowTasks();
    }
  };

  _scheduledMessagesSnapshot = (querySnapshot) => {
    const idsForSetting = [];
    const idsForRemoving = [];

    lodash.forEach(querySnapshot.docChanges(), (change) => {
      const message = this._buildData(change.doc);

      if (change.type === "added" || change.type === "modified") {
        idsForSetting.push(message.threadId);
      }
      if (change.type === "removed") {
        idsForRemoving.push(message.threadId);
      }
    });

    if (idsForSetting.length) {
      FirestoreService.dispatch(setScheduledConversations(lodash.uniq(idsForSetting)));
    }
    if (idsForRemoving.length) {
      FirestoreService.dispatch(removeScheduledConversations(lodash.uniq(idsForRemoving)));
    }
  };

  _threadsSnapshot = ({ docs }) => {
    console.log("THREADS UPDATE");
    const newThreads = lodash.map(docs, (doc) => ({
      ...doc.data(),
      id: doc.id,
      lastContactMessageAt: doc.data().lastContactMessageAt
        ? doc.data().lastContactMessageAt.toDate()
        : new Date(),
    }));
    FirestoreService.dispatch(setThreads(newThreads));
  };

  _listSnapshot = (querySnapshot) => {
    lodash.forEach(querySnapshot.docChanges(), (change) => {
      const listData = change.doc.data();

      if (change.type === "added") {
        FirestoreService.dispatch({
          type: ADD_LIST_SUCCESS,
          list: {
            ...listData,
            id: change.doc.id,
            createdAt: listData.createdAt && listData.createdAt.toDate(),
          },
        });
      }

      if (change.type === "modified") {
        FirestoreService.dispatch({
          type: UPDATE_LIST_ATTRS,
          listId: change.doc.id,
          attr: {
            ...listData,
            createdAt: listData.createdAt && listData.createdAt.toDate(),
          },
        });
      }

      if (change.type === "removed") {
      }
    });
  };

  // Public methods
  setupListeners = (org = null) => {
    if (org === null) {
      org = accountSlug();
    }
    // Unsubscribe before subscribe
    this.unsubscribeListenersForOrg();

    this._messagesRef = getRef("messages", org);
    this._threadsRef = getRef("threads", org);
    this._teamMembersRef = getRef("team_members", org);
    this._listsRef = getRef("lists");

    // Subscribing
    this._subscribes.messages = this._messagesRef
      .orderBy("createdAt", "desc")
      .limit(MESSAGES_LIMIT)
      .onSnapshot(this._messagesSnapshot);

    this._subscribes.scheduleMessages = this._messagesRef
      .where("deliveryStatus", "==", "scheduled")
      .limit(THREADS_LIMIT)
      .onSnapshot(this._scheduledMessagesSnapshot);

    this._subscribes.threads = this._threadsRef
      .orderBy("createdAt", "desc")
      .limit(THREADS_LIMIT)
      .onSnapshot(this._threadsSnapshot);

    this._subscribes.lists = this._listsRef
      .orderBy("createdAt", "desc")
      .limit(LISTS_LIMIT)
      .onSnapshot(this._listSnapshot);
  };

  loadingMessages = async (threadId, prevMessage = false) => {
    let cursor = this._messagesRef
      .where("threadId", "==", threadId)
      .limit(MESSAGES_LIMIT)
      .orderBy("createdAt", "desc");

    if (prevMessage && this._lastDoc) {
      cursor = cursor.startAfter(this._lastDoc);
    }

    let querySnapshot;
    try {
      querySnapshot = await cursor.get();
    } catch (error) {
      console.log(error);
      return;
    }

    const messages = lodash.map(querySnapshot.docs, (doc) => this._buildData(doc));

    this._lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    return {
      messages,
      hasPrevPage: messages.length >= MESSAGES_LIMIT,
    };
  };

  sendMessage = async (
    threadId,
    author,
    { bodyText = "", imageUrl = null, wfResponseId = null, scheduledAt = null }
  ) => {
    if (threadId) {
      if (scheduledAt) {
        scheduledAt = firebase.firestore.Timestamp.fromDate(scheduledAt);
      }

      await this._messagesRef.add({
        threadId,
        bodyText: bodyText || "",
        imageUrl,
        wfResponseId,
        scheduledAt,
        directionState: "outgoing",
        teamMemberId: author,
        deliveryStatus: scheduledAt ? "scheduled" : "created",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  };

  createNewConversation = async (contactAddress, teamMemberAddress, teamMemberId, contactId) => {
    const newThreadDocRef = getRef("threads").doc();

    const orgNewThreadPermissionDefault =
      (await FirestoreService.state.application.orgNewThreadPermissionDefault) || "public";
    const members = orgNewThreadPermissionDefault === "public" ? [] : [teamMemberId];

    await newThreadDocRef.set({
      contactAddress,
      teamMemberAddress,
      contactId,
      lastAuthorAddress: teamMemberAddress,
      contactPlatform: "SMS",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      lastContactMessageAt: firebase.firestore.FieldValue.serverTimestamp(),
      lastMessageText: null,
      replyState: "Answered",
      replyStateAlteredBy: null,
      unreadCount: 0,
      threadId: newThreadDocRef.id,
      notifications: [{ id: teamMemberId, muted: false }],
      archived: false,
      members,
    });

    return newThreadDocRef.id;
  };

  unsubscribeListenersForOrg = () => {
    const keys = Object.keys(this._subscribes);
    keys.forEach((key) => {
      this._subscribes[key]();
    });
  };

  deleteMessage = async (id) => {
    try {
      const result = await getRef("messages").doc(id).get();
      const { threadId } = result.data();

      await getRef("messages").doc(id).delete();
      FirestoreService.dispatch(removeMessage({ threadId, refId: id }));
    } catch (e) {
      console.log("Error with deleting message: ", e);
    }
  };

  updateMessage = async (id, attrs) => {
    if (attrs.scheduledAt) {
      attrs.scheduledAt = firebase.firestore.Timestamp.fromDate(attrs.scheduledAt);
    }

    try {
      await getRef("messages").doc(id).update(attrs);
    } catch (e) {
      console.log("Error with updating message: ", e);
    }
  };

  setArchiveThread = async (threadId, archived = true) => {
    if (!threadId) {
      return;
    }

    try {
      await this._threadsRef.doc(threadId).update({ archived });
    } catch (e) {
      console.log(e);
    }
  };

  setTeamMemberForwardingPhoneNumber = async (notifyId, forwardingPhoneNumber) => {
    if (!notifyId) {
      return;
    }
    try {
      await this._teamMembersRef.doc(notifyId).set(
        {
          forwardingPhoneNumber,
        },
        { merge: true }
      );
    } catch (e) {
      console.log(e);
    }
  };

  setOrgForwardingPhoneNumber = async (org, forwardingPhoneNumber) => {
    try {
      await firebase.firestore().collection("orgs").doc(org).set(
        {
          forwardingPhoneNumber,
        },
        { merge: true }
      );
    } catch (e) {
      console.log(e);
    }
  };

  getOrgPhoneList = async (org) => {
    try {
      const result = await firebase.firestore().collection("orgs").doc(org).get();
      const orgPhoneList = result.data().orgPhoneList || [];
      const orgPhoneName = result.data().orgPhoneName || "";
      const orgNewThreadPermissionDefault = result.data().orgNewThreadPermissionDefault || "public";
      const orgForwardingPhoneNumber = result.data().forwardingPhoneNumber || "";
      return {
        orgPhoneList,
        orgPhoneName,
        orgNewThreadPermissionDefault,
        orgForwardingPhoneNumber,
      };
    } catch (e) {
      console.log(e);
    }
  };

  setThreadNotification = async (threadId, memberId, state) => {
    let thread;
    let doc;
    try {
      doc = await this._threadsRef.doc(threadId);
      const record = await doc.get();
      thread = record.data();
    } catch (e) {
      return false;
    }

    const notifications = thread.notifications || [];
    const idx = lodash.findIndex(notifications, { id: memberId });
    if (idx === -1) {
      notifications.push({ id: memberId, muted: state });
    } else {
      notifications[idx].muted = state;
    }

    try {
      await doc.update({ notifications });
    } catch (error) {
      console.log("==================");
      console.log(error);
      return false;
    }

    return true;
  };

  setThreadMemberAccess = async (threadId, members) => {
    let doc;
    try {
      doc = await this._threadsRef.doc(threadId);
    } catch (e) {
      return false;
    }

    try {
      await doc.update({ members });
    } catch (error) {
      console.log("==================");
      console.log(error);
      return false;
    }

    return true;
  };
}

const firestoreService = new FirestoreService();

export default firestoreService;
