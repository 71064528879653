import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Tasks from "./Tasks";
import { fetchWorkflows } from "../../../actions/workflow";
import {
  setCurrentTemplate,
  setCurrentContactIndex,
  fetchWorkflowTasksContacts,
  fetchWorkflowContacts,
  startTask
} from "../../../actions/tasks";

const mapStateToProps = ({
  workflow: { workflowsById, workflows, loadingWorkflows },
  tasks: { tasks, loadingTasks },
  contacts: { fetching },
  application: { org }
}) => ({
  tasks,
  workflowsById,
  org,
  loading:
    (loadingWorkflows && workflows.length === 0) || (loadingTasks && tasks.length === 0) || fetching
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchWorkflowTasksContacts,
      fetchWorkflowContacts,
      fetchWorkflows,
      startTask,
      setCurrentTemplate,
      setCurrentContactIndex
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
