import React from "react";
import Avatar from "antd/es/avatar";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
import { string, arrayOf, objectOf, any } from "prop-types";

import { toShortName } from "../../../../utils/string";

const { Paragraph, Title } = Typography;

const Info = ({ avatar, name, tags, children }) => (
  <div className="inbox-conversation-info">
    <div className="center-v">
      <div>
        <Avatar size="large" src={avatar}>
          {toShortName(name)}
        </Avatar>
      </div>

      <div>
        <Title level={4} style={{ marginBottom: 0 }}>
          {name}
        </Title>

        {tags.length ? (
          <Paragraph>
            {tags.map(tag => <Tag key={tag} color="volcano">{tag}</Tag>)}
          </Paragraph>
        ) : null}
      </div>
    </div>

    {children}
  </div>
);

Info.propTypes = {
  avatar: string,
  name: string,
  tags: arrayOf(string)
};

Info.defaultProps = {
  avatar: null,
  name: null,
  tags: null
};

export default Info;
