import React, { useCallback, useState } from "react";
import lodash from "lodash";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import message from "antd/es/message";

import * as firebase from "firebase/app";
import "firebase/auth";

const FormItem = Form.Item;

const reauthenticate = password => {
  const user = firebase.auth().currentUser;
  const cred = firebase.auth.EmailAuthProvider.credential(user.email, password);

  return user.reauthenticateWithCredential(cred);
};

const ChangePassword = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const toggle = useCallback(() => {
    setErrors({});
    setCurrentPassword("");
    setNewPassword("");
    setLoading(false);
    setVisible(!visible);
  }, [
    visible,
    setVisible,
    setErrors,
    setCurrentPassword,
    setNewPassword,
    setLoading
  ]);

  const changeCurrentPassword = useCallback(({ target: { value } }) => {
    setCurrentPassword(value)
    setErrors({});
  }, [setCurrentPassword, setErrors]);

  const changeNewPassword = useCallback(({ target: { value } }) => {
    setNewPassword(value);
    setErrors({});
  }, [setNewPassword, setErrors]);

  const submit = useCallback(e => {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    (async () => {
      try {
        await reauthenticate(currentPassword);
      } catch (e) {
        setLoading(false);
        setErrors({ currentPassword: "Password is incorrect" });
        return;
      }

      try {
        const user = firebase.auth().currentUser;
        await user.updatePassword(newPassword);
      } catch (e) {
        setErrors({ newPassword: lodash.get(e, 'message', 'Password is incorrect') });
        setLoading(false);
        return;
      }

      toggle();
      message.success("Password Changed");
    })();
  }, [loading, currentPassword, newPassword, setLoading, setErrors, toggle]);

  return (
    <>
      <Modal
        title="Change Password"
        okText="Save"
        closable={false}
        confirmLoading={loading}
        visible={visible}
        onOk={submit}
        onCancel={toggle}
      >
        <Form onSubmit={submit}>
          <FormItem
            label="Current Password"
            validateStatus={errors.currentPassword ? "error" : null}
            help={errors.currentPassword}
          >
            <Input type="password" value={currentPassword} onChange={changeCurrentPassword} />
          </FormItem>
          <FormItem
            label="New Password"
            validateStatus={errors.newPassword ? "error" : null}
            help={errors.newPassword}
          >
            <Input type="password" value={newPassword} onChange={changeNewPassword} />
          </FormItem>
          <Button htmlType="submit" style={{ display: "none" }}/>
        </Form>
      </Modal>
      <Button type="primary" onClick={toggle}>
        Change Password
      </Button>
    </>
  );
};

export default ChangePassword;
