import { connect } from "react-redux";

import ContactInfo from "../../Inbox/Full/ContactInfo/ContactInfo";

const mapStateToProps = ({
  contacts: { contactsById },
  tasks: { currentTaskContacts, currentContactIndex }
}) => {
  if (!currentTaskContacts[currentContactIndex]) {
    return {};
  }

  const contact = contactsById[currentTaskContacts[currentContactIndex].notifyId];

  return {
    contact,
    contactsById,
    conversationSelected: true
  };
};


export default connect(mapStateToProps)(ContactInfo);
