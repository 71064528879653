import message from "antd/es/message";

import { getTeamMembers } from "../services/api/teamMember";
import { getOrgTags, updateOrgTags } from "../services/api/account";
import { getOrg } from "../services/api/orgs";
import { getRefRootDoc, getRef } from "../services/firestoreService";
import { accountSlug } from "../utils/other";
import {
  FETCH_APP_DATA_BEGIN,
  FETCH_APP_DATA_SUCCESS,
  FETCH_APP_DATA_FAILURE,
  UPDATE_APP_TAGS,
  TOGGLE_VISIBLE_NEW_CONVERSATION,
  HIDE_NEW_CONVERSATION,
  APPLICATION_UPDATE_TAGS_BEGIN,
  APPLICATION_UPDATE_TAGS_SUCCESS,
  APPLICATION_UPDATE_TAGS_FAILURE,
  APPLICATION_SET_GLOBAL_LOADING,
  APPLICATION_SET_ORG_TIMEZONE,
  DEFAULT_TIMEZONE,
} from "../constants";

export const fetchTags = () => async (dispatch) => {
  try {
    const tags = await getOrgTags();
    dispatch({ type: UPDATE_APP_TAGS, tags });
  } catch (e) {}
};

export const fetchAppData = () => async (dispatch) => {
  dispatch({ type: FETCH_APP_DATA_BEGIN });

  try {
    const result = await Promise.all([await getTeamMembers(), await getOrgTags(), await getOrg()]);
    const firestoreTeamMembersQuery = await getRef("team_members").get();
    const firestoreTeamMembers = {};
    firestoreTeamMembersQuery.forEach(function (doc) {
      firestoreTeamMembers[doc.id] = doc.data();
    });

    console.log("FIRESTORE TEAM MEMBERS", firestoreTeamMembers);
    dispatch({
      type: FETCH_APP_DATA_SUCCESS,
      teamMembers: result[0],
      tags: result[1],
      org: result[2],
      firestoreTeamMembers,
    });
  } catch (error) {
    dispatch({ type: FETCH_APP_DATA_FAILURE, error });
  }
};

export const toggleVisibleStartNewConversation = () => ({
  type: TOGGLE_VISIBLE_NEW_CONVERSATION,
});

export const hideStartNewConversation = () => ({
  type: HIDE_NEW_CONVERSATION,
});

export const setGlobalLoading = (loading) => ({
  type: APPLICATION_SET_GLOBAL_LOADING,
  loading,
});

export const updateTags = (data) => async (dispatch) => {
  dispatch({ type: APPLICATION_UPDATE_TAGS_BEGIN });
  try {
    await updateOrgTags(data);
  } catch (error) {
    dispatch({ type: APPLICATION_UPDATE_TAGS_FAILURE, error });
    return;
  }

  message.success("Updated");
  dispatch({ type: APPLICATION_UPDATE_TAGS_SUCCESS, tags: data.tags });
};

export const fetchTimezone = () => async (dispatch) => {
  const firestoreOrg = getRefRootDoc(accountSlug());
  try {
    const result = await firestoreOrg.get();
    dispatch({
      type: APPLICATION_SET_ORG_TIMEZONE,
      timezone: result.data().timezone || DEFAULT_TIMEZONE,
    });
  } catch (err) {}
};

export const updateTimezone = (newTimezone) => async (dispatch) => {
  dispatch({ type: APPLICATION_SET_ORG_TIMEZONE, timezone: newTimezone });

  const firestoreOrg = getRefRootDoc(accountSlug());
  await firestoreOrg.set({ timezone: newTimezone }, { merge: true });
};
