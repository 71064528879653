import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import StoreVisit from "./StoreVisit";
import { addTimelineEvent } from "../../../../actions/contacts";

const mapStateToProps = ({
  contacts: { currentContactId }
}) => ({
  contactId: currentContactId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addTimelineEvent
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StoreVisit);
