import React, { useCallback } from "react";
import Select from "antd/es/select";
import MaskedInput from "react-text-mask";
import { string, func } from "prop-types";

import dataInfo from "./data";

const Option = Select.Option;

const Phone = ({
  label,
  value,
  onChangeLabel,
  onChange
}) => {
  const callbackChange = useCallback(e => {
    onChange(e.target.value);
  }, [onChange]);

  let labels = dataInfo.labels.phones;
  if (label && !labels.includes(label)) {
    labels.push(label);
  }

  return (
    <span className="ant-input-group-wrapper group-with-date">
      <span className="ant-input-wrapper ant-input-group">
        <span className="ant-input-group-addon">
          <Select
            placeholder="Select Label"
            style={{ width: 200 }}
            value={label}
            onChange={onChangeLabel}
          >
            {labels.map(l => (
              <Option key={l} value={l}>{l}</Option>
            ))}
          </Select>
        </span>

        <MaskedInput
          placeholder="Enter number"
          className="ant-input"
          mask={["+", "1", "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
          value={value}
          onChange={callbackChange}
        />
      </span>
    </span>
  );
};

Phone.propTypes = {
  label: string,
  value: string.isRequired,
  onChangeLabel: func.isRequired,
  onChange: func.isRequired
};

Phone.defaultProps = {
  label: null
};

export default Phone;
