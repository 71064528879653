import lodash from "lodash";

import {
  WORKFLOW_FETCH_BEGIN,
  WORKFLOW_FETCH_SUCCESS,
  WORKFLOW_FETCH_FAILURE,
  WORKFLOW_UPDATE_ATTRS
} from "../constants";

const initialState = {
  workflows: [],
  workflowsById: {},
  loadingWorkflows: true,
  loaded: false,
  error: null
};

export default (state = initialState, action) => {
  let workflows;

  switch (action.type) {
    case WORKFLOW_FETCH_BEGIN:
      return {
        ...state,
        loadingWorkflows: true
      };
    case WORKFLOW_FETCH_SUCCESS:
      workflows = lodash.orderBy(
        lodash.values(action.workflows),
        ["created_at"],
        ["desc"]
      );

      return {
        ...state,
        workflows,
        workflowsById: lodash.keyBy(workflows, "id"),
        loadingWorkflows: false,
        loaded: true
      };
    case WORKFLOW_FETCH_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingWorkflows: false
      };
    case WORKFLOW_UPDATE_ATTRS:
      const workflowsById = {
        ...state.workflowsById,
        [action.id]: {
          ...state.workflowsById[action.id],
          ...action.attrs
        }
      };

      workflows = lodash.orderBy(
        lodash.values(workflowsById),
        ["created_at"],
        ["desc"]
      );

      return {
        ...state,
        workflows,
        workflowsById
      };
    default:
      return {
        ...state
      };
  }
};
