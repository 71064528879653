import request from "../request";
import { accountSlug, accountId } from "../../utils/other";

const getProfile = async () =>
  await request({
    url: "/profile/",
    method: "GET"
  });

const getTeamMembers = async () =>
  await request({
    url: `/org/${accountSlug()}/team_member/`,
    method: "GET",
    params: {
      bot: accountId()
    }
  });

const setTeamMemberMobileNumber = async (orgSlug, mobileNumber, notifyId) =>
  await request({
    url: `/org/${orgSlug}/team_member/${notifyId}/`,
    method: "PATCH",
    data: {
      mobile_number: mobileNumber
    }
  });

const setAvailable = (notifyId, available) => (
  request({
    url: `/org/${accountSlug()}/team_member/${notifyId}/`,
    method: "PATCH",
    data: { is_available: available },
    params: { bot: accountId() }
  })
);

export { getProfile, getTeamMembers, setTeamMemberMobileNumber, setAvailable };
