export const isValid = (name, message, setErrors) => {
  const tmp = {};
  if (!name) {
    tmp.name = "can't be blank";
  }
  if (!message) {
    tmp.message = "can't be blank";
  }
  if (Object.keys(tmp).length) {
    setErrors(tmp);
    return false;
  }

  return true;
};
