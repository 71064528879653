import React, { useCallback, useRef, useState } from "react";
import { string, func } from "prop-types";
import Button from "antd/es/button";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Typography from "antd/es/typography/Typography";
import Spin from "antd/es/spin";
import message from "antd/es/message";

import { getRefRootDoc } from "../../services/firestoreService";
import { phoneNumberVerifyRequest, phoneNumberVerifyCode } from "../../services/api/onboarding";

const { Title } = Typography;

const LENGTH = 6;

const Confirmation = ({ phoneNumber, onSuccess }) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const inputRef = useRef(null);

  const checkAndUpdate = useCallback(() => {
    setLoading(true);
    (async () => {
      // Checking code.
      try {
        const verifyResult = await phoneNumberVerifyCode(phoneNumber, code);
        if (verifyResult.status !== "success") {
          message.error("Code is incorrect.");
          setLoading(false);
          inputRef.current.focus()
          return;
        }
      } catch (error) {
        message.error("Problem verifying the code, please try again.");
        setLoading(false);
        inputRef.current.focus();
        return;
      }

      onSuccess(phoneNumber, () => {
        setLoading(false);
      });
    })();
  }, [inputRef, phoneNumber, code, setLoading]);

  const change = useCallback(({ target: { value } }) => {
    setCode(value);
  }, [setCode]);

  const resend = useCallback(() => {
    setResending(true);
    (async () => {
      try {
        await phoneNumberVerifyRequest(phoneNumber);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
        setResending(false);
        return;
      }
      message.success("Code resent");
      setResending(false);
    })();
  }, [phoneNumber, setResending]);

  return (
    <div>
      <Title level={4}>
        Please enter the verification code {loading ? <Spin /> : null}
      </Title>

      <Row gutter={12}>
        <Col span={17}>
          <Input
            ref={inputRef}
            maxLength={LENGTH}
            value={code}
            disabled={loading || resending}
            onChange={change}
          />
        </Col>
        <Col span={7}>
          <Button
            block
            type="primary"
            disabled={resending}
            loading={loading}
            onClick={checkAndUpdate}
          >
            Confirm
          </Button>
        </Col>
      </Row>
      <br/>

      <div style={{ textAlign: "center" }}>
        <Button type="link" loading={resending} disabled={loading} onClick={resend}>
          Resend Code
        </Button>
      </div>
    </div>
  );
};

Confirmation.propTypes = {
  phoneNumber: string.isRequired,
  onSuccess: func.isRequired
};

export default Confirmation;
