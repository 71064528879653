import React, { useCallback } from "react";
import PageHeader from "antd/es/page-header";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { string, any, objectOf } from "prop-types";

import "./style.css";
import browserHistory from "../../browserHistory";

const Wrapper = ({ children, className, title, backUrl, footer, extra }) => {
  const goBack = useCallback(
    () => {
      browserHistory.replace(backUrl);
    }, [backUrl]
  );

  return (
    <Row className={className}>
      <Col span={20} offset={2}>
        <div className="wrapper">
          {title ? (
            <PageHeader
              title={title}
              style={{ marginBottom: 2 }}
              onBack={backUrl ? goBack : null}
              footer={footer}
              extra={extra}
            />
          ) : null}

          {children ? (
            <div className="content">
              {children}
            </div>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};


Wrapper.propTypes = {
  className: string,
  title: string,
  backUrl: string,
  footer: objectOf(any),
  extra: objectOf(any)
};

Wrapper.defaultProps = {
  className: "",
  title: null,
  backUrl: null,
  footer: null,
  extra: null
};

export default Wrapper;
