import {
  WORKFLOW_FETCH_BEGIN,
  WORKFLOW_FETCH_SUCCESS,
  WORKFLOW_FETCH_FAILURE,
  WORKFLOW_UPDATE_ATTRS
} from "../constants";

import { getWorkflows, setWorkflowStatus, getWorkflowSummary } from "../services/api/workflow";

export const fetchWorkflows = () => async dispatch => {
  dispatch({ type: WORKFLOW_FETCH_BEGIN });

  try {
    const workflows = await getWorkflows();
    dispatch({ type: WORKFLOW_FETCH_SUCCESS, workflows });
  } catch (error) {
    dispatch({ type: WORKFLOW_FETCH_FAILURE, error });
  }
};

export const updateWorkflowStatus = (id, state) => async dispatch => {
  let status = state ? "active" : "disabled";

  await dispatch({ id, type: WORKFLOW_UPDATE_ATTRS, attrs: { toggleBusy: true } });

  try {
    const result = await setWorkflowStatus(id, status);
    status = result.status;
  } catch (error) {
    console.log(error);
    dispatch({ id, type: WORKFLOW_UPDATE_ATTRS, attrs: { toggleBusy: false } });
    return;
  }

  dispatch({
    id,
    type: WORKFLOW_UPDATE_ATTRS,
    attrs: { toggleBusy: false, status }
  });
};

export const fetchSummary = id => async dispatch => {
  dispatch({
    id,
    type: WORKFLOW_UPDATE_ATTRS,
    attrs: { loadingSummary: true }
  });

  let summaryDetails;
  try {
    summaryDetails = await getWorkflowSummary(id);
  } catch (error) {
    dispatch({
      id,
      type: WORKFLOW_UPDATE_ATTRS,
      attrs: { error, loadingSummary: false }
    });
    return;
  }

  dispatch({
    id,
    type: WORKFLOW_UPDATE_ATTRS,
    attrs: { summaryDetails, loadingSummary: false }
  });
};
