import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AddList from "./AddList";
import { addList } from "../../actions/lists";

const mapStateToProps = ({
  lists: { loading }
}) => ({
  loading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onAdd: addList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddList);
