import React  from "react";

import Title from "../../../components/Title";
import AdminTopNavigation from "../../../components/AdminTopNavigation";
import Wrapper from "../../../components/Wrapper";

const Dashboard = () => (
  <div>
    <Title title="Admin Dashboard" />
    <AdminTopNavigation />

    <Wrapper>
      <div>
        Coming soon
      </div>
    </Wrapper>
  </div>
);

export default Dashboard;
