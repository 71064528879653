import React, { useMemo, useState, useCallback } from 'react';
import { arrayOf, any, string, func } from "prop-types";
import Table from 'antd/es/table';
import Input from 'antd/es/input';

import columns from './columns';
import { getContactsSearchResults } from '../../utils/contacts';

const { Search } = Input;

const SelectContacts = ({ selectedKeys, contacts, onChange }) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');

  const changeQuery = useCallback(({ target: { value } }) => {
    setQuery(value);
    setPage(1);
  }, []);

  const dataSource = useMemo(() => {
    if (query) {
      return getContactsSearchResults(contacts, query);
    }

    return contacts;
  }, [contacts, query]);

  return (
    <>
      <Search
        placeholder="Search"
        value={query}
        onChange={changeQuery}
      />
      <br/>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: 10,
          current: page,
          onChange: p => setPage(p),
        }}
        rowSelection={{
          selectedRowKeys: selectedKeys,
          onChange: onChange
        }}
      >
      </Table>
    </>
  );
};

SelectContacts.propTypes = {
  contacts: arrayOf(any).isRequired,
  selectedKeys: arrayOf(string),
  onChange: func.isRequired,
};

SelectContacts.defaultProps = {
  selectedKeys: [],
};

export default SelectContacts;
