import React, { useCallback, useState } from "react";
import moment from "moment";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Form from "antd/es/form";
import DatePicker from "antd/es/date-picker";
import Divider from "antd/es/divider";
import { func, string } from "prop-types";

const Purchase = ({ contactId, addTimelineEvent, onDone }) => {
  const [bought, setBought] = useState("");
  const [price, setPrice] = useState("");
  const [comments, setComments] = useState("");
  const [datePurchased, setDatePurchased] = useState(moment());
  const [loading, setLoading] = useState(false);

  const callbackChangeBought = useCallback(e => {
    setBought(e.target.value);
  }, [setBought]);

  const callbackChangePrice = useCallback(e => {
    setPrice(e.target.value);
  }, [setPrice]);

  const callbackChangeComments = useCallback(e => {
    setComments(e.target.value);
  }, [setComments]);

  const callbackChangeDatePurchased = useCallback(d => {
    setDatePurchased(d);
  }, [setDatePurchased]);

  const callbackSend = useCallback(() => {
    const data = {
      name: "Purchase",
      value: {
        description: bought,
        total_price: price,
        comments: comments
      },
      whenAt: moment(datePurchased).toISOString()
    };

    setLoading(true);
    addTimelineEvent(contactId, data)
      .then(() => {
        setLoading(false);
        setBought("");
        setPrice("");
        setComments("");
        setDatePurchased(moment());

        onDone();
      });
  }, [
    contactId,
    bought,
    price,
    comments,
    datePurchased,
    setBought,
    setPrice,
    setComments,
    setDatePurchased,
    setLoading,
    addTimelineEvent,
    onDone
  ]);

  return (
    <Form>
      <Form.Item label="Items Bought">
        <Input
          disabled={loading}
          value={bought}
          onChange={callbackChangeBought}
        />
      </Form.Item>

      <Form.Item label="Total Price">
        <Input
          disabled={loading}
          value={price}
          onChange={callbackChangePrice}
        />
      </Form.Item>

      <Form.Item label="Comments">
        <Input.TextArea
          disabled={loading}
          value={comments}
          onChange={callbackChangeComments}
        />
      </Form.Item>

      <Form.Item label="Date Purchased">
        <DatePicker
          disabled={loading}
          value={datePurchased}
          onChange={callbackChangeDatePurchased}
        />
      </Form.Item>

      <Divider />

      <Button
        block
        type="primary"
        loading={loading}
        disabled={!bought && !price && !comments && !datePurchased}
        onClick={callbackSend}
      >
        Add Purchase
      </Button>
    </Form>
  );
};

Purchase.propTypes = {
  contactId: string.isRequired,
  addTimelineEvent: func.isRequired,
  onDone: func.isRequired
};

export default Purchase;
