import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import StartNewConversation from "./StartNewConversation";
import { createContactAndConversation } from "../../actions/conversations";
import { toggleVisibleStartNewConversation } from "../../actions/application";

const mapStateToProps = ({
  application: { visibleStartNewConversation },
  contacts: { contacts }
}) => ({
  contacts,
  visible: visibleStartNewConversation
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onCreateContactAndConversation: createContactAndConversation,
  onClose: toggleVisibleStartNewConversation
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StartNewConversation);
