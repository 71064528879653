import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TaskHandler from "./TaskHandler";
import {
  stopTask,
  fetchWorkflowContacts,
  setCurrentContactIndex,
  setCurrentTemplate
} from "../../actions/tasks";

const mapStateToProps = ({
  workflow: { workflowsById },
  tasks: {
    tasksById,
    currentTask,
    currentTaskStatus,
    currentTaskContacts,
    currentTaskContactsLoading
  }
}) => {
  if (!currentTask) {
    return {};
  }

  const task = tasksById[currentTask];
  const workflow = workflowsById[task.workflow];

  return {
    task,
    workflow,
    loading: currentTaskContactsLoading,
    contacts: currentTaskContacts,
    status: currentTaskStatus,
    starting: true
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  stopTask,
  fetchWorkflowContacts,
  setCurrentContactIndex,
  setCurrentTemplate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaskHandler);
