import moment from "moment";

export const moveScroolToBottom = () => {
  const element = window.document.getElementById("elementMessagesList");
  if (!element) {
    return;
  }

  element.scrollTo(0, element.scrollHeight);
};


export const formatSendDate = sentAt => {
  return moment(sentAt).format("h:mm A");
};

export const formatScheduledDate = sentAt => {
  return moment(sentAt).format("MMM DD h:mm a");
};
