import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getTemplates, addTemplate, updateTemplate } from "../actions/calendar-events-template";

const mapStateToProps = ({
  profile: { calendar },
  calendarEventsTemplate: { loading, busy, templates },
}) => ({
  loading,
  busy,
  templates,
  calendar,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTemplates,
      addTemplate,
      updateTemplate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps);
