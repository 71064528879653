import configureStore from "../store/configureStore";
import firestoreService from "../services/firestoreService";
import { isAuthenticated } from "./auth";
import { fetchProfile } from "../actions/profile";
import { fetchAppData, fetchTimezone } from "../actions/application";
import { fetchLists } from "../actions/lists";
import { fetchContacts } from "../actions/contacts";
import { fetchWorkflows } from "../actions/workflow";
import { fetchOrgs } from "../actions/orgs";
import { beginFetchConversations } from "../actions/conversations";

export const refreshData = async () => {
  if (!isAuthenticated()) {
    return;
  }

  const {
    routing: {
      locationBeforeTransitions: { pathname }
    }
  } = configureStore.getState();

  await configureStore.dispatch(fetchProfile(true));
  await configureStore.dispatch(fetchTimezone());

  if (pathname.includes("/admin")) {
    configureStore.dispatch(fetchOrgs());
  } else {
    await configureStore.dispatch(beginFetchConversations());
    await configureStore.dispatch(fetchAppData());
    await configureStore.dispatch(fetchLists());
    await configureStore.dispatch(fetchContacts());
    await configureStore.dispatch(fetchWorkflows());
    firestoreService.setupListeners();
  }
};
