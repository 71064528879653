import request from "../request";
import { accountSlug } from "../../utils/other";

const getTeamMemberWorkflowTasks = async teamMemberNotifyId => await request({
  url: `/org/${accountSlug()}/team_member/${teamMemberNotifyId}/workflow_tasks/`,
  method: "GET",
  params: {
    team_member: teamMemberNotifyId
  }
});

const getWorkflows = async () => await request({
  url: `/org/${accountSlug()}/workflow/`,
  method: "GET"
});

const getTeamMemberWorkflowTaskResponse = async (
  taskId,
  workflowId,
  teamMemberNotifyId,
  contactState = null
) => await request({
  url: `/org/${accountSlug()}/workflow/${workflowId}/task/${taskId}/response/`,
  method: "GET",
  params: {
    team_member: teamMemberNotifyId,
    state: contactState
  }
});

const setTeamMemberWorkflowTaskResponseStatus = async (
  teamMemberNotifyId,
  workflowId,
  taskId,
  taskResponseId,
  status
) => await request({
  url: `/org/${accountSlug()}/workflow/${workflowId}/task/${taskId}/response/${taskResponseId}/`,
  method: "PATCH",
  data: {
    contact_state: status
  },
  params: {
    team_member: teamMemberNotifyId
  }
});

const setWorkflowStatus = async (workflowId, status) => await request({
  url: `/org/${accountSlug()}/workflow/${workflowId}/`,
  method: "PATCH",
  data: {
    status
  }
});

const tryWorkflowContactList = async data => await request({
  url: `/org/${accountSlug()}/try-contact-list`,
  method: "GET",
  params: {
    property_name: data.propertyName,
    method_type: data.methodType,
    property_value: data.propertyValue,
    contact_type: data.contactType
  }
});

const createWorkflow = async (
  data,
  status = "active",
  isAutomated = true
) => await request({
  url: `/org/${accountSlug()}/workflow/`,
  method: "POST",
  data: {
    ...data,
    status,
    is_automated: isAutomated,
  }
});

const updateWorkflow = async (
  id,
  data,
  status = "active"
) => await request({
  url: `/org/${accountSlug()}/workflow/${id}/`,
  method: "PATCH",
  data: {
    ...data,
    status
  }
});

const createWorkflowResponseOption = async (workflowId, data) => await request({
  url: `/org/${accountSlug()}/workflow/${workflowId}/response-option/`,
  method: "POST",
  data: { ...data, workflow: workflowId }
});

const getWorkflowSummary = async id => await request({
  url: `/org/${accountSlug()}/workflow/${id}/summary/`,
  method: "GET"
});

export {
  getTeamMemberWorkflowTasks,
  getWorkflows,
  getTeamMemberWorkflowTaskResponse,
  setTeamMemberWorkflowTaskResponseStatus,
  setWorkflowStatus,
  tryWorkflowContactList,
  createWorkflow,
  updateWorkflow,
  createWorkflowResponseOption,
  getWorkflowSummary
};
