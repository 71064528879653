import React, { Fragment, useCallback, useState } from "react";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import Button from "antd/es/button";
import { bool, func } from "prop-types";

import { formatPhone, validatePhoneNumber } from "../../../utils/string";

const Item = Form.Item;

const UpdatePhoneNumber = ({ disabledButton, onUpdatePhoneNumber, initialPhoneNumber }) => {
  const [errors, setErrors] = useState({});
  const [visible, setVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(formatPhone(initialPhoneNumber) || "");

  const callbackChangePhoneNumber = useCallback(
    ({ target: { value } }) => {
      setPhoneNumber(formatPhone(value));
      setErrors({});
    },
    [setPhoneNumber]
  );

  const callbackToggle = useCallback(() => {
    setVisible(!visible);
  }, [visible, setVisible]);

  const callbackUpdate = useCallback(() => {
    if (!validatePhoneNumber(phoneNumber)) {
      setErrors({ phoneNumber: "Please enter a valid phone number." });
      return;
    }
    onUpdatePhoneNumber(phoneNumber);
    callbackToggle();
    setPhoneNumber("");
  }, [phoneNumber, setErrors, setPhoneNumber, callbackToggle, onUpdatePhoneNumber]);

  return (
    <Fragment>
      <Button type="link" disabled={disabledButton} onClick={callbackToggle}>
        Edit mobile phone number
      </Button>

      <Modal visible={visible} onCancel={callbackToggle} onOk={callbackUpdate}>
        <Item
          label="Mobile Number"
          help={errors.phoneNumber}
          validateStatus={errors.phoneNumber ? "error" : undefined}
        >
          <Input
            name="phone"
            placeholder="(999) 999-9999"
            value={phoneNumber}
            onChange={callbackChangePhoneNumber}
          />
        </Item>
      </Modal>
    </Fragment>
  );
};

UpdatePhoneNumber.propTypes = {
  disabledButton: bool,
  onUpdatePhoneNumber: func.isRequired
};

UpdatePhoneNumber.defaultProps = {
  disabledButton: false
};

export default UpdatePhoneNumber;
