import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React, { useCallback } from 'react';
import Card from 'antd/es/card';

import './style.css';
import Title from '../../../components/Title';
import TopNavigation from '../../../components/TopNavigation';
import Wrapper from '../../../components/Wrapper';
import { createContactAndConversation } from '../../../actions/conversations';

const Support = ({ onCreateContactAndConversation }) => {
  const askQuestion = useCallback(() => {
    const phoneNumber = "+14159431680";
    onCreateContactAndConversation(phoneNumber);
  }, [onCreateContactAndConversation]);

  return (
    <>
      <Title title="Support" />
      <TopNavigation />

      <Wrapper title="Support">
        <div className="support-list">
          <Card onClick={askQuestion}>
            <a href="#">Ask a Question</a>
          </Card>
          <Card>
            <a target="_blank" href="https://intercom.help/textwith/en">Help Center</a>
          </Card>
        </div>
      </Wrapper>
    </>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({
  onCreateContactAndConversation: createContactAndConversation,
}, dispatch);

export default connect(null, mapDispatchToProps)(Support);
