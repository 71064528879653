import React, { Fragment, useState, useCallback } from "react";
import Icon from "antd/es/icon";
import Button from "antd/es/button";
import { bool, func } from "prop-types";

import DateTimePicker from "../../DateTimePicker";

const SendLater = ({ disabled, onSend }) => {
  const [visible, setVisible] = useState(false);

  const callbackToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible, setVisible]);

  return (
    <Fragment>
      <Button
        style={{ marginRight: 10 }}
        disabled={disabled}
        onClick={callbackToggleVisible}
      >
        <Icon type="clock-circle" />
      </Button>

      <DateTimePicker visible={visible} setVisible={setVisible} onDone={onSend} />
    </Fragment>
  );
};

SendLater.propTypes = {
  disabled: bool,
  onSend: func.isRequired
};

SendLater.defaulProps = {
  bool: false
};

export default SendLater;
