import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import DeleteContact from './DeleteContact';
import { deleteContact } from "../../actions/contacts";

const mapDispatchToProps = dispatch => bindActionCreators({
  onDelete: deleteContact
}, dispatch);

export default connect(null, mapDispatchToProps)(DeleteContact);
