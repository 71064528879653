import React, { useEffect, useState, useCallback, useRef } from "react";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import { string, number, bool, func } from "prop-types";

import "./style.css";
import SendLater from "../SendLater";
import TemplateChoosing from "../TemplateChoosing";
import { buildMessageByTemplate } from "../../../utils/string";
import { Popconfirm } from "antd";

const TextArea = Input.TextArea;

const Compose = ({
  contactId,
  currentConversation,
  contactAddress,
  initText,
  textForReset,
  disabled,
  wfResponseId,
  sendMessageToServer,
  teamMemberAddress,
  onHeightChanged
}) => {
  const inputFileEl = useRef(null);
  const wrapperRef = useRef(null);
  const [text, setText] = useState(initText);

  const getHeight = () => {
    try {
      return wrapperRef.current.getBoundingClientRect().height
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    setText(textForReset);
    window.addEventListener("resize", () => {
      onHeightChanged && onHeightChanged(getHeight());
    });
    onHeightChanged && onHeightChanged(getHeight());
  }, []);

  const changeMessage = useCallback(({ target: { value } }) => {
    setText(value);
    setTimeout(() => {
      onHeightChanged && onHeightChanged(getHeight());
    }, 100);
  }, [onHeightChanged]);

  const callbackSendText = useCallback(
    (e, scheduledAt) => {
      if (!text || disabled) {
        return;
      }
      sendMessageToServer(currentConversation, contactAddress, contactId, teamMemberAddress, {
        messageText: text,
        wfResponseId,
        scheduledAt,
      });
      setText(textForReset);
    },
    [
      text,
      contactId,
      currentConversation,
      contactAddress,
      disabled,
      textForReset,
      wfResponseId,
      sendMessageToServer,
    ]
  );

  const callbackSendImage = useCallback(
    ({ target: { files } }) => {
      sendMessageToServer(currentConversation, contactAddress, contactId, teamMemberAddress, {
        dataFile: files[0],
        wfResponseId,
      });
    },
    [currentConversation, contactAddress, wfResponseId, sendMessageToServer]
  );

  const callbackChooseImage = useCallback(() => {
    inputFileEl.current.click();
  }, [inputFileEl]);

  const callbackSetFromTemplate = useCallback(
    ({ templateText }) => {
      setText(buildMessageByTemplate(contactId, templateText));
    },
    [contactId, setText]
  );

  const callbackSetVCard = useCallback(() => {
    if (disabled) {
      return;
    }
    sendMessageToServer(currentConversation, contactAddress, contactId, teamMemberAddress, {
      messageText: "Here is my information {{vcard}}",
    });
  }, [contactId, currentConversation, contactAddress, disabled, sendMessageToServer]);

  const callbackEnterHandler = useCallback(e => {
    if (e.ctrlKey) {
      changeMessage({ target: { value: `${text}\n` } });
    } else {
      e.preventDefault();
      callbackSendText(null, null);
    }
  }, [text]);

  const disableSender = text.replace(/\s{2,}/g, " ").trim().length === 0;

  return (
    <div className="composer-wrapper" ref={wrapperRef}>
      <TextArea
        autosize
        placeholder="Type a message..."
        maxLength={500}
        disabled={disabled}
        value={text}
        onChange={changeMessage}
        onPressEnter={callbackEnterHandler}
      />

      <div className="composer-actions">
        <TemplateChoosing disabled={disabled} onSelected={callbackSetFromTemplate} />

        <Button style={{ marginRight: 10 }} disabled={disabled} onClick={callbackChooseImage}>
          <Icon type="picture" />
        </Button>

        <Popconfirm title="Send vCard Information" onConfirm={callbackSetVCard}>
          <Button style={{ marginRight: 10 }} disabled={disabled}>
            <Icon type="idcard" />
          </Button>
        </Popconfirm>

        <SendLater disabled={disableSender} onSend={callbackSendText} />

        <Button type="primary" disabled={disableSender} onClick={callbackSendText}>
          Send
        </Button>

        <input
          type="file"
          accept="image/*"
          style={{ position: "fixed", left: -20000 }}
          ref={inputFileEl}
          onChange={callbackSendImage}
        />
      </div>
    </div>
  );
};

Compose.propTypes = {
  contactId: string,
  currentConversation: string,
  contactAddress: string,
  initText: string,
  textForReset: string,
  disabled: bool.isRequired,
  wfResponseId: number,
  sendMessageToServer: func.isRequired,
};

Compose.defaultProps = {
  currentConversation: null,
  contactAddress: null,
  initText: "",
  textForReset: "",
  wfResponseId: null,
};

export default Compose;
