import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContactAssignMember from "./ContactAssignMember";
import { assignMember } from "../../actions/contacts";

const mapStateToProps = ({
  application: { teamMembers },
}) => ({
  teamMembers
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onAssignMember: assignMember
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactAssignMember);
