import React, { Fragment, useState, useCallback } from "react";
import { func } from "prop-types";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import message from "antd/es/message";

import Header from "./Header";
import Content from "./Content";
import ButtonContinue from "./ButtonContinue";
import UpdatePhoneNumber from "./UpdatePhoneNumber";
import onboarding from "../../../services/onboarding";

const FormItem = Form.Item;

const VerificationPhone = ({ onContinue }) => {
  const [resending, setResending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState({});
  const [phoneNumber, setPhoneNumber] = useState(onboarding.phoneNumber || "");

  const changeCode = useCallback(
    ({ target: { value } }) => {
      setCode(value);
      setErrors({});
    },
    [setCode, setErrors]
  );

  const resentCode = useCallback(async () => {
    setResending(true);
    const [status, error] = await onboarding.sendVerifyCode();
    if (status) {
      message.success("Code resent.");
    } else {
      message.error(error);
    }
    setResending(false);
  }, [setResending]);

  const settingPhoneNumber = useCallback(
    value => {
      onboarding.setPhoneNumber(value, () => {
        setPhoneNumber(value);
      });
    },
    [setPhoneNumber]
  );

  const nextStep = useCallback(async () => {
    setLoading(true);
    let [status, error] = await onboarding.checkingConfirmationCode(code);

    if (status) {
      await onboarding.setTeamMemberPhoneVerified();
      await onboarding.finish();
    } else {
      setErrors({ code: error });
    }

    setLoading(false);
  }, [code, setLoading, setErrors, onContinue]);

  return (
    <div>
      <Header
        title="One last thing..."
        description={`We just need to verify your mobile phone. We’ve you a code to ${phoneNumber}`}
      />

      <Content>
        <Fragment>
          <FormItem
            label="Enter Your Code"
            validateStatus={errors.code ? "error" : undefined}
            help={errors.code}
          >
            <Input
              placeholder="Enter your code"
              name="code"
              maxLength={6}
              disabled={loading}
              value={code}
              onChange={changeCode}
            />
          </FormItem>
          <div style={{ marginBottom: 20, textAlign: "center" }}>
            <Button type="link" loading={resending} disabled={loading} onClick={resentCode}>
              Resend code
            </Button>
            {" | "}
            <UpdatePhoneNumber
              disabledButton={resending || loading}
              onUpdatePhoneNumber={settingPhoneNumber}
            />
          </div>

          <ButtonContinue
            disabled={resending}
            loading={loading}
            title="Verify and Finish"
            onClick={nextStep}
          />
        </Fragment>
      </Content>
    </div>
  );
};

VerificationPhone.propTypes = {
  onContinue: func.isRequired
};

export default VerificationPhone;
