import React, { Fragment, useCallback, useRef, useState, useEffect } from "react";
import { any, func, objectOf } from "prop-types";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import antdMessage from "antd/es/message";

import CustomFields from "../CustomFields";
import { updateWorkflow } from "../../services/api/workflow";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const Automation = ({ dataInit, onSuccess, fetchWorkflows }) => {
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(null);
  const [duration, setDuration] = useState(null);
  const [delayInMinutes, setDelayInMinutes] = useState(null);
  const [updating, setUpdating] = useState(null);
  const messageRef = useRef(null);

  const contactQuery = dataInit && dataInit.contact_queries[0];

  const callbackChangeMessage = useCallback(
    ({ target: { value } }) => {
      setMessage(value);
      setErrors({});
    },
    [setMessage, setErrors]
  );

  const callbackChangeDuration = useCallback(
    ({ target: { value } }) => {
      setDuration(value);
      setErrors({});
    },
    [setDuration, setErrors]
  );

  const callbackChangeDelayInMinutes = useCallback(
    ({ target: { value } }) => {
      setDelayInMinutes(value);
      setErrors({});
    },
    [setDelayInMinutes, setErrors]
  );

  const callbackSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (updating) {
        return;
      }

      const data = {};
      if (message !== null) {
        data.template_text = message;
      }
      if (delayInMinutes !== null) {
        data.send_delay_in_minutes = delayInMinutes;
      }
      if (duration !== null) {
        data.contact_query = {
          ...(contactQuery || {}),
          property_value: duration
        };
      }

      setUpdating(true);

      let success = false;
      try {
        await updateWorkflow(dataInit.id, data);
        success = true;
      } catch (error) {
        console.log(error);
      }

      if (success) {
        await fetchWorkflows();
        antdMessage.success("Updated.");
        if (onSuccess) {
          onSuccess();
        }
      } else {
        antdMessage.error("Unknown error, please repeat again.");
      }
      setUpdating(false);
    },
    [updating, dataInit, contactQuery, message, delayInMinutes, duration, setUpdating]
  );

  useEffect(() => {
    setMessage(null);
    setDelayInMinutes(null);
    setDuration(null);

    if (dataInit && dataInit.id) {
      if (dataInit.send_automatically) {
        setMessage(dataInit.template_text || "");
        setDelayInMinutes(dataInit.send_delay_in_minutes || "");
      } else if (dataInit.template_text) {
        setMessage(dataInit.template_text || "");
      }

      if (contactQuery && contactQuery.lookup_type !== "istag") {
        setDuration(contactQuery.property_value || "");
      }
    }
  }, [dataInit, contactQuery, setMessage, setDelayInMinutes, setDuration]);

  return (
    <Form className="workflow-form" onSubmit={callbackSubmit}>
      {message !== null ? (
        <Fragment>
          <FormItem
            label="Message (Required)"
            extra={`Character Count: ${message.length}`}
            validateStatus={errors.message ? "error" : null}
            help={errors.message}
          >
            <TextArea
              maxLength={500}
              ref={messageRef}
              value={message}
              onChange={callbackChangeMessage}
            />
          </FormItem>
          <CustomFields inputRef={messageRef} />
        </Fragment>
      ) : null}

      {delayInMinutes !== null ? (
        <FormItem label="Delay In Minutes">
          <Input type="number" value={delayInMinutes} onChange={callbackChangeDelayInMinutes} />
        </FormItem>
      ) : null}

      {duration !== null ? (
        <FormItem label="Update Number of Days for the trigger">
          <Input type="number" value={duration} onChange={callbackChangeDuration} />
        </FormItem>
      ) : null}

      <Button block type="primary" htmlType="submit" loading={updating}>
        Update
      </Button>
    </Form>
  );
};

Automation.propTypes = {
  dataInit: objectOf(any),
  fetchWorkflows: func.isRequired,
  onSuccess: func.isRequired
};

export default Automation;
