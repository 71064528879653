import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import { func, string } from "prop-types";

import "./style.css";
import Field from "./Field";
import ForwardingNumber from "./ForwardingNumber";
import Title from "../../../components/Title";
import Wrapper from "../../../components/Wrapper";
import TopNavigation from "../../../components/TopNavigation";
import ChangePassword from "../../../components/ChangePassword";
import CelPhoneMask from "../../../utils/CelPhoneMask/cel-phone.mask";
import OrganizationForwardingNumber from "./OrganizationForwardingNumber";
import OrganizationTimezone from "./OrganizationTimezone";
import { DEFAULT_TIMEZONE } from "../../../constants";
import { currentUserToken } from "../../../utils/auth";

const Profile = ({
  notifyId,
  firstName,
  lastName,
  email,
  phone,
  mobileNumber,
  orgTimezoneName,
  orgPhoneList,
  orgForwardingPhoneNumber,
  onUpdateTimezone,
}) => {
  const token = currentUserToken();

  return (
    <div>
      <Title title="Profile" />
      <TopNavigation />

      <Wrapper title="Profile">
        <Field label="First Name" value={firstName} />

        <Field label="Last Name" value={lastName} />

        <Field label="Email" value={email} />

        <Field label="Phone" value={new CelPhoneMask().getValue(phone)} />

        <Field label="Mobile Number" value={new CelPhoneMask().getValue(mobileNumber)} />

        <ForwardingNumber notifyId={notifyId} />

        {orgPhoneList.map((orgPhone, index) => (
          <Field
            key={index}
            label="Organization Number"
            value={new CelPhoneMask().getValue(orgPhone)}
          />
        ))}

        <OrganizationForwardingNumber
          notifyId={notifyId}
          phone={new CelPhoneMask().getValue(orgForwardingPhoneNumber)}
        />

        <Field
          label="Organization Timezone"
          extra={
            <OrganizationTimezone orgTimezoneName={orgTimezoneName} onUpdate={onUpdateTimezone} />
          }
        />

        <br />
        <Row style={{ marginBottom: 8 }}>
          <Col span={19} offset={5}>
            <ChangePassword />
          </Col>
        </Row>
      </Wrapper>
    </div>
  );
};

Profile.propTypes = {
  notifyId: string.isRequired,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  orgTimezoneName: string,
  orgPhone: string,
  mobileNumber: string,
  onUpdateTimezone: func.isRequired,
};

Profile.defaultProps = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  orgPhone: "",
  orgTimezoneName: DEFAULT_TIMEZONE,
  mobileNumber: "",
};

export default Profile;
