import React from "react";
import lodash from "lodash";
import Tabs from "antd/es/tabs";
import Table from "antd/es/table";
import { arrayOf, objectOf, any, string, func } from "prop-types";

import columns from "./columns";
import Overview from "./Overview";
import { excludeUnassigned, orderStatuses } from "./utils";

const Tab = Tabs.TabPane;

const ContactsGroupedByStatuses = ({ tabKey, contacts, list, onChangeTabbKey }) => {
  const renderTab = (status, withTab = true) => {
    const membersIds = (status ? list.statusMembers[status.id] :list.members) || [];
    const dataSource =
      lodash.filter(contacts, ({ id }) =>
        membersIds.includes(id)).map(c => ({ ...c, status }));

    const content = (
      <Table
        dataSource={dataSource}
        columns={columns}
      />
    );

    return withTab ? (
      <Tab key={(status && status.id) || "all"} tab={(status && status.name) || "All Contacts"}>
        {content}
      </Tab>
    ) : content;
  };

  const statuses = orderStatuses(excludeUnassigned(list.statuses));
  const mode = list.statuses.filter(s => !s.isDefault).length ?
    "grouped" : "unassigned";

  return (
    mode === "grouped" ? (
      <Tabs activeKey={tabKey} tabPosition="top" onChange={onChangeTabbKey}>
        <Tab tab="Overview" key="overview">
          <Overview list={list} />
        </Tab>
        {renderTab()}
        {statuses.map(status => renderTab(status))}
      </Tabs>
    ) : renderTab(null, false)
  );
};

ContactsGroupedByStatuses.propTypes = {
  tabKey: string.isRequired,
  contacts: arrayOf(any).isRequired,
  list: objectOf(any).isRequired,
  onChangeTabbKey: func.isRequired
};

export default ContactsGroupedByStatuses;
