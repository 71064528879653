import React from "react";

import "./style.css";

const GroupDate = ({ date }) => (
  <div className="inbox-body-date-message-wrapper">
    <div className="inbox-body-date-message_date">{date}</div>
  </div>
);

export default GroupDate;
