import lodash from "lodash";

import { getRef, getTimestamp } from "../services/firestoreService";
import {
  FETCH_LISTS_BEGIN,
  FETCH_LISTS_SUCCESS,
  FETCH_LISTS_FAILURE,
  ADD_LIST_BEGIN,
  ADD_LIST_SUCCESS,
  ADD_LIST_FAILURE,
  UPDATE_LIST_BEGIN,
  UPDATE_LIST_FAILURE,
  UPDATE_LIST_ATTRS
} from "../constants";

export const fetchLists = () => async dispatch => {
  dispatch({ type: FETCH_LISTS_BEGIN });

  let result = [];
  try {
    const querySnapshot = await getRef("lists").get();
    querySnapshot.forEach(doc => {
      result.push({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt && doc.data().createdAt.toDate()
      });
    });
  } catch (error) {
    dispatch({ type: FETCH_LISTS_FAILURE, error });
    return;
  }

  dispatch({
    type: FETCH_LISTS_SUCCESS,
    items: result
  });
};

export const addList = list => async dispatch => {
  dispatch({ type: ADD_LIST_BEGIN });

  list.createdAt = getTimestamp();
  list.statusMembers = {};

  if (list.category !== "contactsList") {
    list.members = [];
  }

  try {
    const result = await getRef("lists").add(list);
    list.id = result.id;
  } catch (error) {
    dispatch({ type: ADD_LIST_FAILURE, error });
    return await Promise.reject(error);
  }

  await dispatch({ type: ADD_LIST_SUCCESS, list });
};

export const updateList = (listId, data) => async dispatch => {
  dispatch({ type: UPDATE_LIST_BEGIN });

  // Unzip members Ids
  if (data.statusMembers && data.category !== "contactsList") {
    try {
      data.members = lodash.uniq(lodash.flatten(lodash.values(data.statusMembers)));
    } catch (e) {
    }
  }

  try {
    await getRef("lists").doc(listId).update(data);
  } catch (error) {
    dispatch({ type: UPDATE_LIST_FAILURE, error });
    return;
  }

  dispatch({ type: UPDATE_LIST_ATTRS, listId, attr: data });
};

export const updateMembers = (listId, statusId, membersIds) => async (dispatch, getState) => {
  const { lists: { byId } } = getState();
  const list = byId[listId];

  if (!list.statusMembers) {
    list.statusMembers = {};
  }

  if (list.category === "contactsList") {
    list.statusMembers = {};
    list.members = membersIds;
  } else {
    list.statusMembers[statusId] = membersIds;
  }

  dispatch(updateList(listId, list));
};
