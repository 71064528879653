import React, { useState, useCallback, useMemo } from "react";
import _filter from "lodash/filter";
import _map from "lodash/map";
import _orderBy from "lodash/orderBy";
import List from "antd/es/list";
import Icon from "antd/es/icon";
import Button from "antd/es/button";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { arrayOf, any, bool, objectOf } from "prop-types";

import Item from "./Item";
import Title from "../../../../components/Title";
import Wrapper from "../../../../components/Wrapper";
import TopNavigation from "../../../../components/TopNavigation";
import AddList from "../../../../components/AddList";

const Lists = ({ lists, contacts, contactsById }) => {
  const [visibleAddList, setVisibleAddList] = useState(false);

  const callbackToggleAddNewList = useCallback(() => {
    setVisibleAddList(!visibleAddList);
  }, [visibleAddList, setVisibleAddList]);

  const dataSource = useMemo(() => {
    const filtered = _orderBy(
      _map(lists, l => ({ ...l, members: _filter(l.members, m => contactsById[m]) })),
      { createdAt: "DESC" }
    );
    const pipelines = _filter(filtered, l => l.category !== "contactsList");
    const contactsLists = _filter(filtered, l => l.category === "contactsList");

    return [
      {
        id: 'all',
        category: 'contactsList',
        name: 'TextWith Contacts',
        members: _filter(contacts, c => !c.fromDevice),
      },
      ...pipelines,
      ...contactsLists
    ];
  }, [lists, contacts, contactsById]);

  return (
    <div>
      <Title title="Contacts" />
      <TopNavigation />

      <AddList
        visible={visibleAddList}
        onClose={callbackToggleAddNewList}
      />

      <Wrapper
        title="Contacts"
        extra={(
          <Button type="primary" onClick={callbackToggleAddNewList}>
            <Icon type="plus" />
            Add List
          </Button>
        )}
      >
        <Row>
          <Col lg={{ span: 20, offset: 2 }}>
            <List
              itemLayout="vertical"
              dataSource={dataSource}
              renderItem={item => <Item key={item.id} { ...item } />}
            />
          </Col>
        </Row>
      </Wrapper>
    </div>
  );
};

Lists.propTypes = {
  lists: arrayOf(any),
  contacts: arrayOf(any),
  contactsById: objectOf(any),
  fetchingLists: bool,
};

Lists.defaultProps = {
  lists: [],
  contacts: [],
  contactsById: {},
  fetchingLists: false,
};

export default Lists;
