import React, { useState, useCallback, useMemo } from 'react';
import { any, arrayOf, func, objectOf, string } from 'prop-types';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';

import ContactsPicker from '../ContactsPicker';

const AddContactsToList = ({ contacts, list, currentStatusId, onUpdateMembers }) => {
  const [visible, setVisible] = useState(false);

  const statusId = useMemo(() => {
    if (list.category !== "contactsList" && list.statuses.length === 1) {
      return list.statuses[0].id;
    }
    return currentStatusId;
  }, [currentStatusId, list]);

  const defSelectedIds = useMemo(() => (
    currentStatusId ? list.statusMembers[currentStatusId] : list.members
  ), [list, currentStatusId]);

  const toggle = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const submit = useCallback(ids => {
    onUpdateMembers(list.id, statusId, ids);
    setVisible(false);
  }, [list, statusId, onUpdateMembers]);

  const disableAddContacts = list.category !== "contactsList" && list.statuses.length > 1 && !statusId;

  return (
    <>
      <Tooltip title={disableAddContacts ? "First, select a status to Add Contacts to the status." : null}>
        <Button
          block
          type="primary"
          disabled={disableAddContacts}
          onClick={toggle}
        >
          Add Contacts
        </Button>
      </Tooltip>

      <ContactsPicker
        selectedIds={defSelectedIds}
        visible={visible}
        contacts={contacts}
        onApply={submit}
        onClose={toggle}
      />
    </>
  );
};

AddContactsToList.propTypes = {
  list: objectOf(any),
  currentStatusId: string,
  contacts: arrayOf(any).isRequired,
  onUpdateMembers: func.isRequired,
};

AddContactsToList.defaultProps = {
  list: null,
  currentStatusId: null,
};

export default AddContactsToList;
