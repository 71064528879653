import React, { useCallback, useState } from "react";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Divider from "antd/es/divider";
import { func, string } from "prop-types";

const Note = ({ contactId, addTimelineNote, onDone }) => {
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const callbackChangeNote = useCallback(e => {
    setNote(e.target.value);
  }, [setNote]);

  const callbackSend = useCallback(() => {
    setLoading(true);

    addTimelineNote(contactId, note).then(() => {
      setLoading(false);
      setNote("");

      onDone();
    });
  }, [
    contactId,
    note,
    setLoading,
    setNote,
    addTimelineNote,
    onDone
  ]);

  return (
    <Form>
      <Form.Item label="Note">
        <Input.TextArea
          disabled={loading}
          value={note}
          onChange={callbackChangeNote}
        />
      </Form.Item>

      <Divider />

      <Button
        block
        type="primary"
        disabled={!note}
        loading={loading}
        onClick={callbackSend}
      >
        Add Note
      </Button>
    </Form>
  );
};

Note.propTypes = {
  contactId: string.isRequired,
  addTimelineNote: func.isRequired,
  onDone: func.isRequired
};

export default Note;
