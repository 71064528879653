import React from "react";

import TemplatesContent from "../../../../components/Templates";

import AppTitle from "../../../../components/Title";
import TopNavigation from "../../../../components/TopNavigation";

const Templates = () => (
  <>
    <AppTitle title="Templates" />
    <TopNavigation />

    <TemplatesContent />
  </>
);

export default Templates;
