import React, { Fragment, useState, useCallback } from "react";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import Modal from "antd/es/modal";
import { bool, func } from "prop-types";

import FormTemplate from "../FormTemplate";

const AddTemplate = ({ isCalendarEvent, creating, addTemplate }) => {
  const [visible, setVisible] = useState(false);
  const callbackToggleVisible = useCallback(() => {
    if (creating && visible) {
      return;
    }
    setVisible(!visible);
  }, [creating, visible, setVisible]);

  const callbackSubmit = useCallback((data, callback) => {
    addTemplate(data).then(() => {
      setVisible(false);
      if (callback) {
        callback();
      }
    });
  }, [setVisible, addTemplate]);

  return (
    <Fragment>
      <Button type="primary" onClick={callbackToggleVisible}>
        <Icon type="plus-circle" />
        Add
      </Button>
      <Modal
        title="New Template"
        visible={visible}
        footer={null}
        onCancel={callbackToggleVisible}
      >
        {isCalendarEvent ? (
          <FormTemplate.CalendarEventsForm
            loading={creating}
            onSubmit={callbackSubmit}
          />
        ) : (
          <FormTemplate.MainForm
            loading={creating}
            onSubmit={callbackSubmit}
          />
        )}
      </Modal>
    </Fragment>
  );
};

AddTemplate.propTypes = {
  isCalendarEvent: bool,
  creating: bool.isRequired,
  addTemplate: func.isRequired
};

AddTemplate.defaultProps = {
  isCalendarEvent: false,
};

export default AddTemplate;
