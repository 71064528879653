import React, { useEffect, useState } from "react";

import "./style.css";
import ConversationsList from "./ConversationsList";
import ContactInfo from "./ContactInfo";
import MessagesBody from "../Body";

const Inbox = () => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    if (window.innerWidth <= 1000) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    handleCollapse();
    window.addEventListener("resize", handleCollapse)
  }, []);

  return (
    <div className="inbox-wrapper">
      <ConversationsList />

      <div className="inbox-body-wrapper" style={collapsed ? { right: 0 } : {}}>
        <MessagesBody />
      </div>

      {collapsed ? null : <ContactInfo />}
    </div>
  );
};

export default Inbox;
