import React from "react";
import { Route, Redirect } from "react-router-dom";
import { bool } from "prop-types";

import Error403 from "../pages/errors/403";
import { isAuthenticated, isAdmin } from "../utils/auth";

const ProtectedRoute = props => {
  if (!isAuthenticated()) {
    return <Redirect to="/sign_in" />;
  }

  if (props.adminArea && !isAdmin()) {
    return <Error403 />;
  }

  return <Route {...props}/>;
};

ProtectedRoute.propTypes = {
  ...Route.propTypes,
  adminArea: bool
};

ProtectedRoute.defaultProps = {
  adminArea: false
};

export { ProtectedRoute };
