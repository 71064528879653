import React, { useCallback, useState } from "react";
import moment from "moment";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Form from "antd/es/form";
import DatePicker from "antd/es/date-picker";
import Divider from "antd/es/divider";
import { func, string } from "prop-types";

const StoreVisit = ({ contactId, addTimelineEvent, onDone }) => {
  const [comments, setComments] = useState("");
  const [dateVisited, setDateVisited] = useState(moment());
  const [loading, setLoading] = useState(false);

  const callbackChangeComments = useCallback(e => {
    setComments(e.target.value);
  }, [setComments]);

  const callbackChangeDateVisited = useCallback(d => {
    setDateVisited(d);
  }, [setDateVisited]);

  const callbackSend = useCallback(() => {
    const data = {
      name: "StoreVisit",
      value: {
        comments: comments
      },
      whenAt: moment(dateVisited).toISOString()
    };

    setLoading(true);
    addTimelineEvent(contactId, data)
      .then(() => {
        setComments("");
        setDateVisited(moment());
        setLoading(false);

        onDone();
      });
  }, [
    contactId,
    comments,
    dateVisited,
    setLoading,
    setComments,
    setDateVisited,
    addTimelineEvent,
    onDone
  ]);

  return (
    <Form>
      <Form.Item label="Comments">
        <Input.TextArea
          disabled={loading}
          value={comments}
          onChange={callbackChangeComments}
        />
      </Form.Item>

      <Form.Item label="Date Visited">
        <DatePicker
          disabled={loading}
          value={dateVisited}
          onChange={callbackChangeDateVisited}
        />
      </Form.Item>

      <Divider />

      <Button
        block
        type="primary"
        loading={loading}
        disabled={!comments && !dateVisited}
        onClick={callbackSend}
      >
        Add Store Visit
      </Button>
    </Form>
  );
};

StoreVisit.propTypes = {
  contactId: string.isRequired,
  addTimelineEvent: func.isRequired,
  onDone: func.isRequired
};

export default StoreVisit;
