import React  from "react";

import "./style.css";
import Title from "../../components/Title";

const Error403 = () => (
  <div>
    <Title title="Forbidden" />

    <div>
      <p className="title">This page is forbidden</p>
      <h1 className="code">403</h1>
    </div>
  </div>
);

export default Error403;
