import {
  CURRENT_ACCOUNT_SLUG,
  CURRENT_ACCOUNT_ID,
  CURRENT_GROUP_SLUG,
  CURRENT_USER_ID,
} from "../constants";
import moment from "moment-timezone";

export const accountSlug = () => localStorage.getItem(CURRENT_ACCOUNT_SLUG);

export const accountId = () => localStorage.getItem(CURRENT_ACCOUNT_ID);

export const groupSlug = () => localStorage.getItem(CURRENT_GROUP_SLUG);

export const userId = () => localStorage.getItem(CURRENT_USER_ID);

const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

export const uuidv4 = () =>
  s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();

export const getParameter = name => {
  let result = null, tmp = [];
  const items = window.location.search.substr(1).split("&");

  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === name) result = decodeURIComponent(tmp[1]);
  }

  return result;
};

export const toUTCFomTZ = (data, tz) => {
  const tzDate = moment.tz(data, tz);
  return tzDate.clone().utc();
};

export const toTZFromUTC = (data, tz) => {
  const utcDate = moment.utc(data);
  return utcDate.clone().tz(tz);
};
