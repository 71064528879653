import * as firebase from "firebase";
import configureStore from "../store/configureStore";
import {
  createAccountAndTeamMemberV2,
  phoneNumberVerifyCode,
  phoneNumberVerifyRequest,
  setTeamMemberAuthId,
  setTeamMemberPhoneVerified,
} from "./api/onboarding";
import { fetchProfile, setProfileEmail } from "../actions/profile";
import firestoreService from "./firestoreService";

const KEY = "onboarding_process";
const SHOPIFY_KEY = "onboarding_shopify";

class Onboarding {
  _data = {
    account: {},
    step: "welcome",
    phoneVerified: false,
    verifyCodeSent: false,
    connectWithAccountCreated: false,
    firebaseAccountCreated: false,
    teamMemberAssigned: false,
    steps: {},
  };

  _shopifyData = {
    shopName: null,
    shopAlreadyExists: null,
  };

  constructor() {
    const loadedData = localStorage.getItem(KEY);
    if (loadedData) {
      this._data = {
        ...this._data,
        ...JSON.parse(loadedData),
      };
    }

    const loadedShopifyData = localStorage.getItem(SHOPIFY_KEY);
    if (loadedShopifyData) {
      this._shopifyData = {
        ...this._shopifyData,
        ...JSON.parse(loadedShopifyData),
      };
    }
  }

  get currentStep() {
    return this._data.step;
  }

  get phoneNumber() {
    return this._data.steps.profile && this._data.steps.profile.phoneNumber;
  }

  get workPhone() {
    return this._data.steps.getStart && this._data.steps.getStart.phoneNumber;
  }

  get cartAbandoment() {
    const { message, duration } = this._data.steps.firstAutoMessage || {};

    if (message && duration) {
      return {
        message,
        time: duration,
      };
    } else {
      return null;
    }
  }

  get getShopName() {
    return this._shopifyData.shopName;
  }

  get getShopAlreadyExists() {
    return this._shopifyData.shopAlreadyExists;
  }

  get isFinished() {
    return !localStorage.getItem(KEY);
  }

  setShopName(name) {
    if (name) {
      this._shopifyData.shopName = name;
      this._updateShopifyStorage();
    }
  }

  setShopAlreadyExists(alreadyExists) {
    this._shopifyData.shopAlreadyExists = alreadyExists;
    this._updateShopifyStorage();
  }

  setStep(step) {
    this._data.step = step;
    this._updateStorage();
  }

  updateStep(currentStep, attrs) {
    this._data.steps[currentStep] = {
      ...(this._data.steps[currentStep] || {}),
      ...attrs,
    };

    this._updateStorage();
  }

  setPhoneNumber(phoneNumber, callback) {
    if (phoneNumber !== this.phoneNumber) {
      this._data.phoneVerified = false;
      this._data.verifyCodeSent = false;
      this.updateStep("profile", { phoneNumber });
    }

    callback && callback();
  }

  async sendVerifyCode(phoneNumber = this.phoneNumber) {
    let result;
    try {
      result = await phoneNumberVerifyRequest(phoneNumber);
    } catch (error) {
      return [false, error.message || "Error sending verification code."];
    }

    if (result && result.status === "failure") {
      return [false, [result.msg]];
    }

    this._data.verifyCodeSent = true;
    this._updateStorage();

    return [true, null];
  }

  async checkingConfirmationCode(code, phoneNumber = this.phoneNumber) {
    let result;
    try {
      const phone = `+${(phoneNumber || "").replace(/\D+/g, "")}`;
      result = await phoneNumberVerifyCode(phone, code);
    } catch ({ data }) {
      let error = "Problem verifying the code, please try again.";
      if (data && data.status !== "success") {
        error = "That code you entered is invalid. Please try again.";
      }
      return [false, error];
    }

    if (result.status === "failure") {
      return [false, "That code you entered is invalid. Please try again."];
    }

    this._data.phoneVerified = true;
    this._updateStorage();

    return [true, null];
  }

  async setTeamMemberPhoneVerified(data = this._data.account) {
    const { accountSlug, notifyId, phone } = data;
    try {
      await setTeamMemberPhoneVerified(accountSlug, phone, notifyId);
    } catch (error) {
      return [false, "Problem setting team member verified."];
    }

    this._data.teamMemberAssigned = true;
    this._updateStorage();

    await firestoreService.setOrgForwardingPhoneNumber(accountSlug, phone);

    return [true, null];
  }

  async createConnectWithAccount(email, firstName, lastName, phoneNumber) {
    let result;
    try {
      result = await createAccountAndTeamMemberV2(
        email,
        firstName,
        lastName,
        this.workPhone,
        phoneNumber,
        this._shopifyData.shopName,
        this.cartAbandoment
      );
    } catch ({ data }) {
      let errors = ["Problem creating account, please try again."];
      if (data && data.status === "failure") {
        if (data.errors.mobile_number) {
          errors = { phoneNumber: data.errors.mobile_number[0] };
        }
      }

      return [false, errors];
    }

    if (result.status === "failure") {
      return [false, result.msg ? [result.msg] : result.errors];
    }

    const accountData = result.data;
    const newTeamMember = accountData.team_member;
    const newOrgSlug = accountData.bot.organization.slug;
    const newBot = accountData.bot;

    this._data.account.notifyId = newTeamMember.notify_id;
    this._data.account.accountId = newBot.id;
    this._data.account.accountSlug = newOrgSlug;
    this._data.account.phone = newTeamMember.mobile_number;
    this._data.connectWithAccountCreated = true;
    this._updateStorage();

    return [true, null];
  }

  async createFirebaseAccount(email, password) {
    let result;
    try {
      result = await firebase.auth().createUserWithEmailAndPassword(email, password);
    } catch (error) {
      let errors = ["Problem creating account, please try again."];
      if (error.code === "auth/weak-password") {
        errors = {
          password:
            "Password isn't strong enough. It must be at least 6 characters, please try again.",
        };
      } else if (error.code === "auth/invalid-email") {
        errors = { email: error.message };
      }

      return [false, errors];
    }

    const { user } = result;
    this._data.firebaseAccountCreated = true;
    this._data.account.authId = user.uid;
    this._data.account.email = user.email;
    this._updateStorage();
    this.updateStep("profile", { email: user.email });

    return [true, null];
  }

  async assignTeamMemberAuthId() {
    const { accountSlug, notifyId, authId } = this._data.account;
    try {
      await setTeamMemberAuthId(accountSlug, notifyId, authId);
    } catch (error) {
      return [false, "Problem creating account, please try again."];
    }

    this._data.teamMemberAssigned = true;
    this._updateStorage();

    return [true, null];
  }

  async createAccount({ name, email, phoneNumber, password }) {
    this.setPhoneNumber(phoneNumber);

    const [firstName, lastName] = name.split(" ");
    phoneNumber = `+${phoneNumber ? phoneNumber.replace(/\D+/g, "") : ""}`;

    if (!this._data.connectWithAccountCreated) {
      const [success, errors] = await this.createConnectWithAccount(
        email,
        firstName,
        lastName,
        phoneNumber
      );

      if (!success) {
        return [success, errors];
      }
    }

    if (!this._data.firebaseAccountCreated && this._data.connectWithAccountCreated) {
      const [success, errors] = await this.createFirebaseAccount(email, password);
      if (!success) {
        return [success, errors];
      }
    }

    if (
      !this._data.teamMemberAssigned &&
      this._data.connectWithAccountCreated &&
      this._data.firebaseAccountCreated
    ) {
      const [success, errors] = await this.assignTeamMemberAuthId();
      if (!success) {
        return [success, errors];
      }
    }

    return await this.sendVerifyCode();
  }

  async finish() {
    const { email, password } = this._data.steps.profile;
    const result = await firebase.auth().signInWithEmailAndPassword(email, password);
    await configureStore.dispatch(setProfileEmail(result.user.email));
    await configureStore.dispatch(fetchProfile(true));

    await localStorage.removeItem(KEY);
    await localStorage.removeItem(SHOPIFY_KEY);

    const {
      profile: { notifyId },
    } = configureStore.getState();
    window.analytics.identify(notifyId, {
      email: result.user.email,
      webUser: true,
    });

    window.location.href = "/";
  }

  async clearStorage() {
    await localStorage.removeItem(KEY);
  }

  async clearShopifyStorage() {
    await localStorage.removeItem(SHOPIFY_KEY);
  }

  _updateStorage() {
    const tmp = JSON.stringify(this._data);
    localStorage.setItem(KEY, tmp);
  }

  _updateShopifyStorage() {
    const tmp = JSON.stringify(this._shopifyData);
    localStorage.setItem(SHOPIFY_KEY, tmp);
  }
}

const onboarding = new Onboarding();
export default onboarding;
