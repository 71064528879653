import React from 'react';
import { any, arrayOf, objectOf } from 'prop-types';
import { Link } from 'react-router-dom';
import Table from 'antd/es/table';
import Avatar from 'antd/es/avatar';

import { toShortName } from '../../../../utils/string';
import Tags from '../../../../components/Tags';

const columns  = [
  {
    dataIndex: "avatar",
    key: "avatar",
    width: 70,
    render: (avatar, record) => <Avatar src={avatar}>{toShortName(record.name)}</Avatar>,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, record) => <Link to={`/contacts/${record.key}`}>{name}</Link>,
  },
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
    render: (tags) => <Tags tags={tags} />,
  },
  {
    title: "",
    key: "action",
    render: (_, record) => (
      <div>
        <Link to={`/contacts/${record.key}/edit`}>Edit</Link>
        <span style={{ marginRight: 18 }} />
      </div>
    ),
  },
];

const Contacts = ({ contacts, pagination }) => (
  <Table
    dataSource={contacts}
    columns={columns}
    pagination={pagination}
  />
);

Contacts.propTypes = {
  contacts: arrayOf(any),
  pagination: objectOf(any),
};

Contacts.defaultProps = {
  contacts: [],
  pagination: {}
};

export default Contacts;
