import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import NewContact from "./NewContact";
import { addContact} from "../../../actions/contacts";

const mapStateToProps = ({
  application: {
    tags: { tags }
  },
  contacts: {
    creating
  }
}) => ({
  tags,
  loading: creating
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addContact
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewContact);
