import React, { Fragment, useState, useCallback } from "react";
import { func } from "prop-types";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Select from "antd/es/select";

import Header from "./Header";
import Content from "./Content";
import ButtonContinue from "./ButtonContinue";
import { availablePhones } from "../../../services/api/onboarding";

const FormItem = Form.Item;
const Option = Select.Option;

const GetStart = ({ onContinue }) => {
  const [loading, setLoading] = useState(false);
  const [areaCode, setAreaCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [phones, setPhones] = useState([]);
  const [errors, setErrors] = useState({});

  const loadPhones = useCallback(
    code => {
      setLoading(true);
      availablePhones(code)
        .then(result => {
          const numbers = (result && result.numbers) || [];
          if (numbers.length === 0) {
            setErrors({
              ...errors,
              areaCode:
                "Unfortunately there are no available phone numbers in that area code. " +
                "Try a different area code."
            });
          } else {
            setPhones(numbers);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [errors, setErrors, setPhones, setLoading]
  );

  const changeAreaCode = useCallback(
    ({ target: { value } }) => {
      setAreaCode(value);
      setErrors({});
      if (value.length === 3) {
        loadPhones(value);
      } else {
        setPhones([]);
      }
    },
    [setErrors, setAreaCode, loadPhones]
  );

  const changePhoneNumber = useCallback(
    ph => {
      setErrors({});
      setPhoneNumber(ph);
    },
    [setPhoneNumber, setErrors]
  );

  const nextStep = useCallback(
    e => {
      if (phoneNumber) {
        onContinue({
          areaCode,
          phoneNumber
        });
      } else {
        setErrors({ phoneNumber: "Is required" });
      }
    },
    [areaCode, phoneNumber, setErrors, onContinue]
  );

  return (
    <div>
      <Header
        title="Let's Get Started!"
        description="Our text messages see 10X more clicks and opens than email. Let's find you a great number to use with TextWith."
      />

      <Content>
        <Fragment>
          <FormItem
            label="Desired Area Code"
            validateStatus={errors.areaCode ? "error" : undefined}
            help={errors.areaCode}
          >
            <Input placeholder="415" value={areaCode} onChange={changeAreaCode} />
          </FormItem>

          {areaCode.length === 3 && phones.length > 0 ? (
            <FormItem
              label="Select a Phone Number"
              validateStatus={errors.phoneNumber ? "error" : undefined}
              help={errors.phoneNumber}
            >
              <Select
                showSearch
                placeholder="Choose Your Option"
                style={{ width: "100%" }}
                loading={loading}
                value={phoneNumber}
                onChange={changePhoneNumber}
              >
                {phones.map(phone => (
                  <Option key={phone.phone_number} value={phone.friendly}>
                    {phone.friendly}
                  </Option>
                ))}
              </Select>
            </FormItem>
          ) : null}

          <ButtonContinue
            disabled={areaCode.length !== 3 || phones.length === 0}
            title="Continue"
            onClick={nextStep}
          />
        </Fragment>
      </Content>
    </div>
  );
};

GetStart.propTypes = {
  onContinue: func.isRequired
};

export default GetStart;
