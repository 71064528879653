import React, { useState, useCallback, useEffect } from "react";
import lodash from "lodash";
import Steps from "antd/es/steps";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Modal from "antd/es/modal";
import Spin from "antd/es/spin";

import "./style.css";
import Title from "../../../components/Title";
import Wrapper from "../../../components/Wrapper";
import Hav from "./Hav";
import Welcome from "./Welcome";
import GetStart from "./GetStart";
import FirstAutoMessage from "./FirstAutoMessage";
import Profile from "./Profile";
import VerificationPhone from "./VerificationPhone";
import onboarding from "../../../services/onboarding";
import { getParameter } from "../../../utils/other";
import { setGlobalLoading } from "../../../actions/application";
import configureStore from "../../../store/configureStore";
import { signOut } from "../../../actions/profile";

const Step = Steps.Step;

const steps = [
  {
    key: "welcome",
    component: props => <Welcome {...props} />
  },
  {
    key: "getStart",
    component: props => <GetStart {...props} />
  },
  {
    key: "firstAutoMessage",
    component: props => <FirstAutoMessage {...props} />
  },
  {
    key: "profile",
    component: props => <Profile {...props} />
  },
  {
    key: "verificationPhone",
    component: props => <VerificationPhone {...props} />
  }
];

function findStepIndex(key) {
  return lodash.findIndex(steps, { key }) || 0;
}

const Onboarding = () => {
  const [step, setStep] = useState(findStepIndex(onboarding.currentStep));
  const [showSignUp, setShowSignUp] = useState(false);

  const callbackContinue = useCallback(
    stepData => {
      const nextStep = step + 1;
      onboarding.updateStep(steps[step].key, stepData);
      onboarding.setStep(steps[nextStep].key);
      setStep(nextStep);
    },
    [step, setStep]
  );

  useEffect(() => {
    // wait to logunt
    async function setUp() {
      await onboarding.clearStorage();
      await configureStore.dispatch(signOut());

      const shopName = getParameter("shop_name");
      const alreadyExists = getParameter("already_exists");
      if (shopName) {
        await onboarding.clearShopifyStorage();
        onboarding.setShopName(shopName);

        if (alreadyExists) {
          onboarding.setShopAlreadyExists(true);
          window.location.href = "/sign_in";
        } else {
          // continue onboarding
          onboarding.setShopAlreadyExists(false);

          window.location.href = window.location.pathname;
          setStep(0);
          setShowSignUp(true);
        }
      } else {
        setStep(0);
        setShowSignUp(true);
      }
    }
    setUp();
  }, []);

  return showSignUp ? (
    <div className="onboarding">
      <Title title="Sign Up" />
      <Hav />

      <div className="onboarding-blue-lining" />

      <Wrapper>
        <div className="onboarding-wrapper">
          <Row>
            <Col lg={{ span: 8, offset: 8 }} sm={{ span: 24 }}>
              <Steps className="onboarding-steps" current={step}>
                {steps.map(item => (
                  <Step key={item.key} />
                ))}
              </Steps>
            </Col>
          </Row>

          <Row>
            <Col lg={{ span: 18, offset: 3 }} sm={{ span: 24 }}>
              <div className="onboarding-content">
                {steps[step].component({
                  onContinue: callbackContinue
                })}
              </div>
            </Col>
          </Row>
        </div>
      </Wrapper>
    </div>
  ) : (
    <div>
      <Modal centered closable={false} footer={null} visible={true}>
        <div style={{ textAlign: "center", padding: "20px 0" }}>
          <Spin size="large" />
        </div>
      </Modal>
    </div>
  );
};

export default Onboarding;
