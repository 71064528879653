import React, { useCallback } from "react";
import Button from "antd/es/button";
import Popconfirm from "antd/es/popconfirm";
import Divider from "antd/es/divider";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { objectOf, any, string, func } from "prop-types";

import PhoneField from "./Phone";
import EmailField from "./Email";
import AddressField from "./Address";
import AnniversaryField from "./Anniversary";

const BaseField = ({
  type,
  data,
  onDelete,
  onChangeLabel,
  onChangeValue
}) => {
  const callbackDelete = useCallback(() => {
    onDelete(type, data.id);
  }, [type, data, onDelete]);

  const callbackChangeLabel = useCallback(label => {
    onChangeLabel(type, data.id, label);
  }, [type, data, onChangeLabel]);

  const callbackChangeValue = useCallback(t => {
    const value = type === "emails" ? t.target.value : t;
    onChangeValue(type, data.id, value);
  }, [type, data, onChangeValue]);

  const props = {
    label: data.label,
    value: data.value,
    onChangeLabel: callbackChangeLabel,
    onChange: callbackChangeValue
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={22}>
          {type === "phones" ? (
            <PhoneField { ...props } />
          ) : type === "emails" ? (
            <EmailField { ...props } />
          ) : type === "addresses" ? (
            <AddressField { ...props } />
          ) : type === "anniversaries" ? (
            <AnniversaryField { ...props } />
          ) : null}
        </Col>
        <Col span={2}>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            placement="topRight"
            onConfirm={callbackDelete}
          >
            <Button
              icon="delete"
              type="danger"
            />
          </Popconfirm>
        </Col>
      </Row>
      {type === "addresses" ? <Divider /> : null}
    </div>
  );
};

BaseField.propTypes = {
  type: string.isRequired,
  data: objectOf(any).isRequired,
  onDelete: func.isRequired,
  onChangeLabel: func.isRequired,
  onChangeValue: func.isRequired
};

export default BaseField;
