import React from "react";
import { string } from "prop-types";

const Header = ({ title, description }) => (
  <div className="onboarding-header">
    <h1>{title}</h1>
    <div className="onboarding-header_description">
      {description}
    </div>
  </div>
);

Header.propTypes = {
  title: string.isRequired,
  description: string.isRequired
};

export default Header;
