import lodash from "lodash";

import {
  SET_CURRENT_CONVERSATION,
  SET_FILTER_CONVERSATIONS,
  SET_FILTER_2_CONVERSATIONS,
  UPDATE_ATTRS_CONVERSATION,
  SET_SCHEDULED_CONVERSATIONS,
  REMOVE_SCHEDULED_CONVERSATIONS,
  FETCH_THREADS_SUCCESS,
  FETCH_THREADS_BEGIN
} from "../constants";

const initialState = {
  error: null,
  threads: [],
  threadsFetching: true,
  conversationFilter: "my",
  conversationFilter2: "inbox",
  currentConversation: undefined,
  unreadConversations: [],
  conversations: [],
  scheduledConversationIds: [],
  conversationsById: {},
  conversationsByContactAddress: {},
};

export default (state = initialState, action) => {
  let conversations;
  switch (action.type) {
    case SET_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: action.conversation
      };
    case SET_FILTER_CONVERSATIONS:
      return {
        ...state,
        conversationFilter: action.filter
      };
    case SET_FILTER_2_CONVERSATIONS:
      return {
        ...state,
        conversationFilter2: action.filter
      };
    case UPDATE_ATTRS_CONVERSATION:
      const conversationsById = {
        ...state.conversationsById,
        [action.channelId]: {
          ...state.conversationsById[action.channelId],
          ...action.attrs
        }
      };
      conversations = lodash.values(conversationsById);

      return {
        ...state,
        conversationsById,
        conversations,
        conversationsByContactAddress:
          lodash.keyBy(conversations, "contactAddress")
      };
    case SET_SCHEDULED_CONVERSATIONS:
      return {
        ...state,
        scheduledConversationIds: [
          ...state.scheduledConversationIds,
          ...action.threadIds
        ]
      };
    case REMOVE_SCHEDULED_CONVERSATIONS:
      return {
        ...state,
        scheduledConversationIds:
          state.scheduledConversationIds
            .filter(id => !action.threadIds.includes(id))
      };
    case FETCH_THREADS_SUCCESS:
      const buf = lodash.map(action.conversations, item => {
        if (state.conversationsById[item.id] && state.conversationsById[item.id].hasPrevPage) {
          return {
            ...item,
            hasPrevPage: state.conversationsById[item.id].hasPrevPage,
          }
        } else {
          return item;
        }
      });

      return {
        ...state,
        threadsFetching: false,
        threads: action.threads,
        conversations: buf,
        conversationsById: lodash.keyBy(buf, "id"),
        conversationsByContactAddress: lodash.keyBy(buf, "contactAddress")
      };
    case FETCH_THREADS_BEGIN:
      return {
        ...state,
        threadsFetching: true
      };
    default:
      return {
        ...state
      };
  }
};
