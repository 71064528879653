import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AdminSelectOrg from "./AdminSelectOrg";
import { fetchOrgs } from "../../../actions/orgs";
import { beginFetchConversations } from "../../../actions/conversations";

const mapStateToProps = ({
  orgs: { orgs, fetchingOrgs }
}) => ({
  items: orgs,
  loading: fetchingOrgs
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchOrgs,
  beginFetchConversations
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminSelectOrg);
