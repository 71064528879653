import React, { useState, useCallback } from "react";
import Spin from "antd/es/spin";
import Icon from "antd/es/icon";
import Modal from "antd/es/modal";
import { string } from "prop-types";

import "./style.css";

const Image = ({ url, alt }) => {
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ visiblePreview, setVisiblePreview ] = useState(false);

  const togglePreview = useCallback(() => {
    if (loading) {
      return;
    }

    setVisiblePreview(!visiblePreview);
  }, [visiblePreview, loading, setVisiblePreview]);

  return (
    <div
      className="image-spin"
      style={{ cursor: loading ? "" : "pointer" }}
    >
      {loading && !error ? (
        <div className="image-spin-loading">
          <Spin indicator={
            <Icon
              spin
              type="loading"
              style={{ color: "#fff", fontSize: 24 }}
            />
          }/>
        </div>
      ) : null}

      {error ? (
        <div className="image-spin-warning">
          <div>
            <Icon
              type="warning"
              style={{ color: "#fff", fontSize: 24 }}
            />
            <div>
              Wrong image
            </div>
          </div>
        </div>
      ) : (
        <img
          src={url}
          alt={alt}
          onClick={togglePreview}
          onLoad={() => {
            setLoading(false);
          }}
          onError={() => {
            setError(true);
          }}
        />
      )}

      <Modal
        title="Preview Image"
        footer={null}
        visible={visiblePreview}
        onCancel={togglePreview}
      >
        <div className="image-preview">
          <img src={url} alt="" />
        </div>
      </Modal>
    </div>
  );
};

Image.propTypes = {
  url: string.isRequired,
  alt: string,
};

Image.defaultProps = {
  alt: ""
};

export default Image;
