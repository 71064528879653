import lodash from "lodash";
import moment from "moment";

import { formatContactAddress } from "../utils/contacts";
import { formatPhone } from "../utils/string";

export const buildContact = (item, relationships) => {
  let phone = item.phones.filter((p) => p.is_primary)[0];
  if (phone) {
    phone = formatPhone(phone.phone_normalized);
  }
  const name = [item.first_name, item.last_name].join(" ").trim() || phone;

  const relationship = lodash.find(relationships, {
    contact_notify_id: item.notify_id,
  });

  return {
    name,
    phone,
    key: item.notify_id,
    id: item.notify_id,
    dbId: item.db_id,
    contactAddress: formatContactAddress(phone),
    firstName: item.first_name || "",
    lastName: item.last_name || "",
    avatar: item.avatar,
    tagsSource: item.tags,
    emails: item.emails,
    phones: item.phones,
    tags: item.tags.map((rec) => rec.name),
    fetchingDetails: false,
    fetched: false,
    timelineData: [],
    profileData: {},
    memberNotifyId: relationship && relationship.team_member_notify_id,
    relationshipId: relationship && relationship.id,
    properties: item.properties,
  };
};

export const buildIdWithAddress = (records) => {
  const contactsIdsByAddress = {};
  const contactsById = lodash.keyBy(records, "id");

  lodash.forEach(records, (contact) => {
    lodash.forEach(contact.phones, (p) => {
      const key = formatContactAddress(p.phone_normalized || p.phone);
      if (key) {
        if (
          !contactsIdsByAddress[key] ||
          (contact.dbId && contact.dbId >= contactsById[contactsIdsByAddress[key]].dbId)
        ) {
          contactsIdsByAddress[key] = contact.id;
        }
      }
    });
  });

  return contactsIdsByAddress;
};

export const buildContacts = (source, relationships) => {
  const records = source.map((item) => buildContact(item, relationships));
  const contactsIdsByAddress = buildIdWithAddress(records);

  return {
    arr: records,
    byId: lodash.keyBy(records, "id"),
    idsByAddress: contactsIdsByAddress,
  };
};

export const buildTasks = (data) =>
  lodash.map(data, (task) => {
    const stats = task.responses;
    const { total_sent, percent_sent } = stats;
    const sentTotal = total_sent;
    const statusPercent = percent_sent ? Number.parseFloat(percent_sent).toFixed(0) : 0;
    const completed = statusPercent >= 100;
    const daysAgo =
      completed && task.done_at ? moment().diff(new Date(task.done_at), "days") : null;

    return {
      ...task,
      meta: {
        sentTotal,
        statusPercent,
        completed,
        daysAgo
      }
    };
  });
