import lodash from "lodash";

import {
  TASKS_FETCH_TASKS_BEGIN,
  TASKS_FETCH_TASKS_SUCCESS,
  TASKS_FETCH_TASKS_FAILURE,
  TASKS_FETCH_TASK_CONTACTS_BEGIN,
  TASKS_FETCH_TASK_CONTACTS_SUCCESS,
  TASKS_FETCH_TASK_CONTACTS_FAILURE,
  TASKS_START_TASK,
  TASKS_STOP_TASK,
  TASKS_SET_CURRENT_CONTACT,
  TASKS_SET_CURRENT_TEMPLATE,
  TASKS_REMOVE_CONTACT
} from "../constants";

import { userId } from "../utils/other";
import { formatContactAddress } from "../utils/contacts";
import { fetchContactDetails } from "./contacts";
import { fetchMessages } from "./messages";
import {
  getTeamMemberWorkflowTasks,
  getTeamMemberWorkflowTaskResponse,
  setTeamMemberWorkflowTaskResponseStatus
} from "../services/api/workflow";

export const fetchWorkflowTasksContacts = () => async dispatch => {
  dispatch({ type: TASKS_FETCH_TASKS_BEGIN });

  try {
    const { tasks } = await getTeamMemberWorkflowTasks(userId());
    dispatch({ type: TASKS_FETCH_TASKS_SUCCESS, tasks });
  } catch (error) {
    dispatch({ type: TASKS_FETCH_TASKS_FAILURE, error });
  }
};

export const startTask = taskId => ({
  type: TASKS_START_TASK,
  taskId
});

export const stopTask = () => ({
  type: TASKS_STOP_TASK
});

export const setCurrentContactIndex = index => async (dispatch, getState) => {
  const {
    tasks: { currentTaskContacts },
    conversations: { conversationsByContactAddress }
  } = getState();

  const record = currentTaskContacts[index];
  const conversation = conversationsByContactAddress[record.address];

  if (conversation) {
    dispatch(fetchMessages(conversation.id));
  }

  dispatch({ type: TASKS_SET_CURRENT_CONTACT, index });
  dispatch(fetchContactDetails(record.notifyId));
};

export const setCurrentTemplate = text => ({
  type: TASKS_SET_CURRENT_TEMPLATE,
  text
});

export const fetchWorkflowContacts = (
  taskId,
  workflowId
) => async (dispatch, getState) => {
  const {
    profile: { notifyId },
    contacts: { contactIdsByAddress }
  } = getState();

  dispatch({ type: TASKS_FETCH_TASK_CONTACTS_BEGIN });

  let contacts;
  try {
    contacts = await getTeamMemberWorkflowTaskResponse(
      taskId,
      workflowId,
      notifyId
    );
  } catch (error) {
    dispatch({ type: TASKS_FETCH_TASK_CONTACTS_FAILURE });
    return;
  }

  contacts = contacts.filter(item =>
    (item.contact_state === "ready" || item.contact_state === "skipped") &&
    contactIdsByAddress[formatContactAddress(item.contact_address)]
  );

  dispatch({ type: TASKS_FETCH_TASK_CONTACTS_SUCCESS, workflowId, contacts });

  return contacts;
};

export const setContactTaskAsDone = (contactId, contactAddress) => async (dispatch, getState) => {
  const {
    tasks: { currentTaskContacts, currentContactIndex }
  } = getState();

  const predicate = contactId ? { notifyId: contactId } : { address: contactAddress };
  const contactIndex = lodash.findIndex(currentTaskContacts, predicate);
  const taskContact = currentTaskContacts[contactIndex];

  if (currentContactIndex !== 0 && currentContactIndex >= contactIndex) {
    await dispatch(setCurrentContactIndex(currentContactIndex - 1));
  }

  await dispatch({ type: TASKS_REMOVE_CONTACT, contactId: taskContact.notifyId });

  if (currentTaskContacts.length === 1) {
    dispatch(stopTask());
  }
};

export const removeContactFromList = contactId => async (dispatch, getState) => {
  const {
    tasks: { currentTaskContacts },
    profile: { notifyId },
  } = getState();

  const contactIndex = lodash.findIndex(
    currentTaskContacts, { notifyId: contactId });
  const taskContact = { ...currentTaskContacts[contactIndex] };

  dispatch(setContactTaskAsDone(contactId));

  try {
    await setTeamMemberWorkflowTaskResponseStatus(
      notifyId,
      taskContact.workflowId,
      taskContact.taskId,
      taskContact.taskResponseId,
      "skipped"
    );
  } catch (error) {
    console.log(error);
  }
};
