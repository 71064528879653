import { getOrgs } from "../services/api/orgs";
import {
  FETCH_ORGS_BEGIN,
  FETCH_ORGS_SUCCESS,
  FETCH_ORGS_FAILURE
} from "../constants";

export const fetchOrgs = () => async dispatch => {
  dispatch({ type: FETCH_ORGS_BEGIN });

  try {
    const orgs = await getOrgs();
    dispatch({ type: FETCH_ORGS_SUCCESS, orgs });
  } catch (error) {
    dispatch({ type: FETCH_ORGS_FAILURE, error });
  }
};
