import React, { useCallback, useState } from "react";
import Modal from "antd/es/modal";
import { bool, string, objectOf, arrayOf, any, func } from "prop-types";

import "./style.css";
import Preparing from "./Preparing";
import Mailer from "./Mailer";

const confirm = Modal.confirm;

const TaskHandler = ({
  status,
  loading,
  starting,
  task,
  workflow,
  contacts,
  stopTask,
  fetchWorkflowContacts,
  setCurrentContactIndex,
  setCurrentTemplate
}) => {
  const [changed, setChanged] = useState(false);
  const callbackAskStop = useCallback(() => {
    if ((!changed && !loading) || (!loading && contacts.length === 0)) {
      setChanged(false);
      stopTask();
      return;
    }

    confirm({
      title: "Are you sure you want to stop this task?",
      onOk() {
        setChanged(false);
        stopTask();
      },
      onCancel() {}
    });
  }, [changed, stopTask, loading, contacts, setChanged]);

  const callbackStartWorkflow = useCallback(
    txt => {
      setCurrentContactIndex(0);
      setCurrentTemplate(txt);
    },
    [setCurrentContactIndex, setCurrentTemplate]
  );

  return (
    <Modal
      wrapClassName="task-handler-wrapper"
      width="auto"
      footer={null}
      title={workflow && workflow.name}
      visible={starting}
      onCancel={callbackAskStop}
    >
      {status === "preparing" ? (
        <Preparing
          loading={loading}
          taskId={task.id}
          workflowId={workflow.id}
          templateText={workflow.template_text}
          totalContacts={contacts.length}
          onStartWorkflow={callbackStartWorkflow}
          onFetchWorkflowContacts={fetchWorkflowContacts}
          onChanged={setChanged}
        />
      ) : (
        <Mailer />
      )}
    </Modal>
  );
};

TaskHandler.propTypes = {
  status: string,
  loading: bool,
  starting: bool,
  task: objectOf(any),
  workflow: objectOf(any),
  contacts: arrayOf(any),
  stopTask: func.isRequired,
  fetchWorkflowContacts: func.isRequired,
  setCurrentContactIndex: func.isRequired,
  setCurrentTemplate: func.isRequired
};

TaskHandler.defaultProps = {
  status: null,
  loading: false,
  starting: false,
  task: null,
  workflow: null,
  contacts: []
};

export default TaskHandler;
