import lodash from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContactsList from "./ContactsList";
import {
  setCurrentContactIndex,
  removeContactFromList
} from "../../../actions/tasks";

const mapStateToProps = ({
  contacts: { contactsById },
  tasks: { currentTaskContacts, currentContactIndex }
}) => {
  if (!currentTaskContacts[currentContactIndex]) {
    return {};
  }

  const contacts = lodash.map(currentTaskContacts, item => ({
    ...contactsById[item.notifyId],
    state: item.state
  }));

  return {
    contacts,
    currentContactIndex,
    currentContactId: currentTaskContacts[currentContactIndex].notifyId
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setCurrentContactIndex,
  removeContactFromList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
