import React, { Fragment, useState, useCallback, useEffect, useRef } from "react";
import { bool, func, string } from "prop-types";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Select from "antd/es/select";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Spin from "antd/es/spin";

import CustomFields from "../../components/CustomFields";
import { buildMessage } from "../../utils/string";

import "./style.css";

const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Option = Select.Option;

const TEAM_MEMBER = "Sam";
const STORY = "Amazing Store";
const PRODUCT_NAME = "Amazing Product";

const FIELDS = [
  { key: 0, label: "Sales Associate", value: "{{team_member_name}}" },
  { key: 1, label: "Store Name", value: "{{store_name}}" },
  { key: 2, label: "Abandoned Product", value: "{{product}}" }
];

const INIT_MESSAGE =
  "Hey there, this is {{team_member_name}} from {{store_name}}. " +
  "I see you checking out our {{product}}. Great choice! Want me to see " +
  "if I can get you a discount? PS. Msg & data rates may apply. " +
  "Let me know if you want me to stop texting.";

const ShopifyStoreForm = ({
  isVisible,
  onSubmit,
  shopName,
  isEdit,
  isSubmitting,
  onCancel,
  editMessage,
  editDuration,
  editShopName
}) => {
  const [message, setMessage] = useState(INIT_MESSAGE);
  const [duration, setDuration] = useState("120");
  const [displayShopName, setDisplayShopName] = useState(shopName || "");

  const inputRef = useRef();

  const onCancelCallback = useCallback(() => {
    onCancel();
  });

  useEffect(() => {
    if (isEdit) {
      if (editMessage) {
        setMessage(editMessage);
      }

      if (editDuration) {
        console.log("SET DURATION", editDuration);
        setDuration(editDuration);
      }

      if (editShopName) {
        setDisplayShopName(editShopName);
      }
    } else {
      setDisplayShopName(shopName);
    }
  }, [editMessage, editDuration, editShopName, shopName]);

  const changeMessage = useCallback(
    ({ target: { value } }) => {
      setMessage(value);
    },
    [setMessage]
  );

  const changeDuration = useCallback(
    value => {
      setDuration(value);
    },
    [setDuration]
  );

  const submitForm = useCallback(() => {
    onSubmit(message, duration);
  }, [message, setMessage, duration, setDuration, displayShopName]);

  return (
    <Fragment>
      <Modal
        title={`Settings for ${displayShopName}`}
        footer={null}
        width={800}
        visible={isVisible}
        closable={isEdit}
        onCancel={onCancelCallback}
      >
        <Spin spinning={isSubmitting}>
          <Fragment>
            <Row gutter={24}>
              <div className="onboarding-header">
                <h1>Setup Your Automated Message!</h1>
                <div className="onboarding-header_description">
                  We’ll text your customers after they abandon their checkout. Here is an example
                  message and delivery time that converts well. If you have a coupon code you may
                  want to include it here.
                </div>
              </div>
              <Col lg={{ span: 12 }}>
                <FormItem label="Message">
                  <TextArea rows={6} ref={inputRef} value={message} onChange={changeMessage} />
                  <CustomFields
                    showLabel={false}
                    fields={FIELDS}
                    inputRef={inputRef}
                    onChange={setMessage}
                  />
                </FormItem>

                <FormItem label="When should we send it?">
                  <Select style={{ width: "100%" }} value={`${duration}`} onChange={changeDuration}>
                    <Option value="2">2 minutes abandonment</Option>
                    <Option value="120">2 hours after abandonment</Option>
                    <Option value="240">4 hours after abandonment</Option>
                    <Option value="1440">24 hours after abandonment</Option>
                    <Option value="2880">48 hours after abandonment</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col lg={{ span: 12 }}>
                <FormItem label="Preview">
                  <div className="message-preview">
                    {buildMessage(message, {
                      team_member_name: TEAM_MEMBER,
                      store_name: STORY,
                      product: PRODUCT_NAME
                    })}
                  </div>
                </FormItem>
              </Col>
            </Row>
          </Fragment>
          <Button block type="primary" onClick={submitForm}>
            {isEdit ? "Update Settings" : "Link With TextWith"}
          </Button>
        </Spin>
      </Modal>
    </Fragment>
  );
};

ShopifyStoreForm.defaultProps = {
  isVisible: false,
  onSubmit: null,
  onCancel: null,
  isSubmitting: false,
  isEdit: false
};

ShopifyStoreForm.propTypes = {
  isVisible: bool,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  shopName: string,
  isSubmitting: bool,
  isEd: bool
};

export default ShopifyStoreForm;
