import React from "react";
import { bool, any, func, arrayOf, objectOf } from "prop-types";

import AppTitle from "../../../../components/Title";
import TopNavigation from "../../../../components/TopNavigation";
import Wrapper from "../../../../components/Wrapper";
import WorkflowList from "../../../../components/WorkflowList";
import lodash from "lodash";
import EditWorkflow from "../../../../components/EditWorkflow";

class Reminders extends React.PureComponent {
  state = {
    editWorkflowId: null,
    visibleEdit: false
  };

  componentWillMount() {
    const { fetchWorkflows } = this.props;
    fetchWorkflows();
  }

  showEditForm = editWorkflowId =>
    this.setState({ visibleEdit: true, editWorkflowId });

  hideEditModal = () =>
    this.setState({ visibleEdit: false }, () => {
      setTimeout(() => {
        this.setState({editWorkflowId: null})
      }, 300);
    });

  render() {
    const {editWorkflowId, visibleEdit} = this.state;
    const {
      workflows,
      loading,
      teamMembers,
      fetchSummary,
      updateWorkflowStatus,
      fetchWorkflows
    } = this.props;

    return (
      <div>
        <AppTitle title="Automations" />
        <TopNavigation />

        <EditWorkflow
          automation
          visible={visibleEdit}
          editWorkflowData={editWorkflowId && lodash.find(workflows, { id: editWorkflowId })}
          fetchWorkflows={fetchWorkflows}
          onCancel={this.hideEditModal}
        />

        <Wrapper title="Automations">
          <WorkflowList
            filter="automations"
            items={workflows}
            loading={loading}
            teamMembers={teamMembers}
            onFetchSummary={fetchSummary}
            onUpdateStatus={updateWorkflowStatus}
            onEdit={this.showEditForm}
          />
        </Wrapper>
      </div>
    );
  }
}

Reminders.propTypes = {
  loading: bool.isRequired,
  workflows: arrayOf(any).isRequired,
  teamMembers: objectOf(any).isRequired,
  fetchWorkflows: func.isRequired,
  updateWorkflowStatus: func.isRequired,
  fetchSummary: func.isRequired
};

export default Reminders;
