import React from "react";
import Timeline from "antd/es/timeline";
import Empty from "antd/es/empty";
import Icon from "antd/es/icon";
import Typography from "antd/es/typography";
import { arrayOf, objectOf, string } from "prop-types";

import AddTimeline from "./AddTimeline";

const Item = Timeline.Item;
const Text = Typography.Text;

const TimelineGroup = ({ data }) => (
  <div>
    <AddTimeline />

    {data.length === 0 ? (
      <Empty />
    ) : (
      <Timeline>
        {data
          .filter((d) => d !== null)
          .map((item, index) => (
            <Item key={index} color={item.color} dot={item.icon && <Icon type={item.icon} />}>
              <div className="time-line-value">{item.text}</div>
              <Text type="secondary">{item.date}</Text>
            </Item>
          ))}
      </Timeline>
    )}
  </div>
);

TimelineGroup.propTypes = {
  data: arrayOf(objectOf(string)).isRequired,
};

export default TimelineGroup;
