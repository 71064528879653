import React, { useState, useMemo, useCallback } from "react";
import lodash from "lodash";
import { objectOf, any, string } from "prop-types";
import Icon from "antd/es/icon";
import Button from "antd/es/button";

import firestoreService from "../../../services/firestoreService";

const ConversationSettings = ({ notifyId, conversation }) => {
  const [updating, setUpdating] = useState(false);

  const notification = useMemo(() => (
    lodash.find(conversation.notifications, { id: notifyId }) || { muted: true }
  ), [conversation, notifyId]);

  const toggle = useCallback(() => {
    (async () => {
      setUpdating(true);
      await firestoreService.setThreadNotification(
        conversation.id,
        notifyId,
        !notification.muted
      );
      setUpdating(false);
    })();
  }, [notification, conversation, notifyId]);

  return (
    <Button type="link" loading={updating} onClick={toggle}>
      {updating ? null : <Icon theme={notification.muted ? "outlined" : "filled"} type="bell" />}
    </Button>
  );
};

ConversationSettings.propTypes = {
  notifyId: string.isRequired,
  conversation: objectOf(any).isRequired,
};

export default ConversationSettings;
