import React from "react";
import Button from "antd/es/button";
import { func } from "prop-types";

const Footer = ({ onClose, onApply }) => (
  <div className="contacts-picker_footer">
    <Button style={{ marginRight: 8 }} onClick={onClose}>
      Cancel
    </Button>
    <Button onClick={onApply} type="primary">
      Apply
    </Button>
  </div>
);

Footer.propTypes = {
  onClose: func.isRequired,
  onApply: func.isRequired
};

export default Footer;
