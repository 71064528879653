import { batch } from "react-redux";
import * as firebase from "firebase/app";
import "firebase/auth";

import firestoreService from "../services/firestoreService";
import { getProfile, setAvailable } from "../services/api/teamMember";
import { refreshData } from "../utils/data";
import {
  FETCH_PROFILE_BEGIN,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  CURRENT_ACCOUNT_ID,
  CURRENT_ACCOUNT_SLUG,
  CURRENT_GROUP_SLUG,
  CURRENT_USER_ID,
  SET_PROFILE_EMAIL,
  APPLICATION_SET_ORG_API_KEY,
  CLEAR_PROFILE,
  CURRENT_USER_ROLE,
  DOMAINS_FOR_ADMIN,
  UPDATE_PROFILE_ATTRS,
  SET_AVAILABLE_STATE_BEGIN,
  SET_AVAILABLE_STATE_SUCCESS,
  SET_AVAILABLE_STATE_FAILURE,
  APPLICATION_SET_ORG_PHONE,
} from "../constants";

export const fetchProfile = (skipRefresh = false) => async (dispatch) => {
  dispatch({ type: FETCH_PROFILE_BEGIN });

  try {
    const result = await getProfile();
    const { profile, bot, groups } = result;

    localStorage.setItem(CURRENT_USER_ID, profile.notify_id);
    localStorage.setItem(CURRENT_ACCOUNT_ID, bot.id);
    localStorage.setItem(CURRENT_ACCOUNT_SLUG, bot.slug);
    localStorage.setItem(CURRENT_GROUP_SLUG, groups[0].slug);

    await batch(() => {
      dispatch({ type: FETCH_PROFILE_SUCCESS, data: result });
      dispatch({ type: APPLICATION_SET_ORG_API_KEY, apiKey: bot.api_key });
    });

    const orgPhoneData = await firestoreService.getOrgPhoneList(bot.slug);

    await dispatch({
      type: APPLICATION_SET_ORG_PHONE,
      orgPhoneData,
    });

    if (!skipRefresh) {
      await refreshData();
    }
  } catch (error) {
    dispatch({ type: FETCH_PROFILE_FAILURE, error });
  }
};

export const setProfileEmail = (email) => {
  if (DOMAINS_FOR_ADMIN.includes(email.split("@")[1])) {
    localStorage.setItem(CURRENT_USER_ROLE, "admin");
  } else {
    localStorage.removeItem(CURRENT_USER_ROLE);
  }

  return {
    type: SET_PROFILE_EMAIL,
    email,
  };
};

export const updateProfile = (data) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_PROFILE_ATTRS,
    attrs: {
      ...data,
    },
  });
};

export const signOut = () => async (dispatch) => {
  firestoreService.unsubscribeListenersForOrg();
  await Promise.all([
    localStorage.removeItem(CURRENT_USER_ROLE),
    localStorage.removeItem(CURRENT_USER_ID),
    localStorage.removeItem(CURRENT_ACCOUNT_ID),
    localStorage.removeItem(CURRENT_ACCOUNT_SLUG),
    localStorage.removeItem(CURRENT_GROUP_SLUG),
    firebase.auth().signOut(),
  ]);

  await dispatch({ type: CLEAR_PROFILE });
};

export const toggleAvailableState = () => async (dispatch, getState) => {
  const { notifyId, available } = getState().profile;
  const newState = !available;

  dispatch({ type: SET_AVAILABLE_STATE_BEGIN, available: newState });
  try {
    await setAvailable(notifyId, newState);
    dispatch({ type: SET_AVAILABLE_STATE_SUCCESS });
  } catch (e) {
    dispatch({ type: SET_AVAILABLE_STATE_FAILURE });
  }
};
