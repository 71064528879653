import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SignIn from "./SignIn";
import { setProfileEmail } from "../../../actions/profile";
import { setGlobalLoading } from "../../../actions/application";

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setProfileEmail,
      setGlobalLoading
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SignIn);
