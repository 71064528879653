import React from "react";
import List from "antd/es/list";
import Typography from "antd/es/typography";
import { any, objectOf } from "prop-types";

import { excludeUnnecessarily, orderStatuses } from "./utils";

const Item = List.Item;
const Meta = Item.Meta;
const Paragraph = Typography.Paragraph;

const Overview = ({ list }) => {
  const renderItem = status => {
    const membersIds = (status.id === "all" ? list.members : list.statusMembers[status.id]) || [];
    const len = membersIds.length;

    return (
      <Item key={status.id}>
        <Meta
          title={status.name}
          description={`${len} contact${len === 1 ? "" : "s"}`}
        />
      </Item>
    );
  };

  const statuses = orderStatuses(excludeUnnecessarily(list.statuses, list.statusMembers));

  return (
    <div>
      {list.description ? <Paragraph>{list.description}</Paragraph> : null}
      <List>
        {renderItem({ id: "all", name: "All contacts" })}
        {statuses.map(renderItem)}
      </List>
    </div>
  );
};

Overview.propTypes = {
  list: objectOf(any).isRequired
};

export default Overview;
