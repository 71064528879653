import moment from "moment";
import { WORKFLOW_STATUS_MAPPING } from "../constants";

export const formattedInfo = data => {
  const type = data.send_automatically ? "Automatic" : "Manual";
  const status = WORKFLOW_STATUS_MAPPING[data.status] || "";
  const start = (data.end_at && moment(new Date(data.start_at)).format("MMMM DD, YYYY")) || null;
  const end = (data.end_at && moment(new Date(data.end_at)).format("MMMM DD, YYYY")) || null;

  return { type, status, start, end };
};
