export class TagsStateAdding {
  __default__ = [];
  __added__ = [];
  __deleted__ = [];

  constructor(tags) {
    this.__default__ = tags;
  }

  add = id => {
    this.__added__.push(id);
  };

  remove = id => {
    if (this.__added__.includes(id)) {
      this.__added__.splice(this.__added__.indexOf(id), 1);
    } else {
      this.__deleted__.push(id);
    }
  };

  clear = () => {
    this.__added__ = [];
    this.__deleted__ = [];
  };

  buildDataResult = tags => {
    const data = {
      adds: [],
      removes: [],
      tags
    };

    this.__added__.forEach(id => {
      if (tags.includes(id)) {
        data.adds.push(id);
      }
    });

    this.__deleted__.forEach(id => {
      if (this.__default__.includes(id)) {
        data.removes.push(id);
      }
    });

    return data;
  };
}
