import React from "react";
import Typography from "antd/es/typography";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { string, objectOf, any } from "prop-types";

const Text = Typography.Text;

const Field = ({ label, value, extra }) => (
  <Row className="profile-field" gutter={16}>
    <Col span={5}>
      <Text className="profile-field_label">{label}:</Text>
    </Col>
    <Col span={19}>
      {value ? <Text strong>{value}</Text> : null}
      {extra}
    </Col>
  </Row>
);

Field.propTypes = {
  label: string.isRequired,
  value: string,
  extra: objectOf(any),
};

Field.defaultProps = {
  value: null,
  extra: null,
};

export default Field;

