import React, { useCallback, useState } from "react";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import Drawer from "antd/es/drawer";
import { objectOf, any, func } from "prop-types";

import "./style.css";
import Content from "./Content";

const WorkflowSummary = ({ teamMembers, workflow, onFetchSummary }) => {
  const [visible, setVisible] = useState(false);

  const callbackToggleSummary = useCallback(() => {
    if (!visible) {
      onFetchSummary(workflow.id);
    }
    setVisible(!visible);
  }, [workflow, visible, onFetchSummary, setVisible]);

  return (
    <div>
      <Button block type="primary" onClick={callbackToggleSummary}>
        Summary
      </Button>

      <Drawer
        title="Summary"
        width={600}
        visible={visible}
        onClose={callbackToggleSummary}
      >
        {workflow.loadingSummary && !workflow.summaryDetails ? (
          <div className="full-spin-wrapper">
            <Spin />
          </div>
        ) : (
          <Content teamMembers={teamMembers} workflow={workflow} />
        )}
      </Drawer>
    </div>
  );
};

WorkflowSummary.propTypes = {
  teamMembers: objectOf(any).isRequired,
  workflow: objectOf(any).isRequired,
  onFetchSummary: func.isRequired
};

export default WorkflowSummary;
