export default {
  labels: {
    phones: [
      "home",
      "work",
      "iPhone",
      "mobile",
      "main",
      "home fax",
      "work fax",
      "pager",
      "other"
    ],
    emails: ["home", "work", "iCloud", "other"],
    addresses: ["home", "work", "other"],
    anniversaries: ["anniversary", "birthday", "other"]
  }
};
