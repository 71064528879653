import moment from "moment";

export const buildFormData = ({
  firstName,
  lastName,
  company,
  tags,
  properties: {
    phones,
    emails,
    addresses,
    anniversaries
  },
}) => {
  const phoneProperties = phones
    .filter(r => r.value)
    .map((phone, index) => ({
      is_primary: index === 0,
      label: phone.label,
      phone_text: phone.value
    }));

  const emailProperties = emails
    .filter(r => r.value)
    .map((email, index) => ({
      is_primary: index === 0,
      label: email.label,
      name: "Email",
      value: email.value
    }));

  const addressProperties = addresses
    .filter(r => (r.value.street || r.value.city || r.value.state || r.value.zip))
    .map((address, index) => ({
      is_primary: index === 0,
      label: address.label,
      name: "Address",
      value: address.value
    }));

  const anniversaryProperties = anniversaries
    .filter(r => r.value)
    .map(a => ({
      name: `Event ${a.label}`,
      value: moment(a.value).format("YYYY/MM/DD")
    }));

  const companies = company ? [{
    id: company.isNew ? undefined : company.id,
    name: "Company",
    value: company.value
  }] : [];

  return {
    first_name: firstName,
    last_name: lastName,
    phones: phoneProperties,
    tags: tags.map(tag => ({ name: tag })),
    labeled_properties: [
      ...emailProperties,
      ...addressProperties
    ],
    properties: [
      ...anniversaryProperties,
      ...companies
    ]
  };
};
