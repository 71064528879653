import axios from "axios";
import lodash from "lodash";

import { currentUserToken } from "../utils/auth";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000
});

client.interceptors.request.use(
  config => {
    const token = currentUserToken();
    if (token) {
      lodash.set(config, "headers.Authorization", `Bearer ${token}`);
      config.metadata = { startTime: new Date() };
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const request = async options => {
  const onSuccess = response => {
    return response.data;
  };

  const onError = error => {
    try {
      if (error.response.data.detail.toLowerCase().includes("token expired")) {
        // Force redirect to sign in page
        const { pathname } = window.location;
        if (!pathname.includes("sign_in") && !pathname.includes("callback")) {
          window.location.href = "/sign_in";
        }
      }
    } catch (e) {}

    return Promise.reject(error.response || error.message);
  };

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

export default request;
