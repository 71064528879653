import React, { Fragment, useState, useCallback } from "react";
import Form from "antd/es/form";
import Divider from "antd/es/divider";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import Modal from "antd/es/modal";
import { string, func, arrayOf } from "prop-types";

import ProductLaunch from "../WorkflowForms/ProductLaunch";

const { Item } = Form;

const AddWorkflow = ({ fetchWorkflows }) => {
  const [visible, setVisible] = useState(false);
  const [typeOfCampaign, setTypeOfCampaign] = useState(null);

  const callbackToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible, setVisible]);

  const showPersonalize = useCallback(() => {
    setTypeOfCampaign("personalize");
    setVisible(false);
  }, []);

  const showAutomatic = useCallback(() => {
    setTypeOfCampaign("automatic");
    setVisible(false);
  }, []);

  const cancel = useCallback(() => {
    setTypeOfCampaign(null);
  }, []);

  return (
    <Fragment>
      <Button type="primary" onClick={callbackToggleVisible}>
        <Icon type="plus-circle" />
        Add
      </Button>
      <Modal
        title="Type of Campaign"
        footer={null}
        visible={visible}
        onCancel={callbackToggleVisible}
      >
        <Item help="Messages will be queued up for team members to send so that each message can be personalized.">
          <Button block type="primary" onClick={showPersonalize}>
            <Icon type="solution1" />
            Personalize Messages
          </Button>
        </Item>
        <Divider />
        <Item help="Messages will be sent atomically starting at the scheduled time.">
          <Button block type="primary" onClick={showAutomatic}>
            <Icon type="fork" />
            Automatic
          </Button>
        </Item>
      </Modal>

      <Modal
        title="New Workflow"
        footer={null}
        width={800}
        visible={!!typeOfCampaign}
        onCancel={cancel}
      >
        {typeOfCampaign ? (
          <ProductLaunch
            typeOfCampaign={typeOfCampaign}
            fetchWorkflows={fetchWorkflows}
            onSuccess={cancel}
          />
        ) : null}
      </Modal>
    </Fragment>
  );
};

AddWorkflow.propTypes = {
  fetchWorkflows: func.isRequired
};

export default AddWorkflow;
