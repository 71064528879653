import { connect } from "react-redux";

import AdminTopNavigation from "./AdminTopNavigation";

const mapStateToProps = ({ routing }) => ({
  pathname: routing
    .locationBeforeTransitions
    .pathname
    .replace("/", "")
});

export default connect(mapStateToProps)(AdminTopNavigation);
