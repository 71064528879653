import React from "react";
import en_US from "antd/lib/locale-provider/en_US";
import { LocaleProvider, Layout } from "antd";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { syncHistoryWithStore } from "react-router-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./App.css";
import "./services/firebaseSetting";
import browserHistory from "./browserHistory";
import store, { persistor } from "./store/configureStore";
import Routes from "./Routes";
import Loading from "./HOC/Loading";
import CheckingPhoneNumber from "./HOC/CheckingPhoneNumber";
import GlobalLoading from "./components/GlobalLoading";

const history = syncHistoryWithStore(browserHistory, store);
const { Content } = Layout;

function isOnboarding(history) {
  try {
    return history.location.pathname.includes("sign_up");
  } catch (e) {
    return false;
  }
}

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocaleProvider locale={en_US}>
        <Router history={history}>
          <Layout
            style={{
              minHeight: "100vh",
              background: isOnboarding(history) ? "#fff" : undefined
            }}
          >
            <Content className="layout-center-wrapper">
              <GlobalLoading />
              <Loading>
                <CheckingPhoneNumber>
                  <Routes />
                </CheckingPhoneNumber>
              </Loading>
            </Content>
          </Layout>
        </Router>
      </LocaleProvider>
    </PersistGate>
  </Provider>
);

export default App;
