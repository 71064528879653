import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchWorkflows, updateWorkflowStatus, fetchSummary } from "../actions/workflow";

const mapStateToProps = ({
  workflow: {
    workflows,
    loadingWorkflows
  },
  application: {
    teamMembers
  }
}) => ({
  workflows,
  teamMembers,
  loading: loadingWorkflows
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchWorkflows,
  updateWorkflowStatus,
  fetchSummary
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps);
