import React, { useCallback, useState } from "react";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import Modal from "antd/es/modal";
import Tabs from "antd/es/tabs";

import "./style.css";
import Note from "./Note";
import Purchase from "./Purchase";
import StoreVisit from "./StoreVisit";

const TabPane = Tabs.TabPane;

const AddTimeline = () => {
  const [visible, setVisible] = useState(false);
  const callbackToggle = useCallback(() => {
    setVisible(!visible);
  }, [visible, setVisible]);

  return (
    <div>
      <Button
        type="dashed"
        style={{ width: "100%", marginBottom: 15 }}
        onClick={callbackToggle}
      >
        <Icon type="plus" /> Add note, activity, or task
      </Button>

      <Modal
        title="New Timeline"
        bodyStyle={{ paddingTop: 0, paddingLeft: 0 }}
        footer={null}
        visible={visible}
        onCancel={callbackToggle}
      >
        <Tabs
          tabBarStyle={{ width: 100 }}
          defaultActiveKey="1"
          tabPosition="left"
          className="new-time-line-tabs-wrapper"
        >
          <TabPane tab="User Note" key="1">
            <Note onDone={callbackToggle} />
          </TabPane>
          <TabPane tab="Store Visit" key="2">
            <StoreVisit onDone={callbackToggle} />
          </TabPane>
          <TabPane tab="Purchase" key="3">
            <Purchase onDone={callbackToggle} />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default AddTimeline;
