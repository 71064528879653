import React from "react";
import Menu from "antd/es/menu";
import Icon from "antd/es/icon";
import { string } from "prop-types";
import { Link } from "react-router-dom";

const Item = Menu.Item;

const AdminTopNavigation = ({ pathname }) => (
  <Menu mode="horizontal" defaultSelectedKeys={[pathname || "admin"]}>
    <Item key="admin">
      <Link to="/admin">
        <Icon type="dashboard" />
        <span>Dashboard</span>
      </Link>
    </Item>
    <Item key="admin/export">
      <Link to="/admin/export">
        <Icon type="export" />
        <span>Export CSV</span>
      </Link>
    </Item>
  </Menu>
);

AdminTopNavigation.propTypes = {
  pathname: string.isRequired
};

export default AdminTopNavigation;
