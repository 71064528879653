export const COLORS = [
  "#007aff",
  "#8e8e93",
  "#34c759",
  "#5856d6",
  "#ff9500",
  "#ff2d55",
  "#af52de",
  "#ff3b30",
  "#5ac8fa",
  "#ffcc00"
];
