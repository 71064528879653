import React from "react";
import Button from "antd/es/button";
import { arrayOf, any } from "prop-types";

import "./style.css";

const Actions = ({ actions }) => (
  <div className="inbox-panel-actions">
    {actions.map(action => (
      <div>
        {action.label ? <span>{action.label}</span> : null}
        <Button
          key={action.key}
          type="primary"
          loading={action.loading}
          onClick={action.onAction}
        >
          {action.button}
        </Button>
      </div>
    ))}
  </div>
);

Actions.propTypes = {
  actions: arrayOf(any)
};

Actions.defaultProps = {
  actions: []
};

export default Actions;
