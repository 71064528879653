import React, { Fragment, useCallback, useEffect, useState } from "react";
import moment from "moment";
import lodash from "lodash";
import Spin from "antd/es/spin";
import {
  arrayOf,
  objectOf,
  any,
  bool,
  string,
  func
} from "prop-types";

import "./style.css";
import Actions from "../Actions";
import Compose from "../Compose";
import Bubble from "../Bubble";
import SystemMessage from "../SystemMessage";
import GroupDate from "../GropDate";
import ButtonPreload from "../Preload";
import firestoreService from "../../../services/firestoreService";
import { moveScroolToBottom } from "../../../utils/messaging";

let prevDate = null;

const Message = ({
  currentUserId,
  message,
  sender,
  contact,
  onRetrySend,
}) => {
  const deleteMessageCallback = useCallback(() => {
    firestoreService.deleteMessage(message.refId).then(() => {});
  }, [message]);

  const retryMessageCallback = useCallback(() => {
    onRetrySend(message);
  }, [message]);

  const updateScheduleMessageCallback = useCallback((_, date) => {
    firestoreService.updateMessage(message.refId, {
      scheduledAt: new Date(date)
    }).then(() => {});
  }, [message]);

  const formattedDate = moment(message.createdAt).format("MMM D, YYYY");
  let showDate = true;
  if (prevDate && prevDate === formattedDate) {
    showDate = false;
  } else {
    prevDate = formattedDate
  }

  return (
    <Fragment>
      {showDate ? <GroupDate date={formattedDate} /> : null}

      {message.teamMemberId === "NotifyBot" ? (
        <SystemMessage message={message.bodyText} />
      ) : (
        <Bubble
          scheduledAt={message.scheduledAt}
          isOutgoing={!message.contactAddress}
          message={message.bodyText}
          errorMessage={message.errorMessage}
          canRetry={message.canRetry}
          deliveryStatus={message.deliveryStatus}
          updatedAt={message.createdAt}
          imageUrl={message.imageUrl && message.imageUrl}
          contactName={contact && contact.name}
          contactAvatar={contact && contact.avatar}
          senderName={sender && [sender.first_name, sender.last_name].join(" ")}
          senderIsMe={currentUserId === message.teamMemberId}
          onUpdateSchedule={updateScheduleMessageCallback}
          onDelete={deleteMessageCallback}
          onRetry={retryMessageCallback}
        />
      )}
    </Fragment>
  );
};

const Body = ({
  customCompose,
  contact,
  currentConversationId,
  currentUserId,
  replyState,
  replyStateLoading,
  messages,
  teamMembers,
  hasPrevPage,
  isLoading,
  isLoadingPrev,
  fetchMessages,
  noReply,
  onRetrySend
}) => {
  const [composeHeight, setComposeHeight] = useState(null);

  const changeComposeHeight = useCallback(height => {
    setComposeHeight(height);
    moveScroolToBottom();
  }, []);

  const preloadCallback = useCallback(e => {
    if (!isLoadingPrev) {
      fetchMessages(currentConversationId, true);
    }
  }, [
    isLoadingPrev,
    currentConversationId,
    fetchMessages
  ]);

  useEffect(() => {
    prevDate = null;
  }, [currentConversationId]);

  const actions = [];
  if (replyState === "Awaiting TM") {
    actions.push({
      key: "noReply",
      label: "Waiting for reply",
      button: "No Reply Required",
      loading: replyStateLoading,
      onAction: noReply
    });
  }

  const items = lodash.sortBy(
    lodash.map(messages, msg => ({
      ...msg,
      createdAt: msg.scheduledAt ? msg.scheduledAt : msg.createdAt
    })),
    "createdAt"
  );

  return (
    <div className="inbox-body">
      {isLoading ? (
        <div className="inbox-body-spin-wrapper">
          <Spin/>
        </div>
      ) : null}

      {currentConversationId ? (
        <div
          id="elementMessagesList"
          className="messages-list"
          style={{
            marginTop: actions.length > 0 ? "var(--inbox-conversation-actions-height)" : 0,
            ...(composeHeight ? { bottom: composeHeight } : {})
          }}
        >
          {hasPrevPage && !isLoading ? (
            <ButtonPreload busy={isLoadingPrev} onClick={preloadCallback} />
          ) : null}

          {items.map(message => (
            <Message
              key={message.key}
              currentUserId={currentUserId}
              message={message}
              sender={teamMembers[message.teamMemberId || message.author]}
              contact={contact}
              onRetrySend={onRetrySend}
            />
          ))}
        </div>
      ) : null}

      {actions.length > 0 ? <Actions actions={actions} /> : null}

      {customCompose ? customCompose : (
        <Compose contactId={contact && contact.id} onHeightChanged={changeComposeHeight} />
      )}
    </div>
  );
};

Message.propTypes = {
  currentUserId: string.isRequired,
  message: objectOf(any).isRequired,
  sender: objectOf(any),
  contact: objectOf(any),
};

Message.defaultProps = {
  sender: null,
  contact: null
};

Body.propTypes = {
  contact: objectOf(any),
  currentConversationId: string,
  currentUserId: string,
  replyState: string,
  replyStateLoading: bool,
  messages: arrayOf(any),
  sender: objectOf(any),
  hasPrevPage: bool,
  isLoading: bool,
  isLoadingPrev: bool,
  isSelected: bool,
  teamMembers: objectOf(any),
  fetchMessages: func.isRequired,
  noReply: func.isRequired,
  onRetrySend: func.isRequired,
};

Body.defaultProps = {
  customCompose: null,
  contact: null,
  currentConversationId: null,
  currentUserId: null,
  replyState: null,
  replyStateLoading: false,
  messages: [],
  sender: null,
  hasPrevPage: false,
  isLoading: false,
  isLoadingPrev: false,
  isSelected: false
};

export default Body;
