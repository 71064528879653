import React, { useEffect, useState, useCallback, useRef } from "react";
import Typography from "antd/es/typography";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import { bool, string, number, func } from "prop-types";

import CustomFields from "../../CustomFields";

const Title = Typography.Title;
const TextArea = Input.TextArea;

const Preparing = ({
  loading,
  taskId,
  workflowId,
  templateText,
  totalContacts,
  onStartWorkflow,
  onFetchWorkflowContacts,
  onChanged
}) => {
  const [template, setTemplate] = useState(templateText || "");

  const callbackChangeTemplate = useCallback(({ target: { value } }) => {
    onChanged(value !== templateText);
    setTemplate(value);
  }, [templateText, setTemplate, onChanged]);

  const callbackStart = useCallback(() => {
    onStartWorkflow(template);
  }, [template, onStartWorkflow]);

  const messageRef = useRef(null);

  useEffect(() => {
    onFetchWorkflowContacts(taskId, workflowId);
  }, [onFetchWorkflowContacts, taskId, workflowId]);

  return (
    <div className="overflowY">
      <Row>
        <Col span={12} offset={6}>
          <Title level={2}>Ready to Start?</Title>

          <p>
            We have {loading ? "..." : totalContacts} clients to
            invite, we'll slide from conversation to
            conversation one after the other, until you're done.
          </p>

          <TextArea
            autoFocus
            rows={6}
            ref={messageRef}
            value={template}
            onChange={callbackChangeTemplate}
          />
          <CustomFields inputRef={messageRef} onChanged={onChanged} />

          <Divider />

          <Button
            block
            loading={loading}
            disabled={!loading && totalContacts === 0}
            type="primary"
            onClick={callbackStart}
          >
            Start Workflow
          </Button>
        </Col>
      </Row>
    </div>
  );
};

Preparing.propTypes = {
  loading: bool.isRequired,
  taskId: number.isRequired,
  workflowId: number.isRequired,
  templateText: string.isRequired,
  totalContacts: number.isRequired,
  onStartWorkflow: func.isRequired,
  onFetchWorkflowContacts: func.isRequired
};

export default Preparing;
