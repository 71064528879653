import request from "../request";
import { accountSlug } from "../../utils/other";


const getOrgTags = async () => await request({
  url: `/org/${accountSlug()}/tag-list`,
  method: "GET"
});

const getTwilioToken = async (accountId, nid) => await request({
  url: `/bot/${accountId}/access_token`,
  params: {
    nid
  }
});

const updateOrgTags = async data => await request({
  url: `/org/${accountSlug()}/update_tags/`,
  method: "POST",
  data: {
    adds: data.adds,
    removes: data.removes
  }
});

export { getOrgTags, getTwilioToken, updateOrgTags };
