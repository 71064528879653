import React from "react";
import Modal from "antd/es/modal";
import { string, bool, func, any, arrayOf, objectOf } from "prop-types";

import ProductLaunch from "../WorkflowForms/ProductLaunch";
import Automation from "../WorkflowForms/Automation";

const EditWorkflow = ({
  visible,
  automation,
  editWorkflowData,
  fetchWorkflows,
  onCancel
}) => (
  <Modal
    title="Edit Workflow"
    footer={null}
    width={800}
    visible={visible}
    onCancel={onCancel}
  >
    {editWorkflowData ? (
      automation ? (
        <Automation
          dataInit={editWorkflowData}
          fetchWorkflows={fetchWorkflows}
          onSuccess={onCancel}
        />
      ) : (
        <ProductLaunch
          dataInit={editWorkflowData}
          fetchWorkflows={fetchWorkflows}
          onSuccess={onCancel}
        />
      )
    ) : (
      <div />
    )}
  </Modal>
);

EditWorkflow.propTypes = {
  visible: bool,
  automation: bool,
  editWorkflowData: objectOf(any),
  fetchWorkflows: func.isRequired,
  onCancel: func.isRequired
};

EditWorkflow.defaultProps = {
  visible: false,
  automation: false,
  editWorkflowData: null,
};

export default EditWorkflow;
