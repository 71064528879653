import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Profile from "./Profile";
import { updateTimezone } from "../../../actions/application";

const mapStateToProps = ({
  profile: { notifyId, firstName, lastName, email, phone, mobileNumber },
  application: { orgTimezoneName, orgPhoneList, orgForwardingPhoneNumber },
}) => ({
  notifyId,
  firstName,
  lastName,
  email,
  phone,
  mobileNumber,
  orgTimezoneName,
  orgPhoneList,
  orgForwardingPhoneNumber,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onUpdateTimezone: updateTimezone,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
