import lodash from "lodash";

export const filteringByFilter = (workflows, filter) =>
  lodash.filter(
    workflows,
    w =>
      (filter === "automations" && w.category === "Basics") ||
        (w.category !== "Basics" && (
          filter === "all" ||
          (filter === "draft" && filter === w.status) ||
          (filter === "archived" && filter === w.status) ||
          (filter === "completed" && filter === w.status) ||
          (filter === "active" && filter === w.status) ||
          (filter === "paused" && w.status === "disabled")
        ))
  );
