import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { startNewConversation } from "../actions/conversations";

const mapDispatchToProps = dispatch => bindActionCreators({
  startNewConversation
}, dispatch);

export default connect(null, mapDispatchToProps);
