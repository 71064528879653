export const PERSISTS_REHYDRATE = "persist/REHYDRATE";

export const SET_CURRENT_CONVERSATION = "SET_CURRENT_CONVERSATION";
export const SET_FILTER_CONVERSATIONS = "SET_FILTER_CONVERSATIONS";
export const SET_FILTER_2_CONVERSATIONS = "SET_FILTER_2_CONVERSATIONS";
export const UPDATE_ATTRS_CONVERSATION = "UPDATE_ATTRS_CONVERSATION";
export const SET_SCHEDULED_CONVERSATIONS = "SET_SCHEDULED_CONVERSATIONS";
export const REMOVE_SCHEDULED_CONVERSATIONS = "SET_SCHEDULED_CONVERSATIONS";

export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const MESSAGES_ADD_MESSAGE = "MESSAGES_ADD_MESSAGE";
export const MESSAGES_UPDATE_MESSAGE = "MESSAGES_UPDATE_MESSAGE";
export const MESSAGES_REMOVE_MESSAGE = "MESSAGES_REMOVE_MESSAGE";

export const APPLICATION_SET_GLOBAL_LOADING = "APPLICATION_SET_GLOBAL_LOADING";
export const FETCH_APP_DATA_BEGIN = "FETCH_APP_DATA_BEGIN";
export const FETCH_APP_DATA_SUCCESS = "FETCH_APP_DATA_SUCCESS";
export const FETCH_APP_DATA_FAILURE = "FETCH_APP_DATA_FAILURE";
export const UPDATE_APP_TAGS = "UPDATE_APP_TAGS";
export const TOGGLE_VISIBLE_NEW_CONVERSATION = "TOGGLE_VISIBLE_NEW_CONVERSATION";
export const HIDE_NEW_CONVERSATION = "HIDE_NEW_CONVERSATION";
export const APPLICATION_UPDATE_TAGS_BEGIN = "APPLICATION_UPDATE_TAGS_BEGIN";
export const APPLICATION_UPDATE_TAGS_SUCCESS = "APPLICATION_UPDATE_TAGS_SUCCESS";
export const APPLICATION_UPDATE_TAGS_FAILURE = "APPLICATION_UPDATE_TAGS_FAILURE";
export const APPLICATION_SET_ORG_PHONE = "APPLICATION_SET_ORG_PHONE";
export const APPLICATION_SET_ORG_TIMEZONE = "APPLICATION_SET_ORG_TIMEZONE";
export const APPLICATION_SET_ORG_API_KEY = "APPLICATION_SET_ORG_API_KEY";

export const FETCH_PROFILE_BEGIN = "FETCH_PROFILE_BEGIN";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const SET_PROFILE_EMAIL = "SET_PROFILE_EMAIL";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const UPDATE_PROFILE_ATTRS = "UPDATE_PROFILE_ATTRS";

export const SET_AVAILABLE_STATE_BEGIN = "SET_AVAILABLE_STATE_BEGIN";
export const SET_AVAILABLE_STATE_SUCCESS = "SET_AVAILABLE_STATE_SUCCESS";
export const SET_AVAILABLE_STATE_FAILURE = "SET_AVAILABLE_STATE_FAILURE";

export const FETCH_CONTACTS_BEGIN = "FETCH_CONTACTS_BEGIN";
export const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
export const FETCH_CONTACTS_FAILURE = "FETCH_CONTACTS_FAILURE";

export const CREATE_CONTACTS_BEGIN = "CREATE_CONTACTS_BEGIN";
export const CREATE_CONTACTS_STOP = "CREATE_CONTACTS_STOP";
export const CREATE_CONTACTS_SUCCESS = "CREATE_CONTACTS_SUCCESS";
export const CREATE_CONTACTS_FAILURE = "CREATE_CONTACTS_FAILURE";

export const DELETE_CONTACT = "DELETE_CONTACT";

export const UPDATE_CONTACT_ATTRS = "UPDATE_CONTACT_ATTRS";
export const SET_CURRENT_CONTACT_ID = "SET_CURRENT_CONTACT_ID";
export const SET_IMPORTED = "SET_IMPORTED";

export const FETCH_THREADS_SUCCESS = "FETCH_THREADS_SUCCESS";
export const FETCH_THREADS_BEGIN = "FETCH_THREADS_BEGIN";

export const FETCH_ORGS_BEGIN = "FETCH_ORGS_BEGIN";
export const FETCH_ORGS_SUCCESS = "FETCH_ORGS_SUCCESS";
export const FETCH_ORGS_FAILURE = "FETCH_ORGS_FAILURE";

export const WORKFLOW_FETCH_BEGIN = "WORKFLOW_FETCH_BEGIN";
export const WORKFLOW_FETCH_SUCCESS = "WORKFLOW_FETCH_SUCCESS";
export const WORKFLOW_FETCH_FAILURE = "WORKFLOW_FETCH_FAILURE";
export const WORKFLOW_UPDATE_ATTRS = "WORKFLOW_UPDATE_ATTRS";

export const TASKS_FETCH_TASKS_BEGIN = "TASKS_FETCH_TASKS_BEGIN";
export const TASKS_FETCH_TASKS_SUCCESS = "TASKS_FETCH_TASKS_SUCCESS";
export const TASKS_FETCH_TASKS_FAILURE = "TASKS_FETCH_TASKS_FAILURE";
export const TASKS_FETCH_TASK_CONTACTS_BEGIN = "TASKS_FETCH_TASK_CONTACTS_BEGIN";
export const TASKS_FETCH_TASK_CONTACTS_SUCCESS = "TASKS_FETCH_TASK_CONTACTS_SUCCESS";
export const TASKS_FETCH_TASK_CONTACTS_FAILURE = "TASKS_FETCH_TASK_CONTACTS_FAILURE";
export const TASKS_START_TASK = "TASKS_START_TASK";
export const TASKS_STOP_TASK = "TASKS_STOP_TASK";
export const TASKS_SET_CURRENT_CONTACT = "TASKS_SET_CURRENT_CONTACT";
export const TASKS_SET_CURRENT_TEMPLATE = "TASKS_SET_CURRENT_TEMPLATE";
export const TASKS_REMOVE_CONTACT = "TASKS_REMOVE_CONTACT";
export const TASKS_UPDATE_CONTACT_ATTRS = "TASKS_UPDATE_CONTACT_ATTRS";

export const FETCH_TEMPLATE_BEGIN = "FETCH_TEMPLATE_BEGIN";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";
export const FETCH_TEMPLATE_FAILURE = "FETCH_TEMPLATE_FAILURE";
export const CREATE_TEMPLATE_BEGIN = "CREATE_TEMPLATE_BEGIN";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";
export const CREATE_TEMPLATE_FAILURE = "CREATE_TEMPLATE_FAILURE";
export const UPDATE_TEMPLATE_BEGIN = "UPDATE_TEMPLATE_BEGIN";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAILURE = "UPDATE_TEMPLATE_FAILURE";

export const FETCH_CALENDAR_EVENT_TEMPLATE_BEGIN = "FETCH_CALENDAR_EVENT_TEMPLATE_BEGIN";
export const FETCH_CALENDAR_EVENT_TEMPLATE_SUCCESS = "FETCH_CALENDAR_EVENT_TEMPLATE_SUCCESS";
export const FETCH_CALENDAR_EVENT_TEMPLATE_FAILURE = "FETCH_CALENDAR_EVENT_TEMPLATE_FAILURE";
export const CREATE_CALENDAR_EVENT_TEMPLATE_BEGIN = "CREATE_CALENDAR_EVENT_TEMPLATE_BEGIN";
export const CREATE_CALENDAR_EVENT_TEMPLATE_SUCCESS = "CREATE_CALENDAR_EVENT_TEMPLATE_SUCCESS";
export const CREATE_CALENDAR_EVENT_TEMPLATE_FAILURE = "CREATE_CALENDAR_EVENT_TEMPLATE_FAILURE";
export const UPDATE_CALENDAR_EVENT_TEMPLATE_BEGIN = "UPDATE_CALENDAR_EVENT_TEMPLATE_BEGIN";
export const UPDATE_CALENDAR_EVENT_TEMPLATE_SUCCESS = "UPDATE_CALENDAR_EVENT_TEMPLATE_SUCCESS";
export const UPDATE_CALENDAR_EVENT_TEMPLATE_FAILURE = "UPDATE_CALENDAR_EVENT_TEMPLATE_FAILURE";

export const FETCH_LISTS_BEGIN = "FETCH_LISTS_BEGIN";
export const FETCH_LISTS_SUCCESS = "FETCH_LISTS_SUCCESS";
export const FETCH_LISTS_FAILURE = "FETCH_LISTS_FAILURE";
export const ADD_LIST_BEGIN = "ADD_LIST_BEGIN";
export const ADD_LIST_SUCCESS = "ADD_LIST_SUCCESS";
export const ADD_LIST_FAILURE = "ADD_LIST_FAILURE";
export const UPDATE_LIST_BEGIN = "UPDATE_LIST_BEGIN";
export const UPDATE_LIST_FAILURE = "UPDATE_LIST_FAILURE";
export const UPDATE_LIST_ATTRS = "UPDATE_LIST_ATTRS";

export const CURRENT_ACCOUNT_ID = "currentAccountId";
export const CURRENT_ACCOUNT_SLUG = "currentAccountSlug";
export const CURRENT_GROUP_SLUG = "currentGroupSlug";
export const CURRENT_USER_ID = "currentUserId";
export const CURRENT_USER_ROLE = "currentUserRole";

export const DOMAINS_FOR_ADMIN = ["notify.io"];
export const PHONE_MASK_SETTING = { dddMask: "+1 (999) " };

export const WORKFLOW_STATUS_MAPPING = {
  active: "Running",
  disabled: "Paused",
  completed: "Completed",
  archived: "Archived",
  draft: "Draft",
};

export const WORKFLOW_CONTACT_DELIVERY_STATUS = {
  scheduled: "Scheduled to Send",
  ready: "Ready to Send",
  sent: "Sent",
  failed: "Delivery Error",
  replied: "Replied",
  skipped: "Skipped",
  done: "Done",
};

export const DEFAULT_TIMEZONE = "America/Los_Angeles";
