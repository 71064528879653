import React, { useCallback } from "react";
import List from "antd/es/list";
import Button from "antd/es/button"
import { any, func, objectOf } from "prop-types";

const Item = List.Item;
const Meta = Item.Meta;

const TemplateItem = ({ data: { id, name, templateText }, onEdit }) => {
  const callbackEdit = useCallback(() => {
    onEdit(id);
  }, [id, onEdit]);

  return (
    <Item
      actions={[
        <Button type="link" onClick={callbackEdit}>Edit</Button>
      ]}
    >
      <Meta
        title={name}
        description={templateText}
      />
    </Item>
  );
};

TemplateItem.propTypes = {
  data: objectOf(any),
  onEdit: func.isRequired
};

export default TemplateItem;
