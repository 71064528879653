import {
  FETCH_ORGS_BEGIN,
  FETCH_ORGS_SUCCESS,
  FETCH_ORGS_FAILURE
} from "../constants";

const initialState = {
  orgs: [],
  fetchingOrgs: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGS_BEGIN:
      return {
        ...state,
        fetchingOrgs: true
      };
    case FETCH_ORGS_SUCCESS:
      return {
        ...state,
        orgs: action.orgs.filter(item => item.slug),
        fetchingOrgs: false
      };
    case FETCH_ORGS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetchingOrgs: false
      };
    default:
      return {
        ...state
      };
  }
};
