import React from "react";
import Input from "antd/es/input";
import Select from "antd/es/select";
import { string, func } from "prop-types";

import dataInfo from "./data";

const Option = Select.Option;

const Email = ({
  label,
  value,
  onChangeLabel,
  onChange
}) => {
  const labels = dataInfo.labels.emails;
  if (label && !labels.includes(label)) {
    labels.push(label);
  }

  return (
    <Input
      placeholder="Enter Email"
      type="email"
      addonBefore={(
        <Select
          placeholder="Select Label"
          style={{ width: 200 }}
          value={label}
          onChange={onChangeLabel}
        >
          {labels.map(l => (
            <Option key={l} value={l}>{l}</Option>
          ))}
        </Select>
      )}
      value={value}
      onChange={onChange}
    />
  );
};

Email.propTypes = {
  label: string,
  value: string.isRequired,
  onChangeLabel: func.isRequired,
  onChange: func.isRequired
};

Email.defaultProps = {
  label: null
};

export default Email;
