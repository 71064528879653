import React, { useCallback, useState } from "react";
import Popover from "antd/es/popover";
import Icon from "antd/es/icon";
import { arrayOf, bool, string, func } from "prop-types";

import "./style.css";

const ColorItem = ({ selected, color, onChange }) => {
  const callbackSelectColor = useCallback(() => {
    onChange({ color });
  }, [color, onChange]);

  return (
    <div
      className="select-color-item"
      style={{ background: color }}
      onClick={callbackSelectColor}
    >
      {selected ? <Icon type="check" /> : null}
    </div>
  );
};

const ColorPickerContent = ({ colors, selectedColor, onChange }) => (
  <div className="select-color-wrapper">
    {colors.map((color, index) => (
      <ColorItem
        key={index}
        selected={selectedColor === color}
        color={color}
        onChange={onChange}
      />
    ))}
  </div>
);

const ColorPicker = ({ colors, value, onChange }) => {
  const [visible, setVisible] = useState(false);

  const callbackToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible, setVisible]);

  const callbackChange = useCallback(color => {
    setVisible(false);
    onChange(color);
  }, [onChange]);

  return (
    <Popover
      title={null}
      content={(
        <ColorPickerContent
          colors={colors}
          selectedColor={value}
          onChange={callbackChange}
        />
      )}
      visible={visible}
      onVisibleChange={callbackToggleVisible}
    >
      <div
        className="color-indicator"
        style={{ background: value }}
      />
    </Popover>
  );
};

ColorItem.propTypes = {
  selected: bool,
  color: string.isRequired,
  onChange: func.isRequired
};

ColorItem.defaultProps = {
  selected: false
};

ColorPickerContent.propTypes = {
  colors: arrayOf(string).isRequired,
  selectedColor: string.isRequired,
  onChange: func.isRequired
};

ColorPicker.propTypes = {
  colors: arrayOf(string).isRequired,
  value: string.isRequired,
  onChange: func.isRequired
};

export default ColorPicker;
