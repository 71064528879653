import {
  FETCH_TEMPLATE_BEGIN,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  CREATE_TEMPLATE_BEGIN,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  UPDATE_TEMPLATE_BEGIN,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILURE
} from "../constants";

const initialState = {
  templates: [],
  loading: false,
  busy: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true
      };
    case FETCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: action.templates,
        loading: false
      };
    case FETCH_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case CREATE_TEMPLATE_BEGIN:
      return  {
        ...state,
        busy: true
      };
    case CREATE_TEMPLATE_SUCCESS:
      return  {
        ...state,
        templates: [
          ...state.templates,
          action.template
        ],
        busy: false
      };
    case CREATE_TEMPLATE_FAILURE:
      return  {
        ...state,
        busy: false,
        error: action.error
      };
    case UPDATE_TEMPLATE_BEGIN:
      return {
        ...state,
        busy: true
      };
    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        busy: false,
        templates: state.templates.map(template =>
          template.id === action.id ?
            { ...template, ...action.template } :
            template),
      };
    case UPDATE_TEMPLATE_FAILURE:
      return {
        ...state,
        busy: false,
        error: action.error
      };
    default:
      return {
        ...state
      };
  }
};
