import React, { useCallback } from "react";
import Icon from "antd/es/icon";
import Button from "antd/es/button";
import Popconfirm from "antd/es/popconfirm";

const DeleteContact = ({
  backUrl,
  contactId,
  loading,
  onDelete
}) => {
  const remove = useCallback(() => {
    onDelete(contactId, backUrl);
  }, [contactId, backUrl]);

  return (
    <Popconfirm
      title="Are you sure?"
      onConfirm={remove}
    >
      <Button type="danger" size="small" style={{ width: 40 }} loading={loading}>
        <Icon type="delete" />
      </Button>
    </Popconfirm>
  );
};

export default DeleteContact;
