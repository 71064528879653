import lodash from "lodash";

export const excludeUnassigned = statuses =>
  lodash.filter(statuses, status => !status.isDefault || statuses.length > 1);

export const excludeUnnecessarily = (statuses, statusMembers) =>
  statuses.filter(({ id, isDefault }) => {
    const statusMember = (statusMembers && statusMembers[id]) || [];
    return !(isDefault && statusMember.length === 0)
  });

export const orderStatuses = statuses =>
  lodash.sortBy(statuses, ["isDefault", "index"], ["asc", "desc"]);
