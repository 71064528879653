import React, { useCallback, useState, useEffect } from "react";
import lodash from "lodash";
import Tabs from "antd/es/tabs";
import List from "antd/es/list";
import Avatar from "antd/es/avatar";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Badge from "antd/es/badge";
import Typography from "antd/es/typography/Typography";
import moment from "moment";
import { arrayOf, objectOf, any, bool, func, string } from "prop-types";

import "./style.css";
import AdminSelectOrg from "../../AdminSelectOrg";
import { isAdmin } from "../../../../utils/auth";
import { toShortName } from "../../../../utils/string";

const TabPane = Tabs.TabPane;
const Item = List.Item;
const Search = Input.Search;
const { Paragraph } = Typography;

const funcFilterAccess = (c, m) => {
  if (["admin", "owner"].includes(m.role.toLowerCase())) {
    return true;
  }

  // If it's public.
  if (!c.members || c.members.length === 0) {
    return true;
  }

  // If if's owner.
  if (c.teamMemberAddress === m.phone) {
    return true;
  }

  // If there is access for this member.
  if ((c.members || []).length > 0) {
    return (c.members || []).includes(m.notify_id);
  }

  return true;
};

const funcFilterByUser = (items, addr, identity) =>
  lodash.filter(
    items,
    (c) => c.relationshipTeamMemberNotifyId && c.relationshipTeamMemberNotifyId === identity
  );

const funcFilterBySearch = (items, query) =>
  lodash.filter(items, (c) => c.name.match(new RegExp(lodash.escapeRegExp(query), "i")));

const funcFilterByIntegrity = (item) => item.messageCount > 0;

const funcSort = (a, b) => b.lastUpdated - a.lastUpdated;

const formatDate = (date) => {
  const d = moment(date);
  const isSame = d.isSame(moment().clone().startOf("day"), "d");

  return isSame ? d.format("LT") : d.format("l");
};

const buildBadgeText = (txt) => {
  if (txt === "Awaiting TM") {
    return "Your Turn";
  }

  return null;
};

const ConversationsList = ({
  messages,
  conversations,
  conversationsById,
  currentConversation,
  scheduledConversationIds,
  loadingConversations,
  conversationFilter,
  conversationFilter2,
  currentUserPhone,
  currentUserId,
  currentTeamMember,
  toggleVisibleStartNewConversation,
  setCurrentConversation,
  setFilterConversations,
  setFilter2Conversations,
  setCurrentContactId,
  fetchMessages,
  fetchContactDetails,
}) => {
  const [query, setQuery] = useState("");

  const selectConversation = useCallback(
    (channelId) => {
      setCurrentConversation(channelId);
      fetchMessages(channelId);

      if (conversationsById[channelId] && conversationsById[channelId].contactId) {
        fetchContactDetails(conversationsById[channelId].contactId);
        setCurrentContactId(conversationsById[channelId].contactId);
      }
    },
    [
      conversationsById,
      setCurrentConversation,
      setCurrentContactId,
      fetchMessages,
      fetchContactDetails,
    ]
  );

  const renderTab = (filter) => {
    let dataSource = lodash.filter(conversations, (c) => funcFilterAccess(c, currentTeamMember));

    if (conversationFilter === "my") {
      dataSource = funcFilterByUser(dataSource, currentUserPhone, currentUserId);
    }

    if (conversationFilter === "unassigned") {
      dataSource = lodash.filter(dataSource, (item) => !item.relationshipTeamMemberNotifyId);
    }

    if (query) {
      dataSource = funcFilterBySearch(dataSource, query);
    }

    if (filter === "inbox") {
      dataSource = lodash.filter(
        dataSource,
        (c) => !c.archived && (c.replyState === "Awaiting TM" || c.lastInboundMessageAt)
      );
    } else if (filter === "scheduled") {
      dataSource = lodash.filter(dataSource, (c) => scheduledConversationIds.includes(c.id));
      dataSource = lodash.filter(dataSource, (c) =>
        lodash.some(messages[c.id], (m) => m.deliveryStatus === "scheduled")
      );
    } else if (filter === "sent") {
      dataSource = lodash.filter(dataSource, (c) => !c.archived && c.lastOutboundMessageAt);
    } else if (filter === "all") {
      dataSource = lodash.filter(dataSource, (c) => !scheduledConversationIds.includes(c.id));
    } else if (filter) {
      dataSource = lodash.filter(dataSource, filter);
    }

    dataSource = lodash.filter(dataSource, funcFilterByIntegrity).sort(funcSort);

    return (
      <List
        dataSource={dataSource}
        loading={loadingConversations}
        renderItem={({
          key,
          avatar,
          name,
          lastMessage,
          lastUpdated,
          teamMemberName,
          replyState,
        }) => (
          <Item
            key={key}
            id={`conversationItem${key}`}
            className={currentConversation === key && "active"}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
            }}
            onClick={() => {
              selectConversation(key);
            }}
          >
            <Item.Meta
              avatar={<Avatar src={avatar}>{toShortName(name)}</Avatar>}
              title={name}
              description={
                <>
                  {teamMemberName ? (
                    <div style={{ marginBottom: 2 }}>With: {teamMemberName}</div>
                  ) : null}
                  <Paragraph style={{ color: "rgba(0, 0, 0, 0.45)" }} ellipsis={{ rows: 3 }}>
                    {lastMessage}
                  </Paragraph>

                  {buildBadgeText(replyState) ? (
                    <Badge
                      count={buildBadgeText(replyState)}
                      style={{ backgroundColor: "#108ee9" }}
                    />
                  ) : null}
                </>
              }
            />
            <div className="date">{formatDate(lastUpdated)}</div>
          </Item>
        )}
        locale={{
          emptyText: "There is not any conversations yet.",
        }}
      />
    );
  };

  const callbackOnMyMessages = useCallback(() => {
    setFilterConversations("my");
  }, [setFilterConversations]);

  const callbackOnAllMessages = useCallback(() => {
    setFilterConversations("all");
  }, [setFilterConversations]);

  const callbackOnUnassigned = useCallback(() => {
    setFilterConversations("unassigned");
  }, [setFilterConversations]);

  useEffect(() => {
    if (currentConversation) {
      const itemElement = window.document.getElementById(`conversationItem${currentConversation}`);
      if (itemElement) {
        itemElement.scrollIntoView();
      }
    }
  }, []);

  return (
    <div className="inbox-conversations-list-wrapper">
      <div className="inbox-conversations-list-header">
        <Button.Group>
          <Button
            type={conversationFilter === "my" ? "primary" : "secondary"}
            onClick={callbackOnMyMessages}
          >
            Mine
          </Button>
          <Button
            type={conversationFilter === "all" ? "primary" : "secondary"}
            onClick={callbackOnAllMessages}
          >
            All
          </Button>
          <Button
            type={conversationFilter === "unassigned" ? "primary" : "secondary"}
            onClick={callbackOnUnassigned}
          >
            Unassigned
          </Button>
        </Button.Group>
        <Button
          icon="form"
          size="small"
          type="primary"
          style={{ marginLeft: 10 }}
          onClick={toggleVisibleStartNewConversation}
        />
      </div>
      <div className="inbox-conversations-list-container" style={{ bottom: isAdmin() ? 49 : 0 }}>
        <div className="inbox-conversations-list-search">
          <Search
            placeholder="Search Conversation"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>

        <Tabs
          tabPosition="left"
          className="filtering"
          activeKey={conversationFilter2}
          onChange={setFilter2Conversations}
        >
          <TabPane tab="Inbox" key="inbox">
            {renderTab("inbox")}
          </TabPane>
          <TabPane tab="Scheduled" key="scheduled">
            {renderTab("scheduled")}
          </TabPane>
          <TabPane tab="Sent" key="sent">
            {renderTab("sent")}
          </TabPane>
          <TabPane tab="Archives" key="archives">
            {renderTab({ archived: true })}
          </TabPane>
          <TabPane tab="All" key="all">
            {renderTab("all")}
          </TabPane>
        </Tabs>
      </div>

      {isAdmin() ? <AdminSelectOrg /> : null}
    </div>
  );
};

ConversationsList.propTypes = {
  toggleVisibleStartNewConversation: func.isRequired,
  setCurrentConversation: func.isRequired,
  setFilterConversations: func.isRequired,
  setFilter2Conversations: func.isRequired,
  setCurrentContactId: func.isRequired,
  fetchMessages: func.isRequired,
  fetchContactDetails: func.isRequired,
  conversations: arrayOf(any).isRequired,
  conversationsById: objectOf(any).isRequired,
  scheduledConversationIds: arrayOf(string).isRequired,
  currentConversation: string,
  contactId: string,
  loadingConversations: bool,
  conversationFilter: string,
  conversationFilter2: string,
  currentUserPhone: string,
  currentUserId: string,
  currentTeamMember: objectOf(any),
  messages: objectOf(any),
};

ConversationsList.defaultProps = {
  currentConversation: null,
  loadingConversations: false,
  conversationFilter: "my",
  conversationFilter2: "inbox",
  currentUserPhone: null,
  currentUserId: null,
  messages: {},
};

export default ConversationsList;
