import React from "react";
import { bool, string } from "prop-types";
import { connect } from "react-redux";

import VerifyPhoneNumber from "../components/VerifyPhoneNumber";

const CheckingPhoneNumber = ({ accountSlug, notifyId, phoneVerified, mobileNumber, children }) => {
  if (phoneVerified === null || phoneVerified) {
    return children;
  }

  return (
    <div style={{ marginTop: 100 }}>
      <VerifyPhoneNumber accountSlug={accountSlug} notifyId={notifyId} phoneNumber={mobileNumber} />
    </div>
  );
};

CheckingPhoneNumber.propTypes = {
  accountSlug: string,
  notifyId: string,
  phoneVerified: bool,
  mobileNumber: string
};

CheckingPhoneNumber.defaultProps = {
  accountSlug: null,
  notifyId: null,
  phoneVerified: null,
  mobileNumber: null
};

const mapStateToProps = ({ profile }) =>
  profile
    ? {
        accountSlug: profile.accountSlug,
        notifyId: profile.notifyId,
        phoneVerified: profile.phoneVerified,
        mobileNumber: profile.mobileNumber
      }
    : {};

export default connect(mapStateToProps)(CheckingPhoneNumber);
