import React, { useCallback } from "react";
import List from "antd/es/list";
import Typography from "antd/es/typography";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { string, any, func, objectOf } from "prop-types";

import { formatTotalPercent } from "../../utils/string";
import {Button} from "antd";

const Item = List.Item;
const Meta = Item.Meta;
const Title = Typography.Title;
const Text = Typography.Text;

const Card = ({ title, member, statuses, onShowRecipients }) => {
  const callbackShowRecipients = useCallback(() => {
    onShowRecipients(member && member.notify_id);
  }, [member, onShowRecipients]);

  const memberName = member && [member.first_name, member.last_name]
    .join(" ")
    .trim();

  return (
    <Item
      className="workflow-summary-item-wrapper"
      extra={<Button type="dashed" onClick={callbackShowRecipients}>See Recipients</Button>}
    >
      <Meta
        title={title || memberName}
        description={`${statuses.total_sent} Sent of ${statuses.total_recipients} Recipients`}
      />
      <Row gutter={16}>
        <Col span={8}>
          <Title level={4}>{formatTotalPercent(statuses.percent_sent)}%</Title>
          <Text type="secondary">{statuses.total_sent} Sent</Text>
        </Col>
        {statuses.clicked ? (
          <Col span={8}>
            <Title level={4}>{formatTotalPercent(statuses.percent_clicks)}%</Title>
            <Text type="secondary">{statuses.clicked} Clicks</Text>
          </Col>
        ) : null}
        <Col span={8}>
          <Title level={4}>{formatTotalPercent(statuses.percent_replies)}%</Title>
          <Text type="secondary">{statuses.replied} Replies</Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Title level={4}>{statuses.sent_success}</Title>
          <Text type="secondary">Delivered</Text>
        </Col>
        <Col span={8}>
          <Title level={4}>{statuses.delivery_errors}</Title>
          <Text type="secondary">Delivery Errors</Text>
        </Col>
        <Col span={8}>
          <Title level={4}>{formatTotalPercent(statuses.percent_opt_out)}%</Title>
          <Text type="secondary">{statuses.opted_out} Opt-outs</Text>
        </Col>
      </Row>
    </Item>
  );
};

Card.propTypes = {
  title: string,
  member: objectOf(any),
  statuses: objectOf(any).isRequired,
  onShowRecipients: func.isRequired
};

Card.defaultProps = {
  title: null,
  member: null
};

export default Card;
