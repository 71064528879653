import React, {useCallback, useMemo, useState} from 'react';
import { arrayOf, any, bool, objectOf } from "prop-types";
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import { Link } from 'react-router-dom';
import Skeleton from 'antd/es/skeleton';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';

import Title from '../../../../components/Title';
import TopNavigation from '../../../../components/TopNavigation';
import Wrapper from '../../../../components/Wrapper';
import AddContactsToList from '../../../../components/AddContactsToList';
import ContactsWithStatuses from './ContactsWithStatuses';
import Contacts from './Contacts';
import { getContactsSearchResults, getContactsSortedResults } from "../../../../utils/contacts";

const Search = Input.Search;

let currentPage = 1;
let currentQuery = '';

const ListOfContacts = ({
  match: {
    params: { id },
  },
  listsById,
  contacts,
  contactsById,
  loading,
}) => {
  const [query, setQuery] = useState(currentQuery);
  const [page, setPage] = useState(currentPage);
  const [sortBy, setSortBy] = useState("name");
  const [currentStatusId, setCurrentStatusId] = useState(null);

  const changePage = useCallback(p => {
    setPage(p);
    currentPage = p;
  }, [setPage]);

  const callbackChangeQuery = useCallback(e => {
    setQuery(e.target.value);
    currentQuery = e.target.value;
    changePage(1);
  }, [setQuery, changePage]);

  const currentList = listsById[id];

  const title = useMemo(() => {
    if (id === 'all') {
      return 'TextWith Contacts';
    }
    return _get(listsById, `[${id}].name`);
  }, [id, listsById]);

  const data = useMemo(() => {
    if (currentList) {
      const ids =  currentList.members || [];
      return _filter(_map(ids, id => contactsById[id]), c => c);
    }

    return _filter(contacts, c => !c.fromDevice);
  }, [currentList, contacts, contactsById]);

  const filteredData = useMemo(() => (
    query ? getContactsSearchResults(data, query) : data
  ), [data, query]);

  const sortedData = useMemo(() => (
    getContactsSortedResults(filteredData, sortBy)
  ), [filteredData, sortBy]);

  return (
    <>
      <Title title={title} />
      <TopNavigation />

      <Wrapper backUrl="/contacts" title={title}>
        <Row gutter={16}>
          <Col lg={currentList ? 14 : 12}>
            <Search
              placeholder="Search"
              value={query}
              onChange={callbackChangeQuery}
            />
          </Col>
          <Col lg={currentList ? 6 : 4}>
            <Select style={{ width: "100%" }} value={sortBy} onChange={sb => setSortBy(sb)}>
              <Select.Option value="name">Last Name</Select.Option>
              <Select.Option value="last_reply">Last Reply</Select.Option>
              <Select.Option value="last_sent">Last Sent</Select.Option>
              <Select.Option value="created_at">Created At</Select.Option>
            </Select>
          </Col>

          {currentList ? (
            <Col lg={4}>
              <AddContactsToList currentStatusId={currentStatusId} list={currentList} />
            </Col>
          ) : (
            <>
              <Col lg={3}>
                <Link
                  to="/contacts/import"
                  className="ant-btn ant-btn-primary ant-btn-block"
                >
                  Import
                </Link>
              </Col>
              <Col lg={5}>
                <Link
                  to="/contacts/new"
                  className="ant-btn ant-btn-primary ant-btn-block"
                >
                  New Contact
                </Link>
              </Col>
            </>
          )}
        </Row>
        <br/>

        <Skeleton loading={loading && data.length === 0}>
          {currentList && currentList.category !== "contactsList" ? (
            <ContactsWithStatuses
              list={currentList}
              contacts={sortedData}
              onChangeTab={setCurrentStatusId}
            />
          ) : (
            <Contacts
              contacts={sortedData}
              pagination={{
                current: page,
                onChange: changePage,
                pageSize: 10,
              }}
            />
          )}
        </Skeleton>
      </Wrapper>
    </>
  );
};

ListOfContacts.propTypes = {
  listsById: objectOf(any),
  contacts: arrayOf(any),
  contactsById: objectOf(any),
  loading: bool,
};

ListOfContacts.defaultProps = {
  listsById: {},
  contacts: [],
  contactsById: {},
  loading: false,
};

export default ListOfContacts;
