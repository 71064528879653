import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ConversationsList from "./ConversationsList";
import { toggleVisibleStartNewConversation } from "../../../../actions/application";
import { fetchMessages } from "../../../../actions/messages";
import {
  fetchContactDetails,
  setCurrentContactId
} from "../../../../actions/contacts";
import {
  setCurrentConversation,
  setFilterConversations,
  setFilter2Conversations
} from "../../../../actions/conversations";

const mapStateToProps = ({
  application: { teamMembers },
  conversations: {
    conversations,
    conversationsById,
    currentConversation,
    scheduledConversationIds,
    threadsFetching,
    conversationFilter,
    conversationFilter2
  },
  messages: { messages },
  profile: { phone, notifyId }
}) => ({
  messages,
  conversations,
  conversationsById,
  currentConversation,
  conversationFilter,
  conversationFilter2,
  scheduledConversationIds,
  loadingConversations: threadsFetching && conversations.length === 0,
  currentUserPhone: phone,
  currentUserId: notifyId,
  currentTeamMember: teamMembers[notifyId],
});

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleVisibleStartNewConversation,
  setCurrentConversation,
  setFilterConversations,
  setFilter2Conversations,
  setCurrentContactId,
  fetchMessages,
  fetchContactDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsList);
