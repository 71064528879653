import {
  FETCH_PROFILE_BEGIN,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  SET_PROFILE_EMAIL,
  UPDATE_PROFILE_ATTRS,
  SET_AVAILABLE_STATE_BEGIN,
  SET_AVAILABLE_STATE_FAILURE,
  SET_AVAILABLE_STATE_SUCCESS,
} from "../constants";

const initialState = {
  accountId: null,
  accountSlug: null,
  accountName: null,
  notifyId: null,
  phone: null,
  firstName: null,
  lastName: null,
  available: false,
  loadingAvailable: false,
  email: "",
  calendar: {},
  salesforce: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_BEGIN:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_PROFILE_SUCCESS:
      const { bot, profile, twilio, groups, calendar, salesforce } = action.data;
      return {
        ...state,
        accountId: bot.id,
        accountSlug: bot.slug,
        accountName: bot.name,
        notifyId: profile.notify_id,
        phone: profile.phone,
        mobileNumber: profile.mobile_number,
        hasFallbackSms: profile.has_fallback_sms,
        firstName: profile.first_name,
        lastName: profile.last_name,
        fullName: [profile.first_name, profile.last_name].join(" ").trim(),
        phoneVerified: profile.phone_verified,
        fetching: false,
        available: profile.is_available,
        twilio,
        groups,
        calendar,
        salesforce,
      };
    case FETCH_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    case SET_PROFILE_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case UPDATE_PROFILE_ATTRS:
      return {
        ...state,
        ...action.attrs,
      };
    case SET_AVAILABLE_STATE_BEGIN:
      return {
        ...state,
        loadingAvailable: true,
        available: action.available,
      };
    case SET_AVAILABLE_STATE_SUCCESS:
      return {
        ...state,
        loadingAvailable: false,
      };
    case SET_AVAILABLE_STATE_FAILURE:
      return {
        ...state,
        available: state.available,
        loadingAvailable: false,
      };
    default:
      return {
        ...state,
      };
  }
};
