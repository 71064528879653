import { connect } from "react-redux";

import ConversationSettings from "./ConversationSettings";

const mapStateToProps = ({
  application: { teamMembers },
  profile: { notifyId, phone },
}) => ({
  teamMembers,
  notifyId,
  teamMemberAddress: phone
});

export default connect(mapStateToProps)(ConversationSettings);
