import React from "react";
import lodash from "lodash";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Tabs from "antd/es/tabs";
import { func, bool, objectOf, any } from "prop-types";

import Statuses from "./Statuses";
import { COLORS } from "./data";
import { uuidv4 } from "../../utils/other";

const FormItem = Form.Item;
const TextArea = Input.TextArea;
const TabPane = Tabs.TabPane;

class FormPiplineList extends React.Component {
  state = {
    tabKey: "details",
    name: "",
    description: "",
    statuses: [],
    errors: {}
  };

  _statusesDeleted = [];

  componentWillMount() {
    const { editData } = this.props;
    if (editData) {
      this.setState({
        name: editData.name,
        description: editData.description,
        statuses: editData.statuses
      });
    }
  }

  handlerTabKey = tabKey => this.setState({ tabKey });

  handlerChangeName = ({ target: { value } }) => this.setState({ name: value, errors: {} });

  handlerChangeDescription = ({ target: { value } }) => this.setState({ description: value, errors: {} });

  handlerAdd = () => {
    const { statuses } = this.state;

    this.setState({
      statuses: [
        ...statuses,
        {
          id: uuidv4(),
          name: "",
          index: statuses.length + 1,
          color: COLORS[0]
        }
      ]
    });
  };

  handlerChange = (id, attrs) => {
    const { statuses } = this.state;
    const idx = lodash.findIndex(statuses, { id });
    statuses[idx] = {
      ...statuses[idx],
      ...attrs
    };

    this.setState({ statuses, errors: {} });
  };

  handlerChangePosition = (dragIndex, hoverIndex) => {
    const { statuses } = this.state;
    const idx1 = lodash.findIndex(statuses, { index: dragIndex });
    const idx2 = lodash.findIndex(statuses, { index: hoverIndex });

    statuses[idx1].index = hoverIndex;
    statuses[idx2].index = dragIndex;

    this.setState({ statuses, errors: {} });
  };

  handlerDelete = id => {
    const { statuses } = this.state;
    const idx = lodash.findIndex(statuses, { id });
    statuses.splice(idx, 1);
    this._statusesDeleted.push(id);
    this.setState({ statuses, errors: {} });
  };

  validate = () => {
    const { tabKey, name, statuses } = this.state;
    const errors = {};

    if (name.trim().length === 0) {
      errors.name = "can't be blank";
    }

    lodash.forEach(statuses, ({ id, name }) => {
      if (!name.trim()) {
        if (!errors.statuses) {
          errors.statuses = {};
        }
        errors.statuses[id] = "can't be blank";
      }
    });

    if (errors.name && tabKey !== "details") {
      this.handlerTabKey("details");
    } else if (errors.statuses && tabKey !== "statuses") {
      this.handlerTabKey("statuses");
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };

  submit = async () => {
    if (!this.validate()) {
      return;
    }

    const { onSubmit } = this.props;
    const { name, description } = this.state;
    let statuses = [ ...this.state.statuses ];

    // Add default status
    if (!statuses.filter(s => s.isDefault).length) {
      statuses = statuses.map((s, index) => ({...s, index: index + 1}));
      statuses.unshift({
        id: uuidv4(),
        index: 0,
        name: "Unassigned",
        isDefault: true
      });
    }

    await onSubmit({ name, description, statuses }, this._statusesDeleted);
    this.setState({
      tabKey: "details",
      name: "",
      description: "",
      statuses: [],
      errors: {}
    });
  };

  render() {
    const { loading, editData } = this.props;
    const { tabKey, name, description, statuses, errors } = this.state;

    return (
      <Form className="form-list" onSubmit={this.submit}>
        <Tabs
          tabPosition="left"
          className="mb-15"
          activeKey={tabKey}
          onChange={this.handlerTabKey}
        >
          <TabPane key="details" tab="Details">
            <FormItem
              label="Name (Required)"
              validateStatus={errors.name ? "error" : null}
              help={errors.name}
            >
              <Input value={name} onChange={this.handlerChangeName} />
            </FormItem>
            <FormItem label="Description">
              <TextArea value={description} onChange={this.handlerChangeDescription} />
            </FormItem>
          </TabPane>
          <TabPane key="statuses" tab="Statuses">
            <Statuses
              errors={errors.statuses}
              statuses={lodash.filter(statuses, s => !s.isDefault)}
              onAdd={this.handlerAdd}
              onChange={this.handlerChange}
              onChangePosition={this.handlerChangePosition}
              onDelete={this.handlerDelete}
            />
          </TabPane>
        </Tabs>

        <Button
          block
          type="primary"
          loading={loading}
          onClick={this.submit}
        >
          {editData ? "Update" : "Create"}
        </Button>
        <input type="submit" style={{ position: "fixed", left: -2000 }} />
      </Form>
    );
  }
}

FormPiplineList.propTypes = {
  loading: bool,
  editData: objectOf(any),
  onSubmit: func
};

FormPiplineList.defaultProps = {
  loading: false,
  editData: null
};

export default FormPiplineList;
