import React, { useRef, useCallback } from "react";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import { oneOfType, string, any, objectOf, func } from "prop-types";

const AddImage = ({ image, onChange }) => {
  const inputFileEl = useRef(null);

  const callbackChooseImage = useCallback(() => {
    inputFileEl.current.click();
  }, [inputFileEl]);

  const callbackSetImage = useCallback((
    { target: { files } }
  ) => {
    onChange(files[0]);
  }, [onChange]);

  const imageSrc = image ? (
    typeof image === "object" ?
      (window.URL || window.webkitURL).createObjectURL(image) :
      image
  ) : null;

  return (
    <div>
      {image ? (
        <div className="add-image-wrapper">
          <img src={imageSrc} alt="" />
        </div>
      ) : null}
      <Button type="primary" onClick={callbackChooseImage}>
        <Icon type={image ? "edit" : "plus"} />
        {image ? "Change": "Add"}&nbsp;Image
      </Button>

      <input
        type="file"
        accept="image/*"
        style={{ position: "fixed", left: -20000 }}
        ref={inputFileEl}
        onChange={callbackSetImage}
      />
    </div>
  );
};

AddImage.propTypes = {
  image: oneOfType([string, objectOf(any)]),
  onChange: func.isRequired
};

AddImage.defaultProps = {
  image: null
};

export default AddImage;
