import React, { useState, useCallback, useEffect } from "react";
import { func } from "prop-types";
import * as firebase from "firebase/app";
import "firebase/auth";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Message from "antd/es/message";
import Modal from "antd/es/modal";
import Spin from "antd/es/spin";

import "./style.css";
import Title from "../../../components/Title";
import browserHistory from "../../../browserHistory";
import { refreshData } from "../../../utils/data";
import onboarding from "../../../services/onboarding";
import configureStore from "../../../store/configureStore";

const FormItem = Form.Item;

const SignIn = ({ setProfileEmail, setGlobalLoading }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const callbackChangeEmail = useCallback(
    ({ target: { value } }) => {
      setEmail(value);
    },
    [setEmail]
  );

  const callbackChangePassword = useCallback(
    ({ target: { value } }) => {
      setPassword(value);
    },
    [setPassword]
  );

  const redirectTo = () => {
    const existingShop = onboarding.getShopAlreadyExists;
    const existingShopName = onboarding.getShopName;
    const addShopModalVisible = !existingShop && existingShopName !== null;
    if (addShopModalVisible) {
      browserHistory.push("/integrations");
      // setTimeout(() => {
      //   browserHistory.push("/shopify");
      // }, 500);
    } else {
      onboarding.clearShopifyStorage();
      browserHistory.push("/");
      // setTimeout(() => {
      //   browserHistory.push("/");
      // }, 500);
    }
  };

  const callbackLogin = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => firebase.auth().signInWithEmailAndPassword(email, password))
        .then(async ({ user }) => {
          await setProfileEmail(user.email);
          await refreshData();

          onboarding.clearStorage();

          // Message.success("Signed In", 2);
          setLoading(false);
          setEmail("");
          setPassword("");

          redirectTo();
        })
        .catch(({ message }) => {
          if (message) {
            Message.error(message, 2);
          }
          setLoading(false);
        });
    },
    [email, password, setEmail, setPassword, setLoading, setProfileEmail]
  );

  useEffect(() => {
    async function checkCurrentUser() {
      var user = firebase.auth().currentUser;
      if (user) {
        await setProfileEmail(user.email);
        await refreshData();

        onboarding.clearStorage();

        // Message.success("Signed In", 2);
        setEmail("");
        setPassword("");
        const {
          profile: { notifyId },
        } = configureStore.getState();
        window.analytics.identify(notifyId, {
          email: user.email,
          webUser: true,
        });

        redirectTo();
      } else {
        setShowSignIn(true);
      }
    }
    checkCurrentUser();
  }, []);

  return showSignIn ? (
    <div>
      <Title title="Sign In" />

      <Row>
        <Col xl={{ span: 8, offset: 8 }} lg={{ span: 18, offset: 4 }} xs={{ span: 22, offset: 1 }}>
          <div className="block">
            <div className="block-header">
              <h2>Sign In</h2>
            </div>

            <Form className="block-body" onSubmit={callbackLogin}>
              <FormItem label="Email">
                <Input disabled={loading} value={email} onChange={callbackChangeEmail} />
              </FormItem>

              <FormItem label="Password">
                <Input
                  type="password"
                  disabled={loading}
                  value={password}
                  onChange={callbackChangePassword}
                />
              </FormItem>

              <Button block typeof="input" type="primary" loading={loading} onClick={callbackLogin}>
                Sign In
              </Button>
              <input type="submit" style={{ position: "fixed", left: -2000 }} />
              <div className="signup-link">
                <Button size="large" type="link" href="/sign_up">
                  Don't have an account? Click to Register.
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      <Modal centered closable={false} footer={null} visible={true}>
        <div style={{ textAlign: "center", padding: "20px 0" }}>
          <Spin size="large" />
        </div>
      </Modal>
    </div>
  );
};

SignIn.propTypes = {
  setProfileEmail: func.isRequired,
};

export default SignIn;
