import React, { useCallback } from "react";
import Button from "antd/es/button";
import { arrayOf, any, string, func } from "prop-types";

import BaseField from "./BaseField";

const FieldWithLabel = ({
  type,
  items,
  onAdd,
  onDelete,
  onChangeLabel,
  onChangeValue
}) => {
  const callbackAdd = useCallback(() => {
    onAdd(type)
  }, [type, onAdd]);

  return (
    <div>
      {items.map(item => (
        <BaseField
          key={item.id}
          type={type}
          data={item}
          onDelete={onDelete}
          onChangeLabel={onChangeLabel}
          onChangeValue={onChangeValue}
        />
      ))}

      <Button block type="dashed" icon="plus" onClick={callbackAdd} />
    </div>
  );
};

FieldWithLabel.propTypes = {
  type: string.isRequired,
  items: arrayOf(any).isRequired,
  onAdd: func.isRequired,
  onDelete: func.isRequired,
  onChangeLabel: func.isRequired,
  onChangeValue: func.isRequired,
};

export default FieldWithLabel;
