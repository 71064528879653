import { connect } from "react-redux";

import Lists from "./Lists";

const mapStateToProps = ({
  lists,
  contacts,
}) => ({
  contacts: contacts.contacts,
  contactsById: contacts.contactsById,
  lists: lists.items,
  fetchingLists: lists.fetching,
});

export default connect(mapStateToProps)(Lists);
