import React, { Fragment } from "react";
import Row from "antd/es/row";
import Card from "antd/es/card";
import Col from "antd/es/col";
import Skeleton from "antd/es/skeleton";
import Empty from "antd/es/empty";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import message from "antd/es/message";

import lodash from "lodash";
import moment from "moment";
import { bool, arrayOf, objectOf, any, number, func } from "prop-types";

import Available from "../../../components/Available";
import Title from "../../../components/Title";
import TopNavigation from "../../../components/TopNavigation";
import Wrapper from "../../../components/Wrapper";
import Task from "../../../components/Task";
import TaskHandler from "../../../components/TaskHandler";
import { funcFilterTasks, funcActive, funcOngoing, funcCompleted } from "./utils";
import browserHistory from "../../../browserHistory";

const Text = Typography.Text;

const taskStatus = (isCompleted, date) => {
  let status = "";
  if (isCompleted) {
    status = "Completed!";
  } else if (date) {
    const mObj = moment(date);
    const isToday = moment(date).isSame(new Date(), "day");

    if (mObj.isBefore(new Date())) {
      status = `Due ${isToday ? "Today" : moment(date).fromNow()}`;
    } else {
      status = `Complete ${moment(date).fromNow()}`;
    }
  }

  return status;
};

const Loader = ({ totalPreloading }) =>
  totalPreloading && totalPreloading > 3 ? (
    [...Array(totalPreloading).keys()].map(i => <Skeleton active key={i} />)
  ) : (
    <Fragment>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </Fragment>
  );

const renderTask = (data, onClick) => {
  const {
    workflowRec: { name, end_at },
    responses: { ready, skipped },
    meta: { statusPercent, completed, sentTotal },
  } = data;

  const responsesLeft = ready + skipped;
  const statistic = completed
    ? `You sent ${sentTotal} messages`
    : `${responsesLeft} messages remaining (${sentTotal} sent)`;

  return (
    <Task
      key={data.id}
      isCompleted={completed}
      percent={parseFloat(statusPercent)}
      status={taskStatus(completed, end_at)}
      name={name}
      statistic={statistic}
      onClick={() => {
        onClick(data);
      }}
    />
  );
};

const renderShopifyStoreSettings = shopifyStores => {
  return (
    <Row style={{ marginBottom: 10 }}>
      <Col span={16} offset={4} className="task-wrapper">
        <Card hoverable={true} onClick={() => browserHistory.push("/integrations")}>
          <div className="clearfix">
            <div className="task-info">
              <div className="task-info_name">Configure Shopify Store Settings</div>
              <Text type="secondary">You have {shopifyStores.length} connected stores.</Text>
              <div style={{ marginTop: "10px" }}>
                <Button type="primary" onClick={() => browserHistory.push("/integrations")}>
                  Edit Settings
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

class Tasks extends React.Component {
  componentWillMount() {
    const { fetchWorkflowTasksContacts, fetchWorkflows } = this.props;

    fetchWorkflowTasksContacts();
    fetchWorkflows();
  }

  handlerStart = async wt => {
    const {
      workflowsById,
      startTask,
      fetchWorkflowContacts,
      setCurrentContactIndex,
      setCurrentTemplate
    } = this.props;
    const workflow = workflowsById[wt.workflow];

    if (workflow.category === "Basics" && !workflow.template_text) {
      const hide = message.loading("Preparing...");
      let currentTaskContacts = [];
      try {
        currentTaskContacts = await fetchWorkflowContacts(wt.id, wt.workflow);
      } catch (e) {}
      if (!currentTaskContacts.length) {
        hide();
        message.error("This task doesn't have any contacts to start\"")
        return;
      }

      await startTask(wt.id);
      await setCurrentContactIndex(0);
      await setCurrentTemplate("");
      hide();
    } else {
      startTask(wt.id);
    }
  };

  renderGroup = data => (
    <Row key={data.key}>
      {data.title ? <Divider>{data.title}</Divider> : null}
      {data.items.map(item => renderTask(item, this.handlerStart))}
    </Row>
  );

  grouped = () => {
    const { tasks, workflowsById } = this.props;
    const items = lodash.filter(
      lodash.map(tasks, task => ({
        ...task,
        workflowRec: workflowsById[task.workflow]
      })),
      funcFilterTasks
    );

    const main = lodash.filter(items, r => r.team_member_notify_id);
    const newContacts = lodash.filter(items, r => !r.team_member_notify_id);

    return [
      {
        key: 1,
        title: "",
        items: [...funcActive(main), ...funcOngoing(main), ...funcCompleted(main)]
      },
      {
        key: 2,
        title: "To Do's for New Contacts",
        items: [
          ...funcActive(newContacts),
          ...funcOngoing(newContacts),
          ...funcCompleted(newContacts)
        ]
      }
    ];
  };

  render() {
    const { loading, org } = this.props;
    const groups = this.grouped();
    const shopifyStores = (org && org.shopify_stores) || [];
    const total = (groups && (groups[0].items.length + groups[1].items.length)) || 0;

    return (
      <div>
        <Title title="Tasks" />
        <TopNavigation />

        <Wrapper
          title="Today's To Do List"
          extra={(
            <div style={{ marginTop: -10 }}>
              <Available />
            </div>
          )}
        >
          {shopifyStores.length > 0 ? renderShopifyStoreSettings(shopifyStores) : null}
          {!shopifyStores.length && !total ? (
            <Empty />
          ) : (
            !total && loading ? (
              <Row>
                <Col span={16} offset={4}>
                  <Loader totalPreloading={total || 1} />
                </Col>
              </Row>
            ) : (
              (groups || []).filter(r => r.items.length).map(item => this.renderGroup(item) )
            )
          )}
        </Wrapper>

        <TaskHandler />
      </div>
    );
  }
}

Loader.propTypes = {
  totalPreloading: number
};

Loader.defaultProps = {
  totalPreloading: 0
};

Tasks.propTypes = {
  loading: bool.isRequired,
  tasks: arrayOf(any),
  workflowsById: objectOf(any),
  fetchWorkflowTasksContacts: func.isRequired,
  fetchWorkflowContacts: func.isRequired,
  fetchWorkflows: func.isRequired,
  startTask: func.isRequired,
  setCurrentTemplate: func.isRequired,
  setCurrentContactIndex: func.isRequired,
};

export default Tasks;
