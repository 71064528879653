import { batch } from "react-redux";
import lodash from "lodash";
import message from "antd/es/message";

import browserHistory from "../browserHistory";
import { updateConversationName } from "./conversations";
import { fetchTags } from "./application";
import {
  getOrgContacts,
  getContactProfile,
  getContactTimeline,
  addContactNote,
  addContactEvent,
  updateContactData,
  createOrgContact,
  getContactRelationships,
  updateContactRelationship,
  createContactRelationship,
  deleteOrgContact,
} from "../services/api/contact";
import {
  FETCH_CONTACTS_BEGIN,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  UPDATE_CONTACT_ATTRS,
  SET_CURRENT_CONTACT_ID,
  SET_IMPORTED,
  CREATE_CONTACTS_BEGIN,
  CREATE_CONTACTS_SUCCESS,
  CREATE_CONTACTS_FAILURE,
  CREATE_CONTACTS_STOP,
  DELETE_CONTACT
} from "../constants";

export const fetchContacts = () => async dispatch => {
  dispatch({ type: FETCH_CONTACTS_BEGIN });
  let relationships;
  try {
    relationships = await getContactRelationships();
  } catch (e) {
  }

  try {
    const result = await getOrgContacts();
    dispatch({ type: FETCH_CONTACTS_SUCCESS, contacts: result, relationships });
  } catch (error) {
    dispatch({ type: FETCH_CONTACTS_FAILURE, error });
  }
};

export const setCurrentContactId = contactId => ({
  type: SET_CURRENT_CONTACT_ID,
  contactId
});

export const fetchContactDetails = contactId => async dispatch => {
  dispatch({
    contactId,
    type: UPDATE_CONTACT_ATTRS,
    attrs: { fetchingDetails: true }
  });

  const promises = [];
  try {
    promises.push(getContactProfile(contactId));
    promises.push(getContactTimeline(contactId));
    const [profileData, timelineData] = await Promise.all(promises);

    dispatch({
      contactId,
      type: UPDATE_CONTACT_ATTRS,
      attrs: {
        fetchingDetails: false,
        fetched: true,
        timelineData: timelineData.timeline,
        phone: profileData && profileData.phone,
        profileData
      }
    });
  } catch (error) {
    dispatch({
      contactId,
      type: UPDATE_CONTACT_ATTRS,
      attrs: { fetchingDetails: false, fetched: true, error }
    });
  }
};

export const addTimelineNote = (contactId, note) => async (dispatch, getState) => {
  const {
    profile: { notifyId },
    contacts: { contactsById }
  } = getState();

  let result;
  try {
    result = await addContactNote(notifyId, contactId, note);
  } catch (error) {
    console.log(error);
    return;
  }

  const timelineData = contactsById[contactId].timelineData;
  timelineData.push({
    ...result,
    sort_date: result.created_at,
    type: "UserNote"
  });

  dispatch({
    contactId,
    type: UPDATE_CONTACT_ATTRS,
    attrs: { timelineData }
  });
};

export const addTimelineEvent = (contactId, data) => async (dispatch, getState) => {
  const {
    contacts: { contactsById }
  } = getState();

  let result;
  try {
    result = await addContactEvent(contactId, data);
  } catch (error) {
    console.log(error);
    return;
  }

  const timelineData = contactsById[contactId].timelineData;
  timelineData.push({
    ...result,
    sort_date: result.created_at,
    type: "Event"
  });

  dispatch({
    contactId,
    type: UPDATE_CONTACT_ATTRS,
    attrs: { timelineData }
  });
};

export const updateContact = (contactId, data, redirect =true) => async (dispatch, getState) => {
  const {
    profile: { notifyId }
  } = getState();

  dispatch({
    contactId,
    type: UPDATE_CONTACT_ATTRS,
    attrs: {
      contactUpdating: true
    }
  });

  data.team_member_nid = notifyId;

  let profileData;
  try {
    profileData = await updateContactData(contactId, data);
  } catch (error) {
    dispatch({
      contactId,
      type: UPDATE_CONTACT_ATTRS,
      attrs: {
        contactUpdating: false
      }
    });
    return;
  }

  message.success("Updated.");

  batch(() => {
    (async () => {
      await dispatch(fetchTags());
      await dispatch({
        contactId,
        type: UPDATE_CONTACT_ATTRS,
        attrs: {
          profileData,
          contactUpdating: false,
          firstName: profileData.first_name,
          lastName: profileData.last_name,
          name: [profileData.first_name, profileData.last_name].join(" ").trim(),
          tags: profileData.tags.map(t => t.name),
          phone: profileData.phone,
          phones: profileData.phones,
          properties: profileData.properties
        }
      });
      await dispatch(updateConversationName(contactId));
    })();
  });

  if (redirect) {
    browserHistory.push(`/contacts/${contactId}`);
  }
};

export const setImported = data => ({
  type: SET_IMPORTED,
  imported: data
});

export const addContact = (_, data, redirect = true) => async (dispatch, getState) => {
  const {
    profile: { notifyId }
  } = getState();

  dispatch({type: CREATE_CONTACTS_BEGIN});

  let result;
  try {
    result = await createOrgContact({
      ...data,
      team_member_nid: notifyId
    });
  } catch (error) {
    dispatch({type: CREATE_CONTACTS_FAILURE, error});
    return;
  }

  await dispatch(fetchTags());
  await dispatch({ type: CREATE_CONTACTS_STOP });
  await dispatch({ type: CREATE_CONTACTS_SUCCESS, contact: result });

  message.success("Created.");

  if (redirect) {
    browserHistory.push(`/contacts/${result.notify_id}`);
  }

  return result;
};

export const assignMember = (contactId, relationshipId, memberId, callback) => async (dispatch, getState) => {
  let result;

  dispatch({ type: UPDATE_CONTACT_ATTRS, attrs: { assigningMember: true }, contactId });
  try {
    if (relationshipId) {
      result = await updateContactRelationship(relationshipId, memberId);
    } else {
      result = await createContactRelationship(contactId, memberId);
    }
  } catch (e) {
    dispatch({ type: UPDATE_CONTACT_ATTRS, attrs: { assigningMember: false }, contactId });
    return;
  }

  dispatch({
    type: UPDATE_CONTACT_ATTRS,
    attrs: {
      assigningMember: false,
      relationshipId: result.id,
      memberNotifyId: result.team_member_notify_id,
    },
    contactId
  });

  callback && callback();
};

export const deleteContact = (contactId, backUrl) => async (dispatch, getState) => {
  const {
    profile: { notifyId }
  } = getState();

  dispatch({ type: UPDATE_CONTACT_ATTRS, attrs: { deleting: true }, contactId });
  try {
    await deleteOrgContact(contactId, notifyId);
  } catch (e) {
    dispatch({ type: UPDATE_CONTACT_ATTRS, attrs: { deleting: false }, contactId });
    message.error(lodash.get(e, 'data.detail', 'Unknown error, pleas try again.'))
    return;
  }

  message.error("Contact deleted.")
  batch(() => {
    dispatch({ type: UPDATE_CONTACT_ATTRS, attrs: { deleting: false }, contactId });
    dispatch({ type: DELETE_CONTACT, contactId });
  });

  if (backUrl) {
    browserHistory.push(backUrl);
  }
};
