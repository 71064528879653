import jwtDecode from "jwt-decode";
import * as firebase from "firebase/app";
import "firebase/auth";

import { CURRENT_USER_ROLE } from "../constants";

const refreshToken = () => firebase.auth().currentUser.getIdToken(true);

export const currentUserToken = () => firebase.auth().currentUser && firebase.auth().currentUser.ra;

export const isAuthenticated = () => {
  const token = currentUserToken();
  if (!token) {
    return false;
  }

  let tokenDecode;
  try {
    tokenDecode = jwtDecode(token);
  } catch (e) {
    return false;
  }

  const timeStamp = Math.floor(Date.now() / 1000);
  if (tokenDecode.exp - timeStamp <= 60) {
    refreshToken();
  }

  return true;
};

export const isAdmin = () => localStorage.getItem(CURRENT_USER_ROLE) === "admin";
