import lodash from "lodash";

import { buildIdWithAddress, buildContact, buildContacts } from "./utils";
import {
  FETCH_CONTACTS_BEGIN,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  UPDATE_CONTACT_ATTRS,
  SET_CURRENT_CONTACT_ID,
  SET_IMPORTED,
  CREATE_CONTACTS_BEGIN,
  CREATE_CONTACTS_SUCCESS,
  CREATE_CONTACTS_FAILURE,
  CREATE_CONTACTS_STOP,
  DELETE_CONTACT
} from "../constants";

const initialState = {
  contacts: [],
  relationships: [],
  contactsById: {},
  contactIdsByAddress: {},
  currentContactId: null,
  fetching: true,
  creating: false,
  imported: {
    data: [],
    duplicate: null,
    assignment: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTACTS_BEGIN:
      return {
        ...state,
        fetching: true
      };
    case FETCH_CONTACTS_SUCCESS:
      const relationships = action.relationships || state.relationships;
      const result = buildContacts(action.contacts, relationships);

      return {
        ...state,
        relationships: relationships,
        contacts: result.arr,
        contactsById: result.byId,
        contactIdsByAddress: result.idsByAddress,
        fetching: false
      };
    case FETCH_CONTACTS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false
      };
    case UPDATE_CONTACT_ATTRS:
      const contactsById = {
        ...state.contactsById,
        [action.contactId]: {
          ...state.contactsById[action.contactId],
          ...action.attrs
        }
      };
      const contacts = lodash.values(contactsById);

      return {
        ...state,
        contactsById,
        contacts,
        contactIdsByAddress: buildIdWithAddress(contacts)
      };
    case SET_CURRENT_CONTACT_ID:
      return {
        ...state,
        currentContactId: action.contactId
      };
    case SET_IMPORTED:
      return {
        ...state,
        imported: action.imported
      };
    case CREATE_CONTACTS_BEGIN:
      return {
        ...state,
        creating: true
      };
    case CREATE_CONTACTS_SUCCESS:
      const newContacts = [
        ...state.contacts,
        buildContact(action.contact, state.relationships)
      ];

      return {
        ...state,
        contacts: newContacts,
        contactsById: lodash.keyBy(newContacts, "id"),
        contactIdsByAddress: buildIdWithAddress(newContacts),
        creating: false
      };
    case CREATE_CONTACTS_FAILURE:
      return {
        ...state,
        error: action.error,
        creating: false
      };
    case CREATE_CONTACTS_STOP:
      return {
        ...state,
        creating: false
      };
    case DELETE_CONTACT:
      const filtered = state.contacts.filter(({ id }) => id !== action.contactId);

      return {
        ...state,
        contacts: filtered,
        contactsById: lodash.keyBy(filtered, "id"),
        contactIdsByAddress: buildIdWithAddress(filtered),
      };
    default:
      return {
        ...state
      };
  }
};
