import React, { useEffect } from "react";
import { any, arrayOf, bool, func } from "prop-types";

import AddTemplate from "../AddTemplate";
import TemplatesList from "../TemplatesList";
import Wrapper from "../Wrapper";

const Templates = ({
  loading,
  busy,
  templates,
  getTemplates,
  addTemplate,
  updateTemplate,
}) => {
  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <Wrapper
      title="Templates"
      extra={<AddTemplate creating={busy} addTemplate={addTemplate} />}
    >
      <TemplatesList
        updating={busy}
        loading={loading}
        items={templates}
        updateTemplate={updateTemplate}
      />
    </Wrapper>
  );
};

Templates.propTypes = {
  loading: bool,
  busy: bool,
  templates: arrayOf(any),
  addTemplate: func.isRequired,
  updateTemplate: func.isRequired,
  getTemplates: func.isRequired
};

export default Templates;
