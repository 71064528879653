import request from "../request";

import { accountSlug, groupSlug, userId } from "../../utils/other";

const getOrgContacts = async ({
  ordering = null,
  tags = null,
  phone = null,
  list = null,
  tm_notify_id = null,
  created_days_ago = null,
  date_property = null
} = {}) =>
  await request({
    url: `/org/${accountSlug()}/contact/`,
    method: "GET",
    params: {
      ordering,
      tags,
      phone,
      list,
      tm_notify_id,
      created_days_ago,
      date_property
    }
  });

const getContactProfile = async contactId =>
  await request({
    url: `/org/${accountSlug()}/contact/${contactId}/`,
    method: "GET"
  });

const getContactTimeline = async contactId =>
  await request({
    url: `/org/${accountSlug()}/contact/${contactId}/get_timeline/`,
    method: "GET"
  });

const addContactNote = async (authorId, contactId, text) =>
  await request({
    url: `/org/${accountSlug()}/user_note/`,
    method: "POST",
    data: {
      about_notify_id: contactId,
      author_notify_id: authorId,
      text
    }
  });

const addContactEvent = async (contactId, data) =>
  await request({
    url: `/org/${accountSlug()}/contact/${contactId}/event/`,
    method: "POST",
    data: {
      name: data.name,
      value: data.value,
      when_at: data.whenAt
    }
  });

const updateContactData = async (contactId, data) =>
  await request({
    url: `/org/${accountSlug()}/contact/${contactId}/`,
    method: "PATCH",
    data: data
  });

const createOrgContact = async data =>
  request({
    url: `/org/${accountSlug()}/contact/`,
    method: "POST",
    data: data
  });

const setContactNoReplyState = async contactId =>
  await request({
    url: `/org/${accountSlug()}/contact/${contactId}/remove_property/`,
    method: "DELETE",
    data: {
      name: "Awaiting Reply"
    }
  });

const importContacts = async (dataString, settings) => {
  const { assignment, duplicate } = settings;

  const teamMemberNid = assignment === "me" ? userId() : undefined;
  const autoAssign = assignment === "automatically";

  return await request({
    url: `/org/${accountSlug()}/team_member/${userId()}/import_phone_contacts_sync/`,
    method: "POST",
    timeout: 180000,
    data: {
      json_text: dataString,
      teamMemberNid: teamMemberNid,
      auto_assign: autoAssign,
      mergeMode: duplicate,
      import_source: "ConnectWith Web"
    }
  });
};

function getContactRelationships() {
  return request({
    url: `/group/${groupSlug()}/primary/`,
    method: "GET"
  });
}

const updateContactRelationship = (relationshipId, teamMemberNotifyId) => (
  request({
    url: `/group/${groupSlug()}/primary/${relationshipId}/`,
    method: "PATCH",
    data: {
      team_member_notify_id: teamMemberNotifyId
    }
  })
);

const createContactRelationship = (contactNotifyId, teamMemberNotifyId) => (
  request({
    url: `/group/${groupSlug()}/primary/`,
    method: "POST",
    data: {
      contact_notify_id: contactNotifyId,
      team_member_notify_id: teamMemberNotifyId
    }
  })
);

const deleteOrgContact = (contactId, teamMemberNid) => (
  request({
    url: `/org/${accountSlug()}/contact/${contactId}/disable/`,
    method: "DELETE",
    data: {
      team_member_nid: teamMemberNid
    }
  })
);

export {
  getOrgContacts,
  getContactProfile,
  getContactTimeline,
  addContactNote,
  addContactEvent,
  updateContactData,
  createOrgContact,
  setContactNoReplyState,
  importContacts,
  getContactRelationships,
  updateContactRelationship,
  createContactRelationship,
  deleteOrgContact
};
