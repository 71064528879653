import React, { useState, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { string } from "prop-types";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import message from "antd/es/message";

import UpdatePhoneNumber from "../../pages/auth/Onboarding/UpdatePhoneNumber";
import onboarding from "../../services/onboarding";
import { setTeamMemberMobileNumber } from "../../services/api/teamMember";
import { formatPhone } from "../../utils/string";
import { updateProfile } from "../../actions/profile";

const FormItem = Form.Item;

const VerifyPhoneNumber = ({ accountSlug, notifyId, phoneNumber, updateProfile }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const [code, setCode] = useState("");

  const changeCode = useCallback(
    ({ target: { value } }) => {
      setCode(value);
      setErrors({});
    },
    [setCode, setErrors]
  );

  const settingPhoneNumber = useCallback(async mobileNumber => {
    const result = await setTeamMemberMobileNumber(accountSlug, mobileNumber, notifyId);
    updateProfile({ mobileNumber: result.mobile_number });
  }, []);

  const submit = useCallback(
    e => {
      e.preventDefault();
      if (loading) {
        return;
      }

      setLoading(true);
      (async () => {
        let [status, error] = await onboarding.checkingConfirmationCode(code, phoneNumber);

        if (status) {
          [status, error] = await onboarding.setTeamMemberPhoneVerified({
            accountSlug,
            notifyId,
            phone: phoneNumber
          });
          if (status) {
            message.success("Your phone number was verified.");
            await onboarding.clearStorage();
            setTimeout(() => {
              window.location.href = "/";
            }, 1500);
          }
        }

        error && setErrors({ code: error });
        setLoading(false);
      })();
    },
    [code, accountSlug, notifyId, phoneNumber, loading, setLoading, setErrors]
  );

  const resendCode = useCallback(() => {
    setResending(true);
    (async () => {
      const [status, error] = await onboarding.sendVerifyCode(phoneNumber);
      if (status) {
        message.success("Code resent.");
      } else {
        message.error(error);
      }
      setResending(false);
    })();
  }, [phoneNumber, setResending]);

  return (
    <Row>
      <Col offset={7} span={10}>
        <Card
          title="Verify Your Mobile Number"
          style={{ width: "100%" }}
          extra={formatPhone(phoneNumber)}
        >
          <Form onSubmit={submit}>
            <FormItem
              label="Enter Your Code"
              validateStatus={errors.code ? "error" : undefined}
              help={errors.code}
            >
              <Input
                placeholder="Enter your code"
                name="code"
                maxLength={6}
                disabled={loading}
                value={code}
                onChange={changeCode}
              />
            </FormItem>

            <div style={{ marginBottom: 20, textAlign: "center" }}>
              <Button type="link" loading={resending} disabled={loading} onClick={resendCode}>
                Resend code
              </Button>
              {" | "}
              <UpdatePhoneNumber
                disabledButton={resending || loading}
                onUpdatePhoneNumber={settingPhoneNumber}
                initialPhoneNumber={phoneNumber}
              />
            </div>

            <Button block type="primary" htmlType="submit" loading={loading}>
              Verify
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

VerifyPhoneNumber.propTypes = {
  accountSlug: string.isRequired,
  notifyId: string.isRequired,
  phoneNumber: string.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProfile
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(VerifyPhoneNumber);
