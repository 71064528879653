import React from "react";
import { func } from "prop-types";

import Header from "./Header";
import Content from "./Content";
import ButtonContinue from "./ButtonContinue";
import Button from "antd/es/button";
import Typography from "antd/es/typography";

import "./style.css";

const { Text } = Typography;

const Welcome = ({ onContinue }) => (
  <div>
    <Header
      title="Welcome to TextWith!"
      description="Our average store see a 20% more sales per year. We’ll just need to ask you a few questions to get setup."
    />

    <Content>
      <ButtonContinue title="Create New Account" onClick={() => onContinue({})} />
      <div className="onboarding-link">
        <Text>Already have an account?</Text>
      </div>
      <Button style={{ borderColor: "#1890ff" }} block size="large" href="/sign_in">
        Sign In
      </Button>
    </Content>
  </div>
);

Welcome.propTypes = {
  onContinue: func.isRequired
};

export default Welcome;
