import React, { useState, useCallback } from "react";
import { any, arrayOf, func } from "prop-types";
import lodash from "lodash";
import Table from "antd/es/table";
import Typography from "antd/es/typography";
import Input from "antd/es/input";

import CelPhoneMask from "../../utils/CelPhoneMask/cel-phone.mask";
import Tags from "../Tags";
import Launch from "./Launch";

const Text = Typography.Text;
const Search = Input.Search;

function getPhoneNumber(phones) {
  return lodash.find(phones || [], p => p.is_primary);
}

const columns = [
  {
    dataIndex: "name",
    key: "name",
    render: (name, { phones }) => {
      const phone = getPhoneNumber(phones);

      return (
        <div>
          <div>
            <Text type="strong">{name}</Text>
          </div>
          {phone ? (
            <Text type="secondary">
              {phone.label ? (
                <span style={{ color: "#737373", textTransform: "capitalize" }}>{phone.label}: </span>
              ) : null}
              {new CelPhoneMask().getValue(phone.phone_normalized)}
            </Text>
          ) : null}
        </div>
      );
    }
  },
  {
    dataIndex: "tags",
    render: tags => <Tags tags={tags} />
  },
  {
    render: (_, contact) => <Launch contact={contact} />
  }
];

const Contacts = ({ items, onCreateContactAndConversation }) => {
  const [contacts, setContacts] = useState(items);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const search = useCallback(({ target: { value } }) => {
    value = value.toLowerCase().trim().replace(/\s{2,}/g, " ");
    if (value) {
      const result = lodash.filter(items, contact => (
        (contact.name && contact.name.toLowerCase().includes(value)) ||
        (contact.phone && contact.phone.trim().replace(/\D+/g, '').includes(value))
      ));

      setPhoneNumber(
        (result.length === 0 && value.replace(/\D+/g, "").length >= 10) ?
          value.replace(/\D+/g, "") :
          null
      );
      setContacts(result);
    } else {
      setContacts(items);
      setPhoneNumber(null);
    }
  }, [items, setContacts, setPhoneNumber]);

  const callbackCreateNewContactWithThread = useCallback(() => {
    if (!phoneNumber) {
      return;
    }
    onCreateContactAndConversation(phoneNumber);
  }, [phoneNumber, onCreateContactAndConversation]);

  return (
    <div>
      <div>
        <Search
          placeholder="Search..."
          enterButton={phoneNumber ? "Create Conversation" : null}
          onChange={search}
          onSearch={callbackCreateNewContactWithThread}
        />
      </div>
      <br/>
      <Table
        showHeader={false}
        size="small"
        columns={columns}
        dataSource={contacts}
      />
    </div>
  );
};

Contacts.propTypes = {
  items: arrayOf(any).isRequired,
  onCreateContactAndConversation: func.isRequired
};

export default Contacts;
