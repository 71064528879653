import React from "react";
import Select from "antd/es/select";
import DatePicker from "antd/es/date-picker";
import { string, func, any, objectOf } from "prop-types";

import dataInfo from "./data";

const Option = Select.Option;

const Anniversary = ({
  label,
  value,
  onChangeLabel,
  onChange
}) => {
  const labels = dataInfo.labels.anniversaries;
  if (label && !labels.includes(label)) {
    labels.push(label);
  }

  return (
    <span className="ant-input-group-wrapper group-with-date">
      <span className="ant-input-wrapper ant-input-group">
        <span className="ant-input-group-addon">
          <Select
            placeholder="Select Label"
            style={{ width: 200 }}
            value={label}
            onChange={onChangeLabel}
          >
            {labels.map(l => (
              <Option key={l} value={l}>{l}</Option>
            ))}
          </Select>
        </span>

        <DatePicker
          placeholder="Select date"
          style={{ width: "100%" }}
          value={value}
          onChange={onChange}
        />
      </span>
    </span>
  );
};

Anniversary.propTypes = {
  label: string,
  value: objectOf(any),
  onChangeLabel: func.isRequired,
  onChange: func.isRequired
};

Anniversary.defaultProps = {
  label: null,
  value: null
};

export default Anniversary;
