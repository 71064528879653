import React from "react";

const QuickTips = () => (
  <div>
    <span className="ant-typography">
      <strong>
        Follow these quick tips for a successful and smooth import:
      </strong>
    </span>
    <ul style={{marginTop: 5}}>
      <li>
        Review the import help document <a rel="noopener noreferrer" target="_blank" href="https://intercom.help/textwith/en/articles/3386867-importing-data-into-textwith">here</a>
      </li>
      <li>
        Download our sample CVS template <a rel="noopener noreferrer" target="_blank" href="https://static.connectwith.ai/TextWith%20Import%20Template.csv">here</a>
      </li>
    </ul>
  </div>
);

export default QuickTips;
