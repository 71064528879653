import React, { useState, useCallback } from "react";
import { string, bool, func, any } from "prop-types";
import Calendar from "antd/es/calendar";
import Divider from "antd/es/divider";
import TimePicker from "antd/es/time-picker";
import Alert from "antd/es/alert";
import Modal from "antd/es/modal";
import moment from "moment";

const DateTimePicker = ({ buttonTitle, initData, visible, setVisible, onDone }) => {
  const [date, setDate] = useState(initData ? moment(new Date(initData)) : moment());
  const [time, setTime] = useState(initData ? moment(new Date(initData)) : moment());
  const [timeError, setTimeError] = useState(null);

  const callbackToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible, setVisible]);

  const callbackChangeDate = useCallback(d => {
    setDate(d);
  }, [setDate]);

  const callbackChangeTime = useCallback(d => {
    setTimeError(null);
    setTime(d);
  }, [setTime, setTimeError]);

  const callbackSelect = useCallback(() => {
    if (!time) {
      setTimeError("Time is not selected.");
      return;
    }

    const strDate = `${date.format("YYYY-MM-DD")} ${time.format("H:m")}`;
    const connectedDate = moment(new Date(strDate)).toDate();

    setVisible(false);
    onDone(null, connectedDate);
  }, [date, time, setVisible, setTimeError, onDone]);

  return (
    <Modal
      title="Scheduling"
      okText={buttonTitle}
      cancelText="Cancel"
      closable={false}
      visible={visible}
      onOk={callbackSelect}
      onCancel={callbackToggleVisible}
    >
      <Calendar
        defaultValue={date}
        fullscreen={false}
        onChange={callbackChangeDate}
      />
      <Divider />
      <TimePicker
        format="hh:mm a"
        style={{ width: "100%" }}
        defaultValue={time}
        onChange={callbackChangeTime}
      />
      {timeError ? (
        <Alert type="error" style={{ marginTop: 10 }} message={timeError}/>
      ) : null}
    </Modal>
  );
};

DateTimePicker.propTypes = {
  buttonTitle: string,
  initData: any,
  visible: bool,
  setVisible: func.isRequired,
  onDone: func.isRequired
};

DateTimePicker.defaultProps = {
  buttonTitle: "Send",
  initData: null,
  visible: false
};

export default DateTimePicker;
