import React, { Fragment, useState, useCallback } from "react";
import lodash from "lodash";
import Empty from "antd/es/empty";
import Skeleton from "antd/es/skeleton";
import List from "antd/es/list";
import { bool, any, func, arrayOf } from "prop-types";

import TemplateItem from "./TemplateItem";
import EditTemplate from "../EditTemplate";

const TemplatesList = ({
  isCalendarEvent,
  items,
  loading,
  updating,
  updateTemplate,
}) => {
  const [editTemplateId, setEditTemplateId] = useState(null);

  const callbackEdit = useCallback(id => {
    setEditTemplateId(id);
  }, [setEditTemplateId]);

  const callbackCancelEdit = useCallback(() => {
    setEditTemplateId(null);
  }, [setEditTemplateId]);

  const callbackSubmit = useCallback(data => {
    updateTemplate(editTemplateId, data)
      .then(() => {
        callbackCancelEdit();
      });
  }, [editTemplateId, callbackCancelEdit, updateTemplate]);

  const renterItem = data =>
    <TemplateItem data={data} onEdit={callbackEdit} />;

  const count = items.length;
  return (
    <div>
      {count === 0 && !loading ? (
        <Empty />
      ) : loading && count === 0 ? (
        <Fragment>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Fragment>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={items}
          renderItem={renterItem}
        />
      )}

      <EditTemplate
        isCalendarEvent={isCalendarEvent}
        template={lodash.find(items, { id: editTemplateId })}
        updating={updating}
        onSubmit={callbackSubmit}
        onCancel={callbackCancelEdit}
      />
    </div>
  );
};

TemplatesList.propTypes = {
  isCalendarEvent: bool,
  items: arrayOf(any).isRequired,
  loading: bool,
  updating: bool,
  updateTemplate: func.isRequired
};

TemplateItem.defaultProps = {
  isCalendarEvent: false,
  loading: false,
  updating: false
};

export default TemplatesList;
