import React  from "react";
import { string, bool } from "prop-types";
import { Helmet } from "react-helmet";

const Title = ({ title, strong }) => (
  <Helmet
    titleTemplate={`%s${strong ? "" : " | TextWith"}`}
    title={title}
  />
);

Title.propTypes = {
  title: string.isRequired,
  strong: bool
};

Title.defaultProps = {
  strong: false
};

export default Title;
