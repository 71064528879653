import React from "react";
import Divider from "antd/es/divider";
import { string } from "prop-types";

import "./style.css";

const SystemMessage = ({ message }) => (
  <div className="inbox-body-system-message-wrapper">
    <div className="inbox-body-system-message-divider-wrapper">
      <Divider style={{
        marginTop: 5,
        marginBottom: 5,
        fontWeight: "normal",
        fontSize: 13,
        color: "#757575"
      }}>
        system message
      </Divider>
    </div>
    <span className="inbox-body-system-message-content">
      {message}
    </span>
  </div>
);

SystemMessage.propTypes = {
  message: string.isRequired
};

export default SystemMessage;
