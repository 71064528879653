import React, { useState, useCallback } from "react";
import { any, objectOf, string } from "prop-types";
import _xor from "lodash/xor";
import Icon from "antd/es/icon";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Modal from "antd/es/modal";
import Switch from "antd/es/switch";
import List from "antd/es/list";
import message from "antd/es/message";

import firestoreService from "../../../services/firestoreService";

const Item = ({ isSelected, member, isOwner, onChange }) => {
  const change = useCallback(state => {
    onChange(member.notify_id, state);
  }, [member, onChange]);

  return (
    <List.Item>
      <List.Item.Meta
        title={
          [
            member.first_name,
            member.last_name,
            isOwner ? "(Owner)" : ""
          ].join(" ")
        }
      />
      <Switch checked={isSelected} onChange={change} />
    </List.Item>
  );
};

const Members = ({
  notifyId,
  teamMemberAddress,
  teamMembers,
  conversation,
}) => {
  const [visible, setVisible] = useState(false);
  const [publicThread, setPublicThread] = useState(conversation.members && conversation.members.length === 0);
  const [settingPublic, setSettingPublic] = useState(false);
  const [selected, setSelected] = useState(conversation.members || []);

  const toggle = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const changePublicThread = useCallback(state => {
    setPublicThread(state);
    (async () => {
      setSettingPublic(true);
      if (!state) {
        setSelected([notifyId]);
      }
      await firestoreService.setThreadMemberAccess(
        conversation.id,
        state ? [] : [notifyId]
      );
      setSettingPublic(false);
    })();
  }, [notifyId, publicThread]);

  const changeMember = useCallback(memberId => {
    if (conversation.teamMemberAddress !== teamMemberAddress) {
      message.error("Sorry only the the conversation owner can modify permissions.");
      return;
    }

    if (memberId === notifyId) {
      return;
    }

    const ids = _xor([...selected], [memberId]);
    setSelected(ids);
    (async () => {
      await firestoreService.setThreadMemberAccess(conversation.id, ids);
    })();
  }, [selected, conversation, teamMemberAddress, notifyId]);

  const notOwner = conversation.teamMemberAddress !== teamMemberAddress;

  return (
    <>
      <Button type="link" onClick={toggle}>
        <Icon type="team" />
      </Button>

      <Modal
        title="Members"
        footer={null}
        visible={visible}
        onCancel={toggle}
      >
        <div style={{ marginBottom: 15 }}>
          <Switch
            loading={settingPublic}
            disabled={notOwner}
            checked={publicThread}
            onChange={changePublicThread}
          />
          <span style={{ marginLeft: 4 }}/>
          {publicThread ? "Public Conversation" : "Private Conversation"}
          {notOwner ? " (must be owner to change access)" : null}
        </div>

        <Divider>
          {publicThread ? "Everyone on your Team has Access" : "Select Members to give Access"}
        </Divider>

        {publicThread ? null : (
          <List
            itemLayout="horizontal"
            dataSource={Object.values(teamMembers)}
            renderItem={member => (
              <Item
                member={member}
                isSelected={selected.includes(member.notify_id)}
                isOwner={conversation.teamMemberAddress === member.work_phone}
                onChange={changeMember}
              />
            )}
          />
        )}
      </Modal>
    </>
  );
};

Members.propTypes = {
  notifyId: string.isRequired,
  teamMemberAddress: string.isRequired,
  teamMembers: objectOf(any).isRequired,
  conversation: objectOf(any).isRequired,
};

export default Members;
