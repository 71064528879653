import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Contact from "./Contact";
import { fetchContactDetails, setCurrentContactId } from "../../../actions/contacts";

const mapStateToProps = (
  { contacts: { contactsById, contacts, fetching } },
  { match: { params: { id } } }
) => {
  const contact = contactsById[id];

  return {
    data: contact,
    loading: (fetching && !contact) || (contact && contact.fetchingDetails),
    // loading: (fetching && contacts.length === 0) || (contact && contact.fetchingDetails)
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchContactDetails,
  setCurrentContactId
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
