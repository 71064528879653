import React, { Fragment, useCallback } from "react";
import lodash from "lodash";
import Icon from "antd/es/icon";
import Button from "antd/es/button";
import Popover from "antd/es/popover";
import List from "antd/es/list";
import Empty from "antd/es/empty";
import Spin from "antd/es/spin";
import { any, arrayOf, objectOf, bool, func } from "prop-types";

import "./style.css";
import EditTemplate from "../../EditTemplate";

const Item = List.Item;
const Meta = Item.Meta;

const Template = ({ data, onSelected, onEdit }) => {
  const callbackEdit = useCallback(() => {
    onEdit(data.id);
  }, [data, onEdit]);

  const callbackSelect = useCallback(() => {
    onSelected(data);
  }, [data, onSelected]);

  return (
    <Item
      actions={[
        <Button
          size="small"
          type="link"
          onClick={callbackEdit}
        >
          Edit
        </Button>
      ]}
    >
      <Meta
        title={
          <Button
            size="small"
            type="link"
            onClick={callbackSelect}
          >
            {data.name}
          </Button>
        }
      />
    </Item>
  );
};

class TemplateChoosing extends React.Component {
  state = { visible: false, editTemplateId: null };

  componentWillMount() {
    const { getTemplates } = this.props;
    getTemplates();
  }

  toggleVisible = () => {
    const { visible } = this.state;
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    this.setState({ visible: !visible });
  };

  showEditTemplate = editTemplateId => {
    this.setState({ editTemplateId });
    this.toggleVisible();
  };

  cancelEditTemplate = () =>
    this.setState({ editTemplateId: null });

  updateTemplate = async data => {
    const { editTemplateId } = this.state;
    const { updateTemplate } = this.props;

    await updateTemplate(editTemplateId, data);
    this.cancelEditTemplate();
  };

  selectedTemplate = data => {
    const { onSelected } = this.props;

    this.toggleVisible();
    onSelected(data);
  };

  renterItem = data =>
    <Template
      data={data}
      onEdit={this.showEditTemplate}
      onSelected={this.selectedTemplate}
    />;

  renderContent = () => {
    const { templates, loading } = this.props;
    const count = templates.length;

    return (
      <div>
        {count === 0 && !loading ? (
          <Empty />
        ) : loading && !count ? (
          <div className="loader-wrapper">
            <Spin />
          </div>
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={templates}
            renderItem={this.renterItem}
          />
        )}
      </div>
    );
  };

  render() {
    const { editTemplateId, visible } = this.state;
    const { disabled, busy, templates } = this.props;

    return (
      <Fragment>
        <Popover
          visible={disabled ? false : visible}
          placement="top"
          title="Templates"
          trigger="hover"
          content={this.renderContent()}
          onVisibleChange={this.toggleVisible}
        >
          <Button
            disabled={disabled}
            style={{ marginRight: 10 }}
          >
            <Icon type="copy" />
          </Button>
        </Popover>

        <EditTemplate
          template={lodash.find(templates, { id: editTemplateId })}
          updating={busy}
          onSubmit={this.updateTemplate}
          onCancel={this.cancelEditTemplate}
        />
      </Fragment>
    );
  }
}

Template.propTypes = {
  data: objectOf(any).isRequired,
  onSelected: func.isRequired,
  onEdit: func.isRequired
};

TemplateChoosing.propTypes = {
  disabled: bool,
  loading: bool,
  busy: bool,
  templates: arrayOf(any),
  getTemplates: func.isRequired,
  updateTemplate: func.isRequired,
  onSelected: func.isRequired
};

TemplateChoosing.defaultProps = {
  disabled: false,
  loading: false,
  busy: false
};

export default TemplateChoosing;
