import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { updateContact } from "../actions/contacts";

const mapStateToProps = ({
  contacts: { contactsById }
}, { contactId }) => ({
  updating: contactsById[contactId] ? contactsById[contactId].contactUpdating : false
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateContact
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps);
