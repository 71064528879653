import React, { useCallback, useState } from "react";
import { string, func } from "prop-types";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import message from "antd/es/message";
import MaskedInput from "react-text-mask";

import { phoneNumberVerifyRequest } from "../../services/api/onboarding";

const ChangePhone = ({ currentPhone, onDone }) => {
  const [newPhone, setNewPhone] = useState(currentPhone);
  const [loading, setLoading] = useState(false);

  const changePhoneNumber = useCallback(({ target: { value } }) => {
    setNewPhone(value);
  }, [setNewPhone]);

  const send = useCallback(() => {
    setLoading(true);
    const phone = `+${newPhone.replace(/\D+/g, "")}`;

    (async () => {
      try {
        await phoneNumberVerifyRequest(phone);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
        setLoading(false);
        return;
      }

      message.success("Code sent");
      setLoading(false);
      onDone(phone);
    })();
  }, [newPhone, setLoading]);

  return (
    <div>
      <MaskedInput
        className="ant-input"
        placeholder="+1 (999) 999-9999"
        mask={["+", "1", "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
        value={newPhone}
        onChange={changePhoneNumber}
      />

      <Divider />

      <div style={{ textAlign: "right" }}>
        <Button
          type="primary"
          loading={loading}
          disabled={newPhone.replace(/\D+/g, "") === currentPhone.replace(/\D+/g, "")}
          onClick={send}
        >
          Verify Phone Number
        </Button>
      </div>
    </div>
  );
};

ChangePhone.propTypes = {
  currentPhone: string,
  onDone: func.isRequired,
};

ChangePhone.defaultProps = {
  currentPhone: ""
};

export default ChangePhone;
