import React, { useCallback, useMemo } from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { objectOf, arrayOf, any, bool, func } from "prop-types";

import "./style.css";

const FIELDS = [
  { key: 0, label: "Contact First Name", value: "{{first_name}}" },
  { key: 1,label: "Team Member Name", value: "{{team_member_name}}" },
  { key: 2, label: "Team Member Work Phone", value: "{{team_member_work_phone}}" }
];

const FormItem = Form.Item;

const insertAtCursor = (field, value) => {
  //IE support
  if (document.selection) {
    const sel = document.selection.createRange();
    sel.text = value;
  } else if (field.selectionStart || field.selectionStart === '0') {
    const startPos = field.selectionStart;
    const endPos = field.selectionEnd;
    field.value = [
      field.value.substring(0, startPos),
      value,
      field.value.substring(endPos, field.value.length)
    ].join("");
  } else {
    field.value += value;
  }

  return field.value;
};

const Field = ({ field: { label, value }, inputRef, onChange, onChanged }) => {
  const callbackClick = useCallback(e => {
    inputRef.current.focus();
    const newValue = insertAtCursor(inputRef.current.textAreaRef, value);
    onChange && onChange(newValue);
    onChanged && onChanged(true);
  }, [value, inputRef, onChange, onChanged]);

  return (
    <Button
      className="custom-fields_button"
      type="primary"
      size="small"
      onClick={callbackClick}
    >
      {label}
    </Button>
  );
};

const CustomFields = ({ showLabel, extraFields, inputRef, onChange, onChanged }) => {
  const mergedFields = useMemo(() => (
    [...FIELDS, ...extraFields]
  ), [extraFields]);

  const data = (mergedFields.map(field => (
    <Field
      key={field.key}
      field={field}
      inputRef={inputRef}
      onChange={onChange}
      onChanged={onChanged}
    />
  )));

  if (!showLabel) {
    return data;
  }

  return (
    <FormItem label="Custom Fields" className="custom-fields">
      {data}
    </FormItem>
  );
};

Field.propTypes = {
  field: objectOf(any).isRequired,
  inputRef: objectOf(any),
  onChange: func
};

Field.defaultProps = {
  inputRef: null
};

CustomFields.propTypes = {
  extraFields: arrayOf(any),
  inputRef: objectOf(any),
  showLabel: bool,
  onChange: func
};

CustomFields.defaultProps = {
  extraFields: [],
  showLabel: true,
  inputRef: null,
  onChange: null
};

export default CustomFields;
