import React, { useState, useCallback, useEffect, useRef } from "react";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Select from "antd/es/select";
import { objectOf, any, bool, func } from "prop-types";

import CustomFields from "../CustomFields";
import { isValid } from "./utils";

const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Option = Select.Option;

const FormTemplate = ({
  loading,
  editTemplate,
  onSubmit,
}) => {
  const messageRef = useRef(null);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [minutesBefore, setMinutesBefore] = useState(5);
  const [errors, setErrors] = useState({});

  const callbackChange = useCallback(({ target: { name, value } }) => {
    if (name === "name") {
      setName(value);
    } else if (name === "message") {
      setMessage(value);
    }

    setErrors({});
  }, []);

  const callbackSubmit = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    if (!isValid(name, message, setErrors)) {
      return;
    }

    const data = {
      name, templateText: message, minutesBefore,
    };
    onSubmit(data, () => {
      setName("");
      setMessage("");
      setMinutesBefore(5);
    });
  }, [
    name,
    message,
    minutesBefore,
    onSubmit
  ]);

  useEffect(() => {
    if (editTemplate) {
      setName(editTemplate.name || "");
      setMessage(editTemplate.templateText || "");
      setMinutesBefore(editTemplate.minutesBefore);
    }
  }, [editTemplate]);

  return (
    <Form className="workflow-form" onSubmit={callbackSubmit}>
      <FormItem
        label="Name (Required)"
        validateStatus={errors.name ? "error" : null}
        help={errors.name}
      >
        <Input
          name="name"
          maxLength={500}
          value={name}
          disabled={loading}
          onChange={callbackChange}
        />
      </FormItem>

      <FormItem
        label="Message (Required)"
        extra={`Character Count: ${message.length}`}
        validateStatus={errors.message ? "error" : null}
        help={errors.message}
      >
        <TextArea
          name="message"
          ref={messageRef}
          disabled={loading}
          value={message}
          onChange={callbackChange}
        />
      </FormItem>
      <CustomFields
        extraFields={[
          { key: 'title', label: "Title", value: "{{title}}" },
          { key: 'location', label: "Location", value: "{{location}}" },
        ]}
        inputRef={messageRef}
        onChange={setMessage}
      />

      <FormItem>
        <Select value={minutesBefore} onChange={m => setMinutesBefore(m)}>
          <Option value={5}>5 minutes before event</Option>
          <Option value={10}>10 minutes before event</Option>
          <Option value={60}>1 hour before event</Option>
          <Option value={1440}>24 hours before event</Option>
          <Option value={2880}>48 hours before event</Option>
        </Select>
      </FormItem>

      <Button
        block
        type="primary"
        loading={loading}
        onClick={callbackSubmit}
      >
        {editTemplate ? "Update" : "Create"}
      </Button>
      <input type="submit" style={{ position: "fixed", left: -2000 }} />
    </Form>
  );
};

FormTemplate.propTypes = {
  loading: bool.isRequired,
  editTemplate: objectOf(any),
  onSubmit: func.isRequired
};

FormTemplate.defaultProps = {
  editTemplate: null
};

export default FormTemplate;
