import message from "antd/es/message";

import firestoreService from "../services/firestoreService";
import { updateConversationAttributes } from "./conversations";
import { moveScroolToBottom } from "../utils/messaging";
import { firebaseStorage } from "../services/firebaseSetting";
import { setContactTaskAsDone } from "./tasks";
import {
  FETCH_MESSAGES_SUCCESS,
  MESSAGES_ADD_MESSAGE,
  MESSAGES_UPDATE_MESSAGE,
  MESSAGES_REMOVE_MESSAGE,
} from "../constants";

export const fetchMessages = (channelId, prevMessages = false) => async (dispatch) => {
  const keyLoading = prevMessages ? "loadingPrevMessages" : "loadingMessages";
  dispatch(updateConversationAttributes(channelId, { [keyLoading]: true }));

  let result = [];
  try {
    result = await firestoreService.loadingMessages(channelId, prevMessages);
  } catch (error) {
    dispatch(updateConversationAttributes(channelId, { [keyLoading]: false }));
    return;
  }

  const { messages, hasPrevPage } = result;
  await dispatch({
    type: FETCH_MESSAGES_SUCCESS,
    channelId,
    prevMessages,
    messages,
  });
  await dispatch(updateConversationAttributes(channelId, { [keyLoading]: false, hasPrevPage }));

  if (!prevMessages) {
    moveScroolToBottom();
  }
};

export const retrySend = (message) => async (dispatch, getState) => {
  const {
    conversations: { conversationsById },
  } = getState();
  const conversation = conversationsById[message.threadId];

  dispatch(
    sendMessageToServer(
      message.threadId,
      conversation.contactAddress,
      conversation.contactId,
      conversation.teamMemberAddress,
      {
        messageText: message.bodyText,
        wfResponseId: message.wfResponseId,
        scheduledAt: message.scheduledAt,
      },
      true
    )
  );
};

export const sendMessageToServer = (
  threadId,
  contactAddress,
  contactId,
  teamMemberAddress,
  { messageText = "", dataFile = null, wfResponseId = null, scheduledAt = null },
  passTask = false,
  callback = null,
) => async (dispatch, getState) => {
  const {
    profile: { notifyId },
  } = getState();

  // create thread if needed
  let sendToThreadId = threadId;
  if (!sendToThreadId) {
    try {
      sendToThreadId = await firestoreService.createNewConversation(
        contactAddress,
        teamMemberAddress,
        notifyId,
        contactId
      );
    } catch (error) {
      console.log("========= ERROR CREATING THREAD =========");
      console.log(error);
      callback && callback(false);
      return;
    }
  }

  const dataMessage = {
    wfResponseId,
    scheduledAt,
    bodyText: messageText,
    imageUrl: null,
  };

  if (!passTask && wfResponseId) {
    dispatch(setContactTaskAsDone(null, contactAddress));
  }

  if (dataFile) {
    const hideLoading = message.loading("Sending image...", 0);
    const sessionId = new Date().getTime();
    const storageRef = firebaseStorage.ref().child(`${sessionId}`);
    try {
      const snapshot = await storageRef.put(dataFile);
      dataMessage.imageUrl = await snapshot.ref.getDownloadURL();
    } catch (error) {
      hideLoading();
      console.log("========= ERROR SENDING MEDIA MESSAGE =========");
      console.log(error);
      callback && callback(false);
      return;
    }
    hideLoading();
  }

  try {
    await firestoreService.sendMessage(sendToThreadId, notifyId, dataMessage);
  } catch (error) {
    console.log("========= ERROR SENDING MESSAGE =========");
    console.log(error);
    callback && callback(false);
    return;
  }

  callback && callback(true);

  moveScroolToBottom();
};

export const addMessage = (dataMessage) => ({
  type: MESSAGES_ADD_MESSAGE,
  dataMessage,
});

export const updateMessage = (dataMessage) => ({
  type: MESSAGES_UPDATE_MESSAGE,
  dataMessage,
});

export const removeMessage = (dataMessage) => ({
  type: MESSAGES_REMOVE_MESSAGE,
  dataMessage,
});
