import React, { useState, useCallback, useEffect } from "react";
import lodash from "lodash";
import moment from "moment";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Divider from "antd/es/divider";
import Button from "antd/es/button";
import Select from "antd/es/select";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { message } from "antd";
import {
  arrayOf,
  objectOf,
  any,
  string,
  bool,
  func
} from "prop-types";

import "./style.css";
import FieldWithLabel from "./FieldWithLabel";
import dataInfo from "./data";
import { buildFormData } from "./utils";

const FormItem = Form.Item;
const Option = Select.Option;

const buildEmpty = (label, value = "") =>
  ({ id: `key-${new Date().getTime()}`, label, value });

const buildAddress = () => ({
  street: "",
  city: "",
  state: "",
  zip: "",
  country: "United States"
});

const ContactForm = ({ tags, dataInit, loading, onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [company, setCompany] = useState(null);
  const [lastName, setLastName] = useState("");
  const [contactTags, setContactTags] = useState([]);
  const [properties, setProperties] = useState({
    phones: [buildEmpty(dataInfo.labels.phones[0])],
    emails: [buildEmpty(dataInfo.labels.emails[0])],
    addresses: [buildEmpty(dataInfo.labels.addresses[0], buildAddress())],
    anniversaries: [buildEmpty(dataInfo.labels.anniversaries[0], null)]
  });

  const validate = () => {
    const phs = properties.phones.filter(p => p.value).length;
    if (firstName || lastName || phs) {
      return true;
    }

    message.error("Name or Phone number can't be blank.");

    return false;
  };

  const callbackAdd = useCallback(type => {
    const newProperties = { ...properties };
    const newValue = type === "addresses" ?
      buildAddress() :
      type === "anniversaries" ?
        null :
        "";

    newProperties[type].push(buildEmpty(dataInfo.labels[type][0], newValue));
    setProperties(newProperties);
  } ,[properties, setProperties]);

  const callbackDelete = useCallback((type, id) => {
    const newProperties = { ...properties };
    const idx = lodash.findIndex(newProperties[type], { id });

    newProperties[type].splice(idx, 1);
    setProperties(newProperties);
  }, [properties, setProperties]);

  const callbackFirstName = useCallback(({ target: { value }}) => {
    setFirstName(value);
  }, [setFirstName]);

  const callbackLastName = useCallback(({ target: { value }}) => {
    setLastName(value);
  }, [setLastName]);

  const callbackCompany = useCallback(({ target: { value }}) => {
    setCompany(company ? {
      ...company,
      value
    } : {
      value,
      label: "Company",
      isNew: true
    });
  }, [company, setCompany]);

  const callbackChangeLabel = useCallback((type, id, label) => {
    const newProperties = { ...properties };
    const idx = lodash.findIndex(newProperties[type], { id });

    newProperties[type][idx].label = label;
    setProperties(newProperties);
  }, [properties, setProperties]);

  const callbackChangeValue = useCallback((type, id, value) => {
    const newProperties = { ...properties };
    const idx = lodash.findIndex(newProperties[type], { id });

    newProperties[type][idx].value = value;
    setProperties(newProperties);
  }, [properties, setProperties]);

  const callbackChangeTags = useCallback(ts => {
    setContactTags(ts);
  }, [setContactTags]);

  const callbackSubmit = useCallback(() => {
    if (!validate()) {
      return;
    }

    const data = buildFormData({
      firstName,
      lastName,
      company,
      properties: { ...properties },
      tags: [ ...contactTags ]
    });

    onSubmit((dataInit && dataInit.id), data);
  }, [
    dataInit,
    firstName,
    lastName,
    company,
    properties,
    contactTags,
    onSubmit
  ]);

  useEffect(() => {
    if (dataInit && dataInit.id) {
      setFirstName(dataInit.firstName);
      setLastName(dataInit.lastName);
      setCompany(dataInit.company);
      setContactTags(dataInit.tags);

      const newProperties = {
        phones: [],
        emails: [],
        addresses: [],
        anniversaries: []
      };
      newProperties.phones = lodash.map(
        dataInit.phones,
        ({ id, label, phone_normalized }) => ({
          id,
          label,
          value: phone_normalized
        })
      );

      if (dataInit.profileData) {
        lodash.forEach(
          dataInit.profileData.labeled_properties || [],
          ({ name, label, value }) => {
            if (name === "Address") {
              newProperties.addresses.push({
                label,
                value,
                id: `key-${label}-${new Date().getTime()}`
              });
            } else if (name === "Email") {
              newProperties.emails.push({
                label,
                value,
                id: `key-${label}-${value}-${new Date().getTime()}`
              });
            }
          });

        lodash.forEach(
          dataInit.profileData.properties || [],
          ({ name, value }) => {
            if (name.includes("Event")) {
              newProperties.anniversaries.push({
                label: (name || "").replace("Event ", ""),
                value: moment(new Date(value)),
                id: `key-${name}-${value}-${new Date().getTime()}`
              })
            }
          });
      }

      const comp = dataInit
        .properties.filter(r => r.name.startsWith("Company"))[0];
      if (comp) {
        setCompany({
          id: comp.id,
          name: comp.name,
          value: comp.value
        });
      }

      setProperties(newProperties);
    }
  }, []);

  return (
    <Form>
      <FormItem>
      </FormItem>

      <Row gutter={16}>
        <Col span={12}>
          <FormItem label="First Name">
            <Input value={firstName} onChange={callbackFirstName} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Last Name">
            <Input value={lastName} onChange={callbackLastName} />
          </FormItem>
        </Col>
      </Row>

      <FormItem label="Company">
        <Input
          value={(company && company.value) || ""}
          onChange={callbackCompany}
        />
      </FormItem>

      <Divider />

      <FormItem label="Phone">
        <FieldWithLabel
          type="phones"
          items={properties.phones}
          onAdd={callbackAdd}
          onDelete={callbackDelete}
          onChangeLabel={callbackChangeLabel}
          onChangeValue={callbackChangeValue}
        />
      </FormItem>

      <FormItem label="Email">
        <FieldWithLabel
          type="emails"
          items={properties.emails}
          onAdd={callbackAdd}
          onDelete={callbackDelete}
          onChangeLabel={callbackChangeLabel}
          onChangeValue={callbackChangeValue}
        />
      </FormItem>

      <FormItem label="Address">
        <FieldWithLabel
          type="addresses"
          items={properties.addresses}
          onAdd={callbackAdd}
          onDelete={callbackDelete}
          onChangeLabel={callbackChangeLabel}
          onChangeValue={callbackChangeValue}
        />
      </FormItem>

      <FormItem label="Anniversaries">
        <FieldWithLabel
          type="anniversaries"
          items={properties.anniversaries}
          onAdd={callbackAdd}
          onDelete={callbackDelete}
          onChangeLabel={callbackChangeLabel}
          onChangeValue={callbackChangeValue}
        />
      </FormItem>

      <Divider />

      <FormItem label="Tags">
        <Select
          mode="tags"
          placeholder="Select Tags"
          style={{ width: '100%' }}
          value={contactTags}
          onChange={callbackChangeTags}
        >
          {tags.map(t => <Option key={t} value={t}>{t}</Option>)}
        </Select>
      </FormItem>

      <Divider />

      <Button block type="primary" loading={loading} onClick={callbackSubmit}>
        {dataInit && dataInit.id ? "Update" : "Create"}
      </Button>
    </Form>
  );
};

ContactForm.propTypes = {
  tags: arrayOf(string).isRequired,
  dataInit: objectOf(any),
  loading: bool,
  onSubmit: func.isRequired
};

ContactForm.defaultPtops = {
  dataInit: null,
  loading: false
};

export default ContactForm;
