import { connect } from "react-redux";

import ListOfContacts from './ListOfContacts';

const mapStateToProps = ({
  lists,
  contacts,
}) => ({
  contacts: contacts.contacts,
  contactsById: contacts.contactsById,
  listsById: lists.byId,
  loading: lists.fetching || contacts.fetching,
});

export default connect(mapStateToProps)(ListOfContacts);
