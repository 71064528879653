import React from "react";
import Button from "antd/es/button";
import { bool, func } from "prop-types";

import "./style.css";

const ButtonPreload = ({ busy, onClick }) => (
  <div className="inbox-body-preload-wrapper">
    <Button
      size="small"
      type="dashed"
      loading={busy}
      onClick={onClick}
    >
      {busy ? null : "Load earlier messages"}
    </Button>
  </div>
);

ButtonPreload.propTypes = {
  busy: bool,
  onClick: func
};

ButtonPreload.defaultProps = {
  busy: false,
  onClick: null
};

export default ButtonPreload;
