import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getTemplates, addTemplate, updateTemplate } from "../actions/templates";

const mapStateToProps = ({
  templates: {
    loading,
    busy,
    templates
  }
}) => ({
  loading,
  busy,
  templates
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getTemplates,
  addTemplate,
  updateTemplate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps);
