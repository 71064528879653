import React, { useState, useCallback } from "react";
import { bool, func, string } from "prop-types";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography/Typography";

const { Text } = Typography;

const ErrorMessage = ({ isOutgoing, message, canRetry, onRetry, onDelete }) => {
  const [visible, setVisible] = useState(false);

  const toggle = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const retry = useCallback(() => {
    setVisible(false);
    onRetry();
  }, []);

  const remove = useCallback(() => {
    setVisible(false);
    onDelete();
  }, []);

  return (
    <>
      <div style={{ textAlign: isOutgoing ? 'right' : 'left' }}>
        <Button type="link" onClick={toggle}>
          <Text type="danger">{message}</Text>
        </Button>
      </div>
      <Modal
        centered
        closable={false}
        footer={null}
        title={message.replace('. Tap to retry', '')}
        visible={visible}
        onCancel={toggle}
      >
        {canRetry ? (
          <Button block type="primary" style={{ marginBottom: 6 }} onClick={retry}>
            Try Again
          </Button>
        ) : null}
        <Button block type="danger" onClick={remove}>
          Delete Message
        </Button>
      </Modal>
    </>
  );
};

ErrorMessage.propTypes = {
  isOutgoing: bool,
  message: string.isRequired,
  canRetry: bool,
  onRetry: func.isRequired,
  onDelete: func.isRequired
};

ErrorMessage.defaultProps = {
  isOutgoing: true,
  canRetry: false,
};

export default ErrorMessage;
