import React, { useState,  useCallback } from 'react';

import ContactsGroupedByStatuses from '../../../../components/ContactsGroupedByStatuses'

const ContactsWithStatuses = ({ list, contacts, onChangeTab }) => {
  const [currentTabKey, setCurrentTabKey] = useState('overview');

  const changeTab = useCallback(tab => {
    onChangeTab && onChangeTab(['overview', 'all'].includes(tab) ? null : tab);
    setCurrentTabKey(tab);
  }, [onChangeTab]);

  return (
    <ContactsGroupedByStatuses
      list={list}
      tabKey={currentTabKey}
      contacts={contacts}
      onChangeTabbKey={changeTab}
    />
  );
};

export default ContactsWithStatuses;
