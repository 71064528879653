import { connect } from "react-redux";
import React, { useState, useCallback, useRef, useEffect } from "react";
import lodash from "lodash";
import moment from "moment";
import Button from "antd/es/button";
import Input from "antd/es/input";
import Form from "antd/es/form";
import DatePicker from "antd/es/date-picker";
import antdMessage from "antd/es/message";
import { any, string, func, objectOf } from "prop-types";

import "./style.css";
import CustomFields from "../CustomFields";
import AddImage from "./AddImage";
import RecipientsField from "./RecipientsField";
import { firebaseStorage } from "../../services/firebaseSetting";
import { buildMessage } from "../../utils/string";
  import CelPhoneMask from "../../utils/CelPhoneMask/cel-phone.mask";
import {
  buildEventFormData,
  buildResponsesEventFormData,
  baseValidate, buildResponsesFromData
} from "./utils";
import { toTZFromUTC } from '../../utils/other';
import {
  createWorkflow,
  updateWorkflow,
  createWorkflowResponseOption
} from "../../services/api/workflow";
import { DEFAULT_TIMEZONE } from "../../constants";
import { sendMessageToServer } from "../../actions/messages";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const ProductLaunch = ({
  typeOfCampaign,
  dataInit,
  fetchWorkflows,
  orgTimezoneName,
  orgForwardingPhoneNumber,
  conversationsByContactAddress,
  firstName,
  workPhone,
  onSuccess,
  dispatch,
}) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [dataImage, setDataImage] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tag, setTag] = useState("");
  const [responses, setResponses] = useState([]);
  const [errors, setErrors] = useState({});
  const [sendingActive, setSendingActive] = useState(false);
  const [sendingDraft, setSendingDraft] = useState(false);
  const [sendingTestMessage, setSendingTestMessage] = useState(false);
  const [isAutomatic, setIsAutomatic] = useState(typeOfCampaign === "automatic");

  const messageRef = useRef(null);

  const isTestMessageValid = name && message;

  const callbackChangeName = useCallback(e => {
    setName(e.target.value);
    setErrors({});
  }, [setName, setErrors]);

  const callbackChangeMessage = useCallback(e => {
    setMessage(e.target.value);
    setErrors({});
  }, [setMessage, setErrors]);

  const callbackChangeImage = useCallback(data => {
    setDataImage(data);
  }, [setDataImage]);

  const callbackChangeStartDate = useCallback(d => {
    setStartDate(d);
  }, [setStartDate]);

  const callbackChangeEndDate = useCallback(d => {
    setEndDate(d);
  }, [setEndDate]);

  const callbackChangeTag = useCallback(t => {
    setTag(t);
    setErrors({});
  }, [setTag, setErrors]);

  const callbackAddResponse = useCallback(() => {
    setResponses([...responses, {
      id: `key-${new Date().getTime()}`,
      name: "",
      message: "",
      action: "Set",
      tag: "",
      goal: "No"
    }]);
  }, [responses, setResponses]);

  const callbackValidate = useCallback(type => {
    const [success, newErrors] = baseValidate(
      type, { name, message, tag, responses, errors }
    );

    setErrors(newErrors);

    return success;
  }, [
    name,
    message,
    tag,
    responses,
    errors,
    setErrors
  ]);

  const callbackSubmit = useCallback(async status => {
    if (status === "active") {
      setSendingActive(true);
    } else {
      setSendingDraft(true);
    }

    // Creating image URL
    let imageUri;
    if (dataImage && typeof dataImage === "object") {
      const sessionId = new Date().getTime();
      const storageRef = firebaseStorage.ref().child(`${sessionId}`);

      try {
        const snapshot = await storageRef.put(dataImage);
        imageUri = await snapshot.ref.getDownloadURL();
      } catch (error) {
        console.log(error);
      }
    }

    // Build data
    const data = buildEventFormData({
      orgTimezoneName,
      isAutomatic,
      name,
      startDate,
      endDate,
      message,
      tag,
      imageUri,
      category: "product-launch"
    });

    let workflowId = dataInit.id;
    let success = false;

    // Sending Data to the server
    try {
      if (dataInit.id) {
        await updateWorkflow(workflowId, data, status);
      } else {
        const workflow = await createWorkflow(data, status);
        workflowId = workflow.id;
      }
      success = true;
    } catch (error) {
      console.log(error);
    }

    // Sending responses
    if (workflowId && responses.length > 0) {
      const responsesData = buildResponsesEventFormData(workflowId, responses);
      const promises = responsesData.map(r =>
        createWorkflowResponseOption(workflowId, r));

      try {
        await Promise.all(promises);
      } catch (error) {
        console.log(error);
      }
    }

    // Refresh Workflows
    await fetchWorkflows();

    // Stop spins
    setSendingActive(false);
    setSendingDraft(false);

    // Notification if everything is ok than fo to list of workflows
    if (success) {
      antdMessage.success(dataInit.id ? "Updated." : "Created.");
      if (onSuccess) {
        onSuccess();
      }
    } else {
      antdMessage.error("Unknown error, please repeat again.");
    }
  }, [
    orgTimezoneName,
    dataInit,
    dataImage,
    name,
    startDate,
    endDate,
    message,
    tag,
    responses,
    setSendingDraft,
    setSendingActive,
    fetchWorkflows,
    onSuccess
  ]);

  const callbackSaveAsDraft = useCallback(() => {
    if (!callbackValidate("draft")) {
      return;
    }

    setErrors({});
    callbackSubmit("draft");
  }, [callbackValidate, callbackSubmit, setErrors]);

  const callbackCreateAndActivate = useCallback(() => {
    if (!callbackValidate()) {
      return;
    }

    callbackSubmit("active");
  }, [callbackValidate, callbackSubmit]);

  const callbackSendTestMessage = useCallback(() => {
    setSendingTestMessage(true);
    (async () => {
      const conversation = conversationsByContactAddress[orgForwardingPhoneNumber];
      const conversationId = conversation ? conversation.id : null;
      const formattedMessage = buildMessage(message, {
        first_name: "Jane",
        team_member_name: firstName,
        team_member_work_phone: new CelPhoneMask().getValue(workPhone),
      });
      const data = { messageText: formattedMessage, dataFile: dataImage };

      dispatch(
        sendMessageToServer(
          conversationId,
          orgForwardingPhoneNumber,
          null,
          workPhone,
          data,
          false,
          status => {
            if (status) {
              antdMessage.success("Sent! You should receive a text shortly.");
            }
            setSendingTestMessage(false);
          }
        )
      );
    })();
  }, [isTestMessageValid, dataImage, orgForwardingPhoneNumber, conversationsByContactAddress, firstName, workPhone, dispatch]);

  useEffect(() => {
    if (dataInit && dataInit.id) {
      setName(dataInit.name || "");
      setMessage(dataInit.template_text || "");
      setStartDate(dataInit.start_at ? moment(toTZFromUTC(dataInit.start_at, orgTimezoneName).format("YYYY-MM-DD HH:mm")) : null);
      setEndDate(dataInit.end_at ? moment(toTZFromUTC(dataInit.end_at, orgTimezoneName).format("YYYY-MM-DD HH:mm")) : null);
      callbackChangeTag(
        lodash.get(dataInit, "contact_query.property_name") ||
        lodash.get(dataInit, "contact_queries[0].property_name", "")
      );

      if (dataInit.image_url) {
        setDataImage(dataInit.image_url);
      }

      if (dataInit.opts) {
        setResponses(buildResponsesFromData(dataInit.opts));
      }

      setIsAutomatic(dataInit.send_automatically);
    }
  }, [
    orgTimezoneName,
    dataInit,
    setName,
    setMessage,
    setDataImage,
    setStartDate,
    setEndDate,
    setResponses,
    callbackChangeTag
  ]);

  return (
    <Form className="workflow-form">
      <FormItem
        label="Name (Required)"
        validateStatus={errors.name ? "error" : null}
        help={errors.name}
      >
        <Input
          value={name}
          onChange={callbackChangeName}
        />
      </FormItem>

      <FormItem
        label="Message (Required)"
        extra={`Character Count: ${message.length}`}
        validateStatus={errors.message ? "error" : null}
        help={errors.message}
      >
        <TextArea
          maxLength={500}
          ref={messageRef}
          value={message}
          onChange={callbackChangeMessage}
        />
      </FormItem>
      <CustomFields inputRef={messageRef} onChange={setMessage} />

      <FormItem>
        <AddImage image={dataImage} onChange={callbackChangeImage} />
      </FormItem>

      {isAutomatic ? (
        <FormItem
          label="Send At"
          extra={`${orgTimezoneName.replace(/_/g, ' ')} Time Zone`}
        >
          <DatePicker
            showTime={{ format: "h:mm a", use12Hours: true }}
            format="ddd MMM D [at] h:mma"
            value={startDate}
            onChange={callbackChangeStartDate}
          />
        </FormItem>
      ) : (
        <>
          <FormItem
            label="Start Date"
            extra="When Team Members can start sending messages"
          >
            <DatePicker value={startDate} onChange={callbackChangeStartDate} />
          </FormItem>

          <FormItem
            label="End Date (Optional)"
            extra="When Team Members can no longer send messages"
          >
            <DatePicker value={endDate} onChange={callbackChangeEndDate} />
          </FormItem>
        </>
      )}

      <RecipientsField
        tag={tag}
        error={errors.tag}
        onChangeTag={callbackChangeTag}
      />

      <div className="form-actions">
        <Button
          block
          disabled={!isTestMessageValid || sendingActive || sendingDraft}
          loading={sendingTestMessage}
          onClick={callbackSendTestMessage}
        >
          Send Myself a Test Message
        </Button>

        <Button
          block
          type="primary"
          loading={sendingDraft}
          disabled={sendingActive || sendingTestMessage}
          onClick={callbackSaveAsDraft}
        >
          Save as Draft
        </Button>

        <Button
          block
          type="primary"
          loading={sendingActive}
          disabled={sendingDraft || sendingTestMessage}
          onClick={callbackCreateAndActivate}
        >
          {dataInit && dataInit.id ? "Update" : "Create"}&nbsp;and Activate
        </Button>
      </div>
    </Form>
  );
};

ProductLaunch.propTypes = {
  dataInit: objectOf(any),
  orgTimezoneName: string,
  orgForwardingPhoneNumber: string,
  typeOfCampaign: string,
  conversationsByContactAddress: objectOf(any),
  firstName: string,
  workPhone: string,
  fetchWorkflows: func.isRequired,
  onSuccess: func
};

ProductLaunch.defaultProps = {
  dataInit: {},
  typeOfCampaign: "personalize",
  orgTimezoneName: DEFAULT_TIMEZONE,
  orgForwardingPhoneNumber: null,
  firstName: "",
  workPhone: "",
  onSuccess: null
};

const mapStateToProps = ({
  application: {
    orgTimezoneName,
    orgForwardingPhoneNumber,
  },
  conversations: { conversationsByContactAddress },
  profile: { firstName, phone },
}) => ({
  firstName,
  orgTimezoneName,
  orgForwardingPhoneNumber,
  conversationsByContactAddress,
  workPhone: phone,
});

export default connect(mapStateToProps)(ProductLaunch);
