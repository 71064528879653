import lodash from "lodash";

import {
  FETCH_MESSAGES_SUCCESS,
  MESSAGES_ADD_MESSAGE,
  MESSAGES_UPDATE_MESSAGE,
  MESSAGES_REMOVE_MESSAGE
} from "../constants";

const initialState = {
  messages: {}
};

export default (state = initialState, action) => {
  let messages = [];
  switch (action.type) {
    case FETCH_MESSAGES_SUCCESS:
      if (action.prevMessages) {
        if (state.messages[action.channelId]) {
          messages = [...state.messages[action.channelId], ...action.messages];
        } else {
          messages = action.messages;
        }
      } else {
        messages = action.messages;
      }

      messages = lodash.filter(messages, m => m.deliveryStatus !== "hidden");

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.channelId]: lodash.sortBy(lodash.uniqBy(messages, "refId"), "createdAt")
        }
      };
    case MESSAGES_ADD_MESSAGE:
      if (state.messages[action.dataMessage.threadId]) {
        messages = [...state.messages[action.dataMessage.threadId], action.dataMessage];
      } else {
        messages = [action.dataMessage];
      }

      messages = lodash.filter(messages, m => m.deliveryStatus !== "hidden");

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.dataMessage.threadId]: lodash.sortBy(
            lodash.uniqBy(messages, "refId"),
            "createdAt"
          )
        }
      };
    case MESSAGES_UPDATE_MESSAGE:
      if (!state.messages[action.dataMessage.threadId]) {
        return {
          ...state
        };
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.dataMessage.threadId]: state.messages[action.dataMessage.threadId].map(item => {
            if (item.refId === action.dataMessage.refId) {
              return {
                ...action.dataMessage
              };
            }

            return item;
          })
        }
      };
    case MESSAGES_REMOVE_MESSAGE:
      if (!state.messages[action.dataMessage.threadId]) {
        return {
          ...state
        };
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.dataMessage.threadId]: state.messages[action.dataMessage.threadId].filter(
            ({ refId }) => refId !== action.dataMessage.refId
          )
        }
      };
    default:
      return {
        ...state
      };
  }
};
