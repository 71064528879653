import React, { useCallback } from "react";
import Tabs from "antd/es/tabs";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import message from "antd/es/message";
import { bool, func } from "prop-types";

import FormPiplineList from "../FormPiplineList";
import FormListOfContacts from "../FormListOfContacts";

const { TabPane } = Tabs;
const { Text } = Typography;

const AddList = ({ visible, loading, onAdd, onClose }) => {
  const callbackSubmit = useCallback((data, callback) => {
    return onAdd(data)
      .then(() => {
        message.success("Created", 2);
        onClose();
        callback && callback(true);
      })
      .catch(() => {
        callback && callback(false);
      });
  }, [onAdd, onClose]);

  return (
    <Modal
      title="New List"
      footer={null}
      width={700}
      bodyStyle={{ paddingTop: 0 }}
      visible={visible}
      onCancel={onClose}
    >
      <Tabs>
        <TabPane key="list" tab="List">
          <div style={{ marginBottom: 10 }}>
            <Text type="secondary">Create a list of contacts</Text>
          </div>
          <FormListOfContacts onSubmit={callbackSubmit} />
        </TabPane>
        <TabPane key="pipeline" tab="Pipeline">
          <div style={{ marginBottom: 10 }}>
            <Text type="secondary">Create a pipline to manage contacts through a workflow like a sales process</Text>
          </div>
          <FormPiplineList loading={loading} onSubmit={callbackSubmit} />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

AddList.propTypes = {
  visible: bool,
  loading: bool,
  onAdd: func.isRequired,
  onClose: func.isRequired
};

AddList.defaultProps = {
  visible: false,
  loading: false
};

export default AddList;
