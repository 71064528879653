import React  from "react";
import Empty from "antd/es/empty";
import { arrayOf, number, objectOf, oneOfType, string } from "prop-types";

import "./style.css";
import Record from "./Record";

const Properties = ({ data }) => (
  <div className="properties">
    {data.length === 0 ?
      <Empty/> :
      data.map((item, index) =>
        <Record key={index} label={item.label} value={item.value} />
      )}
  </div>
);

Properties.propTypes = {
  data: arrayOf(objectOf(oneOfType([number, string, arrayOf(string)]))).isRequired
};

export default Properties;
