import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Body from "../../Inbox/Body/Body";
import { fetchMessages } from "../../../actions/messages";

const mapStateToProps = ({
  conversations: { conversationsByContactAddress },
  messages: { messages },
  contacts: { contactsById },
  application: { teamMembers, teamMembersByAddress },
  profile: { notifyId },
  tasks: { currentTaskContacts, currentContactIndex }
}) => {
  const record = currentTaskContacts[currentContactIndex];
  if (!record) {
    return {};
  }

  const conversation = conversationsByContactAddress[record.address];
  if (!conversation) {
    return {};
  }

  const msgs = messages[conversation.id] || [];
  const contact = contactsById[conversation.contactId];
  const {
    loadingMessages,
    loadingPrevMessages,
    teamMemberAddress,
    replyState,
    replyStateLoading
  } = conversation;
  const sender = teamMembersByAddress[teamMemberAddress];

  return {
    contact,
    sender,
    teamMembers,
    replyState,
    replyStateLoading,
    currentUserId: notifyId,
    currentConversationId: conversation.id,
    currentConversationContactId: conversation.contactId,
    messages: msgs,
    hasPrevPage: conversation.hasPrevPage,
    isLoading: !!loadingMessages && msgs.length === 0,
    isLoadingPrev: !!loadingPrevMessages
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchMessages
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Body);
