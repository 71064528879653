import React from "react";
import "firebase/auth";
import Modal from "antd/es/modal";
import Spin from "antd/es/spin";
import onboarding from "../services/onboarding";
import * as firebase from "firebase";
import {refreshData} from "../utils/data";

class Loading extends React.PureComponent {
  state = { visibleLoader: true };

  componentWillMount() {
    if (onboarding.isFinished) {
      firebase.auth().onAuthStateChanged(user => {
        this.setState({ visibleLoader: false });
        if (user && onboarding.isFinished) {
          refreshData().then(() => {});
        }
      });
    } else {
      this.setState({ visibleLoader: false });
      if (!window.location.pathname.includes("sign_up") && !window.location.pathname.includes("sign_in")) {
        window.location.href = "/sign_up";
      }
    }
  }

  render() {
    const { visibleLoader } = this.state;
    const { children } = this.props;

    return (
      <div>
        <Modal
          centered
          closable={false}
          footer={null}
          visible={visibleLoader}
        >
          <div style={{ textAlign: "center", padding: "20px 0" }}>
            <Spin size="large" />
          </div>
        </Modal>
        {visibleLoader ? null : children}
      </div>
    );
  }
}

export default Loading;
