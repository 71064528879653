import lodash from "lodash";

import { buildTasks } from "./utils";
import {
  TASKS_FETCH_TASKS_BEGIN,
  TASKS_FETCH_TASKS_SUCCESS,
  TASKS_FETCH_TASKS_FAILURE,
  TASKS_FETCH_TASK_CONTACTS_BEGIN,
  TASKS_FETCH_TASK_CONTACTS_SUCCESS,
  TASKS_FETCH_TASK_CONTACTS_FAILURE,
  TASKS_START_TASK,
  TASKS_STOP_TASK,
  TASKS_SET_CURRENT_CONTACT,
  TASKS_SET_CURRENT_TEMPLATE,
  TASKS_REMOVE_CONTACT,
  TASKS_UPDATE_CONTACT_ATTRS
} from "../constants";

const initialState = {
  tasks: [],
  tasksById: {},
  loadingTasks: true,
  currentTaskContacts: [],
  currentTaskContactsLoading: false,
  currentContactIndex: -1,
  currentTemplate: "",
  currentTask: null,
  currentTaskStatus: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TASKS_FETCH_TASKS_BEGIN:
      return {
        ...state,
        loadingTasks: true
      };
    case TASKS_FETCH_TASKS_SUCCESS:
      const tasks = buildTasks(
        lodash.filter(action.tasks, t => !t.send_automatically)
      );
      return {
        ...state,
        tasks,
        tasksById: lodash.keyBy(tasks, "id"),
        loadingTasks: false
      };
    case TASKS_FETCH_TASKS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingTasks: false
      };
    case TASKS_FETCH_TASK_CONTACTS_BEGIN:
      return {
        ...state,
        currentTaskContactsLoading: true
      };
    case TASKS_FETCH_TASK_CONTACTS_SUCCESS:
      return {
        ...state,
        currentTaskContactsLoading: false,
        currentTaskContacts: lodash.sortBy(
          lodash.map(action.contacts, item => ({
            taskResponseId: item.id,
            address: item.contact_address,
            notifyId: item.contact_notify_id,
            state: item.contact_state,
            taskId: item.task,
            workflowId: action.workflowId,
            orderNum: item.order_num
          })),
          "orderNum"
        )
      };
    case TASKS_FETCH_TASK_CONTACTS_FAILURE:
      return {
        ...state,
        error: action.error,
        currentTaskContactsLoading: false
      };
    case TASKS_START_TASK:
      return {
        ...state,
        currentTask: action.taskId,
        currentTaskStatus: "preparing"
      };
    case TASKS_STOP_TASK:
      return {
        ...state,
        currentTask: null,
        currentTaskStatus: null,
        currentTaskContacts: [],
        currentContactIndex: -1,
        currentTemplate: ""
      };
    case TASKS_SET_CURRENT_CONTACT:
      return {
        ...state,
        currentContactIndex: action.index
      };
    case TASKS_SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: action.text,
        currentTaskStatus: "running"
      };
    case TASKS_REMOVE_CONTACT:
      return {
        ...state,
        currentTaskContacts: lodash.filter(
          state.currentTaskContacts,
          c => c.notifyId !== action.contactId
        )
      };
    case TASKS_UPDATE_CONTACT_ATTRS:
      return {
        ...state,
        currentTaskContacts: lodash.map(state.currentTaskContacts, c => (
          c.taskResponseId === action.id ? { ...c, ...action.attrs } : c
        ))
      };
    default:
      return {
        ...state
      };
  }
};
