import React from "react";
import Select from "antd/es/select";
import { func, arrayOf, any, bool } from "prop-types";

import firestoreService from "../../../services/firestoreService";

const Option = Select.Option;

class AdminSelectOrg extends React.Component {
  state = { org: "" };

  componentWillMount() {
    const { fetchOrgs } = this.props;
    fetchOrgs();
  }

  handlerSelectOrg = org => {
    const { beginFetchConversations } = this.props;
    beginFetchConversations();
    firestoreService.setupListeners(org);
  };

  render() {
    const { org } = this.state;
    const { loading, items } = this.props;

    return (
      <div className="inbox-conversations-list-footer" style={{ height: 48 }}>
        <Select
          showSearch
          placeholder="Select an org"
          style={{ width: "100%" }}
          loading={loading}
          value={org}
          onChange={this.handlerSelectOrg}
        >
          {items.map(item => (
            <Option key={item.id} value={item.slug}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

AdminSelectOrg.propTypes = {
  items: arrayOf(any),
  loading: bool,
  fetchOrgs: func.isRequired,
  beginFetchConversations: func.isRequired
};

AdminSelectOrg.defaultProps = {
  items: [],
  loading: false
};

export default AdminSelectOrg;
