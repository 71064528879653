import React from "react";
import Typography from "antd/es/typography";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { any, objectOf } from "prop-types";

import { formattedInfo } from "../../utils/workflow";

const Title = Typography.Title;
const Text = Typography.Text;

const Details = ({ workflow }) => {
  const { type, status, start, end } = formattedInfo(workflow);
  const {
    name
  } = workflow;

  return (
    <div className="workflow-summary-details-wrapper">
      <Title level={2}>{name}</Title>

      {start || end ? (
        <Row gutter={16}>
          <Col span={12}>
            <Title level={4}>{start}</Title>
            <Text type="secondary">{start && "Start"}</Text>
          </Col>
          <Col span={12}>
            <Title level={4}>{end}</Title>
            <Text type="secondary">{end && "End"}</Text>
          </Col>
        </Row>
      ) : null}
      <Row gutter={16}>
        <Col span={12}>
          <Title level={4}>{status}</Title>
          <Text type="secondary">Status</Text>
        </Col>
        <Col span={12}>
          <Title level={4}>{type}</Title>
          <Text type="secondary">Type</Text>
        </Col>
      </Row>
    </div>
  );
};

Details.propTypes = {
  workflow: objectOf(any).isRequired
};

export default Details;
