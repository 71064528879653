import React from "react";
import {string, bool, func} from "prop-types";
import Button from "antd/es/button";

const ButtonContinue = ({disabled, loading, title, onClick}) => (
  <Button
    block
    type="primary"
    size="large"
    disabled={disabled}
    loading={loading}
    onClick={onClick}
  >
    {title}
  </Button>
);

ButtonContinue.propTypes = {
  disabled: bool,
  loading: bool,
  title: string.isRequired,
  onClick: func.isRequired
};

ButtonContinue.defaultProps = {
  disabled: false,
  loading: false
};

export default ButtonContinue;
