import message from "antd/es/message";

import { getTimestamp, getRef, uploadFile } from "../services/firestoreService";
import {
  FETCH_TEMPLATE_BEGIN,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  CREATE_TEMPLATE_BEGIN,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  UPDATE_TEMPLATE_BEGIN,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILURE
} from "../constants";

export const getTemplates = () => async dispatch => {
  await dispatch({ type: FETCH_TEMPLATE_BEGIN });

  const templates = [];
  try {
    const querySnapshot = await getRef("templates").get();
    querySnapshot.forEach(doc => {
      templates.push({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt && doc.data().createdAt.toDate()
      });
    });
  } catch (error) {
    await dispatch({ type: FETCH_TEMPLATE_FAILURE, error });
    return;
  }

  await dispatch({ type: FETCH_TEMPLATE_SUCCESS, templates });
};

export const addTemplate = data => async (dispatch, getState) => {
  const {
    profile: {
      notifyId
    }
  } = getState();

  await dispatch({ type: CREATE_TEMPLATE_BEGIN });
  if (data.dataFile) {
    data.imageUrl = await uploadFile(data.dataFile);
    delete data.dataFile;
  }

  data.authorId = notifyId;
  data.createdAt = getTimestamp();

  let result;
  try {
    result = await getRef("templates").add(data);
  } catch (error) {
    dispatch({ type: CREATE_TEMPLATE_FAILURE });
    message.error("Unknown error, please repeat again");
    return;
  }

  message.success("Added");

  await dispatch({
    type: CREATE_TEMPLATE_SUCCESS,
    template: { ...data, id: result.id }
  });
};

export const updateTemplate = (id, data) => async dispatch => {
  await dispatch({ type: UPDATE_TEMPLATE_BEGIN });

  if (data.dataFile) {
    if (typeof data.dataFile === "object") {
      const url = await uploadFile(data.dataFile);
      if (url) {
        data.imageUrl = url;
      }
    }
  }
  delete data.dataFile;

  try {
    await getRef("templates").doc(id).update(data);
  } catch (error) {
    dispatch({ type: UPDATE_TEMPLATE_FAILURE, error });
    message.error("Unknown error, please repeat again");
    return;
  }

  message.success("Updated");

  await dispatch({
    type: UPDATE_TEMPLATE_SUCCESS,
    template: data,
    id
  });
};
