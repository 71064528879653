import React, { useCallback } from "react";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Popconfirm from "antd/es/popconfirm";
import { arrayOf, objectOf, any, string, func } from "prop-types";

import Sortable from "../Sortable";
import ColorPicker from "../ColorPicker";
import { COLORS } from "./data";

const FormItem = Form.Item;

const Actions = ({ onDelete }) => (
  <div>
    <Popconfirm
      placement="top"
      title="Are you sure to delete this status?"
      okText="Yes"
      cancelText="No"
      onConfirm={onDelete}
    >
      <Button size="small" type="danger">
        <Icon type="delete" />
      </Button>
    </Popconfirm>

    <Button size="small" type="ghost" style={{ marginLeft: 5 }}>
      <Icon type="menu" />
    </Button>
  </div>
);

const Status = ({ error, status, onChange, onDelete }) => {
  const callbackChangeColor = useCallback(data => {
    onChange(status.id, data);
  }, [status, onChange]);

  const callbackChangeName = useCallback(({ target: { value } }) => {
    onChange(status.id, { name: value });
  }, [status, onChange]);

  const callbackDelete = useCallback(() => {
    onDelete(status.id);
  }, [status, onDelete]);

  return (
    <FormItem
      style={{ padding: 0 }}
      validateStatus={error ? "error" : null}
      help={error}
    >
      <Input
        value={status.name}
        addonBefore={(
          <ColorPicker
            colors={COLORS}
            value={status.color || COLORS[0]}
            onChange={callbackChangeColor}
          />
        )}
        addonAfter={<Actions onDelete={callbackDelete} />}
        onChange={callbackChangeName}
      />
    </FormItem>
  );
};

const Statuses = ({ errors, statuses, onAdd, onChange, onChangePosition, onDelete }) => (
  <div>
    <Sortable
      itemType="STATUS"
      items={statuses}
      itemComponent={props => (
        <Status
          error={errors && errors[props.data.id]}
          status={props.data}
          onChange={onChange}
          onDelete={onDelete}
        />
      )}
      onMoved={onChangePosition}
    />

    <Button block size="small" type="dashed" onClick={onAdd}>
      <Icon type="plus" /> Add Status
    </Button>
  </div>
);

Actions.propTypes = {
  onDelete: func.isRequired
};

Status.propTypes = {
  error: string,
  status: objectOf(any).isRequired,
  onChange: func.isRequired,
  onDelete: func.isRequired
};

Status.defaultProps = {
  error: null
};

Statuses.propTypes = {
  errors: objectOf(any),
  statuses: arrayOf(any).isRequired,
  onAdd: func.isRequired,
  onChange: func.isRequired,
  onChangePosition: func.isRequired,
  onDelete: func.isRequired
};

Statuses.ddefaultProps = {
  errors: null
};

export default Statuses;
