import thunk from "redux-thunk";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware } from "redux";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import { routerMiddleware } from "react-router-redux";

import rootReducer from "../reducers";
import browserHistory from "../browserHistory";

const middleware = [thunk, routerMiddleware(browserHistory)];

if (process.env.NODE_ENV === `development`) {
  middleware.push(logger);
}

const conversationsTransform = createTransform(
  state => state,
  state => {
    let formattedConversations = state.conversations.map(c => {
      if (!(c.lastUpdated instanceof Date)) {
        return {
          ...c,
          lastUpdated: new Date(c.lastUpdated)
        };
      }
      return { ...c };
    });

    return {
      ...state,
      conversations: formattedConversations,
    };
  },
  { whitelist: ["conversations"] }
);

const persistConfig = {
  key: "root",
  blacklist: [
    "routing",
    "conversations",
    "messages",
  ],
  transforms: [conversationsTransform],
  storage
};

const initState = {
  routing: {
    locationBeforeTransitions: browserHistory.location
  }
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, initState, applyMiddleware(...middleware));

export const persistor = persistStore(store);

export default store;
