import * as firebase from "firebase";
import React, { Fragment, useState, useCallback } from "react";
import { func } from "prop-types";
import Form from "antd/es/form";
import Input from "antd/es/input";
import message from "antd/es/message";

import Header from "./Header";
import ButtonContinue from "./ButtonContinue";
import Content from "./Content";
import onboarding from "../../../services/onboarding";
import { formatPhone, validatePhoneNumber, validateEmail } from "../../../utils/string";

const FormItem = Form.Item;

const Profile = ({ onContinue }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const callbackValidate = useCallback(async () => {
    const tmp = {};

    if (!name) {
      tmp.name = "Name is required.";
    }

    if (!validateEmail(email)) {
      tmp.email = "Please enter a valid email address.";
    } else {
      try {
        const result = await firebase.auth().fetchSignInMethodsForEmail(email);
        if (result.length > 0) {
          tmp.email = "Email has already been taken.";
        }
      } catch (e) {}
    }

    if (!validatePhoneNumber(phoneNumber)) {
      tmp.phoneNumber = "Please enter a valid phone number.";
    }

    if (formatPhone(onboarding.workPhone) === phoneNumber) {
      tmp.phoneNumber =
        "Please enter your mobile number, you cannot use your chosen TextWith number.";
    }

    if (password.length < 6) {
      tmp.password = "Password must be at least 6 characters long.";
    }

    setErrors(tmp);
    return Object.keys(tmp).length === 0;
  }, [name, email, phoneNumber, password, setErrors]);

  const changeName = useCallback(
    ({ target: { value } }) => {
      setName(value);
      setErrors({});
    },
    [setName, setErrors]
  );

  const changeEmail = useCallback(
    ({ target: { value } }) => {
      setEmail(value);
      setErrors({});
    },
    [setEmail, setErrors]
  );

  const changePhoneNumber = useCallback(
    ({ target: { value } }) => {
      setPhoneNumber(formatPhone(value));
      setErrors({});
    },
    [setPhoneNumber, setErrors]
  );

  const changePassword = useCallback(
    ({ target: { value } }) => {
      setPassword(value);
      setErrors({});
    },
    [setPassword, setErrors]
  );

  const nextStep = useCallback(async () => {
    setLoading(true);
    if (await callbackValidate()) {
      const [status, errors] = await onboarding.createAccount({
        name,
        email,
        phoneNumber,
        password
      });

      if (status) {
        onContinue({
          name,
          email,
          phoneNumber,
          password
        });
      } else {
        if (Object.prototype.toString.call(errors).includes("Object")) {
          setErrors(errors);
        } else if (Array.isArray(errors)) {
          message.error(errors.join(". "));
        }
      }
    }
    setLoading(false);
  }, [name, email, phoneNumber, password, setLoading, setErrors, onContinue]);

  return (
    <div>
      <Header title="Almost Done!" description="We just need to create your account." />

      <Content>
        <Fragment>
          <FormItem
            label="Name"
            validateStatus={
              errors.name ? "error" : name && name.trim().length > 0 ? "success" : undefined
            }
            help={errors.name}
          >
            <Input name="name" placeholder="Full Name" value={name} onChange={changeName} />
          </FormItem>

          <FormItem
            label="Email"
            validateStatus={errors.email ? "error" : validateEmail(email) ? "success" : undefined}
            help={errors.email}
          >
            <Input
              name="email"
              type="email"
              placeholder="Work Email"
              value={email}
              onChange={changeEmail}
            />
          </FormItem>

          <FormItem
            label="Your Mobile Number"
            validateStatus={
              errors.phoneNumber
                ? "error"
                : validatePhoneNumber(phoneNumber) &&
                  formatPhone(onboarding.workPhone) !== phoneNumber
                ? "success"
                : undefined
            }
            help={errors.phoneNumber}
          >
            <Input placeholder="(999) 999-9999" value={phoneNumber} onChange={changePhoneNumber} />
          </FormItem>

          <FormItem
            label="Password"
            validateStatus={
              errors.password ? "error" : password.length >= 6 ? "success" : undefined
            }
            help={errors.password}
          >
            <Input type="password" value={password} onChange={changePassword} />
          </FormItem>

          <ButtonContinue title="Continue" loading={loading} onClick={nextStep} />
        </Fragment>
      </Content>
    </div>
  );
};

Profile.propTypes = {
  onContinue: func.isRequired
};

export default Profile;
