import { connect } from "react-redux";
import React, { useState, useCallback, useMemo } from "react";
import lodash from "lodash";
import Form from "antd/es/form";
import Tag from "antd/es/tag";
import Collapse from "antd/es/collapse";
import { string, func, arrayOf, any } from "prop-types";

import { tryWorkflowContactList } from "../../services/api/workflow";

const FormItem = Form.Item;
const CheckableTag = Tag.CheckableTag;
const Panel = Collapse.Panel;

const RecipientsField = ({ accountSlug, tag, tags, lists, error, onChangeTag }) => {
  const [totalContacts, setTotalContacts] = useState(null);
  const [loadingTotalContact, setLoadingTotalContact] = useState(false);

  const recipientOptions = useMemo(() => {
    const listRecord = [];

    lodash.forEach(lists, (list) => {
      const statuses = list.statuses;
      listRecord.push({
        id: `!list:${list.id}`,
        name: `${list.name}${statuses.length > 1 ? "/All" : ""}`,
      });

      if (statuses.length > 1) {
        lodash.forEach(statuses, (status) => {
          listRecord.push({
            id: `!list:${list.id}:${status.id}`,
            name: `${list.name}/${status.name}`,
          });
        });
      }
    });

    return [
      {
        name: "Lists",
        id: "lists",
        children: [{ id: `!list:Org:${accountSlug}`, name: "All TextWith Contacts" }, ...listRecord],
      },
      {
        name: "Tags",
        id: "tags",
        children: tags.filter((t) => t).map((tag) => ({ id: tag, name: tag })),
      },
    ];
  }, [accountSlug, tags, lists]);

  const callbackChangeTag = useCallback(t => {
    onChangeTag(t);
    setLoadingTotalContact(true);

    tryWorkflowContactList({
      propertyName: t,
      methodType: "istag",
      propertyValue: "",
      contactType: "Customers"
    }).then(({ total_contacts }) => {
      setTotalContacts(total_contacts);
      setLoadingTotalContact(false);
    }).catch(() => {
      setLoadingTotalContact(false);
    });
  }, [
    setTotalContacts,
    setLoadingTotalContact,
    onChangeTag
  ]);

  return (
    <FormItem
      label="Recipients (Required)"
      extra={totalContacts !== null && !loadingTotalContact ? `This workflow will go out to ${totalContacts} customers` : null}
      validateStatus={error ? "error" : null}
      help={error}
    >
      <Collapse defaultActiveKey={["tags"]}>
        {recipientOptions.map(({ id, name, children }) => (
          <Panel key={id} header={name}>
            {children.map(t => (
              <CheckableTag
                key={t.id}
                checked={tag === t.id}
                onChange={() => callbackChangeTag(t.id)}
              >
                {t.name}
              </CheckableTag>
            ))}
          </Panel>
        ))}
      </Collapse>
    </FormItem>
  );
};

RecipientsField.propTypes = {
  accountSlug: string.isRequired,
  tag: string.isRequired,
  tags: arrayOf(string).isRequired,
  lists: arrayOf(any).isRequired,
  error: string,
  onChangeTag: func.isRequired
};

RecipientsField.defaultProps = {
  error: null
};

const mapStateToProps = ({
  application: { tags: { tags } },
  lists: { items },
  profile: { accountSlug },
}) => ({
  tags,
  accountSlug,
  lists: items,
});

export default connect(mapStateToProps)(RecipientsField);
