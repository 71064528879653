import React from 'react';
import Avatar from 'antd/es/avatar';

import { toShortName } from '../../utils/string';
import Tags from '../Tags';

export default [
  {
    dataIndex: "avatar",
    key: "avatar",
    width: 70,
    render: (avatar, record) => <Avatar src={avatar}>{toShortName(record.name)}</Avatar>,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
    render: tags => <Tags tags={tags} />,
  },
];
