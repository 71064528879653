import request from "../request";

export const availablePhones = async areaCode =>
  await request({
    url: "/availablephones/",
    method: "POST",
    data: {
      area_code: areaCode
    }
  });

export const createAccountAndTeamMember = async (
  email,
  firstName,
  lastName,
  mobileNumber,
  firebaseUid,
  shopName
) =>
  await request({
    url: "/onboard/",
    method: "POST",
    data: {
      email,
      first_name: firstName,
      last_name: lastName,
      mobile_number: mobileNumber,
      auth_id: firebaseUid,
      shop_name: shopName
    }
  });

export const createAccountAndTeamMemberV2 = async (
  email,
  firstName,
  lastName,
  twilioPhone,
  mobileNumber,
  shopName,
  cartAbandoment
) => {
  const data = {
    email,
    first_name: firstName,
    last_name: lastName,
    phone: twilioPhone,
    mobile_number: mobileNumber,
    shop_name: shopName
  };

  if (cartAbandoment) {
    data.cart_abandonment_message = cartAbandoment.message;
    data.cart_abandonment_duration = cartAbandoment.time;
  }

  return await request({
    url: `/onboardv2/`,
    method: "POST",
    data
  });
};

export const setTeamMemberAuthId = async (orgSlug, notifyId, firebaseUid) =>
  await request({
    url: `/org/${orgSlug}/team_member/${notifyId}/`,
    method: "PATCH",
    data: {
      auth_id: firebaseUid
    }
  });

export const assignTeamMemberPhoneNumber = async (orgSlug, phoneNumber, notifyId) =>
  await request({
    url: `/org/${orgSlug}/team_member/${notifyId}/assign_phone_number/`,
    method: "POST",
    data: {
      phone_number: phoneNumber
    }
  });

export const phoneNumberVerifyRequest = async phone =>
  await request({
    url: "/phoneverifysend/",
    method: "POST",
    data: {
      phone
    }
  });

export const phoneNumberVerifyCode = async (phone, code) =>
  await request({
    url: "/phoneverifycheck/",
    method: "POST",
    data: {
      phone,
      code
    }
  });

export const setTeamMemberPhoneVerified = async (orgSlug, mobileNumber, notifyId) =>
  await request({
    url: `/org/${orgSlug}/team_member/${notifyId}/`,
    method: "PATCH",
    data: {
      phone_verified: true,
      has_fallback_sms: true,
      mobile_number: mobileNumber
    }
  });
