import React, { useEffect } from "react";
import Button from "antd/es/button";
import Typography from "antd/es/typography/Typography";

import AppTitle from "../../../../components/Title";
import TopNavigation from "../../../../components/TopNavigation";
import Wrapper from "../../../../components/Wrapper";
import { currentUserToken } from "../../../../utils/auth";

const { Text } = Typography;

const Salesforce = ({ salesforce }) => {
  const token = currentUserToken();

  return (
    <>
      <AppTitle title="Salesforce" />
      <TopNavigation />

      <Wrapper backUrl="/integrations" title="Salesforce">
        <div style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "10px" }}>
            <Text strong>
              {salesforce.connection_status === "connected"
                ? `Your Salesforce Account for ${salesforce.username} is connected`
                : `Please connect your Salesforce Account`}
            </Text>
          </div>
          <div>
            <Button
              type="primary"
              href={`${process.env.REACT_APP_API_URL}salesforce/auth?token=${token}`}
            >
              {salesforce.connection_status == "connected"
                ? "Reconnect Salesforce Account"
                : "Connect Salesforce Account"}
            </Button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Salesforce;
