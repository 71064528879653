import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import EditContact from "./EditContact";
import { fetchContactDetails, updateContact } from "../../../actions/contacts";

const mapStateToProps = (
  {
    contacts: { contactsById, fetching },
    application: { tags: { tags } }
  },
  { match: { params: { id } } }
) => ({
  tags,
  data: contactsById[id],
  loading: fetching || (contactsById[id] && contactsById[id].fetchingDetails),
  updating: contactsById[id] && contactsById[id].contactUpdating
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchContactDetails,
  updateContact
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditContact);
