import React, { useEffect } from "react";
import Button from "antd/es/button";
import Typography from "antd/es/typography/Typography";

import AppTitle from "../../../../components/Title";
import TopNavigation from "../../../../components/TopNavigation";
import Wrapper from "../../../../components/Wrapper";
import TemplatesList from "../../../../components/TemplatesList";
import AddTemplate from "../../../../components/AddTemplate";
import { currentUserToken } from "../../../../utils/auth";

const { Text } = Typography;

const Calendar = ({
  calendar,
  loading,
  busy,
  templates,
  getTemplates,
  updateTemplate,
  addTemplate,
}) => {
  useEffect(() => {
    getTemplates();
  }, []);

  const token = currentUserToken();

  return (
    <>
      <AppTitle title="Calendar Event Template" />
      <TopNavigation />

      <Wrapper
        backUrl="/integrations"
        title="Calendar Event Template"
        extra={<AddTemplate isCalendarEvent creating={busy} addTemplate={addTemplate} />}
      >
        <div style={{ textAlign: "right" }}>
          <Text strong style={{ paddingRight: "5px" }}>
            {calendar.connection_status === "connected"
              ? `Your calendar ${calendar.calendar_name} is connected`
              : null}
          </Text>
          <Button type="primary" href={`${process.env.REACT_APP_API_URL}nylas/auth?token=${token}`}>
            {calendar.connection_status === "connected" ? "Reconnect Calendar" : "Connect Calendar"}
          </Button>
        </div>

        <TemplatesList
          isCalendarEvent
          updating={busy}
          loading={loading}
          items={templates}
          updateTemplate={updateTemplate}
        />
      </Wrapper>
    </>
  );
};

export default Calendar;
