import React, { Fragment } from "react";
import lodash from "lodash";
import Skeleton from "antd/es/skeleton";
import List from "antd/es/list";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Empty from "antd/es/empty";
import { string, bool, any, func, arrayOf, objectOf } from "prop-types";

import "./style.css";
import WorkflowItem from "./WorkflowItem";
import { filteringByFilter } from "./utils";

const WorkflowList = ({
  filter,
  items,
  loading,
  teamMembers,
  onFetchSummary,
  onUpdateStatus,
  onEdit
}) => {
  const count = items.length;

  const renterItem = data =>
    <WorkflowItem
      data={data}
      teamMembers={teamMembers}
      onFetchSummary={onFetchSummary}
      onUpdateWorkflowStatus={onUpdateStatus}
      onEdit={onEdit}
    />;

  const data = lodash.orderBy(
    filteringByFilter(items, filter),
    ["updated_at"],
    ["desc"]
  );

  return (
    <Row>
      <Col span={16} offset={4} >
        {count === 0 && !loading ? (
          <Empty />
        ) : loading && count === 0 ? (
          <Fragment>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Fragment>
        ) : (
          <List
            itemLayout="vertical"
            dataSource={data}
            renderItem={renterItem}
          />
        )}
      </Col>
    </Row>
  );
};

WorkflowList.propTypes = {
  filter: string,
  items: arrayOf(any).isRequired,
  loading: bool,
  teamMembers: objectOf(any).isRequired,
  onFetchSummary: func.isRequired,
  onUpdateStatus: func.isRequired,
  onEdit: func
};

WorkflowList.defaultProps = {
  filter: "active",
  loading: false,
  onEdit: null
};

export default WorkflowList;
