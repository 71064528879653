import React from "react";
import { any, arrayOf, bool, func } from "prop-types";

import Title from "../../../components/Title";
import TopNavigation from "../../../components/TopNavigation";
import Wrapper from "../../../components/Wrapper";
import ContactForm from "../../../components/ContactForm";

const NewContact = ({ tags, loading, addContact }) => (
  <div>
    <Title title={"New Contact"} />
    <TopNavigation />

    <Wrapper title="New Contact" backUrl="/contacts">
      <ContactForm tags={tags} loading={loading} onSubmit={addContact} />
    </Wrapper>
  </div>
);

NewContact.propTypes = {
  tags: arrayOf(any),
  loading: bool,
  addContact: func.isRequired
};

NewContact.defaultProps = {
  tags: [],
  loading: false
};

export default NewContact;
