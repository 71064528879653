import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Available from './Available';
import { toggleAvailableState } from '../../actions/profile';

const mapStateToProps = ({
  profile: {
    available,
    loadingAvailable,
    phone,
  }
}) => ({
  available,
  phone,
  loading: loadingAvailable,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onToggleAvailableState: toggleAvailableState
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Available);
