import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Compose from "../../Inbox/Compose/Compose";
import { formatPhone } from "../../../utils/string";
import { sendMessageToServer } from "../../../actions/messages";
import { buildMessageByParams } from "../utils";

const mapStateToProps = ({
  contacts: { contactsById },
  conversations: { conversationsById, conversationsByContactAddress },
  tasks: { currentTaskContacts, currentContactIndex, currentTemplate },
  profile: { fullName, phone }
}) => {
  const props = {
    disabled: false,
    contactAddress: null,
    currentConversation: null
  };

  const record = currentTaskContacts[currentContactIndex];
  if (record) {
    const conversation = conversationsByContactAddress[record.address];
    const contact = contactsById[record.notifyId];
    if (conversation) {
      props.currentConversation = conversation.id;
    }

    const template = buildMessageByParams(currentTemplate, {
      first_name: contact ? contact.firstName : "",
      team_member_name: fullName,
      team_member_work_phone: formatPhone(phone)
    });

    props.wfResponseId = record.taskResponseId;
    props.contactAddress = record.address;
    props.initText = template;
    props.textForReset = template;
    props.teamMemberAddress = phone;
    props.contactId = contact && contact.id;
  }

  return props;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendMessageToServer
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Compose);
