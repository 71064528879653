import React, { useCallback } from "react";
import { string } from "prop-types";
import message from "antd/es/message";

import EditPhoneWithConfirm from "../../../components/EditPhoneWithConfirm";
import Field from "./Field";
import firestoreService from "../../../services/firestoreService";
import configureStore from "../../../store/configureStore";
import { accountSlug } from "../../../utils/other";
import { APPLICATION_SET_ORG_PHONE } from "../../../constants";

const OrganizationForwardingNumber = ({ notifyId, phone }) => {
  const done = useCallback(
    (updatedPhone, callback) => {
      (async () => {
        const hide = message.loading("Updating");
        try {
          await firestoreService.setOrgForwardingPhoneNumber(accountSlug(), updatedPhone);
          const orgPhoneData = await firestoreService.getOrgPhoneList(accountSlug());
          await configureStore.dispatch({
            type: APPLICATION_SET_ORG_PHONE,
            orgPhoneData,
          });
          hide();
          message.success("Forwarding number updated.");
        } catch (e) {
          hide();
          message.error("Problem setting number, please try again.");
        }
        callback && callback();
      })();
    },
    [notifyId]
  );

  return (
    <Field
      label="Organization Forwarding Number"
      extra={<EditPhoneWithConfirm phone={phone} onDone={done} />}
    />
  );
};

OrganizationForwardingNumber.propTypes = {
  notifyId: string.isRequired,
  phone: string,
};

OrganizationForwardingNumber.defaultProps = {
  phone: "",
};

export default OrganizationForwardingNumber;
