import React from "react";
import { arrayOf, string } from "prop-types";
import Tag from "antd/es/tag";

const detectColor = tag =>
  tag.length > 5 ? "geekblue" : "green";

const Tags = ({ tags }) => tags.map(tag => (
  <Tag key={tag} color={detectColor(tag)}>{tag}</Tag>
));

Tags.propTypes = {
  tags: arrayOf(string)
};

Tags.defaultProps = {
  tags: []
};

export default Tags;
