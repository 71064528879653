import React from "react";
import Tag from "antd/es/tag";
import Avatar from "antd/es/avatar";
import { Link } from "react-router-dom";

import { toShortName } from "../../utils/string";

export default [
  {
    dataIndex: "avatar",
    key: "avatar",
    width: 70,
    render: (avatar, record) => <Avatar src={avatar}>{toShortName(record.name)}</Avatar>
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, record) =>
      <Link to={`/contacts/${record.key}`}>{name}</Link>
  },
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
    render: tags => (
      <span>
        {tags.map(tag => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return <Tag color={color} key={tag}>{tag.toUpperCase()}</Tag>;
        })}
      </span>
    )
  },
  {
    title: "",
    key: "action",
    render: (_, record) => (
      <div>
        <Link to={`/contacts/${record.key}/edit`}>
          Edit
        </Link>
      </div>
    )
  }
];
