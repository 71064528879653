import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Body from "./Body";
import { noReply } from "../../../actions/conversations";
import { fetchMessages, retrySend } from "../../../actions/messages";

const mapStateToProps = ({
  conversations: { currentConversation, conversationsById },
  messages: { messages },
  contacts: { contactsById },
  application: { teamMembers },
  profile: { notifyId }
}) => {
  const conversation = conversationsById[currentConversation];
  if (!conversation) {
    return {};
  }

  const msgs = messages[currentConversation] || [];
  const contact = contactsById[conversation.contactId];
  const {
    loadingMessages,
    loadingPrevMessages,
    replyState,
    replyStateLoading
  } = conversation;

  return {
    contact,
    replyState,
    replyStateLoading,
    teamMembers,
    currentUserId: notifyId,
    currentConversationId: currentConversation,
    messages: msgs,
    hasPrevPage: conversation.hasPrevPage,
    isLoading: !!loadingMessages && msgs.length === 0,
    isLoadingPrev: !!loadingPrevMessages
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  noReply,
  fetchMessages,
  onRetrySend: retrySend
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Body);
