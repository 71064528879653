import React from "react";
import Modal from "antd/es/modal";
import { objectOf, bool, any, func } from "prop-types";

import FormTemplate from "../FormTemplate";

const EditTemplate = ({
  isCalendarEvent,
  template,
  updating,
  onCancel,
  onSubmit,
}) => (
  <Modal
    title="Update Template"
    visible={!!template}
    footer={null}
    onCancel={onCancel}
  >
    {isCalendarEvent ? (
      <FormTemplate.CalendarEventsForm
        loading={updating}
        editTemplate={template}
        onSubmit={onSubmit}
      />
    ) : (
      <FormTemplate.MainForm
        loading={updating}
        editTemplate={template}
        onSubmit={onSubmit}
      />
    )}
  </Modal>
);

EditTemplate.propTypes = {
  isCalendarEvent: bool,
  template: objectOf(any),
  updating: bool.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired
};

EditTemplate.defaultProps = {
  isCalendarEvent: false,
  template: null
};

export default EditTemplate;
