import React, { useState, useCallback } from "react";
import { string, func, bool } from "prop-types";
import Button from "antd/es/button";

import PhoneField from "../ContactForm/Phone";

const EditContactPhoneNumber = ({ contactId, updating, updateContact, onDone }) => {
  const [phone, setPhone] = useState("");
  const [label, setLabel] = useState("main");

  const callbackSubmit = useCallback(() => {
    const data = {
      phones: [{
        is_primary: true,
        label: label,
        phone_text: phone
      }]
    };
    updateContact(contactId, data, false)
      .then(() => {
        onDone(data.phones[0]);
      });
  }, [contactId, phone, label, updateContact, onDone]);

  return (
    <div>
      <div>
        <PhoneField
          value={phone}
          label={label}
          onChange={setPhone}
          onChangeLabel={v => setLabel(v)}
        />
      </div>
      <br/>
      <div>
        <Button
          block
          type="primary"
          loading={updating}
          onClick={callbackSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

EditContactPhoneNumber.propTypes = {
  contactId: string.isRequired,
  updating: bool,
  updateContact: func.isRequired,
  onDone: func.isRequired
};

EditContactPhoneNumber.defaultProps = {
  updating: false
};

export default EditContactPhoneNumber;
