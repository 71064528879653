import lodash from "lodash";

import {
  FETCH_APP_DATA_BEGIN,
  FETCH_APP_DATA_SUCCESS,
  FETCH_APP_DATA_FAILURE,
  TOGGLE_VISIBLE_NEW_CONVERSATION,
  HIDE_NEW_CONVERSATION,
  APPLICATION_UPDATE_TAGS_BEGIN,
  APPLICATION_UPDATE_TAGS_SUCCESS,
  APPLICATION_UPDATE_TAGS_FAILURE,
  APPLICATION_SET_GLOBAL_LOADING,
  UPDATE_APP_TAGS,
  APPLICATION_SET_ORG_PHONE,
  APPLICATION_SET_ORG_TIMEZONE,
  APPLICATION_SET_ORG_API_KEY,
  DEFAULT_TIMEZONE,
} from "../constants";

const initialState = {
  fetching: true,
  visibleStartNewConversation: false,
  error: null,
  teamMembers: {},
  teamMembersByAddress: {},
  tags: [],
  org: {},
  loading: false,
  updating: false,
  orgPhoneList: [],
  orgNewThreadPermissionDefault: "public",
  orgPhoneName: "",
  orgForwardingPhoneNumber: "",
  orgTimezoneName: DEFAULT_TIMEZONE,
  orgApiKey: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APP_DATA_BEGIN:
      return {
        ...state,
        error: null,
        loading: false,
        fetching: true,
      };
    case FETCH_APP_DATA_SUCCESS:
      const teamMembers = {};
      const teamMembersByAddress = {};
      lodash.each(action.teamMembers, (tm) => {
        teamMembers[tm.notify_id] = {
          ...state.teamMembers[tm.notify_id],
          ...tm,
        };
        if (
          action.firestoreTeamMembers[tm.notify_id] &&
          action.firestoreTeamMembers[tm.notify_id]["role"]
        ) {
          console.log("SETTING ROLE", action.firestoreTeamMembers[tm.notify_id]["role"]);
          teamMembers[tm.notify_id].role = action.firestoreTeamMembers[tm.notify_id]["role"];
        }
        if (!teamMembers[tm.notify_id].role) {
          teamMembers[tm.notify_id].role = "Owner";
        }
        teamMembersByAddress[tm.work_phone] = teamMembers[tm.notify_id];
      });

      return {
        ...state,
        teamMembers,
        teamMembersByAddress,
        tags: action.tags,
        org: action.org,
        fetching: false,
      };
    case FETCH_APP_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    case TOGGLE_VISIBLE_NEW_CONVERSATION:
      return {
        ...state,
        visibleStartNewConversation: !state.visibleStartNewConversation,
      };
    case HIDE_NEW_CONVERSATION:
      return {
        ...state,
        visibleStartNewConversation: false,
      };
    case UPDATE_APP_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    case APPLICATION_UPDATE_TAGS_BEGIN:
      return {
        ...state,
        updating: true,
      };
    case APPLICATION_UPDATE_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.tags,
        updating: false,
      };
    case APPLICATION_UPDATE_TAGS_FAILURE:
      return {
        ...state,
        error: action.error,
        updating: false,
      };
    case APPLICATION_SET_ORG_PHONE:
      const {
        orgPhoneList,
        orgPhoneName,
        orgNewThreadPermissionDefault,
        orgForwardingPhoneNumber,
      } = action.orgPhoneData;
      return {
        ...state,
        orgPhoneList: orgPhoneList,
        orgPhoneName: orgPhoneName,
        orgNewThreadPermissionDefault: orgNewThreadPermissionDefault,
        orgForwardingPhoneNumber: orgForwardingPhoneNumber,
      };
    case APPLICATION_SET_GLOBAL_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case APPLICATION_SET_ORG_TIMEZONE:
      return {
        ...state,
        orgTimezoneName: action.timezone,
      };
    case APPLICATION_SET_ORG_API_KEY:
      return {
        ...state,
        orgApiKey: action.apiKey,
      };
    default:
      return {
        ...state,
      };
  }
};
