import { formatPhone } from "../utils/string";
import { formatContactAddress } from "../utils/contacts";

export const buildConversationName = (phone, contact, thread, teamMembersByAddress) => {
  const contactAddress = thread.contactAddress;
  const teamMemberAddress = thread.teamMemberAddress;
  const contactAddressFormatted = formatPhone(contactAddress);
  let name = contact.name ? contact.name : contactAddressFormatted;
  let teamMemberName = null;

  if (teamMemberAddress) {
    const teamMember = teamMembersByAddress[teamMemberAddress];
    if (teamMember) {
      teamMemberName = `${teamMember.first_name} ${teamMember.last_name}`;
    }
  }

  if (teamMemberAddress !== phone && teamMemberName) {
    name = `${name} (to ${teamMemberName})`;
  }

  return name;
};

export const buildConversation = (
  thread,
  unreadConversations,
  contactsById,
  contactIdsByAddress,
  teamMembersByAddress,
  phone
) => {
  const contactAddress = formatContactAddress(thread.contactAddress);
  const teamMemberAddress = formatContactAddress(thread.teamMemberAddress);
  const contactId = contactIdsByAddress[contactAddress];
  const contact = contactId && contactsById[contactId];
  const unread = unreadConversations.includes(thread.id);
  const contactAddressFormatted = formatPhone(contactAddress);

  let name = contact && contact.name ? contact.name : contactAddressFormatted;
  let teamMemberName = null;

  if (teamMemberAddress) {
    const teamMember = teamMembersByAddress[teamMemberAddress];
    if (teamMember) {
      teamMemberName = `${teamMember.first_name} ${teamMember.last_name}`;
    }
  }

  if (teamMemberAddress !== formatContactAddress(phone) && teamMemberName) {
    name = `${name} (to ${teamMemberName})`;
  }

  return {
    unread,
    name,
    contactAddress,
    teamMemberAddress,
    teamMemberName,
    key: thread.id,
    id: thread.id,
    phone: contactAddressFormatted,
    lastMessage: thread.lastMessageText,
    lastMessageFrom: thread.lastAuthorAddress,
    contactId: contactIdsByAddress[contactAddress],
    contactJoined: !!contact,
    relationshipTeamMemberNotifyId: contact ? contact.memberNotifyId : null,
    replyState: thread.replyState,
    archived: thread.archived,
    lastInboundMessageAt: thread.lastInboundMessageAt,
    lastOutboundMessageAt: thread.lastOutboundMessageAt,
    notifications: thread.notifications || [],
    members: thread.members || [],
    avatar: null,
    messageCount: 1,
    lastUpdated: thread.lastContactMessageAt ? new Date(thread.lastContactMessageAt) : new Date(),
    loadingMessages: false,
    loadingPrevMessages: false,
    hasPrevPage: false
  };
};
