import React, { useCallback, useState } from "react";
import lodash from "lodash";
import Divider from "antd/es/divider";
import List from "antd/es/list";
import { any, arrayOf, objectOf, func } from "prop-types";

import Details from "./Details";
import Card from "./Card";
import ContactsList from "./ContactsList";

const SummaryListMembers = ({ teamMembers, data, onShowRecipients }) => {
  const renterItem = ({ title, team_member_id, statuses }) => (
    <Card
      title={title}
      member={teamMembers[team_member_id]}
      statuses={statuses}
      onShowRecipients={onShowRecipients}
    />
  );

  return (
    <List
      itemLayout="vertical"
      dataSource={data}
      renderItem={renterItem}
    />
  );
};

const Content = ({ teamMembers, workflow }) => {
  const [recipients, setRecipients] = useState(null);

  const callbackHideRecipients = useCallback(() => {
    setRecipients(null);
  }, [setRecipients]);

  const callbackShowRecipients = useCallback(teamMemberId => {
    const {
      summaryDetails: {
        details
      }
    } = workflow;

    let contacts = [];
    if (teamMemberId) {
      const member = teamMembers[teamMemberId];
      const item = lodash.find(details, { team_member_id: teamMemberId });
      const teamMemberName = member && [member.first_name, member.last_name].join(" ").trim();
      contacts = item.contacts.map(r => [...r, teamMemberName]);
    } else {
      lodash.forEach(details, item => {
        const member = teamMembers[item.team_member_id];
        const teamMemberName = member && [member.first_name, member.last_name].join(" ").trim();
        lodash.forEach(item.contacts, contact => {
          contacts.push([...contact, teamMemberName])
        });
      });
    }

    setRecipients(contacts);
  }, [teamMembers, workflow, setRecipients]);

  const {
    summaryDetails: {
      details,
      summary
    }
  } = workflow;

  const data = details.filter(d => teamMembers[d.team_member_id]);

  data.unshift({
    title: "Overall Campaign",
    team_member_id: null,
    statuses: summary
  });

  return (
    <div>
      <Details workflow={workflow} />
      <Divider />
      <SummaryListMembers
        teamMembers={teamMembers}
        data={data}
        onShowRecipients={callbackShowRecipients}
      />
      <ContactsList
        recipients={recipients}
        onHide={callbackHideRecipients}
      />
    </div>
  );
};

SummaryListMembers.propTypes = {
  teamMembers: objectOf(any).isRequired,
  data: arrayOf(any).isRequired,
  onShowRecipients: func.isRequired
};

Content.propTypes = {
  teamMembers: objectOf(any).isRequired,
  workflow: objectOf(any).isRequired
};

export default Content;
