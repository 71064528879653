import React from "react";
import { objectOf, any, string } from "prop-types";

import "./style.css";
import Notification from "./Notification";
import Members from "./Members";

const ConversationSettings = ({
  teamMembers,
  notifyId,
  teamMemberAddress,
  conversation,
}) => (
  <div className="conversation-settings">
    <Notification notifyId={notifyId} conversation={conversation} />
    <Members
      notifyId={notifyId}
      teamMemberAddress={teamMemberAddress}
      teamMembers={teamMembers}
      conversation={conversation}
    />
  </div>
);

ConversationSettings.propTypes = {
  teamMembers: objectOf(any).isRequired,
  notifyId: string.isRequired,
  teamMemberAddress: string.isRequired,
  conversation: objectOf(any).isRequired,
};

export default ConversationSettings;
