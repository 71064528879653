import lodash from "lodash";

import {
  FETCH_LISTS_BEGIN,
  FETCH_LISTS_SUCCESS,
  FETCH_LISTS_FAILURE,
  ADD_LIST_BEGIN,
  ADD_LIST_SUCCESS,
  ADD_LIST_FAILURE,
  UPDATE_LIST_BEGIN,
  UPDATE_LIST_FAILURE,
  UPDATE_LIST_ATTRS
} from "../constants";

const initialState = {
  loading: false,
  fetching: true,
  byId: {},
  items: [],
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LISTS_BEGIN:
      return {
        ...state,
        fetching: true
      };
    case FETCH_LISTS_SUCCESS:
      return {
        ...state,
        items: lodash.sortBy(action.items, "name"),
        byId: lodash.keyBy(action.items, "id"),
        fetching: false
      };
    case FETCH_LISTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case ADD_LIST_BEGIN:
      return  {
        ...state,
        loading: true
      };
    case ADD_LIST_SUCCESS:
      const items = lodash.uniqBy([...state.items, action.list], "id");
      return  {
        ...state,
        items: lodash.sortBy(items, "name"),
        byId: lodash.keyBy(items, "id"),
        loading: false
      };
    case ADD_LIST_FAILURE:
      return  {
        ...state,
        loading: false,
        error: action.error
      };
    case UPDATE_LIST_BEGIN:
      return  {
        ...state,
        loading: true
      };
    case UPDATE_LIST_FAILURE:
      return  {
        ...state,
        loading: false,
        error: action.error
      };
    case UPDATE_LIST_ATTRS:
      const updatedItems = lodash.map(state.items, list => (
        list.id === action.listId ?
          { ...list, ...action.attr } :
          list
      ));
      return  {
        ...state,
        loading: false,
        items: lodash.sortBy(updatedItems, "name"),
        byId: lodash.keyBy(updatedItems, "id"),
      };
    default:
      return state;
  }
};
