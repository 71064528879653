import React, { Fragment, useState, useCallback, useRef } from "react";
import { func } from "prop-types";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Row from "antd/es/row";
import Col from "antd/es/col";

import Header from "./Header";
import ButtonContinue from "./ButtonContinue";
import Content from "./Content";
import CustomFields from "../../../components/CustomFields";
import { buildMessage } from "../../../utils/string";

const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Option = Select.Option;

const TEAM_MEMBER = "Sam";
const STORY = "Amazing Store";
const PRODUCT_NAME = "Amazing Product";

const FIELDS = [
  { key: 0, label: "Sales Associate", value: "{{team_member_name}}" },
  { key: 1, label: "Store Name", value: "{{store_name}}" },
  { key: 2, label: "Abandoned Product", value: "{{product}}" }
];

const INIT_MESSAGE =
  "Hey there, this is {{team_member_name}} from {{store_name}}. " +
  "I see you checking out our {{product}}. Great choice! Want me to see " +
  "if I can get you a discount? PS. Msg & data rates may apply. " +
  "Let me know if you want me to stop texting.";

const FirstAutoMessage = ({ onContinue }) => {
  const [message, setMessage] = useState(INIT_MESSAGE);
  const [duration, setDuration] = useState("120");
  const inputRef = useRef();

  const changeMessage = useCallback(
    ({ target: { value } }) => {
      setMessage(value);
    },
    [setMessage]
  );

  const changeDuration = useCallback(
    value => {
      setDuration(value);
    },
    [setDuration]
  );

  const nextStep = useCallback(() => {
    onContinue({
      message,
      duration
    });
  }, [message, duration, onContinue]);

  return (
    <div>
      <Header
        title="Setup Your First Automated Message!"
        description="We’ll text your customers after they abandon their checkout. Here is an example message and delivery time that converts well. If you have a coupon code you may want to include it here."
      />

      <Content lgSize={{ span: 18, offset: 3 }}>
        <Fragment>
          <Row gutter={24}>
            <Col lg={{ span: 12 }}>
              <FormItem label="Message">
                <TextArea rows={6} ref={inputRef} value={message} onChange={changeMessage} />
                <CustomFields
                  showLabel={false}
                  fields={FIELDS}
                  inputRef={inputRef}
                  onChange={setMessage}
                />
              </FormItem>

              <FormItem label="When should we send it?">
                <Select style={{ width: "100%" }} value={duration} onChange={changeDuration}>
                  <Option value="120">2 hours after abandonment</Option>
                  <Option value="240">4 hours after abandonment</Option>
                  <Option value="1440">24 hours after abandonment</Option>
                  <Option value="2880">48 hours after abandonment</Option>
                </Select>
              </FormItem>

              <ButtonContinue title="Continue" onClick={nextStep} />
            </Col>
            <Col lg={{ span: 12 }}>
              <FormItem label="Preview">
                <div className="message-preview">
                  {buildMessage(message, {
                    team_member_name: TEAM_MEMBER,
                    store_name: STORY,
                    product: PRODUCT_NAME
                  })}
                </div>
              </FormItem>
            </Col>
          </Row>
        </Fragment>
      </Content>
    </div>
  );
};

FirstAutoMessage.propTypes = {
  onContinue: func.isRequired
};

export default FirstAutoMessage;
