import lodash from "lodash";
import moment from "moment";

export const funcFilterTasks = t => {
  if (!t.workflowRec) {
    return false;
  }
  if (t.meta && t.meta.daysAgo) {
    return t.meta.daysAgo === null || t.meta.daysAgo < 1;
  }
  return true;
};

export const funcActive = items => {
  const result = lodash.filter(items, item => {
    const workflow = item.workflowRec;
    const { ready, skipped } = item.responses;
    const responsesLeft = ready + skipped;
    return (
      responsesLeft > 0 &&
      (workflow.category !== "Basics" &&
        workflow.status === "active" &&
        (workflow.end_at === null ||
          (workflow.end_at && !moment().isAfter(workflow.end_at, "day"))))
    );
  });

  return lodash.orderBy(
    result,
    ["workflowRec.end_at", "workflowRec.start_at", "created_at"],
    ["asc", "asc", "asc"]
  );
};

export const funcOngoing = items => {
  const result = lodash.filter(items, item => {
    const workflow = item.workflowRec;
    const { ready, skipped } = item.responses;
    const responsesLeft = ready + skipped;
    return workflow.category === "Basics" && responsesLeft > 0;
  });

  return lodash.sortBy(result, "responses.ready");
};

export const funcCompleted = items => {
  const result = lodash.filter(items, item => {
    const workflow = item.workflowRec;
    const { ready, skipped } = item.responses;
    const responsesLeft = ready + skipped;
    return (
      workflow.category !== "Basics" &&
      (responsesLeft <= 0 ||
        (workflow.end_at !== null &&
          moment().isAfter(workflow.end_at, "day") &&
          moment().diff(workflow.end_at, "days") < 2))
    );
  });

  return lodash.orderBy(result, ["workflowRec.end_at"], ["desc"]);
};
