import React, { useState, useCallback } from "react";
import moment from "moment-timezone";
import { string, func } from "prop-types";
import { Select, Button } from "antd";

const { Option } = Select;

const timeZones =
  moment
    .tz
    .zonesForCountry("US")
    .map(tz => tz.replace(/_/g, " "));

const OrganizationTimezone = ({ orgTimezoneName, onUpdate }) => {
  const [visible, setVisible] = useState(false);

  const toggle = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const change = useCallback(newTimezone => {
    onUpdate(newTimezone.replace(/\s/g, '_'));
    setVisible(false);
  }, []);

  const currentTZ = orgTimezoneName.replace(/_/g, ' ');

  return (
    <div>
      {visible ? (
        <div>
          <Select
            showSearch
            size="small"
            style={{ width: 200 }}
            optionFilterProp="children"
            defaultValue={currentTZ}
            filterOption={(input, option) => (
              option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )}
            onChange={change}
          >
            {timeZones.map(tz => <Option key={tz} value={tz}>{tz}</Option>)}
          </Select>
          {" "}
          <Button size="small" onClick={toggle}>Cancel</Button>
        </div>
      ) : (
        <a href="#" onClick={toggle}>{currentTZ}</a>
      )}
    </div>
  );
};

OrganizationTimezone.propTypes = {
  orgTimezoneName: string.isRequired,
  onUpdate: func.isRequired,
};

export default OrganizationTimezone;
