import { connect } from "react-redux";

import TopNavigation from "./TopNavigation";

const mapStateToProps = ({ routing }) => ({
  pathname:
    routing && routing.locationBeforeTransitions ?
      routing.locationBeforeTransitions.pathname.replace("/", "") :
      "/"
});

export default connect(mapStateToProps)(TopNavigation);
