import React from "react";

import Title from "../../../components/Title";
import FullInbox from "../../../components/Inbox/Full";
import StartNewConversation from "../../../components/StartNewConversation";
import TopNavigation from "../../../components/TopNavigation";

const Inbox = () => (
  <div>
    <Title title="Inbox" />
    <TopNavigation />

    <FullInbox />
    <StartNewConversation />
  </div>
);

export default Inbox;
