import React, { useState, useCallback, useEffect, useRef } from "react";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import { objectOf, any, bool, func } from "prop-types";

import CustomFields from "../CustomFields";
import AddImage from "../WorkflowForms/AddImage";
import { isValid } from "./utils";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const MainForm = ({
  loading,
  editTemplate,
  onSubmit,
}) => {
  const messageRef = useRef(null);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [dataFile, setDataFile] = useState(null);
  const [errors, setErrors] = useState({});

  const callbackChange = useCallback(({ target: { name, value } }) => {
    if (name === "name") {
      setName(value);
    } else if (name === "message") {
      setMessage(value);
    }

    setErrors({});
  }, []);

  const callbackChangeImage = useCallback(data => {
    setDataFile(data);
  }, [setDataFile]);

  const callbackSubmit = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    if (!isValid(name, message, setErrors)) {
      return;
    }

    onSubmit({ name, templateText: message, dataFile }, () => {
      setName("");
      setMessage("");
      setDataFile(null);
    });
  }, [
    name,
    message,
    dataFile,
    onSubmit
  ]);

  useEffect(() => {
    if (editTemplate) {
      setName(editTemplate.name || "");
      setMessage(editTemplate.templateText || "");
      setDataFile(editTemplate.imageUrl);
    }
  }, [editTemplate]);

  return (
    <Form className="workflow-form" onSubmit={callbackSubmit}>
      <FormItem
        label="Name (Required)"
        validateStatus={errors.name ? "error" : null}
        help={errors.name}
      >
        <Input
          name="name"
          maxLength={500}
          value={name}
          disabled={loading}
          onChange={callbackChange}
        />
      </FormItem>

      <FormItem
        label="Message (Required)"
        extra={`Character Count: ${message.length}`}
        validateStatus={errors.message ? "error" : null}
        help={errors.message}
      >
        <TextArea
          name="message"
          ref={messageRef}
          disabled={loading}
          value={message}
          onChange={callbackChange}
        />
      </FormItem>
      <CustomFields inputRef={messageRef} onChange={setMessage} />

      <FormItem>
        <AddImage image={dataFile} onChange={callbackChangeImage} />
      </FormItem>

      <Button
        block
        type="primary"
        loading={loading}
        onClick={callbackSubmit}
      >
        {editTemplate ? "Update" : "Create"}
      </Button>
      <input type="submit" style={{ position: "fixed", left: -2000 }} />
    </Form>
  );
};

MainForm.propTypes = {
  loading: bool.isRequired,
  editTemplate: objectOf(any),
  onSubmit: func.isRequired
};

MainForm.defaultProps = {
  editTemplate: null
};

export default MainForm;
