import React, { useRef } from "react";
import HTML5Backend from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { arrayOf, objectOf, any, string, func } from "prop-types";

const sortByIndex = (a, b) => (a.index > b.index ? 1 : -1);

const Item = ({ itemType, data, funcComponent, onMoved }) => {
  const itemRef = useRef(null);

  const [, drop] = useDrop({
    accept: itemType,
    hover(item, monitor) {
      if (!itemRef.current) {
        return
      }
      const dragIndex = item.index;
      const hoverIndex = data.index;
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = itemRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMoved(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: itemType, id: data.id, index: data.index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
  });

  drag(drop(itemRef));

  return (
    <div ref={itemRef} style={{ opacity: isDragging ? 0 : 1 }}>
      {funcComponent({ data })}
    </div>
  );
};

const Sortable = ({ itemType, items, itemComponent, onMoved }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      {items.sort(sortByIndex).map(data => (
        <Item
          key={data.id}
          itemType={itemType}
          data={data}
          funcComponent={itemComponent}
          onMoved={onMoved}
        />
      ))}
    </DndProvider>
  );
};

Item.propTypes = {
  itemType: string.isRequired,
  data: objectOf(any).isRequired,
  funcComponent: func.isRequired,
  onMoved: func
};

Item.defaultProps = {
  onMoved: null
};

Sortable.propTypes = {
  itemType: string.isRequired,
  items: arrayOf(any).isRequired,
  itemComponent: func.isRequired,
  onMoved: func
};

Sortable.defaultProps = {
  onMoved: null
};

export default Sortable;
