import React, { Fragment, useEffect } from "react";
import { objectOf, any, func } from "prop-types";
import Divider from "antd/es/divider";
import Avatar from "antd/es/avatar";
import Typography from "antd/es/typography";
import Tag from "antd/es/tag";

import "./style.css";
import { toShortName } from "../../utils/string";
import { buildProperties, buildTimeline } from "../Inbox/Full/ContactInfo/utils";
import Properties from "../Properties";
import TimelineGroup from "../TimelineGroup";
import StartNewConversation from "../StartNewConversation/Launch";
import ContactAssignMember from "../ContactAssignMember";

const Title = Typography.Title;

const Contact = ({ data, fetchContactDetails, setCurrentContactId }) => {
  const { id, name, tags, avatar, profileData, timelineData, fetched, properties } = data;

  useEffect(() => {
    setCurrentContactId(id);

    if (!fetched) {
      fetchContactDetails(id);
    }
  }, [id, fetched, setCurrentContactId, fetchContactDetails]);

  const dataProperties = buildProperties(profileData);
  const dataTimeline = buildTimeline(timelineData);

  const company =
    properties.filter(r => r.name.startsWith("Company")).map(record => record.value)[0] || "";

  return (
    <div className="contact-details">
      <div className="contact-details-info-wrapper">
        <Avatar size="large" src={avatar}>
          {toShortName(name)}
        </Avatar>

        <div className="contact-details-info">
          <Title level={3}>{name}</Title>
          {company ? <h4 className="company">Company: {company}</h4> : null}

          <div className="contact-details-info-tags">
            {tags.map(tag => (
              <Tag key={tag} color="green">
                {tag}
              </Tag>
            ))}
          </div>

          <StartNewConversation contact={data} />
        </div>
      </div>
      <ContactAssignMember contact={data} />

      {dataProperties.length ? (
        <Fragment>
          <Divider />
          <Properties data={dataProperties} />
        </Fragment>
      ) : null}

      <Divider />
      <TimelineGroup data={dataTimeline} />
    </div>
  );
};

Contact.propTypes = {
  data: objectOf(any).isRequired,
  fetchContactDetails: func.isRequired,
  setCurrentContactId: func.isRequired
};

export default Contact;
