import React, { Fragment, useState, useCallback } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import Select from "antd/es/select";
import Typography from "antd/es/typography";
import { connect } from "react-redux";
import { arrayOf, bool, any } from "prop-types";

import { exportCSVorgs } from "../../../services/api/orgs";

const Title = Typography.Title;
const Option = Select.Option;

const ExportOrgs = ({ items, loading }) => {
  const [org, setOrg] = useState(null);
  const [exporting, setExporting] = useState(false);

  const callbackChangeOrg = useCallback(val => {
    setOrg(val);
  }, [org]);

  const callbackExport = useCallback(() => {
    setExporting(true);
    exportCSVorgs(org)
      .then(result => {
        const a = document.createElement("a");
        a.href = `data:application/octet-stream,${result.csv_text}`;
        a.setAttribute("download", `${org}.csv`);
        a.click();

        setExporting(false);
      })
      .catch(() => {
        setExporting(false);
      });
  }, [exporting, org]);

  return (
    <Fragment>
      <Title level={4}>
        Export orgs
      </Title>
      <Row gutter={16}>
        <Col span={20}>
          <Select
            showSearch
            placeholder="Select an org"
            style={{ width: "100%" }}
            loading={loading}
            value={org}
            onChange={callbackChangeOrg}
          >
            {items.map(item => (
              <Option key={item.id} value={item.slug}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <Button
            block
            loading={exporting}
            type="primary"
            disabled={!org}
            onClick={callbackExport}
          >
            Export {org}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

ExportOrgs.propTypes = {
  items: arrayOf(any),
  loading: bool
};

const mapStateToProps = ({
  orgs: { orgs, fetchingOrgs }
}) => ({
  items: orgs,
  loading: fetchingOrgs
});

export default connect(mapStateToProps)(ExportOrgs);
