import message from "antd/es/message";

import { getRef, getTimestamp } from "../services/firestoreService";
import {
  FETCH_CALENDAR_EVENT_TEMPLATE_BEGIN,
  FETCH_CALENDAR_EVENT_TEMPLATE_SUCCESS,
  FETCH_CALENDAR_EVENT_TEMPLATE_FAILURE,
  CREATE_CALENDAR_EVENT_TEMPLATE_BEGIN,
  CREATE_CALENDAR_EVENT_TEMPLATE_SUCCESS,
  CREATE_CALENDAR_EVENT_TEMPLATE_FAILURE,
  UPDATE_CALENDAR_EVENT_TEMPLATE_BEGIN,
  UPDATE_CALENDAR_EVENT_TEMPLATE_SUCCESS,
  UPDATE_CALENDAR_EVENT_TEMPLATE_FAILURE,
} from "../constants";

export const getTemplates = () => async (dispatch, getState) => {
  const {
    profile: { notifyId },
  } = getState();
  await dispatch({ type: FETCH_CALENDAR_EVENT_TEMPLATE_BEGIN });

  const templates = [];
  try {
    const querySnapshot = await getRef("calendarEventTemplates")
      .where("authorId", "==", notifyId)
      .get();
    querySnapshot.forEach((doc) => {
      templates.push({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt && doc.data().createdAt.toDate(),
      });
    });
  } catch (error) {
    await dispatch({ type: FETCH_CALENDAR_EVENT_TEMPLATE_FAILURE, error });
    return;
  }

  await dispatch({ type: FETCH_CALENDAR_EVENT_TEMPLATE_SUCCESS, templates });
};

export const addTemplate = (data) => async (dispatch, getState) => {
  const {
    profile: { notifyId },
  } = getState();

  await dispatch({ type: CREATE_CALENDAR_EVENT_TEMPLATE_BEGIN });

  data.authorId = notifyId;
  data.createdAt = getTimestamp();

  let result;
  try {
    result = await getRef("calendarEventTemplates").add(data);
  } catch (error) {
    dispatch({ type: CREATE_CALENDAR_EVENT_TEMPLATE_FAILURE });
    message.error("Unknown error, please repeat again");
    return;
  }

  message.success("Added");

  await dispatch({
    type: CREATE_CALENDAR_EVENT_TEMPLATE_SUCCESS,
    template: { ...data, id: result.id },
  });
};

export const updateTemplate = (id, data) => async (dispatch) => {
  await dispatch({ type: UPDATE_CALENDAR_EVENT_TEMPLATE_BEGIN });

  try {
    await getRef("calendarEventTemplates").doc(id).update(data);
  } catch (error) {
    dispatch({ type: UPDATE_CALENDAR_EVENT_TEMPLATE_FAILURE, error });
    message.error("Unknown error, please repeat again");
    return;
  }

  message.success("Updated");

  await dispatch({
    type: UPDATE_CALENDAR_EVENT_TEMPLATE_SUCCESS,
    template: data,
    id,
  });
};
