import React  from "react";
import Typography from "antd/es/typography";
import {string, oneOfType, arrayOf, objectOf, number, any} from "prop-types";

const Text = Typography.Text;

const Record = ({ label, value }) => (
  <div className="properties-item">
    <Text strong>
      {label}
    </Text>
    <br />
    {Array.isArray(value) ? value.map((v, i) => <div key={i}>{v}</div>) : value}
  </div>
);

Record.propTypes = {
  label: string.isRequired,
  value: oneOfType([number, string, arrayOf(string), objectOf(any)]).isRequired
};

export default Record;
