import React  from "react";

import "./style.css";
import Title from "../../components/Title";
import TopNavigation from "../../components/TopNavigation";

const Error404 = () => (
  <div>
    <Title title="Not Found" />
    <TopNavigation />

    <div>
      <p className="title">OOPS! - Could not Find it</p>
      <h1 className="code">404</h1>
    </div>
  </div>
);

export default Error404;
