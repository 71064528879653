import React from "react";
import logo from "../../../images/Logo-318x40-white.png";

const Hav = () => (
  <div className="onboarding-nav">
    <div className="loader-wrapper">
      <img src={logo} alt="text with" />
    </div>
  </div>
);

export default Hav;
