import React from "react";

import DocTitle from "../../../components/Title";
import AdminTopNavigation from "../../../components/AdminTopNavigation";
import Wrapper from "../../../components/Wrapper";
import ExportOrgs from "./ExportOrgs";

const Export = () => (
  <div>
    <DocTitle title="Admin Export" />
    <AdminTopNavigation />

    <Wrapper>
      <ExportOrgs />
    </Wrapper>
  </div>
);

export default Export;
