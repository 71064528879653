import React from "react";
import Spin from "antd/es/spin";
import Icon from "antd/es/icon";
import { Link } from "react-router-dom";
import { objectOf, any, func, bool } from "prop-types";

import Title from "../../../components/Title";
import Wrapper from "../../../components/Wrapper";
import TopNavigation from "../../../components/TopNavigation";
import ContactDetails from "../../../components/Contact";
import Error404 from "../../../pages/errors/404";

const Contact = ({
  data,
  loading,
  fetchContactDetails,
  setCurrentContactId
}) => {
  if (!Object.keys(data).length && !loading) {
    return <Error404 />;
  }

  return (
    <div>
      <Title title={data.name || ""} />
      <TopNavigation />

      <Wrapper
        title={data.name}
        backUrl="/contacts"
        extra={(
          <Link to={`/contacts/${data.id}/edit`}>
            <Icon type="edit"/> Edit
          </Link>
        )}
      >
        {loading ? (
          <div className="spinner-wrapper">
            <Spin />
          </div>
        ) : (
          <ContactDetails
            data={data}
            fetchContactDetails={fetchContactDetails}
            setCurrentContactId={setCurrentContactId}
          />
        )}
      </Wrapper>
    </div>
  );
};

Contact.propTypes = {
  data: objectOf(any),
  loading: bool,
  fetchContactDetails: func.isRequired,
  setCurrentContactId: func.isRequired
};

Contact.defaultProps = {
  data: {},
  loading: false
};

export default Contact;
