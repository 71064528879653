import React from "react";
import { bindActionCreators } from "redux";
import { bool, func } from "prop-types";
import { connect } from "react-redux";
import Modal from "antd/es/modal";
import Spin from "antd/es/spin";

import { setGlobalLoading } from "../actions/application";

class GlobalLoading extends React.PureComponent {
  componentDidMount() {
    const { visible, setGlobalLoading } = this.props;
    if (visible) {
      setGlobalLoading(false);
    }
  }

  render() {
    const { visible } = this.props;

    return visible ? (
      <Modal
        visible
        closable={false}
        title={null}
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          <Spin size="large"/>
        </div>
      </Modal>
    ) : null;
  }
}

GlobalLoading.propTypes = {
  visible: bool,
  setGlobalLoading: func.isRequired
};

GlobalLoading.defaultProps = {
  visible: false
};

const mapStateToProps = ({
  application: { loading }
}) => ({
  visible: loading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setGlobalLoading
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalLoading);
