import React from "react";
import Avatar from "antd/es/avatar";
import { Link } from "react-router-dom";

import { toShortName } from "../../utils/string";

export default [
  {
    dataIndex: "avatar",
    key: "avatar",
    width: 70,
    render: (avatar, record) => <Avatar src={avatar}>{toShortName(record.name)}</Avatar>
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, record) =>
      <Link to={`/contacts/${record.key}`}>{name}</Link>
  }
];
