import React, { Fragment, useState, useCallback } from "react";
import classNames from "classnames";
import Avatar from "antd/es/avatar";
import Icon from "antd/es/icon";
import Popover from "antd/es/popover";
import Button from "antd/es/button";
import Typography from "antd/es/typography/Typography";
import { bool, string, any, func } from "prop-types";

import "./style.css";
import Image from "../Image";
import { toShortName } from "../../../utils/string";
import { formatScheduledDate, formatSendDate } from "../../../utils/messaging";
import DateTimePicker from "../../DateTimePicker";
import ErrorMessage from "./ErrorMessage";

const { Text } = Typography;

const statusIcon = status => {
  switch (status) {
    case "pending":
    case "sending":
      return "clock-circle";
    case "delivered":
      return "check";
    case "failed":
      return "warning";
    default:
      return "";
  }
};

const Bubble = ({
  scheduledAt,
  isOutgoing,
  message,
  errorMessage,
  canRetry,
  deliveryStatus,
  updatedAt,
  imageUrl,
  contactName,
  contactAvatar,
  senderName,
  senderIsMe,
  onDelete,
  onRetry,
  onUpdateSchedule
}) => {
  const [visible, setVisible] = useState(false);

  const callbackToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible, setVisible]);

  return (
    <div className="bubble-wrapper clearfix">
      <div className={classNames("bubble-container", { "flex-right": isOutgoing })}>
        {isOutgoing ? null : <Avatar src={contactAvatar}>{toShortName(contactName)}</Avatar>}
        <div
          className={classNames("bubble", {
            "bubble-outgoing": isOutgoing,
            "bubble-grey": isOutgoing && !senderIsMe
          })}
        >
          <div className="cover">
            {imageUrl && !imageUrl.endsWith(".vcf") ? (
              <div className="bubble-image-wrapper">
                <Image url={imageUrl} />
              </div>
            ) : null}

            {message ? (
              <span className="bubble-body">{message}</span>
            ) : imageUrl && imageUrl.endsWith(".vcf") ? (
              "You Sent your Contact Info."
            ) : null}

            <div className="bubble-footer">
              {isOutgoing && senderName ? (
                <div className="bubble-footer-left">~ {senderName}</div>
              ) : !isOutgoing && contactName ? (
                <div className="bubble-footer-left">{contactName}</div>
              ) : null}

              <div className="bubble-footer-right">
                {scheduledAt
                  ? `Sending on ${formatScheduledDate(scheduledAt)}`
                  : formatSendDate(updatedAt)}

                {isOutgoing ? (
                  <Icon type={statusIcon(deliveryStatus)} style={{ marginLeft: 4, fontSize: 11 }} />
                ) : null}
              </div>
            </div>
          </div>
          {scheduledAt ? (
            <Fragment>
              <Popover
                title="Edit Schedule Message"
                content={
                  <div style={{ width: 250 }}>
                    <Button
                      block
                      type="primary"
                      style={{ marginBottom: 5 }}
                      onClick={callbackToggleVisible}
                    >
                      Update Delivery Time & Date
                    </Button>
                    <Button block type="danger" onClick={onDelete}>
                      Delete Message
                    </Button>
                  </div>
                }
              >
                <a className="schedule-action">
                  <Icon type="clock-circle" />
                  Update Delivery Date/Time * Delete
                </a>
              </Popover>
              <DateTimePicker
                buttonTitle="Update"
                initData={scheduledAt}
                visible={visible}
                setVisible={setVisible}
                onDone={onUpdateSchedule}
              />
            </Fragment>
          ) : null}

          {errorMessage ? (
            <ErrorMessage
              isOutgoing={isOutgoing}
              message={errorMessage}
              canRetry={canRetry}
              onDelete={onDelete}
              onRetry={onRetry}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

Bubble.propTypes = {
  scheduledAt: any,
  isOutgoing: bool,
  message: string,
  errorMessage: string,
  canRetry: bool,
  deliveryStatus: string,
  updatedAt: any,
  imageUrl: string,
  contactName: string,
  contactAvatar: string,
  senderName: string,
  senderIsMe: bool,
  onDelete: func,
  onUpdate: func,
  onRetry: func,
};

Bubble.defaultProps = {
  scheduledAt: null,
  isOutgoing: false,
  message: null,
  errorMessage: null,
  canRetry: false,
  deliveryStatus: null,
  imageUrl: null,
  contactName: null,
  contactAvatar: null,
  senderName: null,
  senderIsMe: false
};

export default Bubble;
