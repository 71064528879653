import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ImportContacts from "./ImportContacts";
import { setImported } from "../../../actions/contacts";

const mapDispatchToProps = dispatch => bindActionCreators({
  setImported
}, dispatch);

export default connect(null, mapDispatchToProps)(ImportContacts);
