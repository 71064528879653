import lodash from "lodash";
import { toUTCFomTZ } from "../../utils/other";

export const buildEventFormData = ({
  orgTimezoneName,
  isAutomatic,
  name,
  startDate,
  endDate,
  message,
  tag,
  imageUri,
  category
}) => {
  const data = {
    send_delay_in_minutes: 0,
    send_automatically: isAutomatic,
    name: name,
    template_text: message,
    contact_type: "Customers",
    start_at: startDate && toUTCFomTZ(startDate.format('YYYY-MM-DD HH:mm'), orgTimezoneName).format("YYYY-MM-DD HH:mm"),
    end_at: endDate && toUTCFomTZ(endDate.format('YYYY-MM-DD HH:mm'), orgTimezoneName).format("YYYY-MM-DD HH:mm"),
  };

  if (isAutomatic) {
    const displayNewDate = toUTCFomTZ(startDate.format('YYYY-MM-DD HH:mm'), orgTimezoneName);
    data.start_at = displayNewDate.format("YYYY-MM-DD HH:mm");
    data.end_at = displayNewDate.add(10, "minutes").format("YYYY-MM-DD HH:mm");
  }

  if (tag) {
    data.contact_query = {
      property_name: tag,
      lookup_type: "istag",
      property_value: "True"
    };
    if (tag.includes("!list")) {
      data.contact_query.lookup_type = "eq";
    }
  }

  if (category) {
    data.category = category;
  }

  if (imageUri) {
    data.image_url = imageUri;
  }

  return data;
};

export const buildResponsesEventFormData = (workflowId, responses) =>
  responses.map((r, i) => {
    const tag_name = r.tag || undefined;
    const data = {
      workflow: workflowId,
      order_num: i,
      answer: r.name,
      template_response: r.message,
      is_goal: r.goal === "yes",
      action: r.action,
      tag_name,
    };

    if (!r.id.includes("key-")) {
      data.id = r.id;
    }

    return data;
  });

export const buildResponsesFromData = data =>
  data.map(r => ({
    id: r.id,
    name: r.answer,
    message: r.template_response,
    action: r.action,
    tag: r.tag_name,
    goal: r.is_goal ? "yes" : "no"
  }));

export const baseValidate = (
  type,
  { name, message, tag, responses, errors }
) => {
  let success = true;
  let skip = false;
  const tmp = { ...errors };

  tmp.responses = {};

  if (type === "draft") {
    skip = name || message || tag;
  }

  if (!skip) {
    if (!name) {
      tmp.name = "Can't be empty";
      success = false;
    }

    if (!message) {
      tmp.message = "Can't be empty";
      success = false;
    }

    if (!tag) {
      tmp.tag = "Choose one tag";
      success = false;
    }
  }

  lodash.forEach(responses, response => {
    if (!tmp.responses[response.id]) {
      tmp.responses[response.id] = {};
    }

    if (!response.name) {
      tmp.responses[response.id].name = "Can't be empty";
      success = false;
    }

    if (!response.message) {
      tmp.responses[response.id].message = "Can't be empty";
      success = false;
    }
  });

  return [success, tmp];
};
