import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContactInfo from "./ContactInfo";
import {archive, unArchive} from "../../../../actions/conversations";

const mapStateToProps = ({
  conversations: { currentConversation, conversationsById },
  contacts: { contactIdsByAddress, contactsById }
}) => {
  if (!currentConversation) {
    return {};
  }

  let contact = null;
  const conversation = conversationsById[currentConversation];
  if (conversation && conversation.contactAddress) {
    const contactId = contactIdsByAddress[conversation.contactAddress];
    contact = contactsById[contactId];
  }

  return {
    contact,
    contactsById,
    currentConversation: conversation,
    conversationSelected: true
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  onArchive: archive,
  onUnArchive: unArchive
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
