import React, { useEffect, useState } from "react";

import "../../../components/Inbox/Full/style.css";
import "../../../components/Inbox/Full/ConversationsList/style.css";
import ContactsList from "../ContactsList";
import ContactInfo from "../ContactInfo";
import BodyMessenger from "../BodyMessenger";
import Compose from "../Compose";

const Mailer = () => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    if (window.innerWidth <= 1000) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    handleCollapse();
    window.addEventListener("resize", handleCollapse)
  }, []);

  return (
    <div className="inbox-wrapper" style={{ top: 55 }}>
      <ContactsList />

      <div className="inbox-body-wrapper" style={collapsed ? { right: 0 } : {}}>
        <BodyMessenger customCompose={<Compose />} />
      </div>

      {collapsed ? null : <ContactInfo />}
    </div>
  );
};

export default Mailer;
