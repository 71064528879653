import React from "react";
import Typography from "antd/es/typography";
import Card from "antd/es/card";
import Row from "antd/es/row";
import Col from "antd/es/col";

import Wrapper from "../../../components/Wrapper";
import AppTitle from "../../../components/Title";
import TopNavigation from "../../../components/TopNavigation";
import salesforceImage from "../../../images/integrations/salesforce.png";
import calendarImage from "../../../images/integrations/calendar.png";
import shopifyImage from "../../../images/integrations/shopify.png";
import browserHistory from "../../../browserHistory";

const { Text, Paragraph } = Typography;

const ITEMS = [
  { key: "sf", title: "Salesforce", url: "/integrations/salesforce", cover: salesforceImage },
  { key: "cl", title: "Calendar", url: "/integrations/calendar", cover: calendarImage },
  { key: "sh", title: "Shopify", url: "/integrations/shopify", cover: shopifyImage },
];

const Integrations = ({ orgApiKey }) => (
  <>
    <AppTitle title="Integrations" />
    <TopNavigation />

    <Wrapper
      title="Integrations"
      footer={
        <div style={{ display: "flex", paddingBottom: 10, marginLeft: 8, marginTop: -8 }}>
          <Text strong>Your Api Key:&nbsp;</Text>
          <Paragraph copyable>{orgApiKey}</Paragraph>
        </div>
      }
    >
      <Row gutter={16}>
        <Col lg={{ span: 3 }} sm={{ span: 0 }} />
        {ITEMS.map((item) => (
          <Col lg={{ span: 6 }} sm={{ span: 8 }} style={{ marginBottom: 16 }}>
            <Card
              hoverable
              key={item.key}
              cover={<img alt={item.title} src={item.cover} />}
              onClick={() => {
                browserHistory.replace(item.url);
              }}
            >
              <Card.Meta title={item.title} />
            </Card>
          </Col>
        ))}
      </Row>
    </Wrapper>
  </>
);

export default Integrations;
