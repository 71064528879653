import React from "react";
import moment from "moment";
import lodash from "lodash";

import { formatPhone } from "../../../../utils/string";

const buildValue = (data) => {
  if (data.value_type === "datetime") {
    return moment(data.value).format("MMMM DD, YYYY");
  }

  return data.value;
};

const sortByDate = (a, b) => new Date(b.sort_date) - new Date(a.sort_date);

const buildAddress = (dataAddress) => {
  const buf = [
    [dataAddress.street, dataAddress.city].filter((b) => b).join("\n"),
    [dataAddress.state, dataAddress.zip].filter((b) => b).join(" "),
  ];

  return buf.filter((b) => b).join(", ");
};

export const buildProperties = (data) => {
  const props = (data.properties || [])
    .filter((prop) => !prop.name.includes("Message Count Since Last Reply"))
    .filter((prop) => !prop.name.includes("Address")) //TODO investigate
    .map((item) => ({
      label: item.name,
      value: buildValue(item),
    }));

  const addresses = [];
  const emails = [];
  const phones = (data.phones || []).map((phone) => formatPhone(phone.phone_normalized));

  lodash.forEach(data.labeled_properties || [], (property) => {
    if (property.name === "Address") {
      addresses.push(`${property.label}: ${buildAddress(property.value)}`);
    } else if (property.name === "Email") {
      emails.push(`${property.label}: ${property.value}`);
    }
  });

  if (phones.length > 0) {
    props.push({
      label: "Phones",
      value: phones,
    });
  }
  if (addresses.length > 0) {
    props.push({
      label: "Address",
      value: addresses,
    });
  }
  if (emails.length > 0) {
    props.push({
      label: "Email",
      value: emails,
    });
  }

  return props;
};

export const buildTimeline = (data) =>
  data.sort(sortByDate).map((item) => {
    let itemColor = "blue";
    let itemIcon = null;
    let itemText = "";
    let itemDate = moment(item.when_at).format("LLL");

    if (item.type === "Event" && item.name === "Purchase") {
      const { comments, description, total_price, myshopify_name } = item.value;
      const tmp = [];
      if (description) {
        tmp.push(`Bought: ${description}`);
      }
      if (total_price) {
        tmp.push(`Price: ${total_price}`);
      }
      if (myshopify_name) {
        tmp.push(`Shopify Name: ${myshopify_name}`);
      }
      if (comments) {
        tmp.push(`Comments: ${comments}`);
      }
      // itemText = tmp.join("\n\n");
      itemText = tmp.map((item, index) => <p key={index}>{item}</p>);
    } else if (item.type === "Event" && item.name === "InPersonMeeting") {
      const { comments } = item.value;
      itemText = `Date Visited: ${moment(item.when_at).format(
        "MMMM DD, YYYY"
      )}\n\nComments: ${comments}`;
    } else if (item.type === "Event" && item.name.includes("Shopify Connected")) {
      itemText = `Connected through Shopify: ${moment(item.when_at).format("MMMM DD, YYYY")}`;
    } else if (item.type === "Event" && item.name.includes("Cart Abandoned")) {
      itemText = `Abandoned Cart: ${moment(item.when_at).format("MMMM DD, YYYY")}`;
    } else if (item.type === "Event" && item.name.includes("Cart Closed")) {
      itemText = `Cart Closed: ${moment(item.when_at).format("MMMM DD, YYYY")}`;
    } else if (item.type === "UserNote") {
      itemText = item.text;
      itemDate = moment(item.created_at).format("LLL");
    } else if (item.type === "Tag") {
      const prefix = "Tagged:";
      itemText = `${prefix} ${item.name}`;
    } else if (item.type === "LabeledProperty") {
      if (item.name === "Address") {
        itemText = `${item.label}: ${item.value.street}, ${item.value.city}, ${item.value.state} ${item.value.zip} ${item.value.country}`;
      } else {
        itemText = `${item.label}: ${item.value}`;
      }
    } else {
      return null;
    }

    if (item.type === "email") {
      itemIcon = "mail";
    }

    return {
      color: itemColor,
      icon: itemIcon,
      text: itemText,
      date: itemDate,
    };
  });

export const buildAssociations = (data, contacts) => {
  if (!data) {
    return [];
  }

  return data
    .filter((lp) => lp.name === "Association" && contacts[lp.value])
    .map((lp) => ({
      label: lp.label,
      value: contacts[lp.value].name || contacts[lp.value].phone,
    }));
};
