import React from "react";
import Button from "antd/es/button";
import Radio from "antd/es/radio";
import Typography from "antd/es/typography";
import FlatfileImporter from "flatfile-csv-importer";

import "./style.css";
import AppTitle from "../../../components/Title";
import TopNavigation from "../../../components/TopNavigation";
import Wrapper from "../../../components/Wrapper";
import { importContacts } from "../../../services/api/contact";
import QuickTips from "./QuickTips";

const Text = Typography.Text;

class ImportContacts extends React.Component {
  state = {
    duplicate: "merge",
    assignment: "me"
  };

  importer = null;

  componentDidMount() {
    this.importer = this.createFileImporter();
  }

  createFileImporter = () =>
    new FlatfileImporter(process.env.REACT_APP_FLATFILE_LICENCE_KEY, {
      title: "Import Contacts",
      type: "Contact",
      disableManualInput: true,
      fields: [
        {
          key: "recordID",
          label: "Record ID",
          alternates: ["record_id", "id", "id #", "ID #", "record number", "record num", "id num"],
          validators: []
        },
        {
          key: "givenName",
          label: "First Name",
          alternates: ["first", "firstname", "surname", "first_name", "fname"],
          validators: [
            {
              validate: "required_without",
              fields: ["emailAddresses"]
            }
          ]
        },
        {
          key: "familyName",
          label: "Last Name",
          alternates: ["last", "last_name", "lastname", "lname"],
          validators: []
        },
        {
          key: "company",
          label: "Company",
          alternates: ["organization"],
          validators: []
        },
        {
          key: "phoneNumbers",
          label: "Phone Number",
          alternates: ["phone", "phonenumber", "phonenumbers", "phone_number", "number"],
          validators: []
        },
        {
          key: "phone_label",
          label: "Phone Label",
          description: "e.g. Mobile, Home, Work",
          validators: []
        },
        {
          key: "emailAddresses",
          label: "Email",
          description: "Email, e.g. support@notify.io",
          alternates: ["email", "email_addresses", "email_addr", "email_addrs"],
          validators: [
            {
              validate: "regex_matches",
              regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            }
          ]
        },
        {
          key: "email_label",
          label: "Email Label",
          description: "e.g. Personal, Work",
          validators: []
        },
        // {
        //   key: "postalAddresses",
        //   alternates: [
        //     "address",
        //     "addresses",
        //     "postal_address",
        //     "postal_addresses",
        //     "addr",
        //     "addrs"
        //   ],
        //   label: "Full Address",
        //   description: "Postal street address, e.g. '300 Sacremento St., San Francisco, CA 94110'",
        //   validators: []
        // },
        {
          key: "address_label",
          label: "Address Label",
          description: "e.g. Home, Work",
          validators: []
        },
        {
          key: "street",
          alternates: ["street address"],
          label: "Street Address",
          description: "Postal street address, e.g. '300 Sacremento St.'",
          validators: []
        },
        {
          key: "city",
          alternates: ["town"],
          label: "City",
          description: "City, e.g. 'San Francisco'",
          validators: []
        },
        {
          key: "state",
          alternates: [],
          label: "State",
          description: "State, e.g. 'CA'",
          validators: []
        },
        {
          key: "zip",
          alternates: [],
          label: "Zip Code",
          description: "State, e.g. '94110', '94110-4321'",
          validators: []
        },
        {
          key: "tags",
          alternates: ["tag", "tag_list", "tags"],
          label: "Tag",
          description: "Tag, e.g. 'New Customer'",
          validators: []
        },
        {
          key: "team_member",
          alternates: ["team member", "sales", "associate", "who"],
          label: "Assigned To",
          description:
            "To whom the contact will be assigned, by email or name. e.g. Amit Kumar, amit@notify.io, amit",
          validators: []
        },
        {
          key: "related_fn",
          alternates: ["Related First Name", "Spouse first", "Partner first"],
          label: "Related First Name",
          description: "A related person such as partner, spouse, child, etc.",
          validators: []
        },
        {
          key: "related_ln",
          alternates: ["Related Last Name", "Spouse Last", "Partner Last"],
          label: "Related Last Name",
          description: "A related person such as partner, spouse, child, etc.",
          validators: []
        }
      ]
    });

  changeDuplicate = ({ target: { value } }) => this.setState({ duplicate: value });
  changeAssignment = ({ target: { value } }) => this.setState({ assignment: value });

  sendResultsData = async results => {
    const { duplicate, assignment } = this.state;

    try {
      this.importer.displayLoader();
      const resp = await importContacts(results.validData, { duplicate, assignment });
      if (resp.status === "failure") {
        let displayError = "";
        resp.issues.forEach(issue => {
          if (issue[0] === "error") {
            displayError += `Error: Line ${issue[1]}: ${issue[2]} \n`;
          } else if (issue[0] === "warning") {
            displayError += `Warning: Line ${issue[1]}: ${issue[2]} \n`;
          }
        });
        results = await this.importer.requestCorrectionsFromUser(displayError);
        this.sendResultsData(results);
        return;
      }
    } catch (error) {
      console.log(error);
      // if ((error.status && error.status === 408) || (error.code && error.code === "ECONNABORTED")) {
      //   alert("TIMEOUT");
      // }
      let displayMesage;

      if (error.data) {
        displayMesage = `Error, ${error.data}`;
      } else {
        displayMesage = `Error, ${error}`;
        if (error.toLowerCase().includes("timeout of")) {
          displayMesage = "Error, importing contacts took too long to complete. Please try again.";
        }
      }
      results = await this.importer.requestCorrectionsFromUser(displayMesage);
      this.sendResultsData(results);
      return;
    }
    this.importer.displaySuccess("Success!");
  };

  launch = async () => {
    let results;

    try {
      results = await this.importer.requestDataFromUser();
      this.sendResultsData(results);
    } catch (error) {
      console.log(error || "closed");
      if (error) {
        this.importer.displayError(error);
      }
      return;
    }
  };

  render() {
    const { duplicate, assignment } = this.state;
    return (
      <div>
        <AppTitle title="Import Contacts" />
        <TopNavigation />
        <Wrapper backUrl="/contacts" title="Import Contacts">
          <div className="wrapper-import-contacts">
            <QuickTips />
            <br />

            <div className="text-center">
              <Button block type="primary" onClick={this.launch}>
                Launch Importer
              </Button>
            </div>
            <br />

            <Text strong>How to deal with duplicate contacts</Text>
            <Radio.Group value={duplicate} onChange={this.changeDuplicate}>
              <Radio value="merge">Ovewrite existing values with my new data</Radio>
              <Radio value="skip">Skip these records</Radio>
            </Radio.Group>
            <br />

            <Text strong>Assignment of contacts to user if not included in CSV</Text>
            <Radio.Group value={assignment} onChange={this.changeAssignment}>
              <Radio value="me">Assign to me</Radio>
              <Radio value="automatically">Automatically assign team member</Radio>
              <Radio value="leave_contacts">Leave contacts unassigned</Radio>
            </Radio.Group>
          </div>
        </Wrapper>
      </div>
    );
  }
}

export default ImportContacts;
