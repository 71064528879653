import React from "react";
import {object} from "prop-types";
import Col from "antd/es/col";
import Row from "antd/es/row";

const Content = ({ lgSize, children }) => (
  <Row>
    <Col lg={lgSize}>
      {children}
      <br/>
      <br/>
      <br/>
    </Col>
  </Row>
);

Content.propTypes = {
  lgSize: object
};

Content.defaultProps = {
  lgSize: {span: 10, offset: 7}
};

export default Content;
