import React from "react";
import { Route, Switch } from "react-router-dom";

import SupportPage from "../pages/user/Support";
import UserTasksPage from "../pages/user/Tasks";
import UserInboxPage from "../pages/user/Inbox";
import UserListsPage from "../pages/user/Contacts/Lists";
import UserListOfContactsPage from "../pages/user/Contacts/ListOfContacts";
import UserContactPage from "../pages/user/Contact";
import UserNewContactPage from "../pages/user/NewContact";
import UserEditContactPage from "../pages/user/EditContact";
import UserImportContactsPage from "../pages/user/ImportContacts";
import UserProfilePage from "../pages/user/Profile";
import UserRemindersPage from "../pages/user/PowerUps/Reminders";
import UserCampaignsPage from "../pages/user/PowerUps/Campaigns";
import UserTemplatesPage from "../pages/user/PowerUps/Templates";
import UserTagsPage from "../pages/user/PowerUps/Tags";
import IntegrationsPage from "../pages/user/Integrations";
import IntegrationCalendarPage from "../pages/user/Integrations/Calendar";
import IntegrationShopifyPage from "../pages/user/Integrations/Shopify";
import IntegrationSalesforcePage from "../pages/user/Integrations/Salesforce";
import AdminDashboard from "../pages/admin/Dashboard";
import AdminExport from "../pages/admin/Export";
import AuthSignIn from "../pages/auth/SignIn";
import AuthOnboarding from "../pages/auth/Onboarding";
import Error404 from "../pages/errors/404";
import { ProtectedRoute } from "./utils";

export default () => (
  <Switch>
    <ProtectedRoute path="/" exact component={UserTasksPage} />
    <ProtectedRoute path="/support" component={SupportPage} />
    <ProtectedRoute path="/inbox" component={UserInboxPage} />
    <ProtectedRoute path="/profile" component={UserProfilePage} />
    <ProtectedRoute path="/contacts" exact component={UserListsPage} />
    <ProtectedRoute path="/contacts/list_:id" exact component={UserListOfContactsPage} />
    <ProtectedRoute path="/contacts/new" exact component={UserNewContactPage} />
    <ProtectedRoute path="/contacts/import" exact component={UserImportContactsPage} />
    <ProtectedRoute path="/contacts/:id" exact component={UserContactPage} />
    <ProtectedRoute path="/contacts/:id/edit" exact component={UserEditContactPage} />

    <ProtectedRoute path="/automations" exact component={UserRemindersPage} />
    <ProtectedRoute path="/campaigns" exact component={UserCampaignsPage} />
    <ProtectedRoute path="/templates" exact component={UserTemplatesPage} />
    <ProtectedRoute path="/tags" exact component={UserTagsPage} />

    <ProtectedRoute path="/integrations" exact component={IntegrationsPage} />
    <ProtectedRoute path="/integrations/calendar" exact component={IntegrationCalendarPage} />
    <ProtectedRoute path="/integrations/shopify" exact component={IntegrationShopifyPage} />
    <ProtectedRoute path="/integrations/salesforce" exact component={IntegrationSalesforcePage} />

    <Route path="/sign_in" component={AuthSignIn} />
    <Route path="/sign_up" component={AuthOnboarding} />

    <ProtectedRoute adminArea exact path="/admin" component={AdminDashboard} />

    <ProtectedRoute adminArea exact path="/admin/export" component={AdminExport} />

    <Route component={Error404} />
  </Switch>
);
