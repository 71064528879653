import React, { Fragment, useCallback, useState, useRef, useEffect } from "react";
import lodash from "lodash";
import Tag from "antd/es/tag";
import Spin from "antd/es/spin";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import Input from "antd/es/input";
import { arrayOf, string, number, bool, func } from "prop-types";

import "./styles.css";
import AppTitle from "../../../../components/Title";
import TopNavigation from "../../../../components/TopNavigation";
import Wrapper from "../../../../components/Wrapper";
import { TagsStateAdding } from "../../../../utils/TagsState";

const ItemTag = ({ isAdding, tag, index, onSubmit, onDelete }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [value, setValue] = useState(tag || "");
  const inputRef = useRef(null);

  const callbackToggleInputVisible = useCallback(() => {
    if (inputVisible) {
      setValue(tag || "");
    }
    setInputVisible(!inputVisible);
  }, [tag, inputVisible, setValue, setInputVisible]);

  const callbackChangeValue = useCallback(({ target: { value } }) =>
    setValue(value), [setValue]);

  const callbackSubmit = useCallback(() => {
    setInputVisible(false);
    if (isAdding) {
      onSubmit(value, index);
      setValue("");
    } else if (value && value !== tag) {
      onSubmit(value, index);
    } else if (!value) {
      onDelete(tag, index);
    }
  }, [index, value, tag, isAdding, setInputVisible, setValue, onSubmit, onDelete]);

  const callbackDelete = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    onDelete(tag, index);
  }, [tag, index, onDelete]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current.focus();
    }
  }, [inputVisible]);

  return (
    inputVisible ? (
      <Input
        type="text"
        size="small"
        style={{ width: "auto" }}
        placeholder="Press Enter to Save"
        ref={inputRef}
        value={value}
        onChange={callbackChangeValue}
        onBlur={callbackToggleInputVisible}
        onPressEnter={callbackSubmit}
      />
    ) : isAdding ? (
      <Tag
        style={{ cursor: "pointer", background: "#fff", borderStyle: "dashed" }}
        onClick={callbackToggleInputVisible}
      >
        <Icon type="plus" /> New Tag
      </Tag>
    ) : (
      <Tag
        closable
        style={{ cursor: "pointer" }}
        onClick={callbackToggleInputVisible}
        onClose={callbackDelete}
      >
        {tag}
      </Tag>
    )
  );
};

class Tags extends React.Component {
  state = { dataTags: this.props.tags || [] };

  tagsStateAdding = new TagsStateAdding([ ...(this.props.tags || []) ]);

  componentWillReceiveProps({ tags }, nextContext) {
    if (tags.length) {
      this.tagsStateAdding = new TagsStateAdding([ ...tags ]);
      this.setState({ dataTags: tags });
    }
  }

  handlerAdd = tag => {
    const { dataTags } = this.state;
    dataTags.push(tag);
    this.setState(dataTags);
    this.tagsStateAdding.add(tag);
  };

  handlerUpdate = (tag, index) => {
    const { dataTags } = this.state;
    dataTags[index] = tag;
    this.setState({ dataTags: lodash.uniq(dataTags) });
  };

  handlerDelete = (tag, index) => {
    const { dataTags } = this.state;
    dataTags.splice(index, 1);
    this.setState({ dataTags });
    this.tagsStateAdding.remove(tag);
  };

  submit = () => {
    const { dataTags } = this.state;
    const { updateTags } = this.props;
    const result = this.tagsStateAdding.buildDataResult(dataTags);
    this.tagsStateAdding.clear();

    updateTags(result);
  };

  render() {
    const { loading, updating } = this.props;
    const { dataTags } = this.state;

    return (
      <Fragment>
        <AppTitle title="Tags" />
        <TopNavigation />

        <Wrapper
          title="Tags"
          className="tags-wrapper"
          extra={(
            <Button
              type="primary"
              disabled={loading}
              loading={updating}
              onClick={this.submit}
            >
              Save
            </Button>
          )}
        >
          {loading ? (
            <div className="spinner-wrapper">
              <Spin />
            </div>
          ) : null}
          <div className="tags-content">
            {dataTags.map((tag, index) => (
              <ItemTag
                key={tag}
                tag={tag}
                index={index}
                onSubmit={this.handlerUpdate}
                onDelete={this.handlerDelete}
              />
            ))}
            {loading ? null : (
              <ItemTag isAdding onSubmit={this.handlerAdd} />
            )}
          </div>
        </Wrapper>
      </Fragment>
    );
  }
}

ItemTag.propTypes = {
  isAdding: bool,
  tag: string,
  index: number,
  onSubmit: func,
  onDelete: func
};

ItemTag.defaultProps = {
  isAdding: false,
  tag: null,
  index: null,
  onSubmit: null,
  onDelete: null
};

Tags.propTypes = {
  loading: bool,
  updating: bool,
  tags: arrayOf(string).isRequired,
  updateTags: func.isRequired
};

Tags.defaultProps = {
  loading: false,
  updating: false
};

export default Tags;
