import { connect } from "react-redux";

import Shopify from "./Shopify";

const mapStateToProps = ({ application: { org }, workflow: { workflows } }) => ({
  org,
  workflows
});

export default connect(mapStateToProps)(Shopify);
