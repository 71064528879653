import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Tags from "./Tags";
import { updateTags } from "../../../../actions/application";

const mapStateToProps = ({
  application: {
    fetching,
    updating,
    tags: { tags }
  }
}) => ({
  loading: (tags || []).length === 0 && fetching,
  updating: updating,
  tags: tags || []
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateTags
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
