import React from "react";
import Drawer from "antd/es/drawer";
import Input from "antd/es/input";
import Table from "antd/es/table";
import { arrayOf, any, string, bool, func } from "prop-types";

import "./style.css";
import columns from "./columns";
import Footer from "./Fotter";
import { getContactsSearchResults } from "../../utils/contacts";

const Search = Input.Search;

class ContactsPicker extends React.PureComponent {
  state = {
    query: "",
    height: "100%",
    selected: []
  };

  refContainer = null;

  componentDidMount() {
    window.addEventListener("resize", this.settingHeight);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.visible && !this.props.visible) {
      setTimeout(() => {
        this.settingHeight();
      },400);

      this.setState({ selected: nextProps.selectedIds, query: "" });
    }
  }

  settingHeight = () => {
    if (!this.refContainer) {
      return;
    }

    const height = this.refContainer.getBoundingClientRect().height - 68;
    this.setState({ height });
  };

  handlerChangeQuery = ({ target: { value } }) =>
    this.setState({ query: value });

  handlerChangeSelected = selectedRowKeys =>
    this.setState({ selected: selectedRowKeys });

  handlerApply = () => {
    const { selected } = this.state;
    const { onApply } = this.props;

    onApply(selected);
  };

  render() {
    const { excludeIds, contacts, visible, onClose } = this.props;
    const { query, selected, height } = this.state;
    const rowSelection = {
      selectedRowKeys: selected,
      onChange: this.handlerChangeSelected
    };

    let dataSource = contacts.filter(c => !excludeIds.includes(c.id));
    if (query) {
      dataSource = getContactsSearchResults(dataSource, query);
    }

    return (
      <Drawer
        title="Select Contacts"
        className="contacts-picker"
        width={520}
        visible={visible}
        onClose={onClose}
      >
        <Search
          placeholder="Search"
          value={query}
          onChange={this.handlerChangeQuery}
        />

        <div
          className="contacts-picker_container"
          ref={ref => this.refContainer = ref}
        >
          <div className="contacts-picker_content">
            <Table
              showHeader={false}
              pagination={{ pageSize: 30 }}
              scroll={{ y: height }}
              dataSource={dataSource}
              rowSelection={rowSelection}
              columns={columns}
            />
          </div>
        </div>

        <Footer onApply={this.handlerApply} onClose={onClose} />
      </Drawer>
    );
  }
}

ContactsPicker.propTypes = {
  visible: bool,
  contacts: arrayOf(any).isRequired,
  selectedIds: arrayOf(string),
  excludeIds: arrayOf(string),
  onClose: func.isRequired,
  onApply: func.isRequired
};

ContactsPicker.defaultProps = {
  visible: false,
  selectedIds: [],
  excludeIds: []
};

export default ContactsPicker;
