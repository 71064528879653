import React from "react";
import Typography from "antd/es/typography";
import Switch from "antd/es/switch";

import { string, bool, func } from "prop-types";

import "./style.css";
import CelPhoneMask from "../../utils/CelPhoneMask/cel-phone.mask";

const { Text } = Typography

const Available = ({
  available,
  loading,
  phone,
  onToggleAvailableState
}) => (
  <div className="user-available">
    <div className="text">
      <div>
        <Text strong>I'm{available ? '' : ' not'} available</Text>
      </div>
      <Text type="secondary">My number: {new CelPhoneMask().getValue(phone)}</Text>
    </div>

    <Switch
      loading={loading || available === null}
      checked={available}
      onClick={onToggleAvailableState}
    />
  </div>
);

Available.propTypes = {
  available: bool,
  loading: bool,
  phone: string.isRequired,
  onToggleAvailableState: func.isRequired
};

Available.defaultProps = {
  loading: false,
  available: null
};

export default Available;
