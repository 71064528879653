import React, { useCallback } from "react";
import List from "antd/es/list";
import Typography from "antd/es/typography";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import { any, func, objectOf } from "prop-types";

import WorkflowSummary from "../WorkflowSummary";
import { formatTotalPercent } from "../../utils/string";
import { formattedInfo } from "../../utils/workflow";

const Item = List.Item;
const Meta = Item.Meta;
const Title = Typography.Title;
const Text = Typography.Text;

const WorkflowItem = ({ data, teamMembers, onFetchSummary, onEdit }) => {
  const callbackEdit = useCallback(() => {
    if (onEdit) {
      onEdit(data.id);
    }
  }, [data, onEdit]);

  const { type, status, start, end } = formattedInfo(data);
  const {
    name,
    analytics: {
      total_contacts,
      sent,
      percent_sent,
      percent_clicks,
      clicked,
      percent_replies,
      replied
    }
  } = data;

  const actions = [
    <div key="sent">
      <Title level={4}>{formatTotalPercent(percent_sent)}%</Title>
      <Text type="secondary">{sent} Sent</Text>
    </div>,
    <div key="clicked">
      <Title level={4}>{formatTotalPercent(percent_clicks)}%</Title>
      <Text type="secondary">{clicked} Clicks</Text>
    </div>,
    <div key="replied">
      <Title level={4}>{formatTotalPercent(percent_replies)}%</Title>
      <Text type="secondary">{replied} Replies</Text>
    </div>
  ];
  if (clicked === 0) {
    actions.splice(1, 1);
  }

  return (
    <Item
      className="workflow-item-wrapper"
      actions={actions}
      extra={(
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Title level={4}>{status}</Title>
              <Text type="secondary">Status</Text>
            </Col>
            {start || end ? (
              <Col span={16}>
                <Title level={4}>{start}</Title>
                <Text type="secondary">{start && "Start"}</Text>
              </Col>
            ) : null}
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Title level={4}>{type}</Title>
              <Text type="secondary">Type</Text>
            </Col>
            {start || end ? (
              <Col span={16}>
                <Title level={4}>{end}</Title>
                <Text type="secondary">{end && "End"}</Text>
              </Col>
            ) : null}
          </Row>

          <br />
          <Row gutter={16}>
            <Col span={12}>
              <Button block type="dashed" onClick={callbackEdit}>
                Edit
              </Button>
            </Col>
            <Col span={12}>
              <WorkflowSummary
                workflow={data}
                teamMembers={teamMembers}
                onFetchSummary={onFetchSummary}
              />
            </Col>
          </Row>
        </div>
      )}
    >
      <Meta
        description={`Recipients ${total_contacts}`}
        title={name}
      />
    </Item>
  );
};

WorkflowItem.propTypes = {
  data: objectOf(any).isRequired,
  teamMembers: objectOf(any).isRequired,
  onFetchSummary: func.isRequired,
  onUpdateWorkflowStatus: func.isRequired,
  onEdit: func
};

WorkflowItem.defaultProps = {
  onEdit: null
};

export default WorkflowItem;
