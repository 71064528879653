import React, { Fragment } from "react";
import lodash from "lodash";
import Tabs from "antd/es/tabs";
import { string, any, arrayOf, objectOf, bool, func } from "prop-types";

import AppTitle from "../../../../components/Title";
import TopNavigation from "../../../../components/TopNavigation";
import Wrapper from "../../../../components/Wrapper";
import WorkflowList from "../../../../components/WorkflowList";
import AddWorkflow from "../../../../components/AddWorkflow";
import EditWorkflow from "../../../../components/EditWorkflow";

const TabPane = Tabs.TabPane;

class Campaigns extends React.PureComponent {
  state = {
    filter: "active",
    visibleEdit: false,
    editWorkflowId: null
  };

  componentWillMount() {
    const { fetchWorkflows } = this.props;
    fetchWorkflows();
  }

  showEditForm = editWorkflowId =>
    this.setState({
      visibleEdit: true,
      editWorkflowId
    });

  hideEditModal = () =>
    this.setState({ visibleEdit: false }, () => {
      setTimeout(() => {
        this.setState({editWorkflowId: null})
      }, 300);
    });

  handlerChangeFilter = filter => this.setState({ filter });

  render() {
    const { filter, editWorkflowId, visibleEdit } = this.state;
    const {
      tags,
      workflows,
      loading,
      updateWorkflowStatus,
      teamMembers,
      fetchWorkflows,
      fetchSummary
    } = this.props;

    return (
      <Fragment>
        <AppTitle title="Campaigns" />
        <TopNavigation />

        <EditWorkflow
          visible={visibleEdit}
          editWorkflowData={editWorkflowId && lodash.find(workflows, { id: editWorkflowId })}
          fetchWorkflows={fetchWorkflows}
          onCancel={this.hideEditModal}
        />

        <Wrapper
          title="Campaigns"
          footer={(
            <Tabs
              defaultActiveKey={filter}
              onChange={this.handlerChangeFilter}
            >
              <TabPane tab="Active" key="active" />
              <TabPane tab="Paused" key="paused" />
              <TabPane tab="Draft" key="draft" />
              <TabPane tab="Completed" key="completed" />
            </Tabs>
          )}
          extra={(
            <AddWorkflow fetchWorkflows={fetchWorkflows} />
          )}
        >
          <WorkflowList
            filter={filter}
            items={workflows}
            loading={loading}
            teamMembers={teamMembers}
            onFetchSummary={fetchSummary}
            onUpdateStatus={updateWorkflowStatus}
            onEdit={this.showEditForm}
          />
        </Wrapper>
      </Fragment>
    );
  }
}

Campaigns.propTypes = {
  loading: bool.isRequired,
  workflows: arrayOf(any).isRequired,
  teamMembers: objectOf(any).isRequired,
  fetchWorkflows: func.isRequired,
  updateWorkflowStatus: func.isRequired,
  fetchSummary: func.isRequired
};

export default Campaigns;
