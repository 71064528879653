import React, { Fragment, useState, useCallback } from "react";
import { objectOf, any, func } from "prop-types";
import lodash from "lodash";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import Modal from "antd/es/modal";

import EditContactPhoneNumber from "../EditContactPhoneNumber";
import CelPhoneMask from "../../utils/CelPhoneMask/cel-phone.mask";
import newConversation from "../../containers/newConversation"

const Number = ({ phone, onClick }) => {
  const callbackClick = useCallback(() => {
    onClick(phone);
  }, [phone, onClick]);

  return (
    <div style={{ marginBottom: 4 }}>
      <Button type="primary" onClick={callbackClick}>
        {new CelPhoneMask().getValue(phone.phone_normalized)}
      </Button>
    </div>
  );
};

const Launch = ({ contact, startNewConversation }) => {
  const phones = lodash.filter(contact.phones, phone =>
    !!new CelPhoneMask().getValue(phone.phone_normalized));
  const totalPhones = phones.length;

  const [visible, setVisible] = useState(false);

  const callbackGo = useCallback(phone => {
    setVisible(false);
    startNewConversation(contact.id, phone);
  }, [contact, setVisible, startNewConversation]);

  const callbackHandler = useCallback(() => {
    if (totalPhones === 1) {
      callbackGo(phones[0]);
    } else {
      setVisible(true);
    }
  }, [totalPhones, phones, setVisible, callbackGo]);

  const callbackCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <Fragment>
      <div>
        <Button onClick={callbackHandler}>
          <Icon type="message" />
        </Button>
      </div>
      {visible ? (
        <Modal
          title={totalPhones === 0 ? "Adding Phone Number" : "Select Number to Start"}
          footer={null}
          visible={true}
          onCancel={callbackCancel}
        >
          {totalPhones === 0 ? (
            <EditContactPhoneNumber contactId={contact.id} onDone={callbackGo} />
          ) : (
            phones.map((phone, index) => (
              <Number
                key={index}
                phone={phone}
                onClick={callbackGo}
              />
            ))
          )}
        </Modal>
      ) : null}
    </Fragment>
  );
};

Launch.propTypes = {
  contact: objectOf(any),
  startNewConversation: func.isRequired
};

export default newConversation(Launch);
