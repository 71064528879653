import React, { useCallback } from "react";
import Select from "antd/es/select";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { string, func, any, objectOf } from "prop-types";

import dataInfo from "./data";
import countries from "./countries";

const Option = Select.Option;

const Address = ({
  label,
  value,
  onChangeLabel,
  onChange
}) => {
  const callbackChangeStreet = useCallback(e => {
    const newValue = { ...value };
    newValue.street = e.target.value;
    onChange(newValue);
  }, [value, onChange]);

  const callbackChangeCity = useCallback(e => {
    const newValue = { ...value };
    newValue.city = e.target.value;
    onChange(newValue);
  }, [value, onChange]);

  const callbackChangeState = useCallback(e => {
    const newValue = { ...value };
    newValue.state = e.target.value;
    onChange(newValue);
  }, [value, onChange]);

  const callbackChangeZip = useCallback(e => {
    const newValue = { ...value };
    newValue.zip = e.target.value;
    onChange(newValue);
  }, [value, onChange]);

  const callbackChangeCountry = useCallback(newCountry => {
    const newValue = { ...value };
    newValue.country = newCountry;
    onChange(newValue);
  }, [value, onChange]);

  const { street, city, state, zip, country } = value;
  let labels = dataInfo.labels.addresses;
  if (label && !labels.includes(label)) {
    labels.push(label);
  }

  return (
    <div className="clearfix group-with-address">
      <div className="address-label">
        <Select
          placeholder="Select Label"
          style={{ width: "100%" }}
          value={label}
          onChange={onChangeLabel}
        >
          {labels.map(l => (
            <Option key={l} value={l}>{l}</Option>
          ))}
        </Select>
      </div>

      <div className="address-value">
        <Row>
          <Col span={24}>
            <Input
              placeholder="Street"
              value={street}
              onChange={callbackChangeStreet}
            />
          </Col>

          <Col span={24}>
            <Input
              placeholder="City"
              value={city}
              onChange={callbackChangeCity}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Input
              placeholder="State"
              value={state}
              onChange={callbackChangeState}
            />
          </Col>

          <Col span={12}>
            <Input
              placeholder="Zip"
              value={zip}
              onChange={callbackChangeZip}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Select
              showSearch
              placeholder="Select Country"
              style={{ width: "100%" }}
              value={country}
              onChange={callbackChangeCountry}
            >
              {countries.map(c => (
                <Option
                  key={`${c.alpha2}-${c.alpha3}`}
                  value={c.name}
                >
                  {c.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Address.propTypes = {
  label: string,
  value: objectOf(any),
  onChangeLabel: func.isRequired,
  onChange: func.isRequired
};

Address.defaultProps = {
  label: null,
  value: {}
};

export default Address;
