import lodash from "lodash";

export const getContactsSearchResults = (contacts, searchTerm) =>
  contacts.filter(
    c =>
      lodash.some(c.emails || [], e => e.email.match(new RegExp(lodash.escapeRegExp(searchTerm), "i"))) ||
      (c.name || "").match(new RegExp(lodash.escapeRegExp(searchTerm), "i")) ||
      c.tags.filter(t => t.match(new RegExp(lodash.escapeRegExp(searchTerm), "i"))).length > 0 ||
      c.phones.filter(
        p =>
          p.phone_normalized &&
          p.phone_normalized
            .replace(/\D/g, "")
            .match(new RegExp(lodash.escapeRegExp(searchTerm), "i"))
      ).length > 0
  );

export const getContactsSortedResults = (contacts, sortBy) => {
  if (sortBy === "name") {
    return lodash.sortBy(contacts, ["lastName", "firstName"]);
  }
  if (sortBy === "last_reply") {
    return lodash.orderBy(
      contacts,
      ({ lastInboundMessagedAt }) => lastInboundMessagedAt || "",
      "desc"
    );
  }
  if (sortBy === "last_sent") {
    return lodash.orderBy(
      contacts,
      ({ lastOutboundMessagedAt }) => lastOutboundMessagedAt || "",
      "desc"
    );
  }
  if (sortBy === "created_at") {
    return lodash.orderBy(
      contacts,
      c => (
        new Date(
          lodash.get(
            lodash.find(c.properties, { name: "Contact Created At" }),
            "value",
          ))
      ),
      "desc"
    );
  }

  return contacts;
};

export function formatContactAddress(inp) {
  if (!inp) {
    return "";
  }

  let key = inp.replace(/\D+/g, "");

  if (key.length === 10) {
    key = `+1${key}`;
  } else if (key.length === 11) {
    key = `+${key}`;
  }

  return key;
}
