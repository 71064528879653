import React, { Fragment } from "react";
import Collapse from "antd/es/collapse";
import Alert from "antd/es/alert";
import Spin from "antd/es/spin";
import Button from "antd/es/button";
import { objectOf, any, bool, func } from "prop-types";

import "./style.css";
import Info from "./Info";
import TimelineGroup from "../../../TimelineGroup";
import Properties from "../../../Properties";
import ConversationSettings from "../../ConversationSettings";
import ContactAssignMember from "../../../ContactAssignMember";
import { formatPhone } from "../../../../utils/string";
import { buildProperties, buildTimeline, buildAssociations } from "./utils";

const Panel = Collapse.Panel;

const ContactInfo = ({
  currentConversation,
  contactsById,
  contact,
  conversationSelected,
  onArchive,
  onUnArchive,
}) => (
  <div className="inbox-conversation-info-wrapper">
    {conversationSelected ? (
      contact ? (
        contact.fetchingDetails ? (
          <div className="inbox-conversation-info-spin-wrapper">
            <Spin />
          </div>
        ) : (
          <Fragment>
            <ConversationSettings conversation={currentConversation} />
            <Info
              avatar={contact.avatar}
              name={contact.name || (contact.phone && formatPhone(contact.phone))}
              tags={contact.tags}
            >
              <ContactAssignMember buttonSize="small" contact={contact} />
            </Info>

            <Collapse bordered={false} defaultActiveKey={["properties"]}>
              <Panel header="Properties" key="properties">
                <Properties data={buildProperties(contact.profileData)} />
              </Panel>
              <Panel header="Associations" key="associations">
                <Properties
                  data={buildAssociations(
                    contact.profileData && contact.profileData.labeled_properties,
                    contactsById
                  )}
                />
              </Panel>
              <Panel header="Timeline" key="timeline">
                <TimelineGroup data={buildTimeline(contact.timelineData)} />
              </Panel>
            </Collapse>
            <div
              style={{
                marginTop: 5,
                marginBottom: 10,
                position: "relative",
              }}
            >
              <Button
                size="large"
                type="danger"
                style={{ position: "absolute", left: 5 }}
                onClick={() => {
                  currentConversation.archived
                    ? onUnArchive(currentConversation.id)
                    : onArchive(currentConversation.id);
                }}
              >
                {currentConversation.archived ? "Unarchive Conversation" : "Archive Conversation"}
              </Button>
            </div>
          </Fragment>
        )
      ) : (
        <div className="inbox-conversation-info-alert-wrapper">
          <Alert type="info" message="This conversation doesn't belong to any contact" />
        </div>
      )
    ) : (
      <div className="inbox-conversation-info-alert-wrapper">
        <Alert type="info" message="Select a conversation" />
      </div>
    )}
  </div>
);

ContactInfo.propTypes = {
  contactsById: objectOf(any),
  contact: objectOf(any),
  currentConversation: objectOf(any),
  conversationSelected: bool,
  onArchive: func.isRequired,
  onUnArchive: func.isRequired,
};

ContactInfo.defaultProps = {
  contactsById: {},
  currentConversation: {},
  contact: null,
  conversationSelected: false,
  onArchive: () => {},
  onUnArchive: () => {},
};

export default ContactInfo;
