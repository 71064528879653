import React from "react";
import Spin from "antd/es/spin";
import { objectOf, arrayOf, string, any, bool, func } from "prop-types";

import Title from "../../../components/Title";
import Wrapper from "../../../components/Wrapper";
import TopNavigation from "../../../components/TopNavigation";
import ContactForm from "../../../components/ContactForm";
import DeleteContact from "../../../components/DeleteContact";
import Error404 from "../../errors/404";

class EditContact extends React.Component {
  componentWillMount() {
    const { data, fetchContactDetails } = this.props;
    if (data && !data.fetched && !data.fetchingDetails) {
      fetchContactDetails(data.id);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { data } = nextProps;
    if (data && !data.fetched && !data.fetchingDetails) {
      const { fetchContactDetails } = this.props;
      fetchContactDetails(data.id);
    }
  }

  render() {
    const { data, tags, loading, updating, updateContact, deleteContact } = this.props;
    if (!data && !loading) {
      return <Error404 />;
    }

    return (
      !data && !loading ? (
        <Error404 />
      ) : (
        <div>
          <Title title="Edit Contact" />
          <TopNavigation />

          <Wrapper
            title="Edit Contact"
            backUrl={`/contacts/${data && data.id}`}
            extra={(
              <DeleteContact
                backUrl="/contacts"
                contactId={data && data.id}
                loading={data.deleting}
                onDelete={deleteContact}
              />
            )}
          >
            {loading ? (
              <div className="spinner-wrapper">
                <Spin />
              </div>
            ) : data ? (
              <ContactForm
                tags={tags || []}
                loading={updating}
                dataInit={data}
                onSubmit={updateContact}
              />
            ) : null}
          </Wrapper>
        </div>
      )
    );
  }
}

EditContact.propTypes = {
  data: objectOf(any),
  tags: arrayOf(string),
  loading: bool,
  updating: bool,
  fetchContactDetails: func.isRequired,
  updateContact: func.isRequired,
  deleteContact: func.isRequired,
};

EditContact.defaultProps = {
  data: null,
  loading: false,
  updating: false
};

export default EditContact;
