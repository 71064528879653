import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Compose from "./Compose";
import { sendMessageToServer } from "../../../actions/messages";

const mapStateToProps = ({ conversations: { currentConversation }, profile: { phone } }) => {
  return {
    currentConversation,
    teamMemberAddress: phone,
    disabled: currentConversation === undefined
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendMessageToServer
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Compose);
