import React from "react";
import Modal from "antd/es/modal";
import List from "antd/es/list";
import Tag from "antd/es/tag";
import { any, arrayOf, func } from "prop-types";

import { WORKFLOW_CONTACT_DELIVERY_STATUS } from "../../constants";

const Item = List.Item;
const Meta = Item.Meta;

const ContactsList = ({ recipients, onHide }) => {
  const renderItem = ([contactName, statusId, contactId, teamMemberName]) => {
    return (
      <Item
        extra={(
          <Tag>
            {WORKFLOW_CONTACT_DELIVERY_STATUS[statusId] || "-"}
          </Tag>
        )}
      >
        <Meta
          title={(
            <a target="_blank" rel="noopener noreferrer"  href={`/contacts/${contactId}`}>
              {contactName}
            </a>
          )}
          description={teamMemberName}
        />
      </Item>
    );
  };

  return (
    <Modal
      title="Contacts List"
      footer={null}
      visible={!!recipients}
      onCancel={onHide}
    >
      {recipients ? <List dataSource={recipients} renderItem={renderItem} /> : null}
    </Modal>
  );
};

ContactsList.propTyprs = {
  recipients: arrayOf(any),
  onHide: func.isRequired
};

ContactsList.defaultProps = {
  recipients: null
};

export default ContactsList;
