import { batch } from "react-redux";
import lodash from "lodash";
import message from "antd/es/message";

import firestoreService, { getRef } from "../services/firestoreService";
import { setContactNoReplyState } from "../services/api/contact";
import { buildConversation, buildConversationName } from "./utils";
import {
  SET_CURRENT_CONVERSATION,
  SET_FILTER_CONVERSATIONS,
  SET_FILTER_2_CONVERSATIONS,
  UPDATE_ATTRS_CONVERSATION,
  SET_SCHEDULED_CONVERSATIONS,
  REMOVE_SCHEDULED_CONVERSATIONS,
  FETCH_THREADS_SUCCESS,
  FETCH_THREADS_BEGIN,
} from "../constants";

import { setGlobalLoading, hideStartNewConversation } from "./application";
import { addContact } from "./contacts";
import { formatContactAddress } from "../utils/contacts";
import browserHistory from "../browserHistory";

export const setCurrentConversation = (conversation) => ({
  type: SET_CURRENT_CONVERSATION,
  conversation,
});

export const setFilterConversations = (filter) => ({
  type: SET_FILTER_CONVERSATIONS,
  filter,
});

export const setFilter2Conversations = (filter) => ({
  type: SET_FILTER_2_CONVERSATIONS,
  filter,
});

export const updateConversationAttributes = (channelId, attrs = {}) => ({
  type: UPDATE_ATTRS_CONVERSATION,
  channelId,
  attrs,
});

export const setScheduledConversations = (threadIds) => ({
  type: SET_SCHEDULED_CONVERSATIONS,
  threadIds,
});

export const removeScheduledConversations = (threadIds) => ({
  type: REMOVE_SCHEDULED_CONVERSATIONS,
  threadIds,
});

export const setThreads = (threads) => async (dispatch, getState) => {
  const {
    conversations: { unreadConversations },
    contacts: { contactsById, contactIdsByAddress },
    application: { teamMembersByAddress },
    profile: { phone },
  } = getState();

  const conversations = lodash.map(threads, (thread) =>
    buildConversation(
      thread,
      unreadConversations,
      contactsById,
      contactIdsByAddress,
      teamMembersByAddress,
      phone
    )
  );

  dispatch({ type: FETCH_THREADS_SUCCESS, threads, conversations });
};

export const updateConversationName = (contactId) => async (dispatch, getState) => {
  const {
    application: { teamMembersByAddress },
    contacts: { contactsById },
    conversations: { conversations },
    profile: { phone },
  } = getState();

  const conversation = lodash.find(conversations, { contactId });
  const contact = contactsById[contactId];
  if (conversation) {
    const name = buildConversationName(phone, contact, conversation, teamMembersByAddress);
    if (conversation.name !== name) {
      dispatch(updateConversationAttributes(conversation.id, { name }));
    }
  }
};

export const noReply = () => async (dispatch, getState) => {
  const {
    profile: { accountId },
    conversations: { currentConversation, conversationsById },
  } = getState();
  const conversation = conversationsById[currentConversation];
  const contactId = conversation.contactId;

  dispatch(updateConversationAttributes(currentConversation, { replyStateLoading: true }));

  try {
    await setContactNoReplyState(contactId);
    await getRef("threads").doc(conversation.id).update({
      replyState: "No reply required",
      replyStateAlteredBy: accountId,
      unreadCount: 0,
    });
  } catch (error) {
    message.error("Problem setting the reply status, please try again.");
  }

  dispatch(updateConversationAttributes(currentConversation, { replyStateLoading: false }));
};

export const beginFetchConversations = () => ({
  type: FETCH_THREADS_BEGIN,
});

export const startNewConversation = (contactId, { phone_normalized }) => async (
  dispatch,
  getState
) => {
  const {
    conversations: { conversationsByContactAddress },
    profile: { notifyId, phone },
  } = getState();
  const key = phone_normalized;
  const conversation = conversationsByContactAddress[key];

  batch(() => {
    dispatch(hideStartNewConversation());
    dispatch(setFilterConversations("all"));
    dispatch(setFilter2Conversations("all"));

    if (conversation) {
      dispatch(setCurrentConversation(conversation.id));
    }
  });

  if (conversation) {
    browserHistory.push("/inbox");
    return;
  }

  const conversationId = await firestoreService.createNewConversation(
    key,
    phone,
    notifyId,
    contactId
  );
  if (conversationId) {
    dispatch(setCurrentConversation(conversationId));
    browserHistory.push("/inbox");
  }
};

export const createContactAndConversation = (phoneNumber) => async (dispatch, getState) => {
  const {
    contacts: { contactsById, contactIdsByAddress },
  } = getState();
  const contactId = contactIdsByAddress[formatContactAddress(phoneNumber)];
  let contact = contactId && contactsById[contactId];

  await dispatch(hideStartNewConversation());
  await dispatch(setGlobalLoading(true));
  if (!contact) {
    contact = await dispatch(
      addContact(
        null,
        {
          first_name: "",
          last_name: "",
          labeled_properties: [],
          properties: [],
          tags: [],
          phones: [
            {
              is_primary: true,
              label: "main",
              phone: phoneNumber,
              phone_text: phoneNumber,
            },
          ],
          contactAddress: formatContactAddress(phoneNumber),
        },
        false
      )
    );
  }

  if (!contact) {
    dispatch(setGlobalLoading(false));
    return;
  }

  const phone = contact.phones[0] || { phone_normalized: phoneNumber };
  await dispatch(startNewConversation(contact.id || contact.notify_id, phone));
  await dispatch(setGlobalLoading(false));
};

export const archive = (id) => async () => {
  await firestoreService.setArchiveThread(id, true);
};

export const unArchive = (id) => async () => {
  await firestoreService.setArchiveThread(id, false);
};
