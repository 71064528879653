import React from "react";
import Modal from "antd/es/modal";
import { bool, any, arrayOf, func } from "prop-types";

import Contacts from "./Contacts";

const StartNewConversation = ({
  visible,
  contacts,
  onCreateContactAndConversation,
  onClose
}) => (
  <Modal
    title="Start New Conversation"
    style={{ top: 10 }}
    width={600}
    visible={visible}
    footer={null}
    onCancel={onClose}
  >
    <Contacts
      items={contacts}
      onCreateContactAndConversation={onCreateContactAndConversation}
    />
  </Modal>
);

StartNewConversation.propTypes = {
  visible: bool,
  contacts: arrayOf(any),
  onCreateContactAndConversation: func.isRequired,
  onClose: func.isRequired
};

StartNewConversation.defaultProps = {
  visible: false,
  contacts: []
};

export default StartNewConversation;
