import React, { useCallback } from "react";
import lodash from "lodash";
import Avatar from "antd/es/avatar";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import List from "antd/es/list";
import Popconfirm from "antd/es/popconfirm";
import {
  number,
  string,
  any,
  func,
  arrayOf,
  objectOf
} from "prop-types";

import { toShortName } from "../../../utils/string";

const Item = List.Item;

const Contact = ({
  item: { id, key, avatar, name, state },
  currentContactId,
  onSetContactIndex,
  onRemoveContact
}) => {
  const callbackStopPropagation = useCallback(e =>
    e.stopPropagation(), []);

  const callbackSetContactIndex = useCallback(() => {
    onSetContactIndex(id);
  }, [id, onSetContactIndex]);

  const callbackRemoveContact = useCallback(e => {
    e.stopPropagation();
    onRemoveContact(id);
  }, [id, onRemoveContact]);

  return (
    <Item
      key={key}
      className={currentContactId === key && "active"}
      style={{
        paddingLeft: 10,
        paddingRight: 10
      }}
      onClick={() => {
        callbackSetContactIndex(id);
      }}
    >
      <Item.Meta
        avatar={<Avatar src={avatar}>{toShortName(name)}</Avatar>}
        title={name}
        description=""
      />

      {state === "done" ? (
        <Icon type="check" />
      ) : (state === "sending" ? (
        <Icon type="loading" />
      ) : null)}

      {/*{state === "done" ? (*/}
      {/*  <Icon type="check" />*/}
      {/*) : (state === "sending" ? (*/}
      {/*  <Icon type="loading" />*/}
      {/*) : (*/}
      {/*  <Popconfirm*/}
      {/*    placement="topLeft"*/}
      {/*    title="Are you sure to delete this contact?"*/}
      {/*    onCancel={callbackStopPropagation}*/}
      {/*    onConfirm={e => {*/}
      {/*      callbackRemoveContact(e, id);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Button*/}
      {/*      type="danger"*/}
      {/*      shape="circle"*/}
      {/*      icon="delete"*/}
      {/*      size="small"*/}
      {/*      onClick={callbackStopPropagation}*/}
      {/*    />*/}
      {/*  </Popconfirm>*/}
      {/*))}*/}
    </Item>
  );
};

const ContactsList = ({
  contacts,
  currentContactIndex,
  currentContactId,
  setCurrentContactIndex,
  removeContactFromList
}) => {
  const callbackSetContactIndex = useCallback(contactId => {
    if (currentContactId === contactId) {
      return;
    }
    const index = lodash.findIndex(contacts, { id: contactId });
    setCurrentContactIndex(index);
  }, [currentContactId, contacts, setCurrentContactIndex]);

  const callbackPrevContact = useCallback(() => {
    setCurrentContactIndex(currentContactIndex - 1);
  }, [currentContactIndex, setCurrentContactIndex]);

  const callbackNextContact = useCallback(() => {
    setCurrentContactIndex(currentContactIndex + 1);
  }, [currentContactIndex, setCurrentContactIndex]);

  const callbackRemoveContact = useCallback(contactId => {
    removeContactFromList(contactId);
  }, [removeContactFromList]);

  const totalContacts = contacts.length;

  return (
    <div className="inbox-conversations-list-wrapper">
      <div className="inbox-conversations-list-container" style={{ top: 0, bottom: 48 }}>
        <List
          dataSource={contacts}
          renderItem={item => (
            <Contact
              item={item}
              currentContactId={currentContactId}
              onSetContactIndex={callbackSetContactIndex}
              onRemoveContact={callbackRemoveContact}
            />
          )}
        />
      </div>

      <div className="inbox-conversations-list-footer" style={{ height: 48 }}>
        <Button.Group>
          <Button
            type="primary"
            disabled={totalContacts <= 1 || currentContactIndex === 0}
            onClick={callbackPrevContact}
          >
            <Icon type="left" />
            Back
          </Button>
          <Button
            type="primary"
            disabled={totalContacts <= 1 || currentContactIndex === (totalContacts - 1)}
            onClick={callbackNextContact}
          >
            Next
            <Icon type="right" />
          </Button>
        </Button.Group>
      </div>
    </div>
  );
};

Contact.propTypes = {
  item: objectOf(any).isRequired,
  currentContactId: string.isRequired,
  onSetContactIndex: func.isRequired,
  onRemoveContact: func.isRequired
};

ContactsList.propTypes = {
  contacts: arrayOf(any),
  currentContactIndex: number,
  currentContactId: string,
  setCurrentContactIndex: func.isRequired,
  removeContactFromList: func.isRequired
};

ContactsList.defaultProps = {
  contacts: [],
  currentContactIndex: null,
  currentContactId: null
};

export default ContactsList;
