import React, { useState, useCallback } from 'react';
import _get from 'lodash/get';
import { arrayOf, objectOf, any, func } from 'prop-types';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Tabs from 'antd/es/tabs';

import SelectContacts from '../SelectContacts';

const { TabPane } = Tabs;
const { Item } = Form;
const { TextArea } = Input;

const FormListOfContacts = ({ contacts, editData, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [tabKey, setTabKey] = useState('details');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [name, setName] = useState(_get(editData, 'name', ''));
  const [description, setDescription] = useState(_get(editData, 'description', ''));

  const validate = useCallback(() => {
    const newErrors = {};

    if (!name) {
      newErrors.name = "can't be blank";
      setTabKey('details');
    }

    if (selectedContacts.length === 0) {
      if (name) {
        setTabKey('contacts');
        newErrors.contacts = "can't be blank";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [name, selectedContacts]);

  const changeName = useCallback(({ target: { value } }) => {
    setName(value);
    setErrors({});
  }, []);

  const changeDescription = useCallback(({ target: { value } }) => {
    setDescription(value);
    setErrors({});
  }, []);

  const changeSelectedContacts = useCallback(ids => {
    setSelectedContacts(ids);
    setErrors({});
  }, []);

  const submit = useCallback(e => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    setLoading(true);
    onSubmit({
      name,
      description,
      members: selectedContacts,
      category: "contactsList",
      statuses: [],
      statusMembers: {},
    }, () => {
      setLoading(false);
    });
  }, [name, description, selectedContacts, onSubmit]);

  return (
    <Form onSubmit={submit}>
      <Tabs
        tabPosition="left"
        className="mb-15"
        activeKey={tabKey}
        onChange={setTabKey}
      >
        <TabPane key="details" tab="Details">
          <Item
            label="Name (Required)"
            validateStatus={errors.name ? "error" : null}
            help={errors.name}
          >
            <Input value={name} onChange={changeName} />
          </Item>
          <Item label="Description">
            <TextArea value={description} onChange={changeDescription} />
          </Item>
        </TabPane>
        <TabPane key="contacts" tab="Contacts">
          <SelectContacts
            contacts={contacts}
            selectedKeys={selectedContacts}
            onChange={changeSelectedContacts}
          />
        </TabPane>
      </Tabs>

      <Button
        block
        type="primary"
        htmlType="submit"
        loading={loading}
      >
        {editData ? "Update" : "Create"}
      </Button>
    </Form>
  );
};

FormListOfContacts.propTypes = {
  contacts: arrayOf(any).isRequired,
  editData: objectOf(any),
  onSubmit: func.isRequired,
};

FormListOfContacts.defaultProps = {
  editData: null,
};

export default FormListOfContacts;
