import React, {useCallback, useEffect, useState} from "react";
import { string } from "prop-types";
import message from "antd/es/message";

import Field from "./Field";
import EditPhoneWithConfirm from "../../../components/EditPhoneWithConfirm";
import CelPhoneMask from "../../../utils/CelPhoneMask/cel-phone.mask";
import { getRefRootDoc } from "../../../services/firestoreService";

const ForwardingNumber = ({ notifyId }) => {
  const [forwardingNumber, setForwardingNumber] = useState("");
  const [loadingForwardingNumber, setLoadingForwardingNumber] = useState(false);

  const done = useCallback((updatedPhone, callback) => {
    (async () => {
      // Update phone number.
      try {
        const firestoreOrg = getRefRootDoc();
        const orgDoc = await firestoreOrg.get();
        if (orgDoc.data().allowCallForwarding) {
          const firestoreTeamMember = firestoreOrg.collection("team_members").doc(notifyId);
          await firestoreTeamMember.set(
            { forwardingPhoneNumber: updatedPhone },
            { merge: true }
          );

          console.log(firestoreTeamMember);
          message.success("Forwarding number updated.");
          setForwardingNumber(updatedPhone);
        }
      } catch (e) {
        message.error("Problem setting number, please try again.");
      }
      callback && callback();
    })();
  }, [notifyId]);

  useEffect(() => {
    setLoadingForwardingNumber(true);
    (async () => {
      const firestoreOrg = getRefRootDoc();
      const teamMemberRef = await firestoreOrg
        .collection("team_members")
        .doc(notifyId);
      const teamMemberDoc = await teamMemberRef.get();
      let initialPhoneNumber = "";
      if (teamMemberDoc && teamMemberDoc.data() && teamMemberDoc.data().forwardingPhoneNumber) {
        initialPhoneNumber = teamMemberDoc.data().forwardingPhoneNumber.replace(/\D+/g, "");
      } else {
        const orgDoc = await firestoreOrg.get();
        if (orgDoc && orgDoc.data() && orgDoc.data().forwardingPhoneNumber) {
          initialPhoneNumber = orgDoc.data().forwardingPhoneNumber.replace(/\D+/g, "");
        }
      }

      setLoadingForwardingNumber(false);
      setForwardingNumber(new CelPhoneMask().getValue(initialPhoneNumber));
    })();
  }, []);

  return (
    <Field
      label="Forwarding Number"
      extra={(
        <EditPhoneWithConfirm
          loading={loadingForwardingNumber}
          phone={forwardingNumber}
          onDone={done}
        />
      )}
    />
  );
};

ForwardingNumber.propTypes = {
  notifyId: string.isRequired
};

export default ForwardingNumber;
