import { routerReducer as routing } from "react-router-redux";
import { combineReducers } from "redux";

import application from "./application";
import contacts from "./contacts";
import conversations from "./conversations";
import messages from "./messages";
import profile from "./profile";
import orgs from "./orgs";
import workflow from "./workflow";
import tasks from "./tasks";
import templates from "./templates";
import calendarEventsTemplate from "./calendar-events-template";
import lists from "./lists";
import { PERSISTS_REHYDRATE, CLEAR_PROFILE } from "../constants";

const appReducer = combineReducers({
  routing,
  application,
  contacts,
  conversations,
  messages,
  profile,
  orgs,
  workflow,
  tasks,
  templates,
  calendarEventsTemplate,
  lists
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_PROFILE) {
    state = undefined;
  } else if (action.type === PERSISTS_REHYDRATE && action.payload) {
    if (action.payload._persist) {
      delete action.payload._persist;
    }
    state = {
      ...state,
      ...action.payload
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
