import React, { useCallback } from "react";
import Menu from "antd/es/menu";
import Icon from "antd/es/icon";
import Modal from "antd/es/modal";
import { string } from "prop-types";
import { Link } from "react-router-dom";

import browserHistory from "../../browserHistory";
import configureStore from "../../store/configureStore";
import { signOut } from "../../actions/profile";

const Item = Menu.Item;
const SubMenu = Menu.SubMenu;

const clearingPathname = (p) => {
  const tmpPathname = p.replace(/\/+.*/, "");

  if (["contacts", "workflows"].includes(tmpPathname)) {
    return tmpPathname;
  }

  return p || "tasks";
};

const TopNavigation = ({ pathname }) => {
  const logout = useCallback(() => {
    Modal.confirm({
      title: "Are you sure you want to logout?",
      okText: "Yes",
      cancelText: "No",
      onOk: () =>
        new Promise(async (resolve) => {
          browserHistory.push("/sign_in");
          await configureStore.dispatch(signOut());
          resolve();
        }),
    });
  }, []);

  return (
    <Menu mode="horizontal" defaultSelectedKeys={[clearingPathname(pathname)]}>
      <Item key="tasks">
        <Link to="/">
          <Icon type="container" />
          <span>Tasks</span>
        </Link>
      </Item>
      <Item key="inbox">
        <Link to="/inbox">
          <Icon type="inbox" />
          <span>Inbox</span>
        </Link>
      </Item>
      <Item key="contacts">
        <Link to="/contacts">
          <Icon type="contacts" />
          <span>Contacts</span>
        </Link>
      </Item>

      <Item key="automations">
        <Link to="/automations">
          <span>Automations</span>
        </Link>
      </Item>
      <Item key="campaigns">
        <Link to="/campaigns">
          <span>Campaigns</span>
        </Link>
      </Item>
      <Item key="templates">
        <Link to="/templates">
          <span>Templates</span>
        </Link>
      </Item>
      <Item key="tags">
        <Link to="/tags">
          <span>Tags</span>
        </Link>
      </Item>
      <Item key="integrations">
        <Link to="/integrations">
          <span>Integrations</span>
        </Link>
      </Item>

      <SubMenu
        style={{ float: "right" }}
        title={
          <span className="submenu-title-wrapper">
            <Icon type="setting" />
          </span>
        }
      >
        <Item key="profile">
          <Link to="/profile">
            <Icon type="profile" />
            <span>Profile</span>
          </Link>
        </Item>
        <Item key="support">
          <Link to="/support">
            <Icon type="question-circle" />
            <span>Support</span>
          </Link>
        </Item>
        <li className="ant-menu-item" onClick={logout}>
          <Icon type="logout" />
          Logout
        </li>
      </SubMenu>
    </Menu>
  );
};

TopNavigation.propTypes = {
  pathname: string.isRequired,
};

export default TopNavigation;
