import CelPhoneMask from "../utils/CelPhoneMask/cel-phone.mask";

import configureStore from "../store/configureStore";
import {PHONE_MASK_SETTING} from "../constants";

export const toShortName = str => {
  if (!str || /^\W+/.test(str)) {
    return "#";
  }

  const tmp = str.split(" ");
  if (tmp.length === 1) {
    return tmp[0][0].toLocaleUpperCase();
  }

  return [tmp[0][0], tmp[1][0]].join("").toLocaleUpperCase();
};

export const formatPhone = phone => {
  if (!phone) {
    return "";
  }

  return new CelPhoneMask().getValue(phone, PHONE_MASK_SETTING);
};

export const validatePhoneNumber = phone =>
  (phone && new CelPhoneMask().validate(phone, PHONE_MASK_SETTING));

export const buildMessage = (tmpl, params) => {
  for (const [key, value] of Object.entries(params)) {
    tmpl = tmpl.replace(new RegExp(`{{${key}}}`, "g"), value);
  }

  return tmpl;
};

export const buildMessageByTemplate = (contactId, tmpl) => {
  const {
    contacts: { contactsById },
    profile: { fullName, phone }
  } = configureStore.getState();

  const contact = contactsById[contactId];
  const params = {
    first_name: contact ? contact.firstName : "",
    team_member_name: fullName,
    team_member_work_phone: formatPhone(phone)
  };

  for (const [key, value] of Object.entries(params)) {
    tmpl = tmpl.replace(new RegExp(`{{${key}}}`, "g"), value);
  }

  return tmpl;
};

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const formatTotalPercent = percentString =>
  percentString ? Number.parseFloat(percentString).toFixed(0) : 0;
