import React  from "react";
import Card from "antd/es/card";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Progress from "antd/es/progress";
import { string, number, func } from "prop-types";

import "./style.css";

const Text = Typography.Text;

const Task = ({ percent, status, name, statistic, onClick }) => {
  const isDone = percent >= 100;

  return (
    <Col span={16} offset={4} className="task-wrapper">
      <Card hoverable={!isDone} onClick={isDone ? null : onClick}>
        <div className="clearfix">
          <div style={{ float: 'left' }}>
            <Progress
              width={100}
              type="circle"
              percent={percent}
            />
          </div>

          <div className="task-info">
            <Text type="secondary">{status}</Text>
            <div className="task-info_name">{name}</div>
            <div>{statistic}</div>
          </div>
        </div>
      </Card>
    </Col>
  );
};

Task.propTypes = {
  percent: number.isRequired,
  name: string.isRequired,
  status: string.isRequired,
  statistic: string.isRequired,
  onClick: func.isRequired
};

export default Task;
