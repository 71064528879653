import React, {Fragment, useCallback, useEffect, useState} from "react";
import { string, bool } from "prop-types";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Spin from "antd/es/spin";
import Typography from "antd/es/typography/Typography";

import ChangePhone from "./ChangePhone";
import Confirmation from "./Confirmation";
import CelPhoneMask from "../../utils/CelPhoneMask/cel-phone.mask";

const { Text } = Typography;

const EditPhoneWithConfirm = ({ loading, phone, onDone }) => {
  const [visible, setVisible] = useState(false);
  const [currentPhone, setCurrentPhone] = useState("");
  const [newPhone, setNewPhone] = useState("");

  const toggle = useCallback(() => {
    setCurrentPhone(phone);
    setNewPhone("");
    setVisible(!visible);
  }, [phone, visible, setVisible]);

  const codeSent = useCallback(updatedPhone => {
    setNewPhone(updatedPhone);
  }, [setNewPhone]);

  const confirmed = useCallback(updatedPhone => {
    setCurrentPhone(updatedPhone);
    setNewPhone("");
    setVisible(false);
    onDone(updatedPhone);
  }, [setCurrentPhone, setNewPhone, toggle]);

  useEffect(() => {
    setCurrentPhone(phone);
  }, [phone]);

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <Fragment>
          <Text strong>
            {new CelPhoneMask().getValue(currentPhone)}
          </Text>
          <Button
            type="link"
            icon="edit"
            size="small"
            onClick={toggle}
          />
        </Fragment>
      )}

      <Modal
        title="Forwarding Phone Number"
        visible={visible}
        footer={null}
        onCancel={toggle}
      >
        {newPhone ? (
          <Confirmation
            phoneNumber={newPhone}
            onSuccess={confirmed}
          />
        ) : visible ? (
          <ChangePhone
            currentPhone={currentPhone}
            onDone={codeSent}
          />
        ) : null}
      </Modal>
    </>
  );
};

EditPhoneWithConfirm.propTypes = {
  loading: bool,
  phone: string,
};

EditPhoneWithConfirm.defaultProps = {
  loading: false,
  phone: ""
};

export default EditPhoneWithConfirm;
