import request from "../request";
import { accountSlug } from "../../utils/other";

const getOrgs = async () =>
  await request({
    url: `/org`,
    method: "GET"
  });

const getOrg = async () =>
  await request({
    url: `/org/${accountSlug()}`,
    method: "GET"
  });

const exportCSVorgs = async org =>
  await request({
    url: `/org/${org}/export_csv_v2/`,
    timeout: 180000,
    method: "GET"
  });

const addShopifyStore = async (shopName, cartAbandomentDuration, cartAbandomentMessage) =>
  await request({
    url: `/org/${accountSlug()}/shopify_add_store/`,
    method: "POST",
    data: {
      shop_name: shopName,
      cart_abandonment_duration: cartAbandomentDuration,
      cart_abandonment_message: cartAbandomentMessage
    }
  });

export { getOrgs, exportCSVorgs, getOrg, addShopifyStore };
