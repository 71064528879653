import React, { useMemo } from 'react';
import { Link } from "react-router-dom";
import List from 'antd/es/list';

const Item = ({ id, name, description, category, members, statuses }) => {
  const actions = useMemo(() => {
    const totalMembers = (members && members.length) || 0;
    const buf = [<span key="contacts">{totalMembers} Contacts</span>];

    if (category !== "contactsList") {
      const totalStatuses = statuses.filter(status => !status.isDefault).length;
      if (totalStatuses) {
        buf.push(<span key="statuses">{totalStatuses} Statuses</span>);
      }
    }

    return buf;
  }, [category, members]);

  return (
    <List.Item actions={actions}>
      <List.Item.Meta
        title={<Link to={`/contacts/list_${id}`}>{name}</Link>}
        description={description || null}
      />
    </List.Item>
  );
};

export default Item;
