import React from "react";
import Empty from "antd/es/empty";
import List from "antd/es/list";
import Button from "antd/es/button";
import { objectOf, any } from "prop-types";

import AppTitle from "../../../../components/Title";
import TopNavigation from "../../../../components/TopNavigation";
import Wrapper from "../../../../components/Wrapper";
import onboarding from "../../../../services/onboarding";
import { addShopifyStore } from "../../../../services/api/orgs";
import { updateWorkflow } from "../../../../services/api/workflow";
import { refreshData } from "../../../../utils/data";

import ShopifyStoreForm from "../../../../components/ShopifyStoreForm";

const Item = List.Item;
const Meta = Item.Meta;

class Shopify extends React.Component {
  state = {
    addShopModalVisible: false,
    existingShop: false,
    existingShopName: null,
    isEdit: false,
    isSubmitting: false,
    editMessage: null,
    editDuration: null,
    editShopName: null
  };

  matchingWorkflow = null;

  async componentDidMount() {
    const existingShop = await onboarding.getShopAlreadyExists;
    const existingShopName = await onboarding.getShopName;
    const addShopModalVisible = !existingShop && existingShopName !== null;
    this.setState({ addShopModalVisible, existingShop, existingShopName });
  }

  storeFormSubmit = async (message, duration) => {
    const { existingShopName, isEdit } = this.state;
    this.setState({ isSubmitting: true });
    try {
      if (existingShopName) {
        await addShopifyStore(existingShopName, duration, message);
        onboarding.clearShopifyStorage();
        this.setState({ existingShop: false, existingShopName: null });

        await refreshData();
        this.setState({ addShopModalVisible: false });
      } else if (isEdit && this.matchingWorkflow) {
        const data = {};
        if (message !== null) {
          data.template_text = message;
        }
        if (duration !== null) {
          data.send_delay_in_minutes = duration;
        }
        await updateWorkflow(this.matchingWorkflow.id, data);
        this.matchingWorkflow = null;

        await refreshData();
        this.setState({ addShopModalVisible: false });
        this.setState({ isEdit: false });
      }
      this.setState({ isSubmitting: false });
    } catch (error) {
      alert("Problem updating store settings");
      this.setState({ isSubmitting: false });
      this.setState({ addShopModalVisible: false });
    }
  };

  editStoreClick = store => {
    const { workflows } = this.props;
    const matchingWorkflows = workflows.filter(
      workflow => workflow.name === `Cart Abandonment for ${store.myshopify_name}`
    );
    this.matchingWorkflow = matchingWorkflows[0];

    this.setState({
      addShopModalVisible: true,
      isEdit: true,
      editMessage: this.matchingWorkflow.template_text,
      editDuration: this.matchingWorkflow.send_delay_in_minutes,
      editShopName: store.myshopify_name
    });
  };

  onFormCancel = () => {
    this.setState({ addShopModalVisible: false });
  };

  renderShopifyStore = store => (
    <Item>
      <Meta title={store.store_name} description={`Domain: ${store.name}`} />
      <Button type="primary" onClick={() => this.editStoreClick(store)}>
        Edit Settings
      </Button>
    </Item>
  );

  render() {
    const {
      addShopModalVisible,
      existingShopName,
      isEdit,
      isSubmitting,
      editMessage,
      editDuration,
      editShopName
    } = this.state;
    const {
      org: { shopify_stores }
    } = this.props;
    const shopifyStores = shopify_stores;
    return (
      <>
        <AppTitle title="Shopify Integration" />
        <TopNavigation />

        <Wrapper backUrl="/integrations" title="Shopify">
          {shopifyStores && shopifyStores.length > 0 ? (
            <List
              itemLayout="vertical"
              dataSource={shopifyStores}
              renderItem={this.renderShopifyStore}
            />
          ) : (
            <Empty />
          )}
        </Wrapper>

        <ShopifyStoreForm
          isVisible={addShopModalVisible}
          onSubmit={this.storeFormSubmit}
          shopName={existingShopName}
          isEdit={isEdit}
          isSubmitting={isSubmitting}
          editMessage={editMessage}
          editDuration={editDuration}
          editShopName={editShopName}
          onCancel={this.onFormCancel}
        />
      </>
    );
  }
}

Shopify.propTypes = {
  org: objectOf(any)
};

export default Shopify;
