import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AddContactsToList from './AddContactsToList';
import { updateMembers } from '../../actions/lists';

const mapStateToProps = ({ contacts: { contacts } }) => ({ contacts });

const mapDispatchToProps = dispatch => bindActionCreators({
  onUpdateMembers: updateMembers,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddContactsToList);
